import { catchAPIError, getFetch, postFetch, putFetch } from "../utils/misc/api.js";
import { successfulJwtValidation, sessionExpired } from "../utils/user/auth.js";

import * as General from "../../config/constants/General.js";
import * as Paths from "../../config/constants/Paths.js";
import { getLanguage } from "../../config/language/language.js";

/**
 * Check if the JWT is still valid - if not, then redirect to log in page.
 * Executed every time the page loads.
 *
 * Includes check whether user has logged in before.
 */
export const checkJWTvalidity = (props) => {
    const accessToken = props.user.accessToken;

    postFetch(Paths.API_AUTH_JWT_VALIDATION_PATH, {
        token: accessToken,
    })
        .then(function (response) {
            if (response.ok) {
                props.server.setIsConnectionEstablished(true);

                if (response.body.valid) {
                    props.ws.resetWebSocket(); // Reset connection to authenticate w/ user credentials.
                    successfulJwtValidation(props);
                    props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNED_IN });
                } else {
                    if (accessToken !== "") sessionExpired(props); // The JWT probably expired.
                }
            } else {
                catchAPIError(props, response);
            }
        })
        .finally(() => {
            setTimeout(function () {
                props.browser.setIsPageLoading(false);
            }, General.PAGE_LOADING_MS);
        })
        .catch((err) => catchAPIError(props, err));
};

export const verifyEmail = (props, hash) => {
    postFetch(`${Paths.API_VERIFY_EMAIL}/${hash}`, {})
        .then(function (response) {
            if (response.ok) {
                props.showSnackbar({ message: `Account verified!`, color: "success" });
                setTimeout(() => props.browser.navigate("/signin"), 2500);
            } else {
                props.showSnackbar({ message: `Error verifying: ${response?.body?.message ?? "No connection"}`, color: "error" });
                if (props.setFailed) props.setFailed(true);
            }
        })
        .catch((err) => {
            if (props.setFailed) props.setFailed(true);
            catchAPIError(props, err);
        });
};

export const verifyToken = (props, hash) => {
    getFetch(`${Paths.API_PASSWORD_RESET}/${hash}`)
        .then(function (response) {
            if (!response.ok || !response.body.valid) {
                props.setToken("");
            }
        })
        .catch((_) => {
            props.setToken("");
        });
};

export const requestPasswordReset = (props, body) => {
    postFetch(`${Paths.API_PASSWORD_RESET}`, body)
        .then(function (response) {
            if (response.ok) {
                props.showSnackbar({ message: response?.body?.message ?? `Email sent`, color: "success" });
                props.browser.navigate("/signin");
            } else {
                props.showSnackbar({ message: `Error requesting password reset: ${response?.body?.message ?? "No connection"}`, color: "error" });
            }
        })
        .catch((err) => catchAPIError(props, err));
};

export const resetPassword = (props, body, token) => {
    putFetch(`${Paths.API_PASSWORD_RESET}/${token}`, body)
        .then(function (response) {
            if (response.ok) {
                props.showSnackbar({ message: response?.body?.message ?? `Password reset`, color: "success" });
                props.browser.navigate("/signin");
            } else {
                props.showSnackbar({ message: `Error resetting password: ${response?.body?.message ?? "No connection"}`, color: "error" });
            }
        })
        .catch((err) => catchAPIError(props, err));
};

export const resendVerificationEmail = (props, username) => {
    putFetch(Paths.API_RESEND_EMAIL, { username: username })
        .then(function (response) {
            if (response.ok) {
                props.showSnackbar({ message: `Email sent`, color: "success" });
            } else {
                props.showSnackbar({ message: `Error sending email: ${response?.body?.message ?? "No connection"}`, color: "error" });
            }
        })
        .catch((err) => catchAPIError(props, err));
};

/**
 * Send sign in request to the server.
 * Receive a JWT and save it to localstorage.
 */
export const signIn = (props, body) => {
    props?.server?.setWaitingForResponse(true);

    postFetch(Paths.API_AUTH_LOGIN_PATH, body)
        .then(function (response) {
            props?.server?.setWaitingForResponse(false);
            props.server.setIsConnectionEstablished(true);

            if (response.ok) {
                props.ws.resetWebSocket(); // Reset connection to authenticate w/ user credentials.

                successfulJwtValidation(props, response.body.accessToken, response.body.refreshToken);
                props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNED_IN });

                // Redirect to the page the user was on before signing in.
                const currentPath = props.browser.location.pathname;
                props.browser.navigate(currentPath === "/signin" ? "/home" : currentPath);
            } else if (response?.status === 403) {
                props.setLastRequestUsername(body?.username);
                props.setEmailVerified(false);
                props.showSnackbar({ message: `Please verify your email!`, color: "warning" });
            } else {
                props.showSnackbar({ message: `Error signing in: ${response?.body?.message ?? "No connection"}`, color: "error" });
            }
        })
        .catch((err) => catchAPIError(props, err));
};

/**
 * Send a sign up request to the server.
 * If successful, redirect to the sign in page.
 */
export const signUp = (props, body) => {
    props?.server?.setWaitingForResponse(true);

    postFetch(Paths.API_AUTH_SIGNUP_PATH, body)
        .then(function (response) {
            props?.server?.setWaitingForResponse(false);
            props.server.setIsConnectionEstablished(true);

            if (response.ok) {
                props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNED_UP });

                props.browser.navigate("/signin");
            } else {
                props.showSnackbar({ message: `Error signing up: ${response?.body?.message ?? "No response"}`, color: "error" });
            }
        })
        .catch((err) => catchAPIError(props, err));
};
