import { queryReadNotifications, queryUnreadNotifications } from "../../api/notificationQueries";

/**
 * Handle adding notification to state.
 */
export const handleAddNotifications = (props, state, response) => {
    state.setData(response.elements);
};

/**
 * Handle deleting all notifications from read notifications.
 */
export const handleDeleteAll = (state) => {
    state.setPage(0);
    state.setRowCount(0);
    state.setData([]);
};

/**
 * Handle marking all notifications as read.
 */
export const handleMarkAllRead = (props, readState, unreadState) => {
    unreadState.setPage(0);
    unreadState.setRowCount(0);
    unreadState.setData([]);

    queryReadNotifications(props, { state: readState });
};

/**
 * Handle marking one notification as read.
 */
export const handleMarkOneRead = (props, readState, unreadState) => {
    if (unreadState.data.length === 1 && unreadState.page > 0) {
        // This has a useEffect that triggers the query
        unreadState.setPage((prev) => prev - 1);
    } else {
        queryUnreadNotifications(props, { state: unreadState });
    }

    queryReadNotifications(props, { state: readState });
};

/**
 * Handle deleting a notification.
 */
export const handleDeleteOne = (props, state) => {
    if (state.data.length === 1 && state.page > 0) {
        state.setPage((prev) => prev - 1);
    }

    queryReadNotifications(props, { state });
};

/**
 * Handle add info from websockets.
 */
export const handleWebsocketAddNotification = (props, payload) => {
    const unread = props.notifications.unread;

    // Increment the notification count and add the new notification to the top.
    if (unread.page === 0) {
        unread.setRowCount((prev) => prev + 1);

        if (payload) {
            unread.setData((prev) => [...prev, payload].slice(0, 3));
        }

        return;
    }

    queryUnreadNotifications(props, { state: unread });
};

/**
 * Handle update info from websockets.
 */
export const handleWebsocketUpdateNotifications = (props, payload) => {
    if (payload.status === "all") {
        handleMarkAllRead(props, props.notifications.read, props.notifications.unread);
        return;
    }

    handleMarkOneRead(props, props.notifications.read, props.notifications.unread);
};

/**
 * Handle delete info from websockets.
 */
export const handleWebsocketDeleteNotification = (props, payload) => {
    if (payload.status === "all") {
        handleDeleteAll(props.notifications.read);
        return;
    }

    handleDeleteOne(props, props.notifications.read);
};

/**
 * Play a sound for notifications.
 */
export const playNotification = (props) => {
    if (props.notifications.isEnabled) {
        props.audio.playNotificationSound();
    }
};

export const isNotificationUnread = (notification) => {
    return notification.status === "UNREAD";
};
