import { getJwtValueByKey } from "../utils/user/auth.js";

import * as General from "../../config/constants/General.js";

// Style the rows depending on class name.
export const defaultRowStyles = {};

export const defaultRowTheme = (props, params) => {
    return null;
};

// Initial state of the table.
// Data Grid will not react if you change the these values later on.
export const defaultInitialState = (options = {}) => {
    const { field = "id", sort = "desc" } = options.sorting || {};
    const { items = [], quickFilterValues = [] } = options.filter || {};

    return {
        sorting: {
            sortModel: [{ field, sort }],
        },
        filter: {
            filterModel: { items, quickFilterValues },
        },
        columns: {
            columnVisibilityModel: {},
        },
    };
};

/**
 * Whether some row can be selected.
 *
 * Disable rows which have already been subscribed to.
 */
export const defaultIsRowSelectable = (props, params) => {
    if (props.type === "combined-public") {
        const userId = getJwtValueByKey(props, "id");
        return params.row.ownerId !== userId;
    }

    return true;
};

/**
 * Controlled selection state.
 * NB! DataGrid uses ids.
 */
export const defaultRowSelection = (selected) => {
    return selected.map((row) => row.id);
};

/**
 * Controlled selection state.
 * Add the full object to the 'selected' state.
 */
export const defaultRowSetSelection = (setSelected, selected, rows) => {
    setSelected(rows.filter((item) => selected.includes(item.id)));
};

/**
 * Return all assets, no filtering.
 * Return: [ {id: 1, asset: TSLA, ...}, {id: 5, asset: BTCUSD} ]
 */
export const getAllAssets = (assetDict) => {
    const assetsArray = Object.values(assetDict);
    return assetsArray;
};

/**
 * Filter and return those alerts that match the selected asset in the URL.
 * Return: [ {id: 1, asset: TSLA, ...}, {id: 2, asset: TSLA} ]
 */
export const getAssetsByTicker = (assetDict, ticker) => {
    const assetsArray = getAllAssets(assetDict);
    return assetsArray.filter((row) => row.asset === ticker);
};
