// Write any export constants here.

// // // // //
// GENERAL
// Basic items about the application.
// // // // //
export const WEBSITE_NAME = "Investing Edge";
export const WEBSITE_SLOGAN = "Sharpen your investment strategy.";
export const COPYRIGHT = "Copyright © " + WEBSITE_NAME;
export const DISCLAIMER = "Powered by Twelve Data and TradingView.";

// TOOLBAR
export const TOOLBAR_TITLE = "Investing Edge";
export const TOOLBAR_DESCRIPTION = "Sharpen your investment strategy";

// DRAWERS
export const DRAWER_NOTIFICATION_TITLE = `Notifications`;

// // // // //
// APP SIDEBAR
// Text on the sidebar on the left side of the page.
// // // // //
export const SIDEBAR_TITLE = `Page selection`;

export const SIDEBAR_OVERVIEW = `Overview`;
export const SIDEBAR_ABOUT = `About`;
export const SIDEBAR_PORTFOLIOS = `Portfolios`;
export const SIDEBAR_USER_ASSETS = `User assets`;
export const SIDEBAR_ASSETS_STOCK = `Stocks`;
export const SIDEBAR_ASSETS_CRYPTO = `Crypto`;
export const SIDEBAR_ASSETS_FOREX = `Forex`;
export const SIDEBAR_ASSETS_OTHER = `Other`;

export const SIDEBAR_USER_ALERTS = `User alerts`;

export const SIDEBAR_USER_SIGNALS = `User signals`;
export const SIDEBAR_SIGNALS = `External signals`;
export const SIDEBAR_COMBINED_SIGNALS = `Combined signals`;
export const SIDEBAR_COMBINED_ALERTS = `Combined alerts`;
export const SIDEBAR_EXTERNAL_ALERTS = `External alerts`;

export const SIDEBAR_PUBLIC_ASSETS = `Community assets`;
export const SIDEBAR_PUBLIC_SIGNALS = `Community signals`;

export const SIDEBAR_USER_SETTINGS = `User settings`;
export const SIDEBAR_SETTINGS = `Settings`;
export const SIDEBAR_ACCOUNT = `Account`;

export const SIDEBAR_OTHER = `Other`;
export const SIDEBAR_ADMINISTRATION = `Administration`;
export const SIDEBAR_API_DOC = `API documentation`;
export const SIDEBAR_FEEDBACK = `Feedback & support`;
export const SIDEBAR_EASTER_EGG = `?? ? ??`;

// // // // //
// NOTIFICATION SIDEBAR
// Text on the sidebar on the right side of the page.
// // // // //
export const SIDEBAR_UNREAD = `Unread notifications`;
export const SIDEBAR_READ = `Read notifications`;

// // // // //
// BUTTONS
// Text on the buttons.
// // // // //
export const BUTTON_HOME = `Home`;
export const BUTTON_SIGNUP = `Sign up`;
export const BUTTON_SIGNIN = `Sign in`;

export const BUTTON_REQUEST_RESET_PASSWORD = `Request password reset`;
export const BUTTON_RESET_PASSWORD = `Reset password`;

export const BUTTON_RESEND_VERIFICATION = `Resend verification email`;

export const BUTTON_ADD_ASSET = `Request asset to be added`;

export const BUTTON_ADD_PORTFOLIO = `Create portfolio`;
export const BUTTON_UPDATE_PORTFOLIO = `Update portfolio`;

export const BUTTON_ADD_TRADE = `Create trade`;

export const BUTTON_ADD_SIGNAL = `Create signal`;
export const BUTTON_SHARE_SIGNAL = `Modify visibility`;
export const BUTTON_SUBSCRIBE_SIGNAL = `Subscribe`;
export const BUTTON_UPDATE_SIGNAL = `Update signal`;
export const BUTTON_DELETE_SIGNAL = `Delete signal`;

export const BUTTON_DELETE_ALERT = `Delete alert`;

export const BUTTON_ADD_COMBINED_SIGNAL = `Create combined signal`;
export const BUTTON_UPDATE_COMBINED_SIGNAL = `Update combined signal`;
export const BUTTON_DELETE_COMBINED_SIGNAL = `Delete combined signal`;
export const BUTTON_DELETE_COMBINED_ALERT = `Delete combined alert`;

export const BUTTON_UPDATE_ACCOUNT = `Update information`;
export const BUTTON_DELETE_ACCOUNT = `Delete account`;

export const BUTTON_SEND_FEEDBACK = `Send feedback`;
export const BUTTON_MORE_INFO = `More info`;

// // // // //
// SNACKBAR
// Small message pop-ups.
// // // // //

// Auth.
export const SNACKBAR_SUCCESS_SIGNED_IN = `Signed in!`;
export const SNACKBAR_SUCCESS_SIGNED_UP = `Account successfully created!`;

export const SNACKBAR_ERROR_SESSION_EXPIRED = `Session expired - please log back in!`;
export const SNACKBAR_ERROR_SERVER = `Error connecting to server!`;

// Assets.
export const SNACKBAR_ERROR_ASSET_INVALID = `Asset is invalid - check for any errors!`;

export const SNACKBAR_SUCCESS_ASSET_ADDED = `Asset requested!`;

// Portfolios.
export const SNACKBAR_ERROR_PORTFOLIO_INVALID = `Portfolio is invalid - check for any errors!`;
export const SNACKBAR_ERROR_TRADE_INVALID = `Trade entry is invalid - check for any errors!`;

export const SNACKBAR_SUCCESS_PORTFOLIO_CREATED = `Portfolio created!`;
export const SNACKBAR_SUCCESS_PORTFOLIO_UPDATED = `Portfolio updated!`;
export const SNACKBAR_SUCCESS_PORTFOLIO_ASSET_CREATED = `Asset(s) added to portfolio!`;
export const SNACKBAR_SUCCESS_PORTFOLIO_TRADE_CREATED = `Trade added to portfolio!`;

// Signals.
export const SNACKBAR_ERROR_SIGNAL_INVALID = `Signal is invalid - check for any errors!`;

export const SNACKBAR_SUCCESS_SIGNAL_ADDED = `Signal added!`;
export const SNACKBAR_ERROR_SIGNAL_ADDED = `Error adding signal, every signal has to be unique!`;

export const SNACKBAR_SUCCESS_SIGNAL_UPDATED = `Signal updated!`;
export const SNACKBAR_ERROR_SIGNAL_UPDATED = `Error updating signal!`;

export const SNACKBAR_SUCCESS_ALERTS_UPDATED = `Signal visibility updated!`;
export const SNACKBAR_ERROR_ALERTS_UPDATED = `Error updating signal(s)!`;

export const SNACKBAR_SUCCESS_SIGNAL_ENABLED = `Signal enabled!`;
export const SNACKBAR_ERROR_SIGNAL_ENABLED = `Error enabling signal!`;

export const SNACKBAR_SUCCESS_SIGNAL_DISABLED = `Signal disabled!`;
export const SNACKBAR_ERROR_SIGNAL_DISABLED = `Error disabling signal!`;

export const SNACKBAR_SUCCESS_SIGNALS_ENABLED = `Signals enabled!`;
export const SNACKBAR_ERROR_SIGNALS_ENABLED = `Error enabling signals!`;

export const SNACKBAR_SUCCESS_SIGNALS_DISABLED = `Signals disabled!`;
export const SNACKBAR_ERROR_SIGNALS_DISABLED = `Error disabling signals!`;

export const SNACKBAR_SUCCESS_SIGNAL_SUBSCRIBED = `Signal subscribed to!`;
export const SNACKBAR_ERROR_SIGNAL_SUBSCRIBED = `Error subscribing to signal!`;

export const SNACKBAR_SUCCESS_SIGNAL_UNSUBSCRIBED = `Signal unsubscribed from!`;
export const SNACKBAR_ERROR_SIGNAL_UNSUBSCRIBED = `Error unsubscribing from signal!`;

export const SNACKBAR_SUCCESS_SIGNAL_DELETED = `Signal deleted!`;
export const SNACKBAR_ERROR_SIGNAL_DELETED = `Error deleting signal, invalid password!`;

export const SNACKBAR_SUCCESS_COMBINED_SIGNAL_ADDED = `Combined signal added!`;
export const SNACKBAR_ERROR_COMBINED_SIGNAL_ADDED = `Error adding combined signal, every signal has to be unique!`;

export const SNACKBAR_SUCCESS_COMBINED_SIGNAL_UPDATED = `Combined signal updated!`;
export const SNACKBAR_ERROR_COMBINED_SIGNAL_UPDATED = `Error updating combined signal!`;

export const SNACKBAR_SUCCESS_COMBINED_SIGNAL_SUBSCRIBED = `Combined signal subscribed to!`;
export const SNACKBAR_ERROR_COMBINED_SIGNAL_SUBSCRIBED = `Error subscribing to combined signal!`;

export const SNACKBAR_SUCCESS_COMBINED_SIGNAL_UNSUBSCRIBED = `Combined signal unsubscribed from!`;
export const SNACKBAR_ERROR_COMBINED_SIGNAL_UNSUBSCRIBED = `Error unsubscribing from combined signal!`;

export const SNACKBAR_SUCCESS_COMBINED_SIGNAL_DELETED = `Combined signal deleted!`;
export const SNACKBAR_ERROR_COMBINED_SIGNAL_DELETED = `Error deleting combined signal, invalid password!`;

// Alerts.
export const SNACKBAR_SUCCESS_ALERT_DELETED = `Alert deleted!`;
export const SNACKBAR_ERROR_ALERT_DELETED = `Error deleting alert, invalid password!`;

export const SNACKBAR_SUCCESS_COMBINED_ALERT_DELETED = `Combined alert deleted!`;
export const SNACKBAR_ERROR_COMBINED_ALERT_DELETED = `Error deleting combined alert, invalid password!`;

// Users.
export const SNACKBAR_SUCCESS_ACCOUNT_UPDATED = `Account information updated!`;
export const SNACKBAR_ERROR_ACCOUNT_UPDATED = `Error updating info, possible username or email conflict!`;

export const SNACKBAR_SUCCESS_SECRET_UPDATED = `New secret created!`;

export const SNACKBAR_SUCCESS_ACCOUNT_DELETED = `Account deleted!`;
export const SNACKBAR_ERROR_ACCOUNT_DELETED = `Error deleting user, invalid password!`;

export const SNACKBAR_ERROR_PASSWORD_MATCH = `New passwords don't match!`;

// Misc.
export const SNACKBAR_SUCCESS_FEEDBACK_SENT = `Feedback sent, thank you!`;
export const SNACKBAR_ERROR_FEEDBACK_SENT = `Error sending info!`;

// // // // //
// FORMS
// Text on form-related elements, like input field descriptions.
// // // // //
export const FORM_CONFIRMATION_PASSWORD_ADMIN = `Admin password`;
export const FORM_CONFIRMATION_PASSWORD_USER = `Current password`;

export const FORM_DELETE_SUBTITLE = `Purge everything related to`;

export const FORM_DELETE_ALERT_ADMIN = `I understand that deleting another user's alert is final and cannot be reverted.`;
export const FORM_DELETE_ALERT_USER = `I understand that deleting a alert is final and cannot be reverted.`;

export const FORM_DELETE_SIGNAL_ADMIN = `I understand that deleting another user's signal will probably make them angry.`;
export const FORM_DELETE_SIGNAL_USER = `I understand that deleting a signal will remove all of my alerts related to this signal.`;

export const FORM_DELETE_COMBINED_ALERT_ADMIN = `I understand that deleting another user's combined alert will probably make them angry.`;
export const FORM_DELETE_COMBINED_ALERT_USER = `I understand that deleting a combined alert is final and cannot be reverted.`;

export const FORM_DELETE_COMBINED_SIGNAL_ADMIN = `I understand that deleting another user's combined signal will probably make them angry.`;
export const FORM_DELETE_COMBINED_SIGNAL_USER = `I understand that deleting a combined signal will remove all of my alerts related to this signal.`;

export const FORM_DELETE_ACCOUNT_ADMIN = `I understand that deleting another user's account is to be used as a last resort.`;
export const FORM_DELETE_ACCOUNT_USER = `I understand that deleting my account will remove all of my user info and alerts.`;

export const FORM_FEEDBACK_PLACEHOLDER = `Write feedback here...`;
export const FORM_FEEDBACK_SHARE_LABEL = `I wouldn't mind sharing the feedback publicly.`;

// Tooltips.
export const FORM_ASSET_TYPE_LABEL = "Asset type";
export const FORM_CURRENCY_LABEL = "Baseline currency, e.g. USD or EUR";
export const FORM_ASSET_LABEL = "Asset";

export const FORM_TICKER_LABEL = "Ticker, e.g. TSLA or BTCUSD";
export const FORM_NAME_LABEL = "Name, e.g. 'TSLA 4h Trend Buy'";
export const FORM_ALERT_FIRED_WITHIN_LABEL = "All alerts fired within name, e.g. 30m or 4h";
export const FORM_INDICATOR_LABEL = "Indicator, e.g. Trend";
export const FORM_DESCRIPTON_LABEL = "Description (optional)";
export const FORM_ORDER_TYPE_LABEL = "Order type";
export const FORM_STATUS_TYPE_LABEL = "Status type";
export const FORM_CODE_LABEL = "Subscription code";
export const FORM_CODE_DISABLED_LABEL = "Subscription code (disabled)";

export const FORM_SELECTED_LABEL = "Selected";
export const FORM_ORDER_TYPE_OPTIONAL_LABEL = "Order type (optional)";
export const FORM_ASSET_OPTIONAL_LABEL = "Ticker (optional)";
export const FORM_DESCRIPTION_LABEL = "Alert message";
export const FORM_WITHIN_TIMEFRAME_LABEL = "Within name, e.g. 30m or 4h";

// // // // //
// UNEXPECTED ERROR PAGE
// Text on the error page if some unexpected exception occurred.
// // // // //
export const UNEXPECTED_ERROR_TITLE = `Unexpected error (<code>)`;
export const UNEXPECTED_ERROR_SUBTITLE = `Something went wrong!

Make sure you are logged in, or are authorized to access this page.
Otherwise, please let us know of the error using the feedback form!`;

export const UNEXPECTED_ERROR_BUTTON_1 = `Feedback`;
export const UNEXPECTED_ERROR_BUTTON_2 = `Sign in`;

// // // // //
// 404 NOT FOUND PAGE
// Text on the error page if some unknown URL was queried.
// // // // //
export const NOT_FOUND_TITLE = `Unknown page`;
export const NOT_FOUND_SUBTITLE = `This page doesn't exist!
Please check the URL for any typos.`;

// // // // //
// WELCOME PAGE
// Intro text about our mission, etc.
// // // // //
export const WELCOME_TITLE = `Sharpen your investment strategy with ${WEBSITE_NAME}`;
export const WELCOME_SUBTITLE = `Analyze markets, manage investment portfolios, and use AI-powered investment tools.

Usable by both investors and traders, remove the noise and stop looking at the charts all day!`;
export const WELCOME_SUBTITLE_BUTTON = `Give yourself an investing edge, and navigate markets with confidence.`;

export const WELCOME_TEXT_1_TITLE = `Market analysis, simplified`;
export const WELCOME_TEXT_1_SUBTITLE = `Popular financial analysis platforms often provide superficial information, alongside an outdated user interface.
Our app aims to simplify this process and cater to users of all levels.

Market analysis encompasses real-time market data, news aggregation, and visualization of fundamental indicators, empowering users to make informed decisions.`;

export const WELCOME_TEXT_2_TITLE = `Level up your trading strategies`;
export const WELCOME_TEXT_2_SUBTITLE = `Our platform offers a wide range of tools to help you develop and test your trading strategies.

Create, modify, and subscribe to buy and sell signals to receive alerts for your trading strategies.

Combine multiple signals into one remove the noise and stop looking at the charts all day!`;

export const WELCOME_TEXT_3_TITLE = `Track your investments`;
export const WELCOME_TEXT_3_SUBTITLE = `Investment portfolio management offers a holistic view of your investments, including...
- visual representation of your portfolio,
- historical performance,
- viewing the portfolio value in different currencies.`; //  risk assessment, and diversification recommendations.

export const WELCOME_TEXT_4_TITLE = `AI-powered tools for financial freedom`;
export const WELCOME_TEXT_4_SUBTITLE = `Investment tools leverage artificial intelligence to analyze vast datasets, identify trends, and offer recommendations, enhancing your decision-making capabilities.

Our chatbot is designed to help you navigate the complex world of investing with ease.`;

export const WELCOME_TEXT_5_TITLE = `Welcome to the fiat-endgame.`;

export const WELCOME_TEXT_5_SUBTITLE = `"Currently to make it in the world, you got to have an IQ of 130 just to save money. You got to beat the bankers, the policy makers, and the incessant monetary degradation. The ability to save your money without financial literacy pushed to the max is impossible."

- Nolan Bauerle`;

export const WELCOME_TEXT_6_TITLE = `That is why this platform was created.`;
export const WELCOME_TEXT_6_SUBTITLE = `We address common shortcomings of existing solutions by bridging the gap between specialized and overly broad platforms.

Gain a comprehensive understanding of market trends, manage your portfolios effectively, and access powerful investment tools, all in one platform.`;

export const WELCOME_SIGN_UP = `Navigate markets with confidence!`;

// // // // //
// HOME PAGE
// Initial configuration and descriptions.
// // // // //
export const HOME_OVERVIEW_TITLE = `Overview`;
export const HOME_OVERVIEW_TEXT_1 = `Welcome!

To customize Your experience, please sign in or create an account.

Public statistics:
External signals: 25
Combined signals: 4
External alerts: 94
Combined alerts: 10
`; // TODO /status
export const HOME_USER_OVERVIEW_TEXT_1 = `Welcome back, <username>!

You have <unreadNotificationNr> unread notifications.

Your statistics:
External signals: 4
Combined signals: 2
External alerts: 15
Combined alerts: 3
`; // TODO /status

export const HOME_OVERVIEW_NO_DATA_TEXT_1 = `No data available!`;

// // // // //
// DASHBOARD PAGE
// Text about the 4 charts
// // // // //
export const DASHBOARD_TEXT_1 = `Below are 4 charts, created using the Echarts library.`;

// // // // //
// AUTH PAGES
// Text used in sign up or sign in pages.
// // // // //
export const FORGOT_PASSWORD = `Forgot password?`;
export const NO_ACCOUNT = `Don't have an account?`;
export const ALREADY_HAVE_ACCOUNT = `Already have an account?`;
export const LOGGED_OUT = `You have been logged out.`;
export const NEXT_TIME = `Until next time!`;
export const LOG_BACK_IN = `You can log back in anytime.`;

export const VERIFY_EMAIL_TITLE = `Verifying email`;
export const VERIFY_EMAIL_SUBTITLE = `Please wait while we verify your email...`;
export const VERIFY_EMAIL_FAILED = `
Email verification failed!

Make sure the verification link has not expired.`;

export const PASSWORD_RESET_SUBTITLE = `Forgot your password?
No problem!

Use the form below to reset your password.`;

// // // // //
// ABOUT PAGE
// In-depth text about our mission, etc.
// // // // //
export const ABOUT_TITLE = `About`;
export const ABOUT_SUBTITLE = `${WEBSITE_NAME} - ${WEBSITE_SLOGAN}`;

export const ABOUT_TEXT_1 = `We aim to provide a comprehensive platform for users to learn, analyze, and act on market trends, all in one place.

Browse market news, analyze fundamental metrics and company profiles, read educational materials or configure buy / sell alerts to suplement your trading strategies.

View the features below for more information.`;

export const ABOUT_TEXT_2 = `Sometimes, the markets can be unpredictable.
That's why we created this website - to help you learn, improve, and be prepared for anything.`;

// // // // //
// ACCOUNT PAGE
// User info, how-tos, etc.
// // // // //
const TRADINGVIEW_MESSAGE_TEMPLATE = `
    "open": "{{open}}",
    "high": "{{high}}",
    "low": "{{low}}",
    "close": "{{close}}",
    "volume": "{{volume}}",

    "interval": "{{interval}}",
    "timenow": "{{timenow}}",
    "time": "{{time}}",

    "asset": "{{ticker}}",
    "exchange": "{{exchange}}",

    "currency": " {{syminfo.currency}}",
    "basecurrency": " {{syminfo.basecurrency}}"`;

export const TRADINGVIEW_MESSAGE_OPTIONS = `{
    "signalId": <signalId>,
    "name": "<name>",
    "description": "<description>",
    ${TRADINGVIEW_MESSAGE_TEMPLATE}
}`;

export const ACCOUNT_SUBTITLE = `Welcome, <username>!
View your account information here.`;

export const ACCOUNT_TEXT_1 = `Username: <username>
User ID: <id>
Privilege: <role>
Email: <email>
Secret: <secret>

Account data can be modifying via the settings page.`;

export const ACCOUNT_TEXT_2 = `You are now ready to use this platform!
Click on the buttons below for more information, and use the sidebar on the left to navigate the site.`;

// // // // //
// ADMIN PAGE
// Administrative descriptions, settings, forms, etc.
// // // // //
export const ADMIN_TITLE = `Administration`;
export const ADMIN_SUBTITLE = `User data and other admin-related settings can be modified on this page.

Clicking on a user's row will open up the modification page for that user.
NB! Be very cautious using this page, as it can irreversably change some user's accessibility to the site.`;

export const USER_ROLES_TITLE = `Modify <username>'s role`;
export const USER_ROLES_SUBTITLE = `Add new privileges to <username>'s account.`;

export const USER_DELETE_TITLE_ADMIN = `Delete <username>'s account`;
export const USER_DELETE_SUBTITLE_ADMIN = `Purge everything related to <username>.`;

export const USER_DELETE_MANY_TITLE_ADMIN = `Delete accounts`;
export const USER_DELETE_MANY_SUBTITLE_ADMIN = `Purge everything related to <length> accounts.`;

// // // // //
// SETTINGS PAGE
// Account settings and other page variables.
// // // // //
export const SETTINGS_TITLE = `Settings`;
export const SETTINGS_SUBTITLE = `Modify your account information and notification settings.`;

export const NOTIFICATION_VOLUME_TITLE = `Notification volume`;
export const NOTIFICATION_VOLUME_SUBTITLE = `Enable, disable, set the volume, and more`;

export const NOTIFICATION_SELECT_TITLE = `Notification sound`;
export const NOTIFICATION_SELECT_SUBTITLE = `Choose which notification sound to use`;

export const USER_UPDATE_TITLE = `Edit account information`;
export const USER_UPDATE_SUBTITLE = `Leave any fields empty to keep their current data.`;

export const USER_SECRET_TITLE = `Regenerate secret`;
export const USER_SECRET_SUBTITLE = `If you believe your secret key has been compromised, you can regenerate it here.`;

export const USER_DELETE_TITLE = `Delete your account`;
export const USER_DELETE_SUBTITLE = `Purge everything related to your user.`;

// // // // //
// SIGNAL AND ALERT COMPONENTS
// Small components consisting of a title, subtitle, and a form of some kind.
// // // // //

// ALERT DELETE COMPONENT
export const ALERT_DELETE_TITLE = `Delete alert`;
export const ALERT_DELETE_SUBTITLE = `Purge everything related to <name> (<timestamp>).`;

export const ALERT_DELETE_MANY_TITLE = `Delete alerts`;
export const ALERT_DELETE_MANY_SUBTITLE = `Purge everything related to <length> alerts.`;

// SIGNAL DELETE COMPONENT
export const SIGNAL_DELETE_TITLE = `Delete signal`;
export const SIGNAL_DELETE_SUBTITLE = `Purge everything related to <name> (<lastAlert>).`;

export const SIGNAL_DELETE_MANY_TITLE = `Delete signals`;
export const SIGNAL_DELETE_MANY_SUBTITLE = `Purge everything related to <length> signals.`;

// COMBINED SIGNAL ADD COMPONENT
export const COMBINED_SIGNAL_ADD_TITLE = `Create new combined signal`;
export const COMBINED_SIGNAL_ADD_SUBTITLE = `Using <amount> signals`;

// COMBINED SIGNAL ADD COMPONENT
export const COMBINED_SIGNAL_DELETE_TITLE = `Delete combined signal`;
export const COMBINED_SIGNAL_DELETE_SUBTITLE = `Purge everything related to <name> (<description>).`;

export const COMBINED_SIGNAL_DELETE_MANY_TITLE = `Delete combined signals`;
export const COMBINED_SIGNAL_DELETE_MANY_SUBTITLE = `Purge everything related to <length> combined signals.`;

// COMBINED ALERT ADD COMPONENT
export const COMBINED_ALERT_DELETE_TITLE = `Delete combined alert`;
export const COMBINED_ALERT_DELETE_SUBTITLE = `Purge everything related to <name> (<description>).`;

export const COMBINED_ALERT_DELETE_MANY_TITLE = `Delete combined alerts`;
export const COMBINED_ALERT_DELETE_MANY_SUBTITLE = `Purge everything related to <length> combined alerts.`;

// SIGNAL UPDATE COMPONENT
export const SIGNAL_UPDATE_TITLE = `Update signal`;
export const SIGNAL_UPDATE_SUBTITLE = `Update the selected signal's fields. Keep in mind, if the signal is already public, then only few fields are allowed to be updated!`;

// SIGNAL SHARE COMPONENT
export const SIGNAL_SHARE_TITLE = `Share signal`;
export const SIGNAL_SHARE_SUBTITLE = `Share the selected signal with others. Subscribed users can then receive the same alerts!`;

export const SIGNAL_SHARE_MANY_TITLE = `Share signals`;
export const SIGNAL_SHARE_MANY_SUBTITLE = `Share the <length> signals with others. Subscribed users can then receive the same alerts!`;

// COMBINED SIGNAL SHARE COMPONENT
export const COMBINED_SIGNAL_SHARE_TITLE = `Share combined signal`;
export const COMBINED_SIGNAL_SHARE_SUBTITLE = `Share the selected combined signal with others. Subscribed users can then receive the same combined alerts!`;

// SIGNAL UPDATE COMPONENT
export const COMBINED_SIGNAL_UPDATE_TITLE = `Update combined signal`;
export const COMBINED_SIGNAL_UPDATE_SUBTITLE = `Update the selected combined signal's fields. Keep in mind, if the signal is already public, then only few fields are allowed to be updated!`;

export const COMMUNITY_SIGNAL_SUBSCRIBE_TITLE = `Subscribe`;
export const COMMUNITY_SIGNAL_SUBSCRIBE_SUBTITLE = `Subscribe to <name> (<description>)`;

// // // // //
// ASSET PAGE
// Page for a specific asset, includes alerts table, news, etc.
// // // // //
export const NO_EXTERNAL_NEWS_FOUND = `Error querying news!`;
export const NO_EXTERNAL_DAILY_PRICE_HISTORY_FOUND = `Error querying daily price history!`;

// // // // //
// ASSETS PAGE
// Info about assets available.
// // // // //
export const ASSETS_TITLE = `Assets`;
export const ASSETS_SUBTITLE = `View and modify assets available on the platform.
Use the form below to request a new asset to be added.`;

export const ASSETS_ADD_TITLE = `Activate new asset`;
export const ASSETS_ADD_SUBTITLE = `Request a new asset to be added to the platform.
Please be advised, asset activation might take up to 24h, as we have to query and analyze the necessary data.`;

export const NO_ASSETS_FOUND = `No assets found!

Use the button aboce to request a new asset to be added.`;

// // // // //
// SCREENER PAGE
// Info about assets available.
// // // // //
export const SCREENER_TITLE = `Market screener`;
export const SCREENER_SUBTITLE = `Use the table below to filter and search for new interesting assets.
The screener will return a list of assets that match the criteria.

Then, use the 'Assets' page to request those selected assets to be added to the platform.`;

export const SCREENER_MARKET_SELECT_TITLE = `Select market`;
export const SCREENER_MARKET_SELECT_SUBTITLE = `Choose whether to filter by stocks (region), crypto or forex.`;

// // // // //
// PORTFOLIOS PAGE
// Info about created portfolios.
// // // // //
export const PORTFOLIOS_TITLE = `Portfolios`;
export const PORTFOLIOS_SUBTITLE = `View and modify portfolios available on the platform.
Portfolios help you keep track of your assets and their performance.`;

export const PORTFOLIOS_ADD_TITLE = `Add new portfolio`;
export const PORTFOLIOS_ADD_SUBTITLE = `Create a new portfolio, which can be used to track your assets and their performance.`;

export const NO_PORTFOLIOS_FOUND = `No portfolios found!

Use the button above to create a new portfolio.`;

// // // // //
// PORTFOLIO PAGE
// Info about a specific portfolio.
// // // // //
export const PORTFOLIO_TITLE = `Portfolio`;
export const PORTFOLIO_SUBTITLE = `View and modify the selected portfolio.
Use the form below to edit fields.`;

export const PORTFOLIO_ADD_TRANSACTION_TITLE = `Add new transaction`;
export const PORTFOLIO_ADD_TRANSACTION_SUBTITLE = `Create a new entry to reflect a buy or sell event.`;

export const NO_PORTFOLIO_FOUND = `Portfolio not found!

Make sure you have access to view this portfolio.`;

export const NO_TRADES_FOUND = `No trades found!

Add a new trade using the button above.`;

// // // // //
// SIGNAL PAGES
// Info about external and combined signals.
// // // // //
export const SIGNALS_TITLE = `Signals`;
export const SIGNALS_SUBTITLE = `Signals are the basis of any real-time alerts coming from various sources.
You can create, combine, or subscribe to signals to receive alerts for your trading strategies.`;

export const SIGNALS_TEXT_1_TITLE = `External signals`;
export const SIGNALS_TEXT_1_SUBTITLE = `If you are an active user of TradingView, you can send your buy and sell indicator alerts to this application for further use. 

There are two ways to achieve this:
1. Create and receive a signal and its alerts via TradingView.
2. Create a signal using this app, and receive alerts via TradingView.

Click the buttons below to see tutorials on how to configure TradingView to send alerts to this application.`;

export const SIGNALS_TEXT_2_TITLE = `Combined signals`;
export const SIGNALS_TEXT_2_SUBTITLE = `Combine multiple signals in order to create a new trading strategy with less noise.

Once configured, a combined alert will be fired after the conditions of all signals are met. You can use your own signals, or community signals you've subscribed to!

Click the button below to see a tutorial on how to combine signals.`;

export const SIGNALS_TEXT_3_TITLE = `Community signals`;
export const SIGNALS_TEXT_3_SUBTITLE = `No interest in configuring your own buy or sell signals?
No problem!

Community signals are created and shared by our very own community.
Subscribing to a community signal allows you to receive the same alerts as the original creator of the signal.

Click the buttons below to learn how to share and subscribe.`;

export const EXTERNAL_SIGNALS_TITLE = `External signals`;
export const EXTERNAL_SIGNALS_SUBTITLE = `External signals are the basis of any actual real-time alerts coming from a 3rd-party platform, like TradingView.
These signal alerts can be modified on this page.

Selecting a row in the table will open up the modification form for that signal.`;
export const EXTERNAL_SIGNAL_ADD_TITLE = `Create new external signal`;
export const EXTERNAL_SIGNAL_ADD_SUBTITLE = `Use this form if you prefer to create a new signal via the application.`;

export const COMBINED_SIGNAL_TITLE = `Combined signals`;
export const COMBINED_SIGNALS_SUBTITLE = `Combined signals allow you to combine multiple external ones and build a completely new signal.

Each combined signal can be extensively configured.
A combined alert will be created every time the combined signal conditions are fulfilled.

Select a couple of signals below to create a new combined one!`;

export const COMMUNITY_SIGNALS_TITLE = `Community signals`;
export const COMMUNITY_SIGNALS_SUBTITLE = `Community signals are external or combined signals created and published by the community.
All subscribers will be receiving the same alerts as the original creator of the signal.

Have you found a great strategy, and would like to commend the creator?
Show your appreciation by hitting the like button!`;

export const COMBINED_SUBSCRIBED_TABLE_TITLE = `Combined subscriptions`;
export const EXTERNAL_SUBSCRIBED_TABLE_TITLE = `External subscriptions`;

export const NO_SIGNALS_FOUND = `No signals configured!

To amplify your trading experience:
* Subscribe to a few community signals,
* Manually create some signals, 
* Configure TradingView to send alerts to this platform.
`;

export const NO_SUBSCRIBABLE_SIGNALS_FOUND = `No signals available!

Seems like there are no existing public signals.
You could be the first to publish one of your signals to the whole trading community!
That way, others can subscribe to your signals and receive the same alerts as you do.
`;

export const NO_SUBSCRIBED_SIGNALS_FOUND = `No subscribed signals found!

Seems like you haven't subscribed to any public signals.
Take a look at the community signals page to see what the trading community has to offer.
`;

// // // // //
// ALERT PAGES
// Info about external and combined signals.
// // // // //
export const ALERTS_TITLE = `Alerts`;
export const ALERTS_SUBTITLE = `Alerts are the result of buy or sell signals firing.
View alerts, including alerts you've subscribed to, below.`;

export const ALERTS_TEXT_1_TITLE = `Receiving alerts`;
export const ALERTS_TEXT_1_SUBTITLE = `In order to receive alerts, you have to either...

1. Configure TradingView to send alerts to this application,
2. Combine multiple signals into one, creating a new comprehensive signal,
3. Subscribe to community signals.

Click the button below to view tutorials how.`;

export const EXTERNAL_ALERTS_TITLE = `External alerts`;
export const EXTERNAL_ALERTS_SUBTITLE = `External alerts mimic real-time alerts coming from a 3rd-party platform, like TradingView.

Each alert is related to a signal, which can be modified on the signals page.`;

export const COMBINED_ALERTS_TITLE = `Combined alerts`;
export const COMBINED_ALERTS_SUBTITLE = `Combined alerts are built on top of external signals by combining 2 or more.

Once configured, a combined alert will be created once all external signals have fired an alert within the specified name.`;

export const NO_ALERTS_FOUND = `No alerts found!

In order to receive alerts, make sure to:
* Subscribe to a few community signals to start receiving their alerts,
* Configure TradingView to send alerts to this platform.
`;

export const NO_SUBSCRIBED_ALERTS_FOUND = `No subscribed alerts found!

Seems like you haven't subscribed to any public signals.
Take a look at the community signals page to see what the trading community has to offer.
`;

// // // // //
// CHATBOT PAGE
// Full-page chatbot.
// // // // //
export const CHATBOT_TITLE = `Chatbot`;
export const CHATBOT_SUBTITLE = `Our chatbot is designed to help you navigate the complex world of investing with ease.

Ask questions, get answers, and learn more about investing.`;

// // // // //
// RESOURCES PAGE
// Info about resources available.
// // // // //
export const RESOURCES_TITLE = `Resources`;
export const RESOURCES_SUBTITLE = `Useful resources or models to help you navigate the world of investing.`;

// // // // //
// POSTS PAGE
// Info about the forum posts.
// // // // //
export const POSTS_TITLE = `Posts`;
export const POSTS_SUBTITLE = `View and create posts about investing and other financial topics.
Follow your favorite authors and stay up-to-date on the latest news!`;

export const POSTS_VIEW_TITLE = `View post`;
export const POSTS_VIEW_SUBTITLE = `View the selected post.`;

export const POSTS_ADD_TITLE = `Create new post`;
export const POSTS_ADD_SUBTITLE = `Use the form below to create a new post.
The form supports markdown, so you can format your post, including images, as you like.`;

export const POSTS_EDIT_TITLE = `Edit post`;
export const POSTS_EDIT_SUBTITLE = `Use the form below to edit a post.
The banner image can be changed by uploading a new image.`;

// // // // //
// FEEDBACK PAGE
// Info about the feedback form and donations.
// // // // //
export const FEEDBACK_TITLE = `Feedback & review`;
export const FEEDBACK_SUBTITLE = `Find any bugs or are any features still missing?
We value your feedback!

Write any feedback you have about the application below.`;

export const FEEDBACK_SUPPORT_US_TITLE = `Support us`;
export const FEEDBACK_SUPPORT_US_SUBTITLE = `Did you find any value from the application?
If so, we appreciate any donations! ;)

BTC: bc1q8cyvffz0y7jhwjdynt9vh4qm5cymkf7c7kgm3j
SOL: 6r6tKpL27CyUj8sF6o98jm2TX48Ak3jRCuEVZB7HHnwM
`;

// // // // //
// TERMS AND CONDITIONS PAGE
// Legal info.
// // // // //
export const TERMS_CONDITIONS_TITLE = `Terms & conditions`;
export const TERMS_CONDITIONS_SUBTITLE = `Legal information about this application.`;
