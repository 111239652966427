import React from "react";

import { Box, Divider, Stack, Typography } from "@mui/material";

import CustomButton from "../input/CustomButton";

import { isEmpty } from "../../helper-functions/utils/validation/object";

/**
 *  Stacked header featuring:
 * - title
 * - subtitle
 * - icon(s), incl. tooltip and click event
 * - button(s), incl. icon, title and click event
 *
 * Props format:
 * - obj: [{icon: </>, title: "", onClick: ()=>{} }]
 */
export default function StackedHeader(props) {
    const { title, subtitle } = props;
    const { icons = [], buttons = [] } = props;

    const renderIcon = (obj, index) => {
        return <CustomButton {...props} key={index} icon={obj?.icon} onClick={obj?.onClick} tooltip={obj?.tooltip} disabled={obj?.disabled} />;
    };

    const renderButton = (obj, index) => {
        return (
            <CustomButton {...props} key={index} startIcon={obj?.icon} onClick={obj?.onClick} title={obj?.title} disabled={obj?.disabled} variant={obj?.variant} size={obj?.size} />
        );
    };

    return (
        <Box mb={1}>
            <Stack m={0.5} mb={0} justifyContent="space-between" alignItems="center" direction="row">
                <Stack direction="row" m={0} p={0}>
                    <Typography mr={2} variant="h5">
                        {title}
                    </Typography>

                    {!isEmpty(icons) && <Divider sx={{ m: 0, mr: 2 }} orientation="vertical" variant="middle" flexItem />}

                    {icons.map((obj, index) => renderIcon(obj, index))}
                </Stack>

                <Stack direction="row" m={0} p={0}>
                    {buttons.map((obj, index) => renderButton(obj, index))}
                </Stack>
            </Stack>

            {subtitle && (
                <Stack m={0} ml={0.5} justifyContent="space-between" alignItems="center" direction="row">
                    <Typography className="sm-font italic pre-line" mr={2}>
                        {subtitle}
                    </Typography>
                </Stack>
            )}
        </Box>
    );
}
