import * as React from "react";
import { useParams } from "react-router";

import { Container, Grid, Tab, Tabs } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";

import Overview from "./components/Overview.js";
import Statistics from "./components/Statistics.js";
import Financials from "./components/Financials.js";
import TechnicalAnalysis from "./components/TechnicalAnalysis.js";
import FundamentalAnalysis from "./components/FundamentalAnalysis.js";

import CustomButton from "../../../helper-components/input/CustomButton";

import { isAdmin } from "../../../helper-functions/utils/misc/utils";
import { getAssetByKey } from "../../../helper-functions/utils/asset/asset.js";
import { isEmpty } from "../../../helper-functions/utils/validation/object.js";
import { queryAsset, queryHistoricalStatistics, queryRecentStatistics, resetAssetData } from "../../../helper-functions/api/assetQueries.js";
import { queryBalanceStatements, queryCashFlowStatements, queryIncomeStatements, queryProfile } from "../../../helper-functions/api/assetQueries.js";

import { ASSET_STOCK, STATUS_LOADING } from "../../../config/constants/Enums.js";

/**
 * A page for some asset.
 *
 * Includes:
 * recent price action
 * any relevant buy/sell alerts (owner + subscribed, external + combined)
 * latest news
 * asset profile / fundamentals
 */
export default function Asset(props) {
    const { id } = useParams(); // Get the URL parameters from react-router.

    const [tabValue, setTabValue] = React.useState(0);

    const [asset, setAsset] = React.useState({}); // Set default value if asset is not available yet.
    const [assetQuery, setAssetQuery] = React.useState(false);
    const [fundamentalsQuery, setFundamentalsQuery] = React.useState(false);

    // Statistics.
    const [recentStatistics, setRecentStatistics] = React.useState({});
    const [historicalStatistics, setHistoricalStatistics] = React.useState({});

    // Profile.
    const [profile, setProfile] = React.useState({});

    // Statements.
    const [cashFlow, setCashFlow] = React.useState({});
    const [balanceSheet, setBalanceSheet] = React.useState({});
    const [income, setIncome] = React.useState({});

    const assetsData = props?.assets?.data;

    const { status, assetType } = asset;

    const isStock = assetType === ASSET_STOCK;
    const isLoading = status === STATUS_LOADING;
    const isReady = !isEmpty(asset);

    // Set the asset, and potentially query it if it's not available.
    React.useEffect(() => {
        const possibleAsset = getAssetByKey(props, "id", parseInt(id));

        if (!isEmpty(possibleAsset)) {
            setAssetQuery(true);
            setAsset(possibleAsset);
        }

        if (isEmpty(possibleAsset) && !assetQuery) {
            setAssetQuery(true);
            queryAsset(props, { id });
        }
    }, [assetsData, asset]);

    // Query fundamentals when the asset is available.
    // Don't show error messages (e.g. when dealing with crypto).
    React.useEffect(() => {
        if (!isReady) return;
        if (fundamentalsQuery) return;
        setFundamentalsQuery(true);

        queryRecentStatistics(props, { setState: setRecentStatistics, id });
        queryHistoricalStatistics(props, { setState: setHistoricalStatistics, id });

        queryProfile(props, { setState: setProfile, id, showMessage: false });

        queryBalanceStatements(props, { setState: setBalanceSheet, id, showMessage: false });
        queryIncomeStatements(props, { setState: setIncome, id, showMessage: false });
        queryCashFlowStatements(props, { setState: setCashFlow, id, showMessage: false });

        // Clear the fundamentals when the component unmounts.
        return () => {
            setRecentStatistics({});
            setHistoricalStatistics({});

            setProfile({});

            setBalanceSheet({});
            setIncome({});
            setCashFlow({});

            console.log("Cleared asset fundamentals.");
        };
    }, [isReady, fundamentalsQuery, props.browser.location.pathname]);

    React.useEffect(() => {
        if (!isReady) return;

        if (isLoading) {
            props.showSnackbar({ message: "Some data might be missing: the asset is still loading.", color: "warning" });
        }
    }, [tabValue]);

    // Query asset every 120 seconds.
    React.useEffect(() => {
        if (!isReady) return;

        const interval = setInterval(() => {
            queryAsset(props, { id });
        }, 120000);

        return () => {
            clearInterval(interval);
        };
    }, [isReady]);

    const handleTabChange = (e, newValue) => setTabValue(newValue);

    const showResetDialog = () => {
        props.dialog.enqueueDialog({
            title: "Reset asset",
            message: `Are you sure you want to reset the asset? 
            This will delete all the fundamental and time-series data associated with it.

            The asset has to be re-activated to be used again.`,
            actionTitle: "Reset",
            actionColor: "error",
            callback: (confirm) => handleResetAsset(confirm),
        });
    };

    const handleResetAsset = (confirm) => {
        if (!confirm) return;

        resetAssetData(props, id);
    };

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Tabs
                        className="tabs"
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        scrollButtons={true}
                        allowScrollButtonsMobile
                        centered>
                        <Tab icon={<InfoIcon />} label="Overview" />
                        <Tab icon={<DescriptionIcon />} label="Statistics" disabled={!isStock} />
                        <Tab icon={<SpaceDashboardIcon />} label="Financials" disabled={!isStock} />
                        <Tab icon={<LeaderboardIcon />} label="Fundamental analysis" disabled={!isStock} />
                        <Tab icon={<QueryStatsIcon />} label="Technical analysis" />
                    </Tabs>
                </Grid>

                {tabValue === 0 && <Overview {...props} asset={asset} profile={profile} />}
                {tabValue === 1 && <Statistics {...props} asset={asset} recentStatistics={recentStatistics} historicalStatistics={historicalStatistics} />}
                {tabValue === 2 && <Financials {...props} asset={asset} cashFlow={cashFlow} balanceSheet={balanceSheet} income={income} />}
                {tabValue === 3 && <FundamentalAnalysis {...props} asset={asset} cashFlow={cashFlow} balanceSheet={balanceSheet} income={income} />}
                {tabValue === 4 && <TechnicalAnalysis {...props} asset={asset} />}

                {isAdmin(props) && (
                    <Grid item xs={12}>
                        <CustomButton onClick={showResetDialog} title="Reset asset" color="error" fullWidth />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
