import * as React from "react";

import { Container, Grid } from "@mui/material";

import MediaCard from "../../../../helper-components/misc/MediaCard";
import CustomStepper from "../../../../helper-components/form/CustomStepper";
import TransparentFragment from "../../../../helper-components/misc/TransparentFragment";

/**
 * A tutorial component showing how to subscribe and share signals.
 *
 * Uses the CustomStepper component to display the steps.
 */
export default function SignalSubscribeTutorial(props) {
    // Create the step components.
    const step1 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                        <TransparentFragment
                            value={`Open up the 'Community Signals' page to browse the available signals.

                            You can view the ratings and the trust score of the signal owner.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <MediaCard
                            {...props}
                            summary="Select a signal and click the 'Subscribe' icon."
                            bannerImage="/images/tutorials/subscribe_signals_1.png"
                            bannerHeight={220}
                            contentHeight={70}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step2 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                        <TransparentFragment
                            value={`A pop-up dialog will be shown.

                            In case of a protected signal, you will need to enter a secret code.
                            Otherwise, the signal can be subscribed to directly.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <MediaCard
                            {...props}
                            summary="In this example, we need to know the secret code in order to subscribe to the signal."
                            bannerImage="/images/tutorials/subscribe_signals_2.png"
                            bannerHeight={200}
                            contentHeight={80}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step3 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                        <TransparentFragment
                            value={`After subscribing to the signal, all related alerts will also be accessible.

                            View the 'Subscribed Signals' and 'Subscribed Alerts' tables.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <MediaCard
                            {...props}
                            summary="You can unsubscribe from the signal at any time."
                            bannerImage="/images/tutorials/subscribe_signals_3.png"
                            bannerHeight={150}
                            contentHeight={50}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step4 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <TransparentFragment
                            value={`You're all set!

                            Be sure to rate the signal depending on the quality and validity!
                            If a signal doesn't provide alpha, you can unsubscribe from it.`}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    // Define the steps and the final step.
    const steps = [
        { label: "Browse and select signal", optional: false, component: step1() },
        { label: "Subscribe to signal", optional: false, component: step2() },
        { label: "View signals", optional: false, component: step3() },
    ];

    const final = { component: step4() };

    return <CustomStepper steps={steps} final={final} />;
}
