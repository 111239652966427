import * as React from "react";

import { Container, Grid } from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import GroupsIcon from "@mui/icons-material/Groups";
import ShortcutIcon from "@mui/icons-material/Shortcut";

import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import StraightIcon from "@mui/icons-material/Straight";
import MergeIcon from "@mui/icons-material/Merge";
import PeopleIcon from "@mui/icons-material/People";

import SignalSubscribeForm from "./components/SignalSubscribeForm.js";

import CustomForm from "../../../helper-components/form/CustomForm.js";
import StyledTable from "../../../helper-components/table/StyledTable.js";
import StackedHeader from "../../../helper-components/misc/StackedHeader.js";
import PaperFragment from "../../../helper-components/misc/PaperFragment.js";
import NavigationFooter from "../../../helper-components/misc/NavigationFooter.js";

import { queryAllSignals } from "../../../helper-functions/api/signalQueries.js";
import { setUniqueArrayState } from "../../../helper-functions/utils/misc/utils.js";
import { useEndpointStatesWithPagination } from "../../../helper-functions/hooks/states/useEndpointStatesWithPagination.js";

import { getCategory } from "../../../helper-functions/utils/asset/utils.js";
import { defaultInitialState } from "../../../helper-functions/table/utils.js";
import { getArrayElementsByKey } from "../../../helper-functions/utils/validation/object.js";
import { comboPublicColumns, defaultTableOptions } from "../../../helper-functions/table/signal.js";

import * as Enums from "../../../config/constants/Enums.js";
import { getLanguage } from "../../../config/language/language.js";

/**
 * The community signals page.
 *
 * Features:
 * - a table of public / protected combined + subscribed signals
 * - forms to add or modify signals
 */
export default function CommunitySignals(props) {
    const combinedCategory = getCategory(Enums.COMBINED_SIGNAL);

    const combine = useEndpointStatesWithPagination("Combined signals (public + protected)", { logData: true });

    const externalSignals = props.signals.external;
    const combinedSignals = props.signals.combined;

    const { public: externalPublic, protected: externalProtected } = externalSignals;
    const { public: combinedPublic, protected: combinedProtected } = combinedSignals;

    // Combine all external and combined signals into one comprehensive state object.
    // TODO: make this into a custom hook
    React.useEffect(() => {
        const externalPublicData = externalPublic.data;
        const externalProtectedData = externalProtected.data;

        const combinedPublicData = combinedPublic.data;
        const combinedProtectedData = combinedProtected.data;

        if (!externalPublicData || !externalProtectedData) return;
        if (!combinedPublicData || !combinedProtectedData) return;

        const combinedExternal = [...externalPublicData, ...externalProtectedData];
        const combinedCombined = [...combinedPublicData, ...combinedProtectedData];
        const combined = [...combinedExternal, ...combinedCombined];

        setUniqueArrayState(combine.setData, combined);
        combine.setRowCount(combined.length); // NB! Also set the row count for pagination.
    }, [externalPublic.data, externalProtected.data, combinedPublic.data, combinedProtected.data]);

    const showSignalSubscribeDialog = () => {
        props.dialog.enqueueDialog({
            id: "signal-subscribe",
            title: "Subscribe to signal(s)",
            message: `Subscribe to the selected signal(s) to receive alerts and use them in combined signals.

            Subscribed signals will be added to their respective tables in either 'External Signals' or 'Combined Signals' pages.`,
            component: <SignalSubscribeForm />,
            componentProps: { category: combinedCategory, state: combine },
        });
    };

    const decideSubscribeDisabled = () => {
        const selected = combine.selected;
        const selectedProtectedLength = getArrayElementsByKey(selected, "visibility", Enums.SIGNAL_VISIBILITY_PROTECTED).length;
        const selectedPublicLength = getArrayElementsByKey(selected, "visibility", Enums.SIGNAL_VISIBILITY_PUBLIC).length;

        return selected.length < 1 || selectedProtectedLength > 1 || (selectedProtectedLength > 0 && selectedPublicLength > 0);
    };

    return (
        <Container maxWidth="xl" className="container">
            <Grid container>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().COMMUNITY_SIGNALS_TITLE} subtitle={getLanguage().COMMUNITY_SIGNALS_SUBTITLE} icon={<GroupsIcon />} />
                </Grid>

                <PaperFragment
                    title={
                        <StackedHeader
                            {...props}
                            title="Community signals"
                            subtitle="View signals which have been shared with the community."
                            icons={[
                                {
                                    icon: <AddCircleIcon />,
                                    tooltip: "Subscribe to signal",
                                    onClick: showSignalSubscribeDialog,
                                    disabled: decideSubscribeDisabled(),
                                },
                            ]}
                            buttons={[
                                {
                                    icon: <ShortcutIcon />,
                                    title: "Alerts",
                                    onClick: () => props.browser.navigate(`/market-analysis/technical/alerts`),
                                    variant: "outlined",
                                },
                            ]}
                        />
                    }
                    value={
                        <StyledTable
                            {...props}
                            {...defaultTableOptions}
                            type="combined-public" // Used for row styling.
                            states={combine}
                            columns={comboPublicColumns(props)}
                            query={(options) => queryAllSignals(props, { ...options, queryPublic: true, queryProtected: true })}
                            empty={getLanguage().NO_SUBSCRIBABLE_SIGNALS_FOUND}
                            initialState={defaultInitialState("lastAlert")}
                            checkboxSelection
                        />
                    }
                />

                <NavigationFooter
                    rows={2}
                    options={[
                        {
                            path: `/market-analysis/technical/signals/external`,
                            title: `External signals`,
                            icon: <StraightIcon />,
                        },
                        {
                            path: `/market-analysis/technical/signals/combined`,
                            title: `Combined signals`,
                            icon: <MergeIcon />,
                        },
                        // {
                        //     path: `/market-analysis/technical/signals/community`,
                        //     title: `Community signals`,
                        //     icon: <PeopleIcon />,
                        // },
                        {
                            path: `/market-analysis/technical/signals`,
                            title: `Signals dashboard`,
                            icon: <RequestQuoteIcon />,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
