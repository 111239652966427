import { handleWebsocketAddNotification, handleWebsocketDeleteNotification, handleWebsocketUpdateNotifications, playNotification } from "./misc/notification";
import { getCorrectPath, isAdmin, isConnected, isLoggedIn, isNotification, isPosts } from "./misc/utils.js";
import { addDataByWebSockets, removeDataByWebsockets, updateDataByWebSockets } from "./asset/utils.js";
import { handleWebsocketAddFollowingPosts, handleWebsocketAddPublicPosts } from "./misc/posts.js";

import * as Enums from "../../config/constants/Enums.js";
import * as Paths from "../../config/constants/Paths.js";

/**
 * Subscribe to the necessary WebSocket destinations as determined by the user role.
 *
 * NB!
 * If the functions are scattered across multiple files, we can't use the latest version of props, not even w/ useRef in conjunction with callbacks (see below).
 * Consider using a state management library like Redux or MobX to manage the global state.
 *
 * TODO: stale version of props... works, but e.g. needs refresh when selecting new notification sound.
 */
export const initializeWebSocketDestinations = (props) => {
    if (!props.browser.userInteractedWithPage) return;
    if (!props.ws.webSocketClient) return;

    console.log("Initializing websocket connections...");

    props.ws.executeFunctionsAfterWebSocketError(() => {
        props.showSnackbar({ message: "Websocket disconnected! Please reload the page.", color: "warning", duration: 6000 });
    });

    if (isConnected(props)) subscribeToPublicDestinations(props);
    if (isLoggedIn(props)) subscribeToUserDestinations(props);
    if (isAdmin(props)) subscribeToAdminDestinations(props);
};

/**
 * Public destination.
 */
export const subscribeToPublicDestinations = (props) => {
    props.ws.subscribeToWebSocketTopic(Paths.WS_PUBLIC_PATH, (message) => {
        parseMessage(props, message);
    });
};

/**
 * User-specific destination.
 */
export const subscribeToUserDestinations = (props) => {
    props.ws.subscribeToWebSocketTopic(Paths.WS_OWNER_PATH.replace("<sessionId>", props.ws.webSocketSessionId), (message) => {
        parseMessage(props, message);
    });
};

/**
 * Destinations for admin privileges.
 */
export const subscribeToAdminDestinations = (props) => {
    props.ws.subscribeToWebSocketTopic(Paths.WS_ADMIN_PATH, (message) => {
        parseMessage(props, message);
    });
};

/**
 * Parse message and decide where to send it depending on:
 * the action type (ADD, UPDATE, DELETE)
 * the response type (EXTERNAL_ALERT, etc.)
 *
 * TODO: refactor - how to associate response with correct useState prop?
 * Currently bodged solution: getCorrectPath()
 * Create 4th websocket endpoint, /protected?
 * Need to either make it 4 or refactor into: public (public + protected) and user (owner + subscribed)
 */
export const parseMessage = (props, message) => {
    console.log("Parsing WebSocket message:", message);

    const { responseType, permissionType, actionType, payload } = message;

    // Add notification to the sidebar as a new entry.
    if (isNotification(props, responseType)) {
        if (actionType === Enums.ACTION_ADD) {
            playNotification(props);
            handleWebsocketAddNotification(props, payload);
        } else if (actionType === Enums.ACTION_UPDATE) {
            handleWebsocketUpdateNotifications(props, payload);
        } else if (actionType === Enums.ACTION_DELETE) {
            handleWebsocketDeleteNotification(props, payload);
        }

        return;
    }

    // Add posts to the posts list.
    if (isPosts(props, responseType)) {
        if (actionType === Enums.ACTION_ADD || actionType === Enums.ACTION_UPDATE) {
            if (permissionType === Enums.PERMISSION_PUBLIC) {
                handleWebsocketAddPublicPosts(props, payload);
            } else if (permissionType === Enums.PERMISSION_SUBSCRIBED) {
                handleWebsocketAddFollowingPosts(props, payload);
            }
        }

        return;
    }

    // Deal with signals, alerts, assets.
    const path = getCorrectPath(props, responseType, permissionType);

    if (actionType === Enums.ACTION_ADD) {
        addDataByWebSockets(props, path, message);
    }

    if (actionType === Enums.ACTION_UPDATE) {
        updateDataByWebSockets(props, path, message);
    }

    if (actionType === Enums.ACTION_DELETE) {
        removeDataByWebsockets(props, path, message);
    }
};
