import * as React from "react";

import { Container, Grid } from "@mui/material";

import MediaCard from "../../../../helper-components/misc/MediaCard";
import CustomButton from "../../../../helper-components/input/CustomButton";
import CustomStepper from "../../../../helper-components/form/CustomStepper";
import TransparentFragment from "../../../../helper-components/misc/TransparentFragment";

import { PUBLIC_TRADINGVIEW_ALERTS_PATH } from "../../../../config/constants/Paths";

/**
 * A tutorial component showing how to receive signals from TradingView.
 *
 * Uses the CustomStepper component to display the steps.
 */
export default function TradingViewTutorial(props) {
    const userData = props?.user?.data;

    // Create the step components.
    const step1 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TransparentFragment
                            value={`If you are an active user of TradingView and have a paid plan, you can send your buy and sell indicator alerts to this application for further use.

                            Go to the 'External Signals' page, and create a new signal according to the indicator configuration you use in TradingView.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard
                            {...props}
                            summary="For example, if a trend indicator for BTCUSD has been set up in TradingView, let's define it here as well."
                            bannerImage="/images/tutorials/tradingview_2_1.png"
                            bannerHeight={200}
                            contentHeight={100}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step2 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={7}>
                        <TransparentFragment
                            value={`Find the signal from the table and click on the "Copy" icon.

                            This will copy a template to the clipboard, which we'll paste into the alert's message field in TradingView.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <MediaCard
                            {...props}
                            summary="The template will allow you to connect the created signal to an alert in TradingView."
                            bannerImage="/images/tutorials/tradingview_2_2.png"
                            bannerHeight={170}
                            contentHeight={100}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step3 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TransparentFragment
                            value={`Once you've acquired the template, configure the alert in TradingView.

                            Log into TradingView. Then, either create a new alert using an indicator you use, or edit and existing alert from the alerts list in the right sidebar.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard {...props} summary="Create a new alert..." bannerImage="/images/tutorials/tradingview_1_1.png" bannerHeight={130} contentHeight={40} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard {...props} summary="... or edit an existing alert" bannerImage="/images/tutorials/tradingview_1_1_2.png" bannerHeight={130} contentHeight={40} />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step4 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                        <TransparentFragment
                            value={`Define the condition for the alert. This is indicator specific.

                            Sometimes, there is a buy or sell condition already defined, other times, you have to create it yourself.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <MediaCard
                            {...props}
                            summary="In this example, let's define the trend crossing up as a buy alert."
                            bannerImage="/images/tutorials/tradingview_1_2.png"
                            bannerHeight={150}
                            contentHeight={60}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step5 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                        <TransparentFragment
                            value={`Select the trigger condition for the alert. Choose either 'Once Per Bar' or 'Once Per Bar Close'. 

                            If you plan on combining multiple signals, it is recommended to choose 'Once Per Bar'.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <MediaCard
                            {...props}
                            summary="Choosing 'Once Per Bar' will give you more potential alerts, but also more false positives."
                            bannerImage="/images/tutorials/tradingview_1_3.png"
                            bannerHeight={100}
                            contentHeight={80}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step6 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                        <TransparentFragment
                            value={`Add a descriptive name to the alert.

                            Copy & paste the signal template into the message field.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <MediaCard
                            {...props}
                            summary="This is the message that will be sent to this application. Do NOT modify fields with braces: {{...}}"
                            bannerImage="/images/tutorials/tradingview_1_4.png"
                            bannerHeight={130}
                            contentHeight={80}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step7 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid container item xs={12} sm={6} alignContent="flex-start">
                        <Grid item xs={12}>
                            <TransparentFragment
                                value={`Define the Webhook URL to send the message to. 

                                Go to the 'Notifications' tab, and copy & paste the URL into the 'Webhook URL' field.

                                Take caution not to share the URL with anyone else, as it contains a secret key!`}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomButton title="Copy Webhook URL" variant="outlined" onClick={handleCopyUrl} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard
                            {...props}
                            summary="You can disable other checkboxes options to reduce the amount of annoying notifications."
                            bannerImage="/images/tutorials/tradingview_1_5.png"
                            bannerHeight={220}
                            contentHeight={90}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step8 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TransparentFragment
                            value={`Save the alert.
                            Congratulations, you have successfully configured TradingView to send an alert to this application!

                            The same alerts you receive in TradingView are accessible in the 'External Signals' and 'External Alerts' pages.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard
                            {...props}
                            summary="Make sure the message field is valid (green checkmark)"
                            bannerImage="/images/tutorials/tradingview_1_6.png"
                            bannerHeight={180}
                            contentHeight={60}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step9 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TransparentFragment
                            value={`Do the same for all the indicators you use in TradingView, and want to use with this application.
                            
                            This way, you can combine multiple signals, and create new comprehensive strategies for trading or investing.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard
                            {...props}
                            summary="As soon as the alert is triggered in TradingView, you will receive a notification in this application."
                            bannerImage="/images/screenshots/welcome-image-2.png"
                            bannerHeight={180}
                            contentHeight={80}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const handleCopyUrl = () => {
        const url = PUBLIC_TRADINGVIEW_ALERTS_PATH.replace("<secret>", userData?.secret || "UNKN");
        navigator.clipboard.writeText(url);

        props.showSnackbar({ message: "Webhook URL copied to clipboard", color: "info" });
    };

    // Define the steps and the final step.
    const steps = [
        { label: "Create signal", optional: false, component: step1() },
        { label: "Copy template", optional: false, component: step2() },
        { label: "Edit or add new alert", optional: false, component: step3() },
        { label: "Define alert condition", optional: false, component: step4() },
        { label: "Select trigger condition", optional: false, component: step5() },
        { label: "Add message", optional: false, component: step6() },
        { label: "Define webhook URL", optional: false, component: step7() },
        { label: "Save", optional: false, component: step8() },
    ];

    const final = { component: step9() };

    return <CustomStepper steps={steps} final={final} />;
}
