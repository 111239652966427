import * as React from "react";

import { Container, Grid } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

import CustomForm from "../../../helper-components/form/CustomForm.js";
import StyledTable from "../../../helper-components/table/StyledTable.js";
import PaperFragment from "../../../helper-components/misc/PaperFragment.js";
import StackedHeader from "../../../helper-components/misc/StackedHeader.js";
import NavigationFooter from "../../../helper-components/misc/NavigationFooter.js";
import AssetSelection from "../../technical-analysis/signal-pages/components/AssetSelection.js";

import { columns } from "../../../helper-functions/table/asset.js";
import { defaultInitialState } from "../../../helper-functions/table/utils.js";
import { getAssetIdsByStatus } from "../../../helper-functions/utils/asset/asset.js";
import { addAsset, queryAssets } from "../../../helper-functions/api/assetQueries.js";

import { getLanguage } from "../../../config/language/language.js";

/**
 * The assets page.
 *
 * Features:
 * Showcasing all assets
 * Includes basic info alongside graphs
 * For to request new assets
 */
export default function Assets(props) {
    const state = props.assets;

    /**
     * Show a dialog for creating a new portfolio.
     */
    const showAssetSelectionDialog = () => {
        props.dialog.enqueueDialog({
            id: "asset-add",
            title: getLanguage().ASSETS_ADD_TITLE,
            message: getLanguage().ASSETS_ADD_SUBTITLE,
            component: <AssetSelection callback={(confirm, selected) => handleAssetClickConfirmation(confirm, selected)} />,
            componentProps: { exclude: getAssetIdsByStatus(props, ["ENABLED", "LOADING"]) },
        });
    };

    const handleAssetClickConfirmation = (confirm, selected = []) => {
        props.dialog.closeDialog({ id: "asset-add" }); // Manually close the dialog with a certain id.
        if (!confirm) return;

        const selectedAsset = selected[0] || {};

        const body = { micCode: selectedAsset?.exchange?.micCode, ticker: selectedAsset?.ticker, assetType: selectedAsset?.assetType };
        addAsset(props, { body });
    };

    return (
        <Container maxWidth="xl" className="container">
            <Grid container>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().ASSETS_TITLE} subtitle={getLanguage().ASSETS_SUBTITLE} icon={<ListAltIcon />} />
                </Grid>

                <Grid item xs={12}>
                    <PaperFragment
                        title={
                            <StackedHeader {...props} title="Available assets" buttons={[{ icon: <AddIcon />, title: "Request new asset", onClick: showAssetSelectionDialog }]} />
                        }
                        value={
                            <StyledTable
                                {...props}
                                states={state}
                                columns={columns(props)}
                                query={(options) => queryAssets(props, options)}
                                empty={getLanguage().NO_ASSETS_FOUND}
                                initialState={defaultInitialState("ticker")}
                                rowHeight={60}
                            />
                        }
                    />
                </Grid>

                <NavigationFooter
                    rows={1}
                    options={[
                        {
                            path: `/market-analysis/fundamental/screener`,
                            title: `Market screener`,
                            icon: <TravelExploreIcon />,
                        },
                        {
                            path: `/home`,
                            title: `Dashboard`,
                            icon: <DashboardIcon />,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
