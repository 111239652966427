import * as React from "react";

import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

import CustomButton from "../../../../helper-components/input/CustomButton.js";

import { playNotification } from "../../../../helper-functions/utils/misc/notification";

import { SOUND_PATH_AND_NAMES } from "../../../../config/constants/Objects.js";

/**
 * Allow the user to choose which notification sound to use.
 * Saved to localstorage.
 *
 * Elements:
 * Selection radio buttons
 * Demo play button
 */
export default function NotificationSelectForm(props) {
    const [isPlaying, setIsPlaying] = React.useState(false);

    const { isEnabled, file, setFile } = props.notifications;

    const onSelectionChange = (event, newValue) => {
        setFile(newValue);
    };

    const handlePlayClick = (filename) => {
        playNotification(props, filename);
        setIsPlaying(true);
        setTimeout(() => setIsPlaying(false), 1000);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControl className="form" component="fieldset">
                    <RadioGroup name="soundSelection" value={file} onChange={onSelectionChange} aria-label="sound-selection">
                        {Object.entries(SOUND_PATH_AND_NAMES).map(([filename, description]) => (
                            <FormControlLabel key={filename} value={filename} control={<Radio />} label={description} />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} m={1}>
                <CustomButton title="Play sound" onClick={() => handlePlayClick()} disabled={isPlaying || !isEnabled} endIcon={<PlayCircleOutlineIcon />} />
            </Grid>
        </Grid>
    );
}
