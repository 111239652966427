import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import { PasswordResetRequestForm } from "./components/PasswordResetRequestForm";
import { PasswordResetForm } from "./components/PasswordResetForm";
import CustomForm from "../../helper-components/form/CustomForm";

import { verifyToken } from "../../helper-functions/api/authQueries";
import { getLanguage } from "../../config/language/language";

/**
 * Password reset page.
 *
 * If no token is found in the URL, display the password reset request form.
 * If a token is found in the URL, display the password reset form.
 */
export const PasswordReset = (props) => {
    const location = useLocation();
    const [token, setToken] = useState("");

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const hashParam = params.get("token");

        if (!hashParam) return;

        setToken(hashParam);
        verifyToken({ ...props, setToken }, hashParam);
    }, []);

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomForm
                        title={token === "" ? "Enter your email to reset password" : "Enter a new password"}
                        subtitle={getLanguage().PASSWORD_RESET_SUBTITLE}
                        icon={<QuestionMarkIcon />}
                        form={
                            <>
                                {token === "" && <PasswordResetRequestForm {...props} />}
                                {token !== "" && <PasswordResetForm {...props} token={token} />}
                            </>
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    );
};
