// These are the rules by which the form input fields will be checked against.

import { getValue, setFormValues } from "./object.js";
import { noValidation } from "./utils.js";
import { isEmpty } from "./object.js";

import * as Enums from "../../../config/constants/Enums.js";

/**
 * Criteria:
 * 1) No whitespace
 * 2) No special characters
 */
const tickerValidation = (prevConfig, row, value) => {
    if (value !== "") {
        // 1)
        if (/\s/g.test(value)) {
            return setFormValues(row, "ticker", true, "Whitespace not allowed");
        }

        // 2)
        if (!/^[a-z0-9./_-]+$/gi.test(value)) {
            return setFormValues(row, "ticker", true, "Special characters not allowed");
        }
    }

    return setFormValues(row, "ticker", false, "");
};

/**
 * Criteria:
 * 1) Must be a valid currency code
 */
const currencyValidation = (prevConfig, row, value) => {
    // 1)
    if (!Enums.CURRENCY_CODES.includes(value) && value !== "") {
        return setFormValues(row, "currency", true, "Must be a valid currency code");
    }

    return setFormValues(row, "currency", false, "");
};

/**
 * Criteria:
 * 1) Must be a valid asset type
 */
const assetValidation = (prevConfig, row, value) => {
    // 1)
    if (!Enums.ASSET_TYPES.includes(value) && value !== "") {
        return setFormValues(row, "assetType", true, "Must be a valid asset type");
    }

    return setFormValues(row, "assetType", false, "");
};

// The main constant, directing to the specific validation function.
export const VALIDATION = {
    ticker: {
        check: tickerValidation,
    },
    currency: {
        check: currencyValidation,
    },
    assetType: {
        check: assetValidation,
    },
};
