import React from "react";
import { NavLink } from "react-router-dom";

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";

import TooltipContent from "./TooltipContent.js";

import { getPathFromUrl } from "../../helper-functions/utils/page/utils.js";

import * as General from "../../config/constants/General.js";

/**
 * Custom list item component.
 * Optionally includes a tooltip (name and description) and/or a navigation link.
 *
 * If 'enableExpand' is true, it also includes an expand more/less icon.
 * The 'handleClick' function is used to control the expand icon.
 */
export default function CustomListItem(props) {
    const { category, path, sx } = props;
    const { id = category?.id, name = category?.name, description = category?.description, icon = category?.icon || <ListIcon /> } = props;
    const { handleClick = () => {}, expanded } = props;
    const { enableTooltip = false, enableExpand = false, enableNavigation = false } = props;

    const tooltipContent = <TooltipContent name={name} description={description} />;
    const listItemText = props?.sidebar?.openAppDrawer ? name : "";
    const expandIcon = enableExpand ? expanded === id ? <ExpandLess /> : <ExpandMore /> : undefined;

    const prevPathname = getPathFromUrl();

    const createComponent = () => {
        const listItem = (
            <ListItemButton onClick={handleClick(String(id))} sx={{ minWidth: General.SIDEBAR_TEXT_MIN_WIDTH }} className={`tour-guide-sidebar-${id}`}>
                <ListItemIcon sx={sx}>{icon}</ListItemIcon>
                <ListItemText primary={listItemText} />
                {expandIcon}
            </ListItemButton>
        );

        const tooltipWrapper = (
            <Tooltip title={tooltipContent} placement="right" arrow enterTouchDelay={100}>
                {listItem}
            </Tooltip>
        );

        const navWrapper = (
            <NavLink to={path} state={{ prevPathname }} className="sidebar-link hide-link">
                {enableTooltip ? tooltipWrapper : listItem}
            </NavLink>
        );

        if (enableNavigation) return navWrapper;
        if (enableTooltip) return tooltipWrapper;
        return listItem;
    };

    return createComponent();
}
