import { api } from "../../api/axios.js";

import { sessionExpired } from "../user/auth.js";
import { isArray } from "../validation/object.js";
import { isConnected, isInteractedWithPage, isLoggedIn } from "./utils.js";

import { checkJWTvalidity } from "../../api/authQueries.js";

import { queryAllAlerts } from "../../api/alertQueries.js";
import { queryAllSignals } from "../../api/signalQueries.js";
import { queryAssets } from "../../api/assetQueries.js";
import { handleTourGuide } from "../page/utils.js";

import * as General from "../../../config/constants/General.js";
import { getLanguage } from "../../../config/language/language.js";

/**
 * Main fetch function.
 *
 * Uses axios to send a request to the backend.
 * In case of expired token, request new token and retry the request.
 */
async function fetchFromBackend(url, method, options = {}) {
    const { headers, body, stringify = true } = options;

    try {
        const response = await api({ method, url, headers, data: stringify ? JSON.stringify(body) : body });

        return { ok: true, body: response.data, status: response.status };
    } catch (error) {
        if (error.response) {
            return { ok: false, body: error.response.data, status: error.response.status };
        } else if (error.request) {
            return { ok: false, body: { message: "No response received" }, status: 0 };
        } else {
            return { ok: false, body: { message: "Unknown error!" }, status: 0 };
        }
    }
}

/**
 * Check if JWT is still valid, if not, log out and redirect to sign in page.
 */
export const executeFunctionsAfterPageLoad = (props) => {
    console.log("Page reloaded.");

    checkJWTvalidity(props);
};

/**
 * Get alerts and other useful info at refresh of page.
 * Query external tickers to show in the sidebar.
 */
export const executeFunctionsAfterConnectionEstablished = (props) => {
    if (!isConnected(props)) return;
    console.log("Connection with server established.");
};

/**
 * Useful for audio drivers, as the user has to have interacted with the page somehow before the browser is allowed to play sounds.
 */
export const executeFunctionsAfterUserHasInteractedWithPage = (props) => {
    if (!isInteractedWithPage(props) || !isLoggedIn(props)) return;
    console.log("User has interacted with the page.");
};

/**
 * Here are functions that should be executed after the user has been verified and logged in.
 * These usually need user authentication.
 */
export const executeFunctionsAfterLogin = (props) => {
    if (!isLoggedIn(props)) return;
    console.log("User logged in.");

    queryAssets(props, { filter: { items: [{ columnField: "status", operatorValue: "isAnyOf", value: ["ENABLED", "LOADING"] }] } });
    queryAssets(props, {
        filter: {
            items: [
                { columnField: "assetType", operatorValue: "equals", value: "STOCK" },
                { columnField: "status", operatorValue: "equals", value: "DISABLED" },
            ],
        },
        modifyPagination: false,
    });

    // Signals and alerts are queried here because there are many pages that might them.
    queryAllSignals(props, { queryOwner: true, querySubscribed: true, queryProtected: true, queryPublic: true });
    queryAllAlerts(props, { queryOwner: true, querySubscribed: true });
};

/**
 * A standardized GET query.
 * Includes a path and a callback function.
 *
 * If interval is specified, periodically query the data.
 * If condition function if defined, query periodically only if said condifition is satisfied.
 */
export const standardGetQuery = (props, path, callback, options = {}) => {
    const { interval, condition } = options;

    const fetchData = () => {
        getFetch(path, options)
            .then(function (response) {
                if (response.ok) {
                    callback(response);
                } else {
                    catchAPIResponse(props, response, options);
                }
            })
            .catch((err) => catchAPIError(props, err, options));
    };

    // Initial query.
    fetchData();

    // Query if condition is satisfied.
    const conditionalQuery = () => {
        if (condition) {
            const result = condition();

            if (result) {
                fetchData();
            }
        } else {
            fetchData();
        }
    };

    // Periodic query if intervalInSeconds is provided.
    if (interval) {
        const loop = setInterval(conditionalQuery, interval * 1000);

        // Return a function to clear the interval when needed.
        return () => clearInterval(loop);
    }
};

/**
 * A standardized POST query.
 * Includes a path and a callback function.
 */
export const standardPostQuery = (props, path, body, callback, options = {}) => {
    props?.server?.setWaitingForResponse(true);

    postFetch(path, body, options)
        .then(function (response) {
            if (response.ok) {
                props?.server?.setWaitingForResponse(false);
                return callback(response);
            } else {
                catchAPIResponse(props, response);
            }
        })
        .catch((err) => catchAPIError(props, err));
};

/**
 * A standardized PUT query.
 * Includes a path and a callback function.
 */
export const standardPutQuery = (props, path, body, callback, options = {}) => {
    props?.server?.setWaitingForResponse(true);

    putFetch(path, body, options)
        .then(function (response) {
            if (response.ok) {
                callback(response);
                props?.server?.setWaitingForResponse(false);
            } else {
                catchAPIResponse(props, response);
            }
        })
        .catch((err) => catchAPIError(props, err));
};

/**
 * A standardized PATCH query.
 * Includes a path and a callback function.
 */
export const standardPatchQuery = (props, path, body, callback) => {
    props?.server?.setWaitingForResponse(true);

    patchFetch(path, body)
        .then(function (response) {
            if (response.ok) {
                callback(response);
                props?.server?.setWaitingForResponse(false);
            } else {
                catchAPIResponse(props, response);
            }
        })
        .catch((err) => catchAPIError(props, err));
};

/**
 * A standardized DELETE query.
 * Includes a path and a callback function.
 */
export const standardDeleteQuery = (props, path, body, callback) => {
    props?.server?.setWaitingForResponse(true);

    deleteFetch(path, body)
        .then(function (response) {
            if (response.ok) {
                callback(response);
                props?.server?.setWaitingForResponse(false);
            } else {
                catchAPIResponse(props, response);
            }
        })
        .catch((err) => catchAPIError(props, err));
};

// TODO: make fn-s below private?

/**
 * Perform a GET request.
 *
 * Parameters include the URL path and the authorization header (optional).
 * After receiving a response, convert into:
 * { ok: bool, body: [ {...} ] }
 */
export const getFetch = async (path, options) => {
    const { url, headers } = createRequestVariables(path, options);

    return fetchFromBackend(url, "GET", { ...options, headers });
};

/**
 * Perform a POST request.
 *
 * Parameters include the URL path, the HTTP body, and the authorization header (optional).
 * After receiving a response, convert into:
 * { ok: bool, body: [ {...} ] }
 */
export const postFetch = async (path, body, options) => {
    const { url, headers } = createRequestVariables(path, options);

    return fetchFromBackend(url, "POST", { ...options, headers, body });
};

/**
 * Perform a PUT request.
 *
 * Parameters include the URL path, the HTTP body, and the authorization header (optional).
 * After receiving a response, convert into:
 * { ok: bool, body: [ {...} ] }
 */
export const putFetch = async (path, body, options) => {
    const { url, headers } = createRequestVariables(path, options);

    return fetchFromBackend(url, "PUT", { ...options, headers, body });
};

/**
 * Perform a PATCH request.
 *
 * Parameters include the URL path, the HTTP body, and the authorization header (optional).
 * After receiving a response, convert into:
 * { ok: bool, body: [ {...} ] }
 */
export const patchFetch = async (path, body) => {
    const { url, headers } = createRequestVariables(path);

    return fetchFromBackend(url, "PATCH", { headers, body });
};

/**
 * Perform a DELETE request.
 *
 * Parameters include the URL path, the HTTP body, and the authorization header (optional).
 * After receiving a response, convert into:
 * { ok: bool, body: [ {...} ] }
 */
export const deleteFetch = async (path, body) => {
    const { url, headers } = createRequestVariables(path);

    return fetchFromBackend(url, "DELETE", { headers, body });
};

/**
 * Return the URL and headers.
 */
const createRequestVariables = (path, options = {}) => {
    const url = createUrl(path, options);

    const headers = {
        "Content-Type": "application/json; charset=utf-8",
        ...options.headers, // Spread the headers from options.
    };

    return { url, headers };
};

/**
 * Return a URL.
 * In case of optional newUrl parameter, the current IP is replaced with the new one.
 */
export const createUrl = (path, options = {}) => {
    const newIp = options.newIp;
    const ip = newIp ? window.location.protocol + "//" + newIp : General.SERVER_IP;
    const url = ip + ":" + General.SERVER_PORT + (path ?? "");

    return url;
};

/**
 * Deal with errors.
 * Executed when the server successfully responds, but the response code is not 200.
 */
const catchAPIResponse = (props, response, options) => {
    console.warn("Non-200 status code:", response);

    props?.server?.setWaitingForResponse(false);

    const status = response?.status ?? "UNKN";
    const message = response?.body?.message ?? "No response from server";
    const showMessage = options?.showMessage ?? true;

    if (!showMessage) {
        console.warn(message);
        return;
    }

    try {
        props.showSnackbar({ message: `Error ${`(${status}):`} ${message}`, color: "error", duration: 6000 });

        const accessToken = props.user.accessToken;

        // If unauthorized, most likely the JWT has expired.
        if ((response.status === 401 || response.status === 403) && accessToken !== "") sessionExpired(props);
    } catch (e) {
        console.warn(e);
    }
};

/**
 * Deal with errors.
 * Executed when the server couldn't respond to request.
 */
export const catchAPIError = (props, err, options) => {
    console.error("Error connecting to server:", err);

    props?.server?.setWaitingForResponse(false);

    const showMessage = options?.showMessage ?? true;
    if (!showMessage) return;

    try {
        props.showSnackbar({ message: getLanguage().SNACKBAR_ERROR_SERVER, color: "error", duration: 6000 });
    } catch (e) {
        console.error(e);
    }
};

/**
 * Return a string with the query parameters for the feedbacks.
 *
 * E.g. "?page=0&pageSize=10&orderBy=asset&order=asc&column=asset&operator=equals&value=TSLA"
 */
export const createCriteriaParameters = (options = {}) => {
    const { page, pageSize, filter, sort } = options;

    let queryParams = "";

    if (page !== undefined) queryParams += `&page=${page}`;
    if (pageSize !== undefined) queryParams += `&pageSize=${pageSize}`;
    if (filter?.quickFilterValues && filter.quickFilterValues.length !== 0) queryParams += `&search=${filter.quickFilterValues.join(",")}`;
    if (filter?.items && filter.items.length !== 0) queryParams += getFilterParameters(filter.items);
    if (sort && sort.length !== 0) queryParams += getSortParameters(sort);

    if (queryParams === "") return "";
    if (queryParams.startsWith("&")) queryParams = queryParams.substring(1);
    return `?${queryParams}`;
};

/**
 * Get the sort parameters by sort array.
 *
 * E.g. "orderBy=asset&order=asc"
 */
const getSortParameters = (sort) => {
    return `&orderBy=${sort[0]?.field}&order=${sort[0]?.sort}`;
};

/**
 * Get the filter parameters by filter.items array
 *
 * E.g. "column=asset&operator=equals&value=TSLA"
 */
const getFilterParameters = (items = []) => {
    let queryParams = "";

    items.forEach((item) => {
        const { columnField, operatorValue, value } = item;

        if (value !== undefined && value !== "") {
            let itemParams = `column=${columnField}&operator=${operatorValue}`;
            const isUpperCase = deviceUpperCaseValue(item);

            if (isArray(value)) {
                itemParams += `&value=${value.map((v) => (isUpperCase ? v.toUpperCase() : v)).join(",")}`;
            } else {
                itemParams += `&value=${isUpperCase ? value.toUpperCase() : value}`;
            }

            queryParams += `&${itemParams}`;
        }
    });

    return queryParams;
};

/**
 * Return whether a value should be uppercased.
 * This is requied because the API is case-sensitive.
 */
const deviceUpperCaseValue = (filterItem) => {
    const column = filterItem.columnField;

    return column === "ticker" || column === "assetType" || column === "orderType" || column === "status";
};
