import { Box, Typography } from "@mui/material";

/**
 * Component spinner / loader.
 *
 * If isEmpty prop is true, show a "no results found" message instead of the spinner.
 */
export default function ComponentLoader(props) {
    const isVisible = props.isVisible;
    const isEmpty = props.isEmpty;

    const height = props.height ?? "40vh";
    const width = props.width ?? "100%";

    const showImg = props.showImg ?? false;

    const loaderWidth = props.loaderWidth ?? "25%";
    const loaderHeight = props.loaderHeight ?? "100%";

    const emptyComponent = props.empty ?? <Typography className="pre-wrap">{"No results found!"}</Typography>;

    return (
        <div hidden={!isVisible} className="component-loader-parent" style={{ height, width }}>
            {isEmpty ? (
                <Box m={0.5} mt={2}>
                    {emptyComponent}
                    <div className="component-loader-empty" style={{ backgroundImage: showImg ? null : "initial" }} />
                </Box>
            ) : (
                <div className="component-loader" style={{ width: loaderWidth, height: loaderHeight }} alt="Loading..." />
            )}
        </div>
    );
}
