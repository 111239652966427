import { Paper, Typography } from "@mui/material";

/**
 * Custom component with styling, used for multi-line text.
 */
export default function PaperText(props) {
    const flexDirection = props.flexDirection ? props.flexDirection : "column";
    const align = props.center ? "center" : "inherit";
    const textAlign = props.center ? "center" : "inherit";
    const height = props.height ? props.height : "inherit";

    const fontWeight = props.bold ? "bold" : "inherit";
    const whiteSpace = props.whiteSpace || "pre-line";

    return (
        <Paper
            sx={{
                p: props.p ? props.p : 1,
                m: props.m ? props.m : 1,
                display: "flex",
                flexDirection: flexDirection,
                alignItems: align,
                textAlign: textAlign,
                height: height,
            }}>
            <Typography style={{ whiteSpace: whiteSpace, fontWeight: fontWeight }}>{props.value}</Typography>
        </Paper>
    );
}
