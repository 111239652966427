import * as React from "react";

import { Container, Grid } from "@mui/material";

import SmartToyIcon from "@mui/icons-material/SmartToy";

import Iframe from "../../helper-components/misc/Iframe.js";
import CustomForm from "../../helper-components/form/CustomForm.js";

import { getLanguage } from "../../config/language/language.js";

/**
 * An embedded chatbot page.
 */
export default function Chatbot(props) {
    const id = "66c2fec3b8d94e5f7fb8cd68";
    const key = `voiceflow-session-${id}`;

    // Check if the chatbot state has changed - if so, reload the page.
    React.useEffect(() => {
        const voiceflowInfoStr = localStorage.getItem(key);
        const voiceflowInfoObj = voiceflowInfoStr ? JSON.parse(voiceflowInfoStr) : null;
        const beginLength = voiceflowInfoObj?.turns?.length || 0;

        // NB! Reload the page to sync the new chatbot state with the chat bubble.
        return () => {
            const voiceflowInfoStr = localStorage.getItem(key);
            const voiceflowInfoObj = voiceflowInfoStr ? JSON.parse(voiceflowInfoStr) : null;
            const endLength = voiceflowInfoObj?.turns?.length || 0;

            if (endLength !== beginLength) {
                window.location.reload();
            }
        };
    }, []);

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().CHATBOT_TITLE} subtitle={getLanguage().CHATBOT_SUBTITLE} icon={<SmartToyIcon />} />
                </Grid>

                <Grid item xs={12} mt={2}>
                    <Iframe {...props} title="chatbot" id="chatbot" height="90vh" src={`${process.env.PUBLIC_URL}/pages/chatbot-embedded.html`} />
                </Grid>
            </Grid>
        </Container>
    );
}
