import { createCriteriaParameters, standardDeleteQuery, standardGetQuery, standardPostQuery, standardPutQuery, postFetch } from "../utils/misc/api.js";
import { setDeleteArrayState } from "../utils/misc/utils.js";

import * as Paths from "../../config/constants/Paths.js";
import { getStateByPostVisibility, handleAddFollowingPosts, handleAddPrivatePosts, handleAddPublicPosts } from "../utils/misc/posts.js";
import { POST_VISIBILITY_PRIVATE, POST_VISIBILITY_PROTECTED, POST_VISIBILITY_PUBLIC } from "../../config/constants/Enums.js";

/**
 * Request post data from the server.
 *
 * Options: id
 */
export const queryPost = (props, options = {}) => {
    const { id } = options;

    const path = Paths.USER_POSTS_PATH + `/${id}`;

    standardGetQuery(props, path, (response) => {
        const visibility = response?.body?.visibility;

        if (visibility === POST_VISIBILITY_PUBLIC) handleAddPublicPosts(props, { elements: [response.body] });
        if (visibility === POST_VISIBILITY_PROTECTED) handleAddFollowingPosts(props, { elements: [response.body] });
        if (visibility === POST_VISIBILITY_PRIVATE) handleAddPrivatePosts(props, { elements: [response.body] });
    });
};

/**
 * Function for querying posts with filters, pagination, sorting etc.
 */
export const queryPublicPosts = (props, options = {}) => {
    const path = Paths.USER_POSTS_PATH + "/public" + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddPublicPosts(props, response.body);

        const states = props.posts.public;
        if (options.page) states.setQueriedUntil(options.page);
        if (response.body.total) states.setRowCount(response.body.total);
    });
};

export const queryPrivatePosts = (props, options = {}) => {
    const path = Paths.USER_POSTS_PATH + "/private" + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddPrivatePosts(props, response.body);

        const states = props.posts.private;
        if (options.page) states.setQueriedUntil(options.page);
        if (response.body.total) states.setRowCount(response.body.total);
    });
};

export const queryFollowingPosts = (props, options = {}) => {
    const path = Paths.USER_POSTS_PATH + "/followed" + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddFollowingPosts(props, response.body);

        const states = props.posts.following;
        if (options.page) states.setQueriedUntil(options.page);
        if (response.body.total) states.setRowCount(response.body.total);
    });
};

/**
 * Send a new post to the server.
 * The post is uploaded as a form-data object.
 */
export const createPost = (props, options = {}) => {
    const { body } = options;

    standardPostQuery(
        props,
        Paths.USER_POSTS_PATH,
        body,
        (response) => {
            props.showSnackbar({ message: "Post successfully uploaded!" });
            props.browser.navigate("/investment-tools/posts");
        },
        { headers: { "Content-Type": "multipart/form-data" }, stringify: false }
    );
};

export const uploadPostImage = async (props, options = {}) => {
    const { body } = options;

    try {
        const response = await postFetch(Paths.USER_POSTS_PATH + "/image", body, { headers: { "Content-Type": "multipart/form-data" }, stringify: false });

        props.showSnackbar({ message: "Image successfully uploaded", color: "info", duration: 1500 });
        return response.body;
    } catch (error) {
        props.showSnackbar({ message: "Image upload failed", color: "error", duration: 1500 });
        return null;
    }
};

/**
 * Send an updated post to the server.
 * The post is uploaded as a form-data object.
 */
export const updatePost = (props, options = {}) => {
    const { body, id, previousVisibility } = options;

    standardPutQuery(
        props,
        Paths.USER_POSTS_PATH,
        body,
        (response) => {
            // Delete post from state, as we query the post again.
            // This is beacause the post might have changed visibility (and thus, state).
            const states = getStateByPostVisibility(props, previousVisibility);
            setDeleteArrayState(states.setData, [id]);

            props.showSnackbar({ message: "Post successfully updated!" });
            props.browser.navigate("/investment-tools/posts");
        },
        { headers: { "Content-Type": "multipart/form-data" }, stringify: false }
    );
};

export const deletePost = (props, options = {}) => {
    const { post } = options;

    const path = Paths.USER_POSTS_PATH + `/${post?.id}`;

    standardDeleteQuery(props, path, undefined, (response) => {
        const states = getStateByPostVisibility(props, post.visibility);

        states.setRowCount((prev) => prev - 1);
        setDeleteArrayState(states.setData, [post.id]);
    });
};
