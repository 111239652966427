import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CustomButton from "../../helper-components/input/CustomButton.js";
import TickerImage from "../../helper-components/misc/TickerImage.js";
import Iframe from "../../helper-components/misc/Iframe.js";

import { defaultStyle, time as defaultTime, status as defaultStatus } from "./shared.js";

import { ASSET_MINI_CHART } from "../../config/widgets/TradingViewWidgets.js";
import { getAssetByKey, formatValue, redirectToAsset } from "../utils/asset/asset.js";

const renderLogo = (params) => {
    return <TickerImage asset={params.row} height="100%" />;
};

const renderPrice = (props, params) => {
    const theme = window.localStorage.getItem("theme");
    const widget = ASSET_MINI_CHART.replace("<theme>", theme).replace("<ticker>", params.row.ticker).replace("<height>", "40");

    return <Iframe {...props} id="miniChart" srcDoc={widget} />;
};

const renderButton = (props, params) => {
    return (
        <CustomButton
            {...props}
            title="View"
            onClick={() => redirectToAsset(props, params?.row?.id)}
            disabled={params?.row?.status === "DISABLED"}
            endIcon={<ArrowForwardIcon />}
            size="small"
        />
    );
};

// Column definitions.
const logo = {
    ...defaultStyle,
    field: "logo",
    headerName: "Logo",
    description: "Logo of the asset",
    flex: 0.03,
    sortable: false,
    filterable: false,
    renderCell: renderLogo,
};
const ticker = { ...defaultStyle, field: "ticker", headerName: "Ticker", flex: 0.05, minWidth: 100 };
const name = { ...defaultStyle, field: "name", headerName: "Name", minWidth: 150 };
const exchange = { ...defaultStyle, field: "exchange", headerName: "Exchange", minWidth: 100, valueGetter: (params) => params.row.exchange?.name };
const time = { ...defaultTime("priceUpdatedOn"), headerName: "Price updated" };
const status = { ...defaultStatus, headerName: "", minWidth: 30, flex: 0.04 };
const price = (props) => {
    return {
        ...defaultStyle,
        field: "currentPrice",
        headerName: "Price",
        minWidth: 100,
        valueGetter: (params) => params.row.currentPrice,
        valueFormatter: (params) => {
            const asset = getAssetByKey(props, "id", params.id);
            return formatValue(params.value, { currency: asset?.currency?.symbol });
        },
    };
};

const chart = (props) => {
    return {
        ...defaultStyle,
        field: "chart",
        headerName: "Graph",
        description: "Recent 1 month price action",
        sortable: false,
        filterable: false,
        flex: 0.25,
        minWidth: 100,
        renderCell: (params) => renderPrice(props, params),
    };
};

const button = (props) => {
    return {
        ...defaultStyle,
        field: "button",
        headerName: "Action",
        description: "Click on the button to view the asset.",
        sortable: false,
        filterable: false,
        flex: 0.1,
        minWidth: 125,
        renderCell: (params) => renderButton(props, params),
    };
};

// Table columns.
export const columns = (props) => [logo, ticker, name, exchange, chart(props), price(props), status, button(props)];
