import { convertToDate, formatDate } from "../utils/validation/object";
import { defaultStyle } from "./shared.js";

import * as Enums from "../../config/constants/Enums.js";

// Define the columns and their style.
const id = { ...defaultStyle, field: "id", headerName: "Id", type: "number" };
const username = { ...defaultStyle, field: "username", headerName: "Username", flex: 0.2 };
const email = { ...defaultStyle, field: "email", headerName: "Email", flex: 0.2 };
const lastLogin = {
    ...defaultStyle,
    field: "lastLogin",
    headerName: "Last login",
    description: "Converted to local time.",
    type: "date",
    width: 200,
    flex: 0.2,
    minWidth: 50,
    valueGetter: (params) => convertToDate(params.row.lastLogin),
    valueFormatter: (params) => formatDate(params.value),
};
const createdOn = {
    ...defaultStyle,
    field: "createdOn",
    headerName: "Created on",
    description: "Converted to local time.",
    type: "date",
    width: 200,
    flex: 0.2,
    minWidth: 50,
    valueGetter: (params) => convertToDate(params.row.createdOn),
    valueFormatter: (params) => formatDate(params.value),
};
const role = {
    ...defaultStyle,
    field: "role",
    headerName: "Role",
    minWidth: 75,
    valueGetter: (params) => userOrAdmin(params.row.role),
};

// Columns.
export const columns = (props) => [id, username, email, lastLogin, createdOn, role];

// Style and other misc. functions.
export const rowStyle = {
    "& .row-theme-Admin": {
        bgcolor: "rgba(255, 254, 152, 0.15)",
    },
    "& .row-theme-User": {
        bgcolor: "rgba(238, 238, 238, 0.1)",
    },
};

export const rowTheme = (props, params) => {
    return userOrAdmin(params.row.role);
};

export const isRowSelectable = (props, params) => {
    return userOrAdmin(params.row.role) !== "Admin";
};

const userOrAdmin = (role) => {
    return role?.name === Enums.ROLE_ADMIN ? "Admin" : "User";
};

// Default aggregate props of the table.
export const defaultTableOptions = {
    rowTheme,
    rowStyle,
    isRowSelectable,
    checkboxSelection: true,
};
