/**
 * Custom list item component.
 * Includes a tooltip with a title and a description in italic.
 *
 * Props:
 * - category: should include name and description fields
 * - name: overwrites category.name
 * - description: overwrites category.description
 */
export default function TooltipContent(props) {
    const { category, name = "", description = "" } = props;

    return (
        <div style={{ whiteSpace: "pre-line" }}>
            <div>{category?.name || name}</div>
            <div style={{ fontStyle: "italic" }}>{category?.description || description}</div>
        </div>
    );
}
