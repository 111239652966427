import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CustomButton from "../../helper-components/input/CustomButton";
import TickerImage from "../../helper-components/misc/TickerImage";

import { defaultStyle, time } from "./shared";
import { formatValue, redirectToAsset } from "../utils/asset/asset";
import { convertToDate, formatDate } from "../utils/validation/object";
import { getPortfolioAssetByKey, getPortfolioByKey, getPortfolioTradeByKey, redirectToPortfolio } from "../utils/misc/portfolio";

// Column render functions.
const renderButton = (props, params) => {
    return <CustomButton {...props} title="View" onClick={() => redirectToPortfolio(props, params?.row?.id)} endIcon={<ArrowForwardIcon />} size="small" />;
};

const renderAssetButton = (props, params) => {
    return (
        <CustomButton
            {...props}
            title="View"
            onClick={() => redirectToAsset(props, params?.row?.asset?.id)}
            disabled={params?.row?.asset?.status === "DISABLED"}
            endIcon={<ArrowForwardIcon />}
            size="small"
        />
    );
};

const renderAssetLogo = (params) => {
    return <TickerImage asset={params?.row?.asset} height="100%" />;
};

// Column definitions.
const name = { ...defaultStyle, field: "name", headerName: "Name", description: "Name of the portfolio", flex: 0.3 };
const changePortfolio = (props) => {
    return {
        ...defaultStyle,
        field: "change",
        headerName: "Change",
        description: "Change in portfolio value since inception",
        type: "number",
        flex: 0.15,
        minWidth: 100,
        valueGetter: (params) => params.row.change,
        valueFormatter: (params) => {
            const portfolio = getPortfolioByKey(props, "id", params.id);
            return formatValue(params.value, { currency: portfolio?.currency });
        },
    };
};
const changeAsset = (props) => {
    return {
        ...defaultStyle,
        field: "change",
        headerName: "Change",
        description: "Change in portfolio value since inception",
        type: "number",
        flex: 0.15,
        minWidth: 100,
        valueGetter: (params) => params.row.change,
        valueFormatter: (params) => {
            const portfolioAsset = getPortfolioAssetByKey(props, "id", params.id);
            return formatValue(params.value, { currency: portfolioAsset?.asset?.currency?.symbol });
        },
    };
};
const changePercentage = {
    ...defaultStyle,
    field: "changePercentage",
    headerName: "Change %",
    description: "Change in portfolio value in % since inception",
    type: "number",
    flex: 0.1,
    minWidth: 100,
    cellClassName: (params) => (isNaN(params.value) ? null : params.value > 0 ? "flashing-green" : "flashing-red"),
    valueFormatter: (params) => (isNaN(params.value) ? "N/A" : `${params.value.toFixed(2)}%`),
};
const valuePortfolio = (props) => {
    return {
        ...defaultStyle,
        field: "value",
        headerName: "Value",
        description: "The current value of the portfolio",
        type: "number",
        flex: 0.2,
        minWidth: 100,
        valueGetter: (params) => params.row.value,
        valueFormatter: (params) => {
            const portfolio = getPortfolioByKey(props, "id", params.id);
            return formatValue(params.value, { currency: portfolio?.currency });
        },
    };
};
const valueAsset = (props) => {
    return {
        ...defaultStyle,
        field: "value",
        headerName: "Value",
        description: "The current value of the portfolio",
        type: "number",
        flex: 0.2,
        minWidth: 100,
        valueGetter: (params) => params.row.value,
        valueFormatter: (params) => {
            const portfolioAsset = getPortfolioAssetByKey(props, "id", params.id);
            return formatValue(params.value, { currency: portfolioAsset?.asset?.currency?.symbol });
        },
    };
};
const timeAtUpdate = {
    ...defaultStyle,
    field: "updatedOn",
    type: "date",
    headerName: "Updated on",
    description: "Converted to local time.",
    flex: 0.3,
    width: 200,
    minWidth: 50,
    sortable: true,
    valueGetter: (params) => convertToDate(params.row.updatedOn),
    valueFormatter: (params) => formatDate(params.value),
};
const button = (props) => {
    return {
        ...defaultStyle,
        field: "button",
        headerName: "Action",
        description: "Click on the button to view and edit the portfolio.",
        sortable: false,
        filterable: false,
        flex: 0.1,
        minWidth: 100,
        renderCell: (params) => renderButton(props, params),
    };
};

// Columns for portfolio assets.
const assetLogo = {
    ...defaultStyle,
    field: "logo",
    headerName: "Logo",
    description: "Logo of the asset",
    sortable: false,
    filterable: false,
    renderCell: renderAssetLogo,
};
const assetTicker = {
    ...defaultStyle,
    field: "ticker",
    headerName: "Ticker",
    minWidth: 100,
    valueGetter: (params) => params?.row?.asset?.ticker,
};
const assetName = {
    ...defaultStyle,
    field: "name",
    headerName: "Name",
    description: "The name of the asset",
    flex: 0.3,
    minWidth: 125,
    valueGetter: (params) => params?.row?.asset?.name,
};
const assetButton = (props) => {
    return {
        ...defaultStyle,
        field: "button",
        headerName: "Actions",
        description: "Click on the buttons to view the transactions or asset details.",
        sortable: false,
        filterable: false,
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => renderAssetButton(props, params),
    };
};

// Columns for portfolio trades.
const price = (props) => {
    return {
        ...defaultStyle,
        field: "price",
        headerName: "Price",
        description: "Price at which the asset was bought or sold",
        type: "number",
        flex: 0.15,
        minWidth: 100,
        valueFormatter: (params) => {
            const portfolioTrade = getPortfolioTradeByKey(props, "id", params.id);
            return formatValue(params.value, { currency: portfolioTrade?.asset?.currency?.symbol });
        },
    };
};
const amount = {
    ...defaultStyle,
    field: "amount",
    headerName: "Amount",
    description: "Amount of the asset bought or sold",
    type: "number",
    flex: 0.15,
    valueFormatter: (params) => (params.value > 0 ? `+${params.value}` : `${params.value}`),
};

// Table columns.
export const columns = (props) => [name, valuePortfolio(props), changePortfolio(props), changePercentage, timeAtUpdate, button(props)];
export const assetColumns = (props) => [assetLogo, assetTicker, assetName, valueAsset(props), changeAsset(props), changePercentage, assetButton(props)];
export const tradeColumns = (props) => [assetLogo, assetTicker, assetName, price(props), amount, time("timestamp")];

// Style and other misc. functions.
export const isRowSelectable = (props, params) => {
    return true;
};

export const tradeRowStyle = {
    "& .row-theme-Buy": {
        bgcolor: "rgba(9, 236, 25, 0.08)",
    },
    "& .row-theme-Sell": {
        bgcolor: "rgba(237, 9, 9, 0.08)",
    },
};

export const tradeRowTheme = (props, params) => {
    return buyOrSell(params.row.amount);
};

const buyOrSell = (amount) => {
    return amount > 0 ? "Buy" : "Sell";
};

// Default aggregate props of the table.
export const defaultTableOptions = {
    isRowSelectable,
    checkboxSelection: true,
};
