// Tradingview widgets, used as srcDoc in iframes.

// Home page widgets don't scale correctly, thus we'll use separate HTML pages.
// See the /public folder.
// TODO: ticker tape works fine, what gives?

export const SCREENER = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-screener.js" async>
  {
  "width": "100%",
  "height": "<height>",
  "defaultColumn": "<column>",
  "defaultScreen": "<screen>",
  "market": "<market>",
  "showToolbar": true,
  "colorTheme": "<theme>",
  "locale": "en",
  "isTransparent": true
}
  </script>
</div>
<!-- TradingView Widget END -->
`;

export const TICKER_TAPE = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js" async>
  {
    "symbols": [
      {
        "proName": "FOREXCOM:SPXUSD",
        "title": "S&P 500"
      },
      {
        "description": "VKG",
        "proName": "AMEX:VGK"
      },
      {
        "proName": "FX_IDC:EURUSD",
        "title": "EUR/USD"
      },
      {
        "description": "JPY/USD",
        "proName": "FX_IDC:JPYUSD"
      },
      {
        "proName": "BITSTAMP:BTCUSD",
        "title": "Bitcoin"
      },
      {
        "proName": "BITSTAMP:ETHUSD",
        "title": "Ethereum"
      },
      {
        "proName": "BITSTAMP:SOLUSD",
        "title": "Solana"
      },
      {
        "description": "Tesla",
        "proName": "NASDAQ:TSLA"
      },
      {
        "description": "MicroStrategy",
        "proName": "NASDAQ:MSTR"
      },
      {
        "description": "Alphabet",
        "proName": "NASDAQ:GOOG"
      },
      {
        "description": "Nvidia",
        "proName": "NASDAQ:NVDA"
      },
      {
        "description": "Amazon",
        "proName": "NASDAQ:AMZN"
      }
    ],
  "showSymbolLogo": true,
  "isTransparent": true,
  "displayMode": "compact",
  "colorTheme": "<theme>",
  "locale": "en"
  }
  </script>
</div>
<!-- TradingView Widget END -->
`;

export const ASSET_CHART = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js" async>
  {
  "height": <height>,
  "width": "100%",
  "symbol": "<ticker>",
  "interval": "60",
  "timezone": "Etc/UTC",
  "theme": "<theme>",
  "style": "1",
  "locale": "en",
  "enable_publishing": false,
  "withdateranges": true,
  "hide_side_toolbar": false,
  "allow_symbol_change": false,
  "save_image": false,
  "calendar": false,
  "support_host": "https://www.tradingview.com"
  }
  </script>
</div>
<!-- TradingView Widget END -->
`;

export const ASSET_INFO = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js" async>
  {
  "symbol": "<ticker>",
  "width": "100%",
  "locale": "en",
  "colorTheme": "<theme>",
  "isTransparent": true
  }
  </script>
</div>
<!-- TradingView Widget END -->
`;

export const ASSET_OVERVIEW = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
<div class="tradingview-widget-container__widget"></div>
<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js" async>
    {
    "symbol": "<ticker>",
    "width": "100%",
    "height": "<height>",
    "locale": "en",
    "dateRange": "<range>",
    "colorTheme": "<theme>",
    "trendLineColor": "rgba(41, 98, 255, 1)",
    "underLineColor": "rgba(41, 98, 255, 0.3)",
    "underLineBottomColor": "rgba(41, 98, 255, 0)",
    "isTransparent": true,
    "autosize": true,
    "largeChartUrl": "",
    "noTimeScale": false
    }
</script>
</div>
<!-- TradingView Widget END -->
`;

export const ASSET_FUNDAMENTALS = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
<div class="tradingview-widget-container__widget"></div>
<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-financials.js" async>
    {
    "colorTheme": "<theme>",
    "isTransparent": true,
    "largeChartUrl": "",
    "displayMode": "regular",
    "width": "100%",
    "height": "<height>",
    "symbol": "<ticker>",
    "locale": "en"
    }
</script>
</div>
<!-- TradingView Widget END -->
`;

export const ASSET_PROFILE = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
<div class="tradingview-widget-container__widget"></div>
<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js" async>
    {
    "width": "100%",
    "height": "<height>",
    "colorTheme": "<theme>",
    "isTransparent": true,
    "symbol": "<ticker>",
    "locale": "en"
    }
</script>
</div>
<!-- TradingView Widget END -->
`;

export const EASY_ASSET_CHART = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
<div id="tradingview_d777a"></div>
<script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
<script type="text/javascript">
    new TradingView.MediumWidget(
    {
    "symbols": [
        [
        "<ticker>",
        "<ticker>|1D"
        ]
    ],
    "chartOnly": true,
    "width": "100%",
    "height": "<height>",
    "locale": "en",
    "colorTheme": "<theme>",
    "autosize": true,
    "showVolume": true,
    "hideDateRanges": false,
    "scalePosition": "right",
    "scaleMode": "Normal",
    "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
    "noTimeScale": false,
    "valuesTracking": "1",
    "chartType": "area",
    "lineColor": "#2962ff",
    "topColor": "rgba(41, 98, 255, 0.3)",
    "bottomColor": "rgba(41, 98, 255, 0)",
    "lineWidth": 2,
    "container_id": "tradingview_d777a"
    }
    );
</script>
</div>
<!-- TradingView Widget END -->
`;

export const ASSET_MINI_CHART = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js" async>
  {
  "symbol": "<ticker>",
  "width": "100%",
  "height": "<height>",
  "locale": "en",
  "dateRange": "1M",
  "colorTheme": "<theme>",
  "isTransparent": true,
  "autosize": true,
  "largeChartUrl": "",
  "noTimeScale": true,
  "chartOnly": true
}
  </script>
</div>
<!-- TradingView Widget END -->`;

export const HEATMAP = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-stock-heatmap.js" async>
  {
  "exchanges": [],
  "dataSource": "SPX500",
  "grouping": "sector",
  "blockSize": "market_cap_basic",
  "blockColor": "change",
  "locale": "en",
  "symbolUrl": "",
  "colorTheme": "<theme>",
  "hasTopBar": true,
  "isDataSetEnabled": true,
  "isZoomEnabled": true,
  "hasSymbolTooltip": true,
  "isMonoSize": false,
  "width": "100%",
  "height": "<height>"
  }
  </script>
</div>
<!-- TradingView Widget END -->
`;

export const STOCK_MARKET_ACTIVITY = `
<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js" async>
  {
  "colorTheme": "<theme>",
  "dateRange": "1D",
  "exchange": "US",
  "showChart": true,
  "locale": "en",
  "width": "100%",
  "height": "<height>",
  "largeChartUrl": "",
  "isTransparent": true,
  "showSymbolLogo": true,
  "showFloatingTooltip": true,
  "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
  "plotLineColorFalling": "rgba(41, 98, 255, 1)",
  "gridLineColor": "rgba(240, 243, 250, 0)",
  "scaleFontColor": "rgba(209, 212, 220, 1)",
  "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
  "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
  "symbolActiveColor": "rgba(41, 98, 255, 0.12)"
  }
  </script>
</div>
<!-- TradingView Widget END -->
`;
