import * as React from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { TableToolbar } from "./TableToolbar.js";

import { defaultInitialState, defaultRowSelection, defaultRowSetSelection, defaultRowStyles, defaultRowTheme } from "../../helper-functions/table/utils.js";

import * as General from "../../config/constants/General.js";

/**
 * A multi-purpose table.
 * Used to show alerts, signals, users, etc.
 *
 * Required props:
 * - DATA:
 * - rows
 * - columns
 *
 * Optional props:
 * - STYLE:
 * - rowTheme (function to style rows)
 * - rowStyle (object to style rows)
 *
 * - PAGINATION:
 * - page, setPage
 * - pageSize, setPageSize
 * - rowCount
 * - getRowId (required in case data has no 'id' key, used to select rows)
 *
 * - FILTER, SORT, COLUMN VISIBILITY:
 * - filter, setFilter
 * - sort, setSort
 * - visibility, setVisibility
 *
 * - SELECTION:
 * - checkboxSelection (boolean to enable row selection)
 * - isRowSelectable (function to check if row is selectable)
 * - handleRowSelection (function to handle row selection)
 * - setSelected (function to set selected rows)
 *
 * - OTHER:
 * - disableSelectionOnClick
 * - disableColumnMenu
 * - disableColumnSelector
 * - disableColumnFilter
 * - disableDensitySelector
 * - onClick
 * - rowHeight
 */
export default function Table(props) {
    const initialState = defaultInitialState();
    const isMobile = props.browser.isMobile;

    // Default useStates in case of undefined props.
    const [defaultPage, setDefaultPage] = React.useState(0);
    const [defaultPageSize, setDefaultPageSize] = React.useState(General.DEFAULT_TABLE_ROWS_PER_PAGE);
    const [defaultVisibility, setDefaultVisibility] = React.useState(initialState.columns.columnVisibilityModel);
    const [defaultFilter, setDefaultFilter] = React.useState(initialState.filter.filterModel);
    const [defaultSort, setDefaultSort] = React.useState(initialState.sorting.sortModel);

    // Data.
    const { rows, columns } = props;

    // Style.
    const { rowTheme = defaultRowTheme, rowStyle = defaultRowStyles } = props;

    // Pagination, sorting, filtering.
    const { page = defaultPage, setPage = setDefaultPage, pageSize = defaultPageSize, setPageSize = setDefaultPageSize } = props;
    const { visibility = defaultVisibility, setVisibility = setDefaultVisibility } = props;
    const { filter = defaultFilter, setFilter = setDefaultFilter } = props;
    const { sort = defaultSort, setSort = setDefaultSort } = props;
    const { rowCount = rows.length, getRowId = null } = props;

    // Selection.
    const { selected, setSelected, rowSelection = defaultRowSelection, handleRowSelection = defaultRowSetSelection } = props;
    const { checkboxSelection = false, isRowSelectable = () => false } = props;

    // Misc. features.
    const { disableSelectionOnClick = true, disableColumnMenu = true, disableColumnSelector = false, disableColumnFilter = false, disableDensitySelector = true } = props;

    return (
        <Box className="table" sx={rowStyle}>
            <DataGrid
                // sx={props.onClick ? { "&:hover": { cursor: "pointer" } } : null}
                autoHeight={true}
                // Table data.
                rows={rows}
                columns={columns}
                getRowId={getRowId}
                // Style.
                getRowHeight={() => props.rowHeight}
                getRowClassName={(params) => (rowTheme ? `row-theme-${rowTheme(props, params)}` : null)}
                disableSelectionOnClick={disableSelectionOnClick}
                disableColumnMenu={disableColumnMenu}
                disableColumnSelector={disableColumnSelector}
                disableColumnFilter={disableColumnFilter}
                disableDensitySelector={disableDensitySelector}
                // autoHeight={true}
                loading={rows.length === 0}
                rowHeight={isMobile ? General.TABLE_ROW_HEIGHT_MOBILE_PX : General.TABLE_ROW_HEIGHT_DESKTOP_PX}
                // Components.
                components={{ Toolbar: TableToolbar }}
                componentsProps={{
                    toolbar: {
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                // Click
                onRowClick={props.onClick}
                // Pagination.
                pagination
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPage) => setPage(newPage)}
                rowsPerPageOptions={General.TABLE_ROWS_PER_PAGE}
                // Filtering.
                filterModel={filter}
                onFilterModelChange={(model) => setFilter(model)}
                // Sorting.
                sortModel={sort}
                onSortModelChange={(model) => setSort(model)}
                // Column visibility.
                columnVisibilityModel={visibility} // Any way to programmatically hide name, but still keep the column filter button working?
                onColumnVisibilityModelChange={(model) => setVisibility(model)}
                // Selection.
                selectionModel={rowSelection(selected)}
                onSelectionModelChange={(selection) => handleRowSelection(setSelected, selection, rows)}
                isRowSelectable={(params) => isRowSelectable(props, params)}
                checkboxSelection={checkboxSelection}
            />
        </Box>
    );
}
