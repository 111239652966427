import jwt_decode from "jwt-decode";

import * as Enums from "../../../config/constants/Enums.js";
import { getLanguage } from "../../../config/language/language.js";

/**
 * Executed when the JWT has expired.
 */
export const sessionExpired = (props) => {
    console.log("Session expired, logging user out...");

    props.showSnackbar({ message: getLanguage().SNACKBAR_ERROR_SESSION_EXPIRED, color: "error" });
    logUserOut(props, true);
};

/**
 * Executed during after successful validation and sign in.
 */
export const successfulJwtValidation = (props, optionalAccessToken, optionalRefreshToken) => {
    const accessToken = optionalAccessToken ?? props.user.accessToken;
    const refreshToken = optionalRefreshToken ?? props.user.refreshToken;

    updateJWT(props, accessToken, refreshToken); // Re-save user-related localstorage variables from the valid JWT token to guard against tampering.

    props.server.setIsLoggedIn(true);
};

/**
 * Decode the JWT and save the JWT to localstorage for easy access.
 *
 * Re-save user-related localstorage variables from the valid JWT token to guard against tampering.
 */
const updateJWT = (props, accessToken, refreshToken) => {
    if (accessToken !== "") {
        try {
            const decodedToken = decodeJWT(accessToken);

            props.user.setAccessToken(accessToken);
            props.user.setRefreshToken(refreshToken);

            const roleName = decodedToken?.role?.name;
            props.role.setIsUser(roleName === Enums.ROLE_USER || roleName === Enums.ROLE_ADMIN);
            props.role.setIsAdmin(roleName === Enums.ROLE_ADMIN);
        } catch (e) {
            console.error(e);
        }
    }
};

/**
 * Decode the JWT to external JSON format.
 */
export const decodeJWT = (jwt) => {
    try {
        return jwt_decode(jwt);
    } catch (e) {
        console.error(e);
    }
};

/**
 * Return some value from the user's JWT.
 */
export const getJwtValueByKey = (props, key) => {
    try {
        const decodedToken = decodeJWT(props.user.accessToken);

        return decodedToken[key];
    } catch (e) {
        console.error(e);
    }
};

/**
 * Deletes local storage variables and refreshes the page.
 *
 * If refreshPage is true, also refresh the whole page.
 * If the path parameter is set, open that page.
 * Otherwise, refresh the current page.
 *
 * NB! If a page refresh doesn't happen, any old useState variables might casuse problems and privacy concerns.
 */
export const logUserOut = (props, refreshPage = false, path = "") => {
    if (props.user.accessToken !== "") {
        props.user.setAccessToken("");
        props.user.setRefreshToken("");
        props.role.setIsUser(false);
        props.role.setIsAdmin(false);

        props.server.setIsLoggedIn(false);

        console.log("User logged out!");

        if (refreshPage) {
            if (path !== "") {
                const currentURL = new URL(window.location.href).origin;
                window.location.assign(currentURL + path);
            } else {
                window.location.reload();
            }
        }
    }
};
