import * as React from "react";

import { List, Divider, ListSubheader } from "@mui/material";

import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import CustomListItem from "../../../../helper-components/misc/CustomListItem";
import CustomListDropdown from "../../../../helper-components/misc/CustomListDropdown";

import { getLanguage } from "../../../../config/language/language";
import * as Objects from "../../../../config/constants/Objects";

/**
 * A sidebar for the whole SPA.
 * Includes all accessible pages with links, dropdown lists, and more.
 */
export default function Sidebar(props) {
    const isMobile = props.browser.isMobile;

    const { goBackOption, goBackOptionRedirect } = props.sidebar;
    const { openAppDrawer: openDrawer, toggleAppDrawer: toggleDrawer } = props.sidebar;

    const { isConnectionEstablished: isConnected, isLoggedIn } = props.server;

    const closeSidebarIfMobile = isMobile ? (openDrawer ? toggleDrawer : undefined) : undefined;

    return (
        <>
            {/* Optional nav props */}
            {goBackOption && (
                <List component="nav">
                    <CustomListItem
                        {...props}
                        name={goBackOption}
                        path={goBackOptionRedirect}
                        icon={<ArrowBackIcon />}
                        sx={{ mb: 1.5, mt: 1.5 }}
                        onClick={(id) => {
                            props.sidebar.setGoBackOption(null);
                            props.sidebar.setGoBackOptionRedirect(null);
                        }}
                    />
                    <Divider />
                </List>
            )}

            {/* Overview items */}
            <List component="nav" onClick={closeSidebarIfMobile}>
                <CustomListItem {...props} name="Overview" description="Overview of the application" icon={<DashboardIcon />} path="/home" enableNavigation enableTooltip />
                <CustomListItem {...props} name="About" description="Info about the platform" icon={<InfoIcon />} path="/about" enableNavigation enableTooltip />
            </List>

            {/* Market analysis */}
            {isConnected && isLoggedIn && (
                <List component="nav" className="tour-guide-3">
                    <Divider />

                    <ListSubheader component="div" className="list-subheader">
                        {"Investments & trading"}
                    </ListSubheader>

                    <CustomListDropdown {...props} categories={[Objects.SIDEBAR_MARKET_ANALYSIS, Objects.SIDEBAR_PORTFOLIOS, Objects.SIDEBAR_TOOLS]} />
                </List>
            )}

            {/* Other */}
            <List component="nav" className="tour-guide-4">
                <Divider />

                <ListSubheader component="div" className="list-subheader">
                    {getLanguage().SIDEBAR_OTHER}
                </ListSubheader>

                <CustomListDropdown {...props} categories={[Objects.SIDEBAR_USER, Objects.SIDEBAR_ADMIN, Objects.SIDEBAR_OTHER]} />
            </List>

            <br></br>
            <br></br>
        </>
    );
}
