import React from "react";
import { useParams } from "react-router";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import { Container, Grid, Typography } from "@mui/material";

import ArticleIcon from "@mui/icons-material/Article";

import CustomForm from "../../helper-components/form/CustomForm";
import CustomImage from "../../helper-components/misc/CustomImage";
import CustomButton from "../../helper-components/input/CustomButton";
import PaperFragment from "../../helper-components/misc/PaperFragment";

import { queryPost } from "../../helper-functions/api/postQueries";
import { getFallbackPosts } from "../../helper-functions/utils/misc/posts";
import { formatDate, isEmpty } from "../../helper-functions/utils/validation/object";

import { getLanguage } from "../../config/language/language";
import { PUBLIC_POSTS_PATH } from "../../config/constants/Paths";

export default function PostViewer(props) {
    const { id } = useParams(); // Get the URL parameters from react-router.

    const [post, setPost] = React.useState({}); // Set default value if post is not available yet.
    const [postQuery, setPostQuery] = React.useState(false);

    const publicPosts = props?.posts?.public?.data;
    const privatePosts = props?.posts?.private?.data;
    const followingPosts = props?.posts?.following?.data;
    const fallbackData = getFallbackPosts();

    const combinedPostsData = [...publicPosts, ...privatePosts, ...followingPosts, ...fallbackData];

    // Set the post, and potentially query it if it's not available.
    React.useEffect(() => {
        const possiblePost = combinedPostsData.find((post) => post.id === parseInt(id));
        if (!isEmpty(post) && postQuery) return;

        if (!isEmpty(possiblePost)) {
            setPostQuery(true);
            setPost(possiblePost);
        }

        if (isEmpty(possiblePost) && !postQuery) {
            setPostQuery(true);
            queryPost(props, { id });
        }
    }, [combinedPostsData, post]);

    const subtitle = `Created on: ${formatDate(post?.createdOn)}
                    ${post?.updatedOn ? `Updated on: ${formatDate(post?.updatedOn)}` : ``}`;

    const bannerImage = post?.fallback ? post.bannerImagePath : `${PUBLIC_POSTS_PATH}/image/${post.bannerImagePath}`;

    // Custom renderer for images
    const renderers = {
        img: ({ alt, src, title, width = "auto", height = "auto" }) => <img alt={alt} src={src} title={title} style={{ maxWidth: "100%", width, height }} />,
    };

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <CustomForm
                        title={getLanguage().POSTS_VIEW_TITLE}
                        subtitle={getLanguage().POSTS_VIEW_SUBTITLE}
                        icon={<ArticleIcon />}
                        form={
                            post?.author?.id === props?.user?.data?.id && (
                                <Grid item xs={12} mt={2}>
                                    <CustomButton {...props} title="Edit post" onClick={() => props.browser.navigate(`/investment-tools/posts/edit/${post.id}`)} size="small" />
                                </Grid>
                            )
                        }
                    />
                </Grid>

                {post?.bannerImagePath && (
                    <Grid item xs={12}>
                        <CustomImage url={bannerImage} maxHeight="30vh" contain alt="Post banner" />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <PaperFragment
                        p={2}
                        title={post?.title || "N/A"}
                        value={
                            <>
                                <Typography pb={1} variant="subtitle2" className="pre-line">
                                    {subtitle}
                                </Typography>

                                <ReactMarkdown children={post?.content} components={renderers} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} />
                            </>
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
