import React from "react";

import { Container, Grid } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";

import StyledTable from "../helper-components/table/StyledTable";
import PaperFragment from "../helper-components/misc/PaperFragment";
import StackedHeader from "../helper-components/misc/StackedHeader";
import CustomForm from "../helper-components/form/CustomForm";

import PortfolioAddForm from "./components/PortfolioAddForm";

import { deletePortfolios, queryPortfolios } from "../helper-functions/api/portfolioQueries";
import { columns, defaultTableOptions } from "../helper-functions/table/portfolio";

import { getLanguage } from "../config/language/language";

/**
 * Portfolio list view.
 *
 * Includes a table of all portfolios.
 * Clicking on a row will redirect to the portfolio page.
 */
export default function Portfolios(props) {
    const portfolios = props.portfolios;

    /**
     * Show a dialog for creating a new portfolio.
     */
    const showCreateDialog = () => {
        props.dialog.enqueueDialog({
            id: "portfolio-create",
            title: "Add new portfolio",
            message: `Create a new portfolio, which can be used to track your assets and their performance.`,
            component: <PortfolioAddForm />,
        });
    };

    /**
     * Show a dialog for deleting selected portfolios.
     */
    const showDeleteDialog = () => {
        props.dialog.enqueueDialog({
            title: "Delete portfolio(s)",
            message: `Are you sure you want to delete the selected portfolio(s)?
            All data, including trades, will be lost.`,
            actionTitle: "Delete",
            actionColor: "error",
            callback: (confirm) => handleDelete(confirm),
        });
    };

    /**
     * Handle the deletion of selected portfolios.
     */
    const handleDelete = (confirm) => {
        if (!confirm) return;

        const ids = portfolios.selected.map((portfolio) => portfolio.id);
        deletePortfolios(props, { body: { ids }, ids });
    };

    return (
        <Container maxWidth="xl" className="container">
            <Grid container>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().PORTFOLIOS_TITLE} subtitle={getLanguage().PORTFOLIOS_SUBTITLE} icon={<CasesRoundedIcon />} />
                </Grid>

                <Grid item xs={12}>
                    <PaperFragment
                        title={
                            <StackedHeader
                                {...props}
                                title="My portfolios"
                                subtitle="View and manage your portfolios."
                                icons={[
                                    {
                                        icon: <DeleteIcon />,
                                        tooltip: "Delete selected portfolios",
                                        disabled: portfolios.selected.length < 1,
                                        onClick: showDeleteDialog,
                                    },
                                ]}
                                buttons={[
                                    {
                                        icon: <AddIcon />,
                                        title: "New portfolio",
                                        onClick: showCreateDialog,
                                    },
                                ]}
                            />
                        }
                        value={
                            <StyledTable
                                {...props}
                                {...defaultTableOptions}
                                rowHeight={60}
                                states={portfolios}
                                columns={columns(props)}
                                query={(options) => queryPortfolios(props, options)}
                                empty={getLanguage().NO_PORTFOLIOS_FOUND}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
