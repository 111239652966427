import * as React from "react";

import { Container, Grid } from "@mui/material";

import MediaCard from "../../../../helper-components/misc/MediaCard";
import CustomStepper from "../../../../helper-components/form/CustomStepper";
import TransparentFragment from "../../../../helper-components/misc/TransparentFragment";

/**
 * A tutorial component showing how to receive signals from TradingView.
 *
 * Uses the CustomStepper component to display the steps.
 */
export default function SignalCombineTutorial(props) {
    // Create the step components.
    const step1 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={7}>
                        <TransparentFragment
                            value={`Make sure you've either configured or subscribed to at least 2 signals before continuing.

                            View the "TradingView" tutorial for more information on how to receive signals, or the "Subscribe" tutorial to learn how to subscribe to community signals.

                            Signals can be viewed from their respective tables.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <MediaCard
                            {...props}
                            summary="An example of two tables, one which has signals, and an empty one."
                            bannerImage="/images/tutorials/combined_signals_1.png"
                            bannerHeight={210}
                            contentHeight={80}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step2 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                        <TransparentFragment value={`Go to the "Combined Signals" page and select the signals you wish to combine.`} />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <MediaCard
                            {...props}
                            summary="Click 'Add new signal' to get started."
                            bannerImage="/images/tutorials/combined_signals_2.png"
                            bannerHeight={200}
                            contentHeight={40}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step3 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TransparentFragment
                            value={`Open the signal selection dialog and choose which signals to combine.
                        
                            Use the search field to find the right signals, and - depending on the alert condition -, keep the signal selection order in mind.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard
                            {...props}
                            summary="In this example, let's combine 'trend buy' and 'mean reversion buy' signals for trading TSLA."
                            bannerImage="/images/tutorials/combined_signals_3.png"
                            bannerHeight={200}
                            contentHeight={80}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step4 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={7}>
                        <TransparentFragment
                            value={`Fill out the other form fields.
                            Define the criteria when an alert for the combined signal should be created.

                            You can choose from a few strategies - see the description below the field for more information.

                            Click on the "Create" button to save the combined signal.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <MediaCard
                            {...props}
                            summary="Let's set the alert to trigger when both signals have fired within the last hour."
                            bannerImage="/images/tutorials/combined_signals_4.png"
                            bannerHeight={210}
                            contentHeight={90}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step5 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TransparentFragment
                            value={`You're all set!

                            Once all of the criteria are met, the combined signal will trigger an alert and you'll receive a notification in this application.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard
                            {...props}
                            summary="See all the alerts in the table of the 'Combined Alerts' page."
                            bannerImage="/images/tutorials/combined_signals_5.png"
                            bannerHeight={180}
                            contentHeight={60}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    // Define the steps and the final step.
    const steps = [
        { label: "Configure signals", optional: false, component: step1() },
        { label: "Add new signal", optional: false, component: step2() },
        { label: "Select signals to combine", optional: false, component: step3() },
        { label: "Configure combined signal", optional: false, component: step4() },
    ];

    const final = { component: step5() };

    return <CustomStepper steps={steps} final={final} />;
}
