import * as React from "react";

import { Container, Grid } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ShortcutIcon from "@mui/icons-material/Shortcut";

import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import StraightIcon from "@mui/icons-material/Straight";
import MergeIcon from "@mui/icons-material/Merge";
import PeopleIcon from "@mui/icons-material/People";

import SignalAddForm from "./components/SignalAddForm.js";
import SignalUpdateForm from "./components/SignalUpdateForm.js";
import SignalShareForm from "./components/SignalShareForm.js";

import CustomForm from "../../../helper-components/form/CustomForm.js";
import StyledTable from "../../../helper-components/table/StyledTable.js";
import StackedHeader from "../../../helper-components/misc/StackedHeader.js";
import PaperFragment from "../../../helper-components/misc/PaperFragment.js";
import NavigationFooter from "../../../helper-components/misc/NavigationFooter.js";

import { setUniqueArrayState } from "../../../helper-functions/utils/misc/utils.js";
import { useEndpointStatesWithPagination } from "../../../helper-functions/hooks/states/useEndpointStatesWithPagination.js";
import { deleteSignals, disableSignals, enableSignals, querySignals, unsubscribeSignals } from "../../../helper-functions/api/signalQueries.js";

import { getCategory } from "../../../helper-functions/utils/asset/utils.js";
import { defaultInitialState } from "../../../helper-functions/table/utils.js";
import { getValueFromObjectArrayByKey } from "../../../helper-functions/utils/validation/object.js";
import { combinedUserColumns, combinedSubscribedColumns, defaultTableOptions } from "../../../helper-functions/table/signal.js";

import * as Enums from "../../../config/constants/Enums.js";
import { getLanguage } from "../../../config/language/language.js";

/**
 * The combined signals page.
 *
 * Features:
 * - a table of owner external + combined signals
 * - a table of subscribed external + combined signals
 * - forms to add or modify signals
 */
export default function CombinedSignals(props) {
    const combinedCategory = getCategory(Enums.COMBINED_SIGNAL);

    const combine = useEndpointStatesWithPagination("Combined signals (owner + subscribed)");

    const externalAlerts = props.alerts.external;
    const externalSignals = props.signals.external;
    const combinedSignals = props.signals.combined;

    const { owner: externalOwner, subscribed: externalSubscribed } = externalSignals;
    const { owner: combinedOwner, subscribed: combinedSubscribed } = combinedSignals;

    const { selected: selectedOwner } = combinedOwner;
    const { selected: selectedSubscribed } = combinedSubscribed;

    // Combine all external and combined signals into one comprehensive state object.
    React.useEffect(() => {
        const externalOwnerData = externalOwner.data;
        const externalSubscribedData = externalSubscribed.data;

        const combinedOwnerData = combinedOwner.data;
        const combinedSubscribedData = combinedSubscribed.data;

        if (!externalOwnerData || !externalSubscribedData) return;
        if (!combinedOwnerData || !combinedSubscribedData) return;

        const combinedExternal = [...externalOwnerData, ...externalSubscribedData];
        const combinedCombined = [...combinedOwnerData, ...combinedSubscribedData];
        const combined = [...combinedExternal, ...combinedCombined];

        setUniqueArrayState(combine.setData, combined);
    }, [externalOwner.data, externalSubscribed.data, combinedOwner.data, combinedSubscribed.data]);

    const showSignalAddDialog = () => {
        props.dialog.enqueueDialog({
            id: "signal-create",
            title: "Create signal",
            message: `Combined signals are created by combining two or more signals into one.

            The combined signal will then be used to create alerts and receive notifications.`,
            component: <SignalAddForm />,
            componentProps: { category: combinedCategory, state: combinedOwner },
        });
    };

    const showSignalEditDialog = () => {
        props.dialog.enqueueDialog({
            id: "signal-edit",
            title: "Edit signal",
            message: `Update the selected signal's fields.
            Keep in mind, if the signal is already public, then only few fields are allowed to be updated!`,
            component: <SignalUpdateForm />,
            componentProps: { category: combinedCategory, state: combinedOwner },
        });
    };

    const showSignalShareDialog = () => {
        props.dialog.enqueueDialog({
            id: "signal-share",
            title: "Share signal(s)",
            message: `Share the selected signal(s) with others.
            Subscribed users can then receive the same alerts and use the signals for their own strategies.`,
            component: <SignalShareForm />,
            componentProps: { category: combinedCategory, state: combinedOwner },
        });
    };

    const showSignalDeleteDialog = () => {
        props.dialog.enqueueDialog({
            title: "Delete signal(s)",
            message: `Are you sure you want to delete the selected signal(s)?
            This action will also delete any associated alerts.`,
            actionTitle: "Delete",
            actionColor: "error",
            callback: (confirm) => handleSignalDelete(confirm),
        });
    };

    const showSignalUnsubscribeDialog = () => {
        props.dialog.enqueueDialog({
            title: "Unsubscribe from signal(s)",
            message: `Are you sure you want to unsubscribe from the selected signal(s)?

            NB! This action might affect your combined signals and the alerts you receive.
            The unsubscribed signals will be removed from your combined signals.`,
            actionTitle: "Unsubscribe",
            actionColor: "error",
            callback: (confirm) => handleSignalUnsubscribe(confirm),
        });
    };

    const handleSignalDelete = (confirm) => {
        if (!confirm) return;

        const ids = getValueFromObjectArrayByKey(selectedOwner);
        deleteSignals(props, { signalsState: combinedOwner, alertsState: externalAlerts.owner, body: { ids } });
    };

    const handleSignalEnable = () => {
        const ids = getValueFromObjectArrayByKey(selectedOwner);

        enableSignals(props, { state: combinedOwner, body: { ids } });
    };

    const handleSignalDisable = () => {
        const ids = getValueFromObjectArrayByKey(selectedOwner);

        disableSignals(props, { state: combinedOwner, body: { ids } });
    };

    const handleSignalUnsubscribe = (confirm) => {
        if (!confirm) return;

        const ids = getValueFromObjectArrayByKey(selectedOwner);
        unsubscribeSignals(props, { signalsState: combinedSubscribed, alertsState: externalAlerts.subscribed, body: { ids } });
    };

    const selectedSignalsHasStatus = (status, len = 0) => selectedOwner.filter((row) => row.status === status).length > len;

    return (
        <Container maxWidth="xl" className="container">
            <Grid container>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().COMBINED_SIGNAL_TITLE} subtitle={getLanguage().COMBINED_SIGNALS_SUBTITLE} icon={<RequestQuoteIcon />} />
                </Grid>

                <PaperFragment
                    title={
                        <StackedHeader
                            {...props}
                            title="Owner signals"
                            subtitle="View combined signals you have created."
                            icons={[
                                { icon: <DeleteIcon />, tooltip: "Delete selected signals", onClick: showSignalDeleteDialog, disabled: selectedOwner.length < 1 },
                                {
                                    icon: <ShareIcon />,
                                    tooltip: "Share selected signals",
                                    onClick: showSignalShareDialog,
                                    disabled: selectedOwner.length < 1 && !selectedSignalsHasStatus(Enums.STATUS_ENABLED),
                                },
                                { icon: <EditIcon />, tooltip: "Edit selected signal", onClick: showSignalEditDialog, disabled: selectedOwner.length !== 1 },
                                {
                                    icon: <PauseCircleOutlineIcon />,
                                    tooltip: "Disable selected signals",
                                    onClick: handleSignalDisable,
                                    disabled: selectedOwner.length < 1 || selectedSignalsHasStatus(Enums.STATUS_DISABLED),
                                },
                                {
                                    icon: <PlayCircleOutlineIcon />,
                                    tooltip: "Enable selected signals",
                                    onClick: handleSignalEnable,
                                    disabled: selectedOwner.length < 1 || selectedSignalsHasStatus(Enums.STATUS_ENABLED),
                                },
                            ]}
                            buttons={[
                                {
                                    icon: <AddIcon />,
                                    title: selectedOwner.length === 0 ? "Add new signal" : "Duplicate signal",
                                    onClick: showSignalAddDialog,
                                    disabled: selectedOwner.length > 1,
                                },
                            ]}
                        />
                    }
                    value={
                        <StyledTable
                            {...props}
                            {...defaultTableOptions}
                            states={combinedOwner}
                            columns={combinedUserColumns(props)}
                            query={(options) => querySignals(props, { ...options, path: combinedCategory.paths.owner, state: combinedOwner })}
                            empty={getLanguage().NO_SIGNALS_FOUND}
                            initialState={defaultInitialState("lastAlert")}
                            checkboxSelection
                        />
                    }
                />

                <PaperFragment
                    title={
                        <StackedHeader
                            {...props}
                            title="Subscribed signals"
                            subtitle="View combined signals you have subscribed to."
                            icons={[
                                {
                                    icon: <RemoveCircleOutlineIcon />,
                                    tooltip: "Unsubscribe from selected signals",
                                    onClick: showSignalUnsubscribeDialog,
                                    disabled: selectedSubscribed.length < 1,
                                },
                            ]}
                            buttons={[
                                {
                                    icon: <ShortcutIcon />,
                                    title: "Find more signals",
                                    onClick: () => props.browser.navigate(`/market-analysis/technical/signals/community`),
                                    variant: "outlined",
                                },
                            ]}
                        />
                    }
                    value={
                        <StyledTable
                            {...props}
                            {...defaultTableOptions}
                            states={combinedSubscribed}
                            columns={combinedSubscribedColumns(props)}
                            query={(options) => querySignals(props, { ...options, path: combinedCategory.paths.subscribed, state: combinedSubscribed })}
                            empty={getLanguage().NO_SIGNALS_FOUND}
                            initialState={defaultInitialState("lastAlert")}
                            checkboxSelection
                        />
                    }
                />

                <NavigationFooter
                    rows={2}
                    options={[
                        {
                            path: `/market-analysis/technical/signals/external`,
                            title: `External signals`,
                            icon: <StraightIcon />,
                        },
                        // {
                        //     path: `/market-analysis/technical/signals/combined`,
                        //     title: `Combined signals`,
                        //     icon: <MergeIcon />,
                        // },
                        {
                            path: `/market-analysis/technical/signals/community`,
                            title: `Community signals`,
                            icon: <PeopleIcon />,
                        },
                        {
                            path: `/market-analysis/technical/signals`,
                            title: `Signals dashboard`,
                            icon: <RequestQuoteIcon />,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
