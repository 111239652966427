import { Grid } from "@mui/material";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import CustomButton from "../input/CustomButton.js";

import { isEmpty } from "../../helper-functions/utils/validation/object.js";

/**
 * Navigation with buttons.
 *
 * Required:
 * options (array of objects with title, path)
 *
 * Optional:
 * options (variant, icon)
 * rows (number of rows)
 * states (includes all state variables, e.g. controller.environment)
 */
export default function NavigationFooter(props) {
    const { options = [] } = props;
    if (isEmpty(options)) return;

    const rowCount = props.rows ?? 1;
    const buttonCount = options.length;

    const buttonsPerRow = Math.ceil(buttonCount / rowCount);
    const remainder = buttonCount % rowCount;

    /**
     * Calculate the width of the button.
     *
     * Examples:
     * 1 row, 1 button -> 12
     * 1 row, 2 buttons -> 6, 6
     * 2 rows, 2 buttons -> 12, 12
     * 2 rows, 3 buttons -> 6, 6, 12
     * 3 rows, 3 buttons -> 4, 4, 4
     */
    const calulateWidth = (index) => (remainder > 0 && index >= (rowCount - remainder) * buttonsPerRow ? 12 / remainder : 12 / buttonsPerRow);

    return (
        <>
            <Grid item xs={12} m={props.m || 0.5} />

            {options.map((option, index) => (
                <Grid item xs={12} md={calulateWidth(index)} key={index}>
                    <CustomButton
                        {...props}
                        title={option.title}
                        path={option.path}
                        variant={option.variant ?? "outlined"}
                        startIcon={option.icon ?? (index === 0 ? <NavigateBeforeIcon /> : <NavigateNextIcon />)}
                        disabled={option.disabled}
                        fullWidth
                    />
                </Grid>
            ))}
        </>
    );
}
