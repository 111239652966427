import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import ListAltIcon from "@mui/icons-material/ListAlt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import Overview from "./components/Overview";
import Iframe from "../../helper-components/misc/Iframe";
import PaperFragment from "../../helper-components/misc/PaperFragment";
import StackedHeader from "../../helper-components/misc/StackedHeader";
import NavigationFooter from "../../helper-components/misc/NavigationFooter";

import { isLoggedIn } from "../../helper-functions/utils/misc/utils";

import { HEATMAP, STOCK_MARKET_ACTIVITY, TICKER_TAPE } from "../../config/widgets/TradingViewWidgets";

/**
 * The homepage.
 *
 * For whatever reason, Tradingview iframes are kind of buggy, and don't scale correctly (width and height) using srcDoc.
 * However, a separate HTML page, set as src, works nicely.
 *
 * The tradingview HTML pages are in the /public folder.
 * Accodring to the theme ("dark" or "light"), we can also set the color mode.
 */
export default function Home(props) {
    const theme = useTheme();

    const colorMode = theme.palette.mode;
    const isMobile = props.browser.isMobile;

    const loggedIn = isLoggedIn(props);

    const [tickerTapeSrc, setTickerTapeSrc] = React.useState("");
    const [heatmapSrc, setHeatmapSrc] = React.useState("");
    const [marketActivitySrc, setMarketActivitySrc] = React.useState("");

    // Set the TradingView widgets' state.
    React.useEffect(() => {
        setTickerTapeSrc(TICKER_TAPE.replace("<theme>", colorMode));
        setHeatmapSrc(HEATMAP.replace("<theme>", colorMode).replace("<height>", isMobile ? 400 : 750));
        setMarketActivitySrc(STOCK_MARKET_ACTIVITY.replace("<theme>", colorMode).replace("<height>", isMobile ? 400 : 550));
    }, [colorMode]);

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={1}>
                {/* Ticker tape. */}
                <Grid item xs={12}>
                    <PaperFragment height={isMobile ? 90 : 100} value={<Iframe {...props} id="tradingview-ticker-tape" loaded={tickerTapeSrc} srcDoc={tickerTapeSrc} />} />
                </Grid>

                {/* <Overview {...props} /> */}

                {/* Market overview. */}
                <Grid item xs={12} lg={6}>
                    <PaperFragment
                        height={650}
                        title={<StackedHeader {...props} title="Market overview" subtitle="Quickly browse indexes, stocks, and digital assets." />}
                        value={<Iframe {...props} id="tradingview-market-overview" src={`${process.env.PUBLIC_URL}/tradingview/${theme.palette.mode}_mode/overview.html`} />}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <PaperFragment
                        height={650}
                        title={<StackedHeader {...props} title="Stock market" subtitle="See the top five gaining, losing, and most active stocks for the day." />}
                        value={<Iframe {...props} id="tradingview-stock-market" loaded={marketActivitySrc} srcDoc={marketActivitySrc} />}
                    />
                </Grid>

                <Grid item xs={12}>
                    <PaperFragment
                        height={isMobile ? 450 : 850}
                        title={<StackedHeader {...props} title="Stock market heatmap" subtitle="A macro view on global stocks. Segment by sector, country or market cap." />}
                        value={<Iframe {...props} id="tradingview-heat-map" loaded={heatmapSrc} srcDoc={heatmapSrc} />}
                    />
                </Grid>

                {/* Market news. */}
                <Grid item xs={12} lg={6}>
                    <PaperFragment
                        height={props.browser.isMobile ? 420 : 600}
                        value={<Iframe {...props} id="tradingview-market-news" src={`${process.env.PUBLIC_URL}/tradingview/${theme.palette.mode}_mode/news.html`} />}
                    />
                </Grid>

                {/* Economic calendar. */}
                <Grid item xs={12} lg={6}>
                    <PaperFragment
                        height={props.browser.isMobile ? 420 : 600}
                        title={<StackedHeader {...props} title="Economic calendar" subtitle="View recent and upcoming economic events." />}
                        value={
                            <Iframe {...props} id="tradingview-economic-calendar" src={`${process.env.PUBLIC_URL}/tradingview/${theme.palette.mode}_mode/economic_calendar.html`} />
                        }
                    />
                </Grid>

                {loggedIn && (
                    <NavigationFooter
                        rows={1}
                        options={[
                            {
                                path: `/market-analysis/fundamental/assets`,
                                title: `Assets`,
                                icon: <ListAltIcon />,
                            },
                            {
                                path: `/market-analysis/technical/signals`,
                                title: `Signals`,
                                icon: <QueryStatsIcon />,
                            },
                            {
                                path: `/portfolio-tracker/portfolios`,
                                title: `Portfolios`,
                                icon: <CasesRoundedIcon />,
                            },
                        ]}
                    />
                )}

                <NavigationFooter
                    rows={1}
                    options={[
                        {
                            path: `/about`,
                            title: `About`,
                            icon: <InfoIcon />,
                        },
                        {
                            path: `/signin`,
                            title: `Sign in`,
                            icon: <AccountCircleIcon />,
                            disabled: loggedIn,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
