import * as React from "react";

import { Box, Grid, TextField } from "@mui/material";

import CustomButton from "../../../helper-components/input/CustomButton";

import { requestPasswordReset } from "../../../helper-functions/api/authQueries";

import { getLanguage } from "../../../config/language/language";

export const PasswordResetRequestForm = (props) => {
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        const body = {
            email: data.get("email"),
        };

        requestPasswordReset(props, body);
    };

    return (
        <Box className="box" minWidth="300px" component="form" onSubmit={handleSubmit}>
            <TextField required name="email" label="Email" id="email" margin="normal" fullWidth />

            <Grid container>
                <CustomButton {...props} title={getLanguage().BUTTON_REQUEST_RESET_PASSWORD} type="submit" fullWidth />
            </Grid>
        </Box>
    );
};
