import * as React from "react";

import { Box, TextField, Grid, FormControlLabel, Checkbox } from "@mui/material";

import CustomButton from "../../../../helper-components/input/CustomButton";

import { getValueFromObjectArrayByKey } from "../../../../helper-functions/utils/validation/object.js";
import { getJwtValueByKey } from "../../../../helper-functions/utils/user/auth.js";
import { deleteUsers } from "../../../../helper-functions/api/userQueries";

import * as Paths from "../../../../config/constants/Paths.js";
import { getLanguage } from "../../../../config/language/language.js";

/**
 * Allow the user itself or the admin to delete some user.
 * Require password confirmation.
 */
export default function UserDeleteForm(props) {
    const userId = getJwtValueByKey(props, "id");

    const disclaimerText = props.isAdminComponent ? getLanguage().FORM_DELETE_ACCOUNT_ADMIN : getLanguage().FORM_DELETE_ACCOUNT_USER;
    const passwordLabel = props.isAdminComponent ? getLanguage().FORM_CONFIRMATION_PASSWORD_ADMIN : getLanguage().FORM_CONFIRMATION_PASSWORD_USER;

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const body = {
            ids: props.isAdminComponent ? getValueFromObjectArrayByKey(props.selectedData) : [userId],
            password: data.get("passwordDelete"),
        };

        deleteUsers(props, Paths.API_USERS_DELETE_PATH, body);
        clearForm();
    };

    const clearForm = () => {
        document.getElementById("userDeleteForm").reset();
    };

    return (
        <Box className="box" id="userDeleteForm" component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField required fullWidth name="passwordDelete" label={passwordLabel} type="password" id="passwordDelete" autoComplete="password" />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox required color="primary" />} label={disclaimerText} />
                </Grid>
            </Grid>

            <CustomButton {...props} title={getLanguage().BUTTON_DELETE_ACCOUNT} onClick={props.resetErrorBoundary} className="button" type="submit" fullWidth color="error" />
        </Box>
    );
}
