import { createCriteriaParameters, standardDeleteQuery, standardGetQuery, standardPostQuery, standardPutQuery } from "../utils/misc/api";
import { handleAddPortfolios, handleAddPortfolio, handleAddPortfolioAssets, handleAddPortfolioTrades, handleAddPortfolioAsset } from "../utils/misc/portfolio";
import { handleUpdatePortfolio, handleCreatePortfolioAsset, handleCreatePortfolioTrade } from "../utils/misc/portfolio";
import { handleDeletePortfolio, handleDeletePortfolioAssets, handleDeletePortfolioTrades } from "../utils/misc/portfolio";
import { replacePlaceholders, handleCreatePortfolio } from "../utils/misc/portfolio";

import * as Paths from "../../config/constants/Paths";
import { getLanguage } from "../../config/language/language";

// ==============
// PORTFOLIOS
// ==============

/**
 * Function for querying portfolios with filters, pagination, sorting etc.
 */
export const queryPortfolios = (props, options = {}) => {
    const path = Paths.USER_PORTFOLIOS_PATH + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddPortfolios(props, response.body);

        const states = props.portfolios;
        if (options.page) states.setQueriedUntil(options.page);
        if (response.body.total) states.setRowCount(response.body.total);
    });
};

/**
 * Get info about a specific portfolio using it's id.
 * NB! Uses 'props.portfolio.setData' to set the data, which is a state in the 'Portfolio' page.
 */
export const queryPortfolio = (props, options = {}) => {
    const path = replacePlaceholders(Paths.USER_PORTFOLIO_PATH, options);

    standardGetQuery(props, path, (response) => {
        handleAddPortfolio(props, response.body);
        // No pagination.
    });
};

/**
 * Function for creating a basic portfolio.
 */
export const createPortfolio = (props, options = {}) => {
    const path = Paths.USER_PORTFOLIOS_PATH;

    standardPostQuery(props, path, options.body, (response) => {
        handleCreatePortfolio(props, response.body);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_PORTFOLIO_CREATED });
        props.dialog.closeDialog({ id: "portfolio-create" }); // Manually close the dialog with a certain id.
    });
};

/**
 * Function for updating a portfolio.
 * NB! Uses 'props.portfolio.setData' to set the data, which is a state in the 'Portfolio' page.
 */
export const updatePortfolio = (props, options = {}) => {
    const path = replacePlaceholders(Paths.USER_PORTFOLIO_PATH, options);

    standardPutQuery(props, path, options.body, (response) => {
        handleUpdatePortfolio(props, response.body);
        props.refreshPortfolio();

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_PORTFOLIO_UPDATED });
    });
};

/**
 * Function for deleting portfolio(s).
 */
export const deletePortfolios = (props, options = {}) => {
    const path = Paths.USER_PORTFOLIOS_PATH;

    standardDeleteQuery(props, path, options.body, (response) => {
        handleDeletePortfolio(props, options.ids);

        const states = props.portfolios;
        states.setRowCount(states.rowCount - options.ids.length);
        states.setSelected([]);

        props.showSnackbar({ message: "Portfolio(s) successfully deleted!" });
    });
};

export const queryPossiblePortfolioCurrencies = (props) => {
    const path = `${Paths.USER_PORTFOLIOS_PATH}/currencies`;

    standardGetQuery(props, path, (response) => {
        props.setPossibleCurrencies(response.body?.currencies.map((obj) => obj.symbol));
    });
};

// ==============
// PORTFOLIO ASSETS
// ==============

/**
 * Function for querying portfolio assets with filters, pagination, sorting etc.
 * NB! Uses 'props.portfolioAssets.setData' to set the data, which is a state in the 'Portfolio' page.
 */
export const queryPortfolioAssets = (props, options = {}) => {
    const path = replacePlaceholders(Paths.USER_PORTFOLIO_ASSETS_PATH, options) + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddPortfolioAssets(props, response.body);

        const states = props.portfolioAssets;
        if (options.page) states.setQueriedUntil(options.page);
        if (response.body.total) states.setRowCount(response.body.total);
    });
};

/**
 * Function for querying portfolio assets with filters, pagination, sorting etc.
 * NB! Uses 'props.portfolioAssets.setData' to set the data, which is a state in the 'Portfolio' page.
 */
export const queryPortfolioAsset = (props, options = {}) => {
    const path = replacePlaceholders(Paths.USER_PORTFOLIO_ASSET_PATH, options);

    standardGetQuery(props, path, (response) => {
        handleAddPortfolioAsset(props, response.body);
        // No pagination.
    });
};

/**
 * Function for creating adding new asset(s) to a portfolio.
 * NB! Uses 'props.portfolioAssets.setData' to set the data, which is a state in the 'Portfolio' page.
 */
export const createPortfolioAsset = (props, options = {}) => {
    const path = replacePlaceholders(Paths.USER_PORTFOLIO_ASSETS_PATH, options);

    standardPostQuery(props, path, options.body, (response) => {
        handleCreatePortfolioAsset(props, response.body);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_PORTFOLIO_ASSET_CREATED });
    });
};

/**
 * Function for deleting portfolio asset(s).
 * NB! Uses 'props.portfolioAssets.setData' to set the data, which is a state in the 'Portfolio' page.
 */
export const deletePortfolioAssets = (props, options = {}) => {
    const path = replacePlaceholders(Paths.USER_PORTFOLIO_ASSETS_PATH, options);

    standardDeleteQuery(props, path, options.body, (response) => {
        handleDeletePortfolioAssets(props, options.assetIds);

        const states = props.portfolioAssets;
        states.setRowCount(states.rowCount - options.assetIds.length);
        states.setSelected([]);

        props.showSnackbar({ message: "Portfolio asset(s) successfully deleted!" });

        checkPortfolioTrades(props, options);
    });
};

/**
 * If there are any trades for the asset, delete them as well.
 */
const checkPortfolioTrades = (props, options) => {
    const states_v2 = props.portfolioTrades;
    const tradesIdsRelatedToAssetIds = states_v2.data.filter((item) => options.assetIds.includes(item.asset.id)).map((item) => item.id);

    states_v2.setRowCount(states_v2.rowCount - tradesIdsRelatedToAssetIds.length);

    states_v2.setData((prev) => {
        return prev.filter((item) => {
            return !tradesIdsRelatedToAssetIds.includes(item.id);
        });
    });

    props.showSnackbar({ message: "Related trade(s) successfully deleted!" });
    states_v2.setSelected([]);
};

// ==============
// PORTFOLIO TRADES
// ==============

/**
 * Function for querying portfolio assets with filters, pagination, sorting etc.
 * NB! Uses 'props.portfolioTrades.setData' to set the data, which is a state in the 'Portfolio' page.
 */
export const queryPortfolioTrades = (props, options = {}) => {
    const path = replacePlaceholders(Paths.USER_PORTFOLIO_TRADES_PATH, options) + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddPortfolioTrades(props, response.body);

        const states = props.portfolioTrades;
        if (options.page) states.setQueriedUntil(options.page);
        if (response.body.total) states.setRowCount(response.body.total);
    });
};

/**
 * Function for creating a basic portfolio.
 */
export const createPortfolioTrade = (props, options = {}) => {
    const path = replacePlaceholders(Paths.USER_PORTFOLIO_ASSET_TRADES_PATH, options);

    standardPostQuery(props, path, options.body, (response) => {
        handleCreatePortfolioTrade(props, response.body);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_PORTFOLIO_TRADE_CREATED });
        props.dialog.closeDialog({ id: "portfolio-trade-create" }); // Manually close the dialog with a certain id.

        checkPortfolioAsset(response, props, options);
    });
};

/**
 * If the asset is not already in the portfolio, add it.
 */
const checkPortfolioAsset = (response, props, options) => {
    const assetId = response?.body?.asset?.id;

    if (assetId && !props.portfolioAssets.data.some((item) => item.asset.id === assetId)) {
        queryPortfolioAsset(props, { ...options, assetId });
    }
};

/**
 * Function for deleting portfolio asset(s).
 * NB! Uses 'props.portfolioTrades.setData' to set the data, which is a state in the 'Portfolio' page.
 */
export const deletePortfolioTrades = (props, options = {}) => {
    const path = replacePlaceholders(Paths.USER_PORTFOLIO_TRADES_PATH, options);

    standardDeleteQuery(props, path, options.body, (response) => {
        handleDeletePortfolioTrades(props, options.tradeIds);

        const states = props.portfolioTrades;
        states.setRowCount(states.rowCount - options.tradeIds.length);
        states.setSelected([]);

        props.showSnackbar({ message: "Portfolio trade(s) successfully deleted!" });
    });
};
