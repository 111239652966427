import { setState } from "./utils";

/**
 * Data queried via the backend, but from external APIs.
 * E.g. news sentiment from AlphaVantage.
 *
 * Structure: { "TSLA": {}, "BTCUSD": {}, ... }
 */
export const addExternalData = (props, type, ticker, response) => {
    const setter = getCorrectExternalPropSetter(props, type);

    setState(setter, response, { overwrite: false, key: ticker });
};

// TODO: refactor
export const getCorrectExternalProp = (props, type) => {
    if (type === "NEWS_SENTIMENT") {
        return { ...props.external.newsSentiment };
    } else if (type === "DAILY_PRICE_HISTORY") {
        return { ...props.external.dailyPriceHistory };
    } else if (type === "EARNINGS_PER_SHARE") {
        return { ...props.external.earningsPerShareHistory };
    }
};

// TODO: refactor
export const getCorrectExternalPropSetter = (props, type) => {
    if (type === "NEWS_SENTIMENT") {
        return props.external.setNewsSentiment;
    } else if (type === "DAILY_PRICE_HISTORY") {
        return props.external.setDailyPriceHistory;
    } else if (type === "EARNINGS_PER_SHARE") {
        return props.external.setEarningsPerShareHistory;
    }
};

/**
 * Find and return the full key that includes a string.
 */
export const findKeyThatIncludesString = (obj, str) => {
    return Object.keys(obj).find((key) => key.toLowerCase().includes(str.toLowerCase()));
};

/**
 * Parse the AlphaVantage API response into a more usable format.
 *
 * Make a date object out of the key.
 * Push all the necessary values into the final array, sorted by the position in the sortedValuesToFind array.
 *
 * E.g. {TSLA: {Time Series (Daily): {2022-12-20: {"1. open": "146.05", "2. high": "148.47", ...}, ...}}} --> [{date: new Date("2022-12-20"), value: ["146.05", "148.47", ...]}, ...]
 */
export const parseAssetDailyPriceHistory = (props, ticker) => {
    const dailyPriceHistory = props.external.dailyPriceHistory[ticker];
    if (!dailyPriceHistory) return [];

    const keyToFind = findKeyThatIncludesString(dailyPriceHistory, "time series");
    const result = [];

    for (const [date, values] of Object.entries(dailyPriceHistory[keyToFind])) {
        const sortedValuesToFind = [
            findKeyThatIncludesString(values, "close"),
            findKeyThatIncludesString(values, "open"),
            findKeyThatIncludesString(values, "high"),
            findKeyThatIncludesString(values, "low"),
        ];

        const [close, open, high, low] = sortedValuesToFind.map((key) => values[key]).filter((value) => value !== undefined);
        const performance = ((close - open) / open) * 100;

        result.push({
            date: new Date(date),
            ticker,
            performance: performance.toFixed(2),
            value: [close, open, high, low],
        });
    }

    return result.sort((a, b) => a.date - b.date);
};

/**
 * Parse the AlphaVantage API response into a more usable format.
 *
 * Make a date object out of the key.
 * Push all the necessary values into the final array, sorted by the position in the sortedValuesToFind array.
 */
export const parseAssetEarningsPerShareHistory = (props, ticker) => {
    const earningsPerShareHistory = props.external.earningsPerShareHistory[ticker];
    if (!earningsPerShareHistory) return [];

    const keyToFind = findKeyThatIncludesString(earningsPerShareHistory, "quarterlyEarnings");
    const result = [];

    for (const [_, values] of Object.entries(earningsPerShareHistory[keyToFind])) {
        const sortedValuesToFind = [findKeyThatIncludesString(values, "fiscalDateEnding"), findKeyThatIncludesString(values, "reportedEPS")];

        const [date, eps] = sortedValuesToFind.map((key) => values[key]).filter((value) => value !== undefined);

        result.push({
            date: new Date(date),
            ticker,
            value: eps,
        });
    }

    return result.sort((a, b) => a.date - b.date);
};

/**
 * Filter the object based on the date range and return an array of filtered data.
 */
export const getAssetDailyPriceHistoryFromRange = (props, ticker, start, end) => {
    const data = parseAssetDailyPriceHistory(props, ticker);

    return data.filter((item) => {
        return item.date >= start && item.date <= end;
    });
};

/**
 * Filter the object based on the date range and return an array of filtered data.
 */
export const getAssetEarningsPerShareHistoryFromRange = (props, ticker, start, end) => {
    const data = parseAssetEarningsPerShareHistory(props, ticker);

    return data.filter((item) => {
        return item.date >= start && item.date <= end;
    });
};

/**
 * Get the last object (daily data) from the array.
 */
export const getLatestAssetDailyPriceHistory = (props, ticker) => {
    const data = parseAssetDailyPriceHistory(props, ticker);

    return data.length === 0 ? [] : data[data.length - 1];
};

/**
 * Get the latest daily price history for each asset.
 *
 * E.g. {TSLA: {Time Series (Daily): {2022-12-20: {"1. open": "146.05", "2. high": "148.47", ...}, ...}}} --> [{ticker: "TSLA", value: -2.1 }, ...]
 */
export const getLatestAssetsDailyPriceHistory = (props, tickers) => {
    const data = [];

    for (const ticker of tickers) {
        data.push(getLatestAssetDailyPriceHistory(props, ticker));
    }

    return data;
};
