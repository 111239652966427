import * as React from "react";

import { Container, Grid } from "@mui/material";

import FeedbackIcon from "@mui/icons-material/Feedback";
import FavoriteIcon from "@mui/icons-material/Favorite";

import CustomForm from "../form/CustomForm.js";
import FeedbackForm from "../form/FeedbackForm.js";
import PaperFragment from "../misc/PaperFragment.js";

import { getLanguage } from "../../config/language/language.js";

export default function Feedback(props) {
    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().FEEDBACK_TITLE} subtitle={getLanguage().FEEDBACK_SUBTITLE} icon={<FeedbackIcon />} />
                </Grid>

                <Grid item xs={12}>
                    <PaperFragment {...props} center value={<FeedbackForm {...props} />} />
                </Grid>

                <Grid item xs={12}>
                    <CustomForm title={getLanguage().FEEDBACK_SUPPORT_US_TITLE} subtitle={getLanguage().FEEDBACK_SUPPORT_US_SUBTITLE} icon={<FavoriteIcon />} />
                </Grid>
            </Grid>
        </Container>
    );
}
