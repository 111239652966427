import React from "react";

import { List, ListItemText, Typography, ListItemButton, Divider, Stack, Box, ListItemIcon, Checkbox } from "@mui/material";

import Search from "../../../../helper-components/input/Search";
import CustomButton from "../../../../helper-components/input/CustomButton";

import { queryAsset } from "../../../../helper-functions/api/assetQueries";
import { getCategory, isCombined } from "../../../../helper-functions/utils/asset/utils";
import { querySignals } from "../../../../helper-functions/api/signalQueries";
import { getAssetByKey } from "../../../../helper-functions/utils/asset/asset.js";
import { setUniqueArrayState } from "../../../../helper-functions/utils/misc/utils";
import { isObjExistsInArray } from "../../../../helper-functions/utils/validation/object";
import { formatListDescription, formatListName } from "../../../../helper-functions/utils/asset/signal.js";

import { useEndpointStatesWithPagination } from "../../../../helper-functions/hooks/states/useEndpointStatesWithPagination";

import * as Enums from "../../../../config/constants/Enums.js";

/**
 * A selection component to choose signals from a list.
 * Includes a search bar and a list of signals alongside some text info about the currently selected signals.
 *
 * Optional props:
 * - multiselect: select multiple items
 * - exclude: hide certain items from the list
 *
 * TODO: many overlapping elements with CombinedSignals page, consider refactoring / simplifying
 */
export default function SignalSelection(props) {
    const { callback, multiselect = false } = props; // Because modals don't re-render if the props' state changes, use this callback.
    const { exclude = [], selected: initalSelected = [] } = props.componentProps;

    const combinedCategory = getCategory(Enums.COMBINED_SIGNAL);
    const externalCategory = getCategory(Enums.EXTERNAL_SIGNAL);

    const combine = useEndpointStatesWithPagination("Combined signals (owner + subscribed)");

    const data = combine?.data || [];

    const externalSignals = props.signals.external;
    const combinedSignals = props.signals.combined;

    const { owner: externalOwner, subscribed: externalSubscribed } = externalSignals;
    const { owner: combinedOwner, subscribed: combinedSubscribed } = combinedSignals;

    // Combine all external and combined signals into one comprehensive state object.
    React.useEffect(() => {
        const externalOwnerData = externalOwner.data;
        const externalSubscribedData = externalSubscribed.data;

        const combinedOwnerData = combinedOwner.data;
        const combinedSubscribedData = combinedSubscribed.data;

        if (!externalOwnerData || !externalSubscribedData) return;
        if (!combinedOwnerData || !combinedSubscribedData) return;

        const combinedExternal = [...externalOwnerData, ...externalSubscribedData];
        const combinedCombined = [...combinedOwnerData, ...combinedSubscribedData];
        const combined = [...combinedExternal, ...combinedCombined];

        setUniqueArrayState(combine.setData, combined);
    }, [externalOwner.data, externalSubscribed.data, combinedOwner.data, combinedSubscribed.data]);

    const [selected, setSelected] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);

    const [searchText, setSearchText] = React.useState("");
    const [prevSearchText, setPrevSearchText] = React.useState("");
    const [queriedAssetIds, setQueriedAssetIds] = React.useState([]); // To prevent querying the same asset multiple times.

    React.useEffect(() => {
        filterItems(searchText);
    }, [data]);

    // Query assets related to the signals if they are not available.
    React.useEffect(() => {
        const temp = [];

        for (const item of filtered) {
            const assetId = item.assetId;
            const asset = getAssetByKey(props, "id", assetId);

            if (!asset && !queriedAssetIds.includes(assetId) && !temp.includes(assetId)) {
                queryAsset(props, { id: assetId });
                temp.push(assetId);
            }
        }

        setQueriedAssetIds((prev) => [...prev, ...temp]);
    }, [filtered]);

    /**
     * Set the filtered rows according to the search text.
     * Query signals from the server with this search param.
     */
    const filterItems = (searchText) => {
        const searchWords = searchText.trim().toLowerCase().split(",");
        handleQuery(searchWords);

        const availableItems = data.filter((signal) => !exclude.includes(signal.id));

        const filteredItems = availableItems.filter((signal) => {
            const asset = getAssetByKey(props, "id", signal.assetId);

            const name = signal.name?.toLowerCase() ?? "";
            const description = signal.description?.toLowerCase() ?? "";
            const signalType = isCombined(signal) ? "combined" : "external";
            const orderType = signal.orderType?.toLowerCase() ?? "";
            const assetName = asset?.name?.toLowerCase() ?? "";

            const signalDescriptionFound = searchWords.some((word) => description.includes(word));
            const signalNameFound = searchWords.some((word) => name.includes(word));
            const signalTypeFound = searchWords.some((word) => signalType.includes(word));
            const orderTypeFound = searchWords.some((word) => orderType.includes(word));
            const assetNameFound = searchWords.some((word) => assetName.includes(word));

            return signalNameFound || signalDescriptionFound || signalTypeFound || orderTypeFound || assetNameFound;
        });

        setSelected(searchText === prevSearchText ? initalSelected : []);
        setFiltered(filteredItems);
    };

    /**
     * Query the assets from the server with the search parameter.
     * Doesn't query if the search text is empty or the search text has not changed.
     */
    const handleQuery = (searchWords) => {
        if ((searchWords && searchWords.length === 0) || searchWords.includes("")) return;
        if (searchText === prevSearchText) return; // Fix for infinite query loop.

        setPrevSearchText(searchText);

        querySignals(props, { filter: { items: [], quickFilterValues: searchWords }, path: externalCategory.paths.owner, state: externalOwner });
        querySignals(props, { filter: { items: [], quickFilterValues: searchWords }, path: externalCategory.paths.subscribed, state: externalSubscribed });
        querySignals(props, { filter: { items: [], quickFilterValues: searchWords }, path: combinedCategory.paths.owner, state: combinedOwner });
        querySignals(props, { filter: { items: [], quickFilterValues: searchWords }, path: combinedCategory.paths.subscribed, state: combinedSubscribed });
    };

    /**
     * Executed when clicking on a list element.
     * Handles both single and multi-select.
     */
    const handleSelectionChange = (item) => {
        const isSelected = isObjExistsInArray(selected, item);

        if (multiselect) {
            setSelected((prev) => {
                if (isSelected) {
                    return prev.filter((obj) => obj.id !== item.id);
                } else {
                    return [...prev, item];
                }
            });
        } else {
            setSelected(isSelected ? [] : [item]);
        }
    };

    /**
     *  A list element with a checkbox.
     */
    const listElement = (item, index) => {
        const isSelected = isObjExistsInArray(selected, item);
        const name = formatListName(props, item, selected);
        const description = formatListDescription(props, item);

        return (
            <ListItemButton key={index} selected={isSelected} onClick={() => handleSelectionChange(item)} dense>
                <ListItemIcon>
                    <Checkbox edge="start" checked={isSelected} onChange={() => handleSelectionChange(item)} disableRipple />
                </ListItemIcon>

                <ListItemText
                    primaryTypographyProps={{ className: "xs-font nowrap" }}
                    secondaryTypographyProps={{ className: "ellipsis nowrap italic xs-font", pl: 2, pr: 0.5 }}
                    primary={name}
                    secondary={description}
                    className="dialog-selection-list-element"
                />
            </ListItemButton>
        );
    };

    /**
     *  Return info about the currently selected element(s).
     */
    const getCurrentSelectionInfo = () => {
        if (selected.length === 0) {
            return `Currently selected: N/A
            Description: N/A
            Signal type: N/A`;
        }

        if (selected.length === 1) {
            const selectedItem = selected[0];

            return `Currently selected: ${selectedItem?.name || "N/A"}
            Description: ${selectedItem?.description || "N/A"}
            Signal type: ${selectedItem?.condition ? "combined" : "external"}`;
        }

        return `Currently selected (${selected.length}):
        ${selected.map((signal) => signal.name).join(", ")}`;
    };

    return (
        <Box className="box">
            <Search {...props} callback={filterItems} searchText={searchText} setSearchText={setSearchText} fullWidth />

            {/* Scrollable list of selection elements */}
            <List className="dialog-selection-list hide-scrollbar">
                <>
                    {filtered.length === 0 ? <ListItemText className="center" primary={`No results found!`} /> : filtered.map((signal, index) => listElement(signal, index))}
                    {filtered.length > 0 && (
                        <Typography className="center italic sm-font sm-margin">Reached the end of the list - use the search field above to query more.</Typography>
                    )}
                </>
            </List>

            <Divider className="sm-margin" />

            {/* Text info about the currently selected element */}
            <Typography className="pre-line" variant="body2" color="textSecondary">
                {getCurrentSelectionInfo()}
            </Typography>

            <Stack direction="row" justifyContent="end" alignItems="end">
                <CustomButton {...props} title="Cancel" onClick={() => callback(false, selected)} color="error" variant="text" />
                <CustomButton {...props} title="Select" onClick={() => callback(true, selected)} color="info" variant="text" />
            </Stack>
        </Box>
    );
}
