import * as React from "react";

import { Box, Grid, TextField } from "@mui/material";

import CustomButton from "../../../../helper-components/input/CustomButton.js";
import CustomInput from "../../../../helper-components/input/CustomInput.js";

import { addFormToObject, getArrayElementsByKey, getValueFromObjectArrayByKey, setValue } from "../../../../helper-functions/utils/validation/object.js";
import { formatSelectedNameOverview } from "../../../../helper-functions/utils/asset/signal.js";
import { createUploadObject } from "../../../../helper-functions/utils/misc/upload.js";
import { validateRow } from "../../../../helper-functions/utils/validation/utils.js";
import { subscribeSignals } from "../../../../helper-functions/api/signalQueries.js";

import * as Enums from "../../../../config/constants/Enums.js";
import { getLanguage } from "../../../../config/language/language.js";

/**
 * A form to modify the visibility and code fields for external signal(s).
 *
 * Required props:
 * - state
 * - category
 */
export default function SignalSubscribeForm(props) {
    const { category, state } = props.componentProps;

    const selected = state.selected;
    const validation = category.validation;

    const [signal, setSignal] = React.useState(addFormToObject(JSON.parse(JSON.stringify(category.obj))));

    const selectedProtectedLength = getArrayElementsByKey(selected, "visibility", Enums.SIGNAL_VISIBILITY_PROTECTED).length;

    // Populate form fields during startup.
    React.useEffect(() => {
        const newSignal = JSON.parse(JSON.stringify(signal));

        const ids = getValueFromObjectArrayByKey(selected);

        newSignal.ids = ids;
        newSignal.code = "";

        setSignal(newSignal);
    }, [selected]);

    /**
     * Executed when typing something into an input field.
     */
    const onChange = (obj, key, value) => {
        const newSignal = JSON.parse(JSON.stringify(signal));
        setValue(newSignal, key, value);

        validateRow(obj, validation, newSignal, ["ids", "asset", "name", "orderType", "description", "status"]);
        setSignal(newSignal);
    };

    /**
     * Set the id and optional subscription code.
     */
    const handleSubmit = () => {
        if (!isValid()) {
            props.showSnackbar({ message: getLanguage().SNACKBAR_ERROR_SIGNAL_INVALID, color: "error" });
            return;
        }
        const body = createUploadObject(signal, ["form"], ["ids", "code"]);
        body.code = selectedProtectedLength > 0 ? signal.code : null;

        subscribeSignals(props, { state, body });
    };

    const isValid = () => {
        if (selectedProtectedLength > 0 && signal.code === "") {
            return false;
        }

        return true;
    };

    return (
        <Box className="box">
            <Grid container spacing={1}>
                <Grid item xs={12} className="center">
                    {signal.ids.map((id, index) => {
                        const name = formatSelectedNameOverview(props, id, index);

                        return <TextField key={id} className="xs-font" value={name} size="small" margin="dense" disabled />;
                    })}
                </Grid>

                {selectedProtectedLength > 0 && (
                    <Grid item xs={12}>
                        <CustomInput field="code" obj={signal} onChange={onChange} label={getLanguage().FORM_CODE_LABEL} type="password" />
                    </Grid>
                )}
            </Grid>

            <CustomButton {...props} title={getLanguage().BUTTON_SUBSCRIBE_SIGNAL} onClick={handleSubmit} className="button" fullWidth />
        </Box>
    );
}
