import * as React from "react";

import CandlestickChartDemoIcon from "@mui/icons-material/CandlestickChart";

import { getImageUrlForTicker } from "../../helper-functions/utils/asset/ticker.js";

/**
 * Show the logo of the asset, queried from an external source.
 * If no image was found, display the default candlestick icon.
 */
export default function TickerImage(props) {
    const { asset, height = "25px" } = props;
    const { ticker, assetType } = asset;

    const queryUrl = getImageUrlForTicker(assetType, ticker);

    const [imageLoaded, setImageLoaded] = React.useState(false);

    return (
        <>
            {queryUrl !== undefined ? (
                <>
                    {!imageLoaded && <CandlestickChartDemoIcon />}
                    <img
                        src={queryUrl}
                        alt={ticker}
                        style={{
                            objectFit: "cover",
                            display: imageLoaded ? "inherit" : "none",
                            marginLeft: -3,
                            height,
                        }}
                        onLoad={() => {
                            setImageLoaded(true);
                        }}
                    />
                </>
            ) : (
                <CandlestickChartDemoIcon />
            )}
        </>
    );
}
