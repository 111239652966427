import { Paper, Typography } from "@mui/material";

/**
 * Customizable and pre-formatted MUI component.
 *
 * Props:
 * - title
 * - value
 * - style props (elevation, center, p, m, flexDirection, height, width, minWidth, maxHeight)
 */
export default function PaperFragment(props) {
    const title = props.title;
    const value = props.value;

    const { elevation, center, className } = props;
    const { p = 1, m = 1, flexDirection = "column", justifyContent = "inherit" } = props;
    const { height = "inherit", width = "inherit", minWidth = "inherit", maxHeight = "inherit", minHeight = "inherit" } = props;

    return (
        <Paper
            elevation={elevation}
            className={className}
            sx={{
                p: p,
                m: m,
                display: "flex",
                flexDirection: flexDirection,
                alignItems: center ? "center" : "inherit",
                textAlign: center ? "center" : "inherit",
                justifyContent: justifyContent,
                minWidth: minWidth,
                maxHeight: maxHeight,
                minHeight: minHeight,
                height: height,
                width: width,
            }}>
            <>
                {title ? (
                    typeof title === "string" ? (
                        <Typography pb={1} variant="h5">
                            {title}
                        </Typography>
                    ) : (
                        title
                    )
                ) : undefined}

                {value}
            </>
        </Paper>
    );
}
