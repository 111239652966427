import { formatDate } from "../validation/object.js";
import { getStateByPath, setDeleteArrayState } from "../misc/utils.js";

import { handleAddAlerts } from "./alert.js";
import { getSignalById, handleAddSignals } from "./signal.js";
import { getAssetByKey, formatValue, handleAddAssets } from "./asset.js";

import { STATUS_DELETED, STATUS_DISABLED, STATUS_ENABLED, ACTION_ADD } from "../../../config/constants/Enums.js";
import { CATEGORIES } from "../../../config/constants/Objects.js";
import * as Paths from "../../../config/constants/Paths.js";

/**
 * Transform string format to integer.
 * "30m" -> 30 minutes -> 30
 * "4h" -> 4 hours -> 240
 *
 * Supported units:
 * s, m, h, D, W, M
 */
export const convertTimeframeToMinutes = (timeframe) => {
    let number = parseInt(timeframe.slice(0, -1)); // From start to the penultimate index.
    let unit = timeframe.slice(-1);

    // Convert the unit to minutes
    if (unit === "m") {
        return number;
    } else if (unit === "h") {
        return number * 60;
    } else if (unit === "d") {
        return number * 1440;
    } else if (unit === "w") {
        return number * 10080;
    } else if (unit === "mon") {
        return number * 43800;
    }
};

/**
 * Return the description of an asset.
 * Used in selection dialogs.
 *
 * E.g. "TSLA/USD    NASDAQ    $180.4"
 */
export const formatAssetListDescription = (asset = {}) => {
    const { ticker, altTicker, currency, exchange, currentPrice } = asset;

    const currencySymbol = currency?.symbol;
    const exchangeName = exchange?.name ?? "N/A";
    const price = formatValue(currentPrice, { currency: currencySymbol, nullOnError: true });

    const formattedTicker = altTicker ? altTicker : `${ticker || "N/A"}${currencySymbol ? "/" + currencySymbol : ""}`;
    const formattedPrice = price || "";

    return `${formattedTicker}${"\u00A0".repeat(4)}${exchangeName}${"\u00A0".repeat(4)}${formattedPrice}`;
};

/**
 * Return the description of an asset.
 * Used in forms to display the asset.
 *
 * E.g. "TSLA/USD"
 */
export const formatAssetFormDescription = (asset = {}) => {
    const { ticker, altTicker, currency } = asset;

    const currencySymbol = currency?.symbol;
    const formattedTicker = altTicker ? altTicker : `${ticker || "N/A"}${currencySymbol ? "/" + currencySymbol : ""}`;

    return `${formattedTicker}`;
};

export const isEnabled = (obj) => {
    return obj.status === STATUS_ENABLED;
};

export const isDisabled = (obj) => {
    return obj.status === STATUS_DISABLED;
};

export const isDeleted = (obj) => {
    return obj.status === STATUS_DELETED;
};

export const isSignal = (obj) => {
    const keys = Object.keys(obj);

    return !keys.includes("timestamp");
};

export const isExternal = (obj) => {
    const keys = Object.keys(obj);

    return !keys.includes("condition");
};

export const isCombined = (obj) => {
    return !isExternal(obj);
};

// Categories.
export const isAssetByPath = (path) => {
    return path.includes(Paths.USER_ASSETS_PATH);
};

export const isSignalByPath = (path) => {
    return path.includes(Paths.USER_SIGNALS_PATH);
};

export const isAlertByPath = (path) => {
    return path.includes(Paths.USER_ALERTS_PATH);
};

export const isUserByPath = (path) => {
    return path.includes(Paths.USER_USERS_PATH);
};

// Subcategories.
export const isSignalByType = (str) => {
    return str.toUpperCase().includes("SIGNAL");
};

export const isAlertByType = (str) => {
    return str.toUpperCase().includes("ALERT");
};

export const isAssetByType = (str) => {
    return str.toUpperCase().includes("ASSET");
};

export const isPortfoliosByType = (str) => {
    return str.toUpperCase().includes("PORTFOLIOS");
};

export const isPortfolioByType = (str) => {
    return str.toUpperCase().includes("PORTFOLIO");
};

export const isPortfolioTradeByType = (str) => {
    return str.toUpperCase().includes("PORTFOLIO_TRADE");
};

export const isExternalByType = (str) => {
    return str.toUpperCase().includes("EXTERNAL");
};

export const isCombinedByType = (str) => {
    return str.toUpperCase().includes("COMBINED");
};

export const isPublicByType = (str) => {
    return str.toUpperCase().includes("PUBLIC");
};

export const isProtectedByType = (str) => {
    return str.toUpperCase().includes("PROTECTED");
};

export const isSubscribedByType = (str) => {
    return str.toUpperCase().includes("SUBSCRIBED");
};

export const isOwnerByType = (str) => {
    return str.toUpperCase().includes("OWNER");
};

/**
 * Return category constants, e.g. external signal endpoints and validation functions.
 * The parameter is a string-enum, e.g. EXTERNAL_SIGNAL.
 */
export const getCategory = (str) => {
    if (isAssetByType(str)) return CATEGORIES.asset;

    if (isPortfolioTradeByType(str)) return CATEGORIES.portfolioTrade;
    if (isPortfoliosByType(str)) return CATEGORIES.portfolios;
    if (isPortfolioByType(str)) return CATEGORIES.portfolioAsset;

    let category;

    if (isSignalByType(str)) category = CATEGORIES.signal;
    if (isAlertByType(str)) category = CATEGORIES.alert;

    if (isExternalByType(str)) return category.external;
    if (isCombinedByType(str)) return category.combined;

    return category;
};

/**
 * Return the category of an obj (signal or alert).
 */
export const decideCategory = (obj) => {
    const category = isSignal(obj) ? CATEGORIES.signal : CATEGORIES.alert;

    if (isExternal(obj)) return category.external;
    return category.combined;
};

/**
 * Replace any placeholders with actual data from an object.
 */
export const replacePlaceholders = (props, text, obj) => {
    const alert = isSignal(obj) ? {} : obj;
    const signal = isSignal(obj) ? obj : getSignalById(props, alert.signalId);
    const asset = getAssetByKey(props, "id", signal.assetId);

    return text
        .replace("<name>", signal?.name || "N/A")
        .replace("<description>", signal?.description || "N/A")
        .replace("<ticker>", asset?.ticker || "N/A")
        .replace("<orderType>", signal?.orderType || "N/A")
        .replace("<lastAlert>", formatDate(signal?.lastAlert || "N/A"))
        .replace("<timestamp>", formatDate(alert?.timestamp || "N/A"));
};

/**
 * Set new values to states according to the path using data from a websocket message.
 * Utilizes the same functions as the standard API responses.
 */
export const addDataByWebSockets = (props, path, message = {}) => {
    const { payload } = message;

    console.log("Adding data via websockets with path", path, "and payload", payload);

    const state = getStateByPath(props, path);
    if (!state) return;

    const elements = Array.isArray(payload) ? payload : [payload];
    const response = { elements };

    if (isAlertByPath(path)) {
        handleAddAlerts(props, state, response);
        state.setRowCount((prev) => prev + elements.length);
    } else if (isAssetByPath(path)) {
        handleAddAssets(props, response);
        state.setRowCount((prev) => prev + elements.length);
    } else if (isSignalByPath(path)) {
        handleAddSignals(props, state, response);
        state.setRowCount((prev) => prev + elements.length);
    } else {
        console.warn("Function not defined for data path, couldn't set state for", path);
        return;
    }
};

/**
 * Set new values to states according to the path using data from a websocket message.
 * Utilizes the same functions as the standard API responses.
 */
export const updateDataByWebSockets = (props, path, message = {}) => {
    const { payload } = message;

    console.log("Updating data via websockets with path", path, "and payload", payload);

    const state = getStateByPath(props, path);
    if (!state) return;

    const elements = Array.isArray(payload) ? payload : [payload];
    const response = { elements };

    if (isAlertByPath(path)) {
        handleAddAlerts(props, state, response);
    } else if (isAssetByPath(path)) {
        handleAddAssets(props, response);
    } else if (isSignalByPath(path)) {
        handleAddSignals(props, state, response);
    } else {
        console.warn("Function not defined for data path, couldn't set state for", path);
        return;
    }
};

/**
 * Delete values from states according to the path using data from a websocket message.
 * Utilizes the same functions as the standard API responses.
 */
export const removeDataByWebsockets = (props, path, message = {}) => {
    const { payload } = message;

    console.log("Removing data via websockets with path", path, "and payload", payload);

    const state = getStateByPath(props, path);
    if (!state) return;

    const elements = Array.isArray(payload) ? payload : [payload];

    if (isAlertByPath(path)) {
        setDeleteArrayState(state.setData, elements, { keys: ["signalId", "timestamp"] });
        state.setRowCount((prev) => prev - elements.length);
    } else if (isSignalByPath(path)) {
        const ids = elements.map((signal) => signal.id);
        const alertState = getStateByPath(props, path.replace("signal", "alert"));
        const alertsRelated = alertState.data.filter((alert) => ids.includes(alert.signalId));

        setDeleteArrayState(state.setData, ids);
        setDeleteArrayState(alertState.setData, ids, { keys: ["signalId"] });

        state.setRowCount((prev) => prev - ids.length);
        alertState.setRowCount((prev) => prev - alertsRelated.length);
    } else {
        console.warn("Function not defined for data path, couldn't set state for", path);
        return;
    }
};
