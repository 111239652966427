import * as Objects from "../../../config/constants/Objects";

/**
 * Retrive a value from a config row by a key.
 */
export const getValue = (obj = {}, key) => {
    return obj[key];
};

/*
 * Modify the config row object and return it.
 * Don't set the actual state.
 */
export const setValue = (obj = {}, key, value) => {
    obj[key] = value;

    return obj;
};

/**
 * Update the error and helperText fields.
 */
export const setFormValues = (obj = {}, type, error, helperText) => {
    obj["form"][type]["error"] = error;
    obj["form"][type]["helperText"] = helperText;
};

/*
 * Modify the config row members object and return it.
 * Don't set the actual state.
 */
export const setNestedValue = (obj = {}, key, index, nestedKey, value) => {
    if (isEmpty(obj[key])) console.error("No such key found:", key);

    obj[key][index][nestedKey] = value;

    return obj;
};

/**
 * Return whether the configuration is saved.
 */
export const formIsSaved = (obj = {}) => {
    return obj["form"]["saved"];
};

/**
 * Return whether there exists at least one config row.
 */
export const arrayHasNoElements = (arr = []) => {
    return arr.length < 1;
};

/**
 * Return whether the row is in a saved state.
 */
export const objIsSaved = (obj = {}) => {
    const form = getValue(obj, "form");
    return form["saved"];
};

/**
 * Return whether the user has inputted something to the field.
 */
export const objHasValue = (obj = {}, key) => {
    const value = getValue(obj, key);

    if (value === undefined || value === "") return false;
    if (isObject(value) || isArray(value)) return !isEmpty(value);

    return true;
};

/**
 * Return whether any input elements in some row have errors.
 * Allows to exclude certain keys.
 */
export const objHasErrors = (obj = {}, exclude = []) => {
    const form = getValue(obj, "form");

    for (const entry of Object.entries(form)) {
        const key = entry[0];
        const value = entry[1];

        if (exclude.includes(key)) continue;
        if (value.error) return true;
    }

    return false;
};

/**
 * Pretty-print the JSON object.
 */
export const formatObj = (obj = {}) => {
    return JSON.stringify(obj, null, 4);
};

/**
 * Boolean whether an array, string or object is empty.
 */
export const isEmpty = (obj) => {
    if (obj === undefined || obj === null) return true;

    if (isArray(obj) || isString(obj)) return obj.length === 0;
    return Object.keys(obj).length === 0;
};

export const isObject = (value) => {
    return typeof value === "object" && !isArray(value) && value !== null;
};

export const isArray = (obj) => {
    return Array.isArray(obj);
};

export const isString = (obj) => {
    return typeof obj === "string";
};

/**
 * Return whether the array is sorted in ascending order with a step of 1.
 */
export const isArraySorted = (arr = []) => {
    for (let i = 1; i < arr.length; i++) {
        if (parseInt(arr[i]) < parseInt(arr[i - 1])) return false;
    }

    return true;
};

/**
 * Delete all rows where a specified key matches a value.
 *
 * Supports a single integer or an array.
 * Returns the config after deletion.
 */
export const deleteArrayElementsByKey = (array = [], key = "", values = []) => {
    const valuesArray = isArray(values) ? values : [values];
    const newRows = [...array];

    return newRows.filter((obj) => !valuesArray.includes(obj[key]));
};

/**
 * Return all rows if all keys match their respective values.
 *
 * Arrays required for keys and values.
 */
export const getArrayElementsByKeys = (array = [], keys = [], values = []) => {
    const result = [];

    for (let i = array.length - 1; i >= 0; i--) {
        const row = array[i];
        let shouldAdd = true;

        // Check if all keys match their respective values.
        for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            const value = values[j];

            if (row[key] !== value) {
                shouldAdd = false;
                break;
            }
        }

        // If all keys match, delete the row.
        if (shouldAdd) {
            result.push(row);
        }
    }

    return result;
};

/**
 * Delete row if all keys match their respective values.
 *
 * Arrays required for keys and values.
 * Returns the config after deletion.
 */
export const deleteObjByKeys = (obj = {}, keys = [], values = []) => {
    const newRows = [...obj];

    for (let i = newRows.length - 1; i >= 0; i--) {
        const row = newRows[i];
        let shouldDelete = true;

        // Check if all keys match their respective values.
        for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            const value = values[j];

            if (row[key] !== value) {
                shouldDelete = false;
                break;
            }
        }

        // If all keys match, delete the row.
        if (shouldDelete) {
            newRows.splice(i, 1);
        }
    }

    return newRows;
};

/**
 * Return the first row of some config where configKey matches the searchKey.
 *
 * E.g. [{id: 1, status: "OK"}, {id: 2, status: "OK"}]
 */
export const getArrayElementByKey = (array = [], configKey = "", searchKey = "") => {
    for (let index = 0; index < array.length; index++) {
        const row = array[index];

        if (row[configKey] === searchKey) {
            return row;
        }
    }
};

/**
 * Return all rows of some obj where configKey matches the searchKey.
 *
 * E.g. [{id: 1, status: "OK"}, {id: 2, status: "OK"}]
 */
export const getArrayElementsByKey = (array = [], configKey = "", searchKey = "") => {
    const res = [];

    for (let index = 0; index < array.length; index++) {
        const row = array[index];

        if (row[configKey] === searchKey) {
            res.push(row);
        }
    }

    return res;
};

/**
 * Return an array of all the values of the config object.
 * This can be used to find any duplicates, e.g. service keys.
 */
export const getObjValuesAsArray = (obj = {}, key = "") => {
    const values = Object.values(obj);
    const res = [];

    for (const row of values) {
        res.push(row[key]);
    }

    return res;
};

/**
 * Return an array of all the form values of the config object.
 */
export const getObjFormValuesAsArray = (obj = {}, key = "") => {
    const values = Object.values(obj);
    const res = [];

    for (const row of values) {
        res.push(row["form"][key]);
    }

    return res;
};

/**
 * Return all valueKey of some config where configKey matches the searchKey.
 *
 * E.g. return all network config rows "mode" values where interface === "eth0".
 */
export const getObjValuesByKey = (obj = {}, configKey = "", searchKey = "", returnKey = "") => {
    const res = [];

    for (let index = 0; index < obj.length; index++) {
        const row = obj[index];
        if (row[configKey] === searchKey) {
            res.push(row[returnKey]);
        }
    }

    return res;
};

/**
 * Remove objects from the configuration by their key values.
 * All others will remain.
 *
 * Preserves the original data type.
 */
export const removeObjectsByKeys = (obj, keysToRemove = []) => {
    if (isArray(obj)) {
        return obj.map((item) => removeObjectsByKeys(item, keysToRemove));
    }

    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    const updatedObj = {};

    for (const key in obj) {
        if (!keysToRemove.includes(key)) {
            updatedObj[key] = removeObjectsByKeys(obj[key], keysToRemove);
        }
    }

    return updatedObj;
};

/**
 * Adds a 'form' object to every level of the configuration.
 */
export const addFormToObject = (obj, parentKey) => {
    const formObj = Objects.FORM_ELEMENT;
    const exclude = ["members"];

    if (isArray(obj)) {
        return obj.map((item) => addFormToObject(item, parentKey));
    }

    // Leave strings and other non-objects as-is.
    if (typeof obj === "object" && obj !== null) {
        const updatedObj = {};

        for (const key in obj) {
            updatedObj[key] = addFormToObject(obj[key], key);

            // Don't add the form element if the parent key is in the 'exclude' array.
            if (exclude.includes(parentKey)) continue;

            // Add a new key to the 'form' object.
            updatedObj.form = {
                ...updatedObj.form,
                [key]: { ...formObj },
            };
        }

        return updatedObj;
    }

    return obj;
};

/**
 * Include objects from the configuration by their key values.
 * All others will be removed.
 *
 * Preserves the original data type.
 */
export const includeObjectsByKeys = (obj, keysToInclude = []) => {
    if (keysToInclude.includes("*")) return obj;
    if (isArray(obj)) return obj.map((item) => includeObjectsByKeys(item, keysToInclude));
    if (typeof obj !== "object") return obj;

    const updatedObj = { ...obj };

    for (const key in updatedObj) {
        if (!matchesWildcard(key, keysToInclude) && !keysToInclude.includes(key)) {
            delete updatedObj[key];
        } else if (typeof updatedObj[key] === "object" && updatedObj[key] !== null) {
            updatedObj[key] = includeObjectsByKeys(updatedObj[key], keysToInclude);
        }
    }

    return updatedObj;
};

/**
 * Check if a string matches any wildcard ("*") patterns in the provided array.
 */
const matchesWildcard = (str, patterns) => {
    for (const pattern of patterns) {
        if (wildcardMatch(str, pattern)) {
            return true;
        }
    }

    return false;
};

/**
 * Check if a string matches a wildcard ("*") pattern.
 */
const wildcardMatch = (str, pattern) => {
    const regex = new RegExp(`^${pattern.replace(/\*/g, ".*")}$`);
    return regex.test(str);
};

/**
 * Return 'empty key' or 'key: value'.
 */
const createDialogLine = (row, key) => {
    return row[key] === "" ? `Empty ${key}` : `${capitalizeFirstLetter(key)}: ${row[key]}`;
};

/**
 * Return multiple lines of text, showing info about each key.
 */
export const createDialogString = (row, keys) => {
    let res = "\n";

    for (const key of keys) {
        res += createDialogLine(row, key) + "\n";
    }

    return res;
};

/**
 * Duplicate a row, returning it.
 *
 * Update the 'saved' property to false.
 * If 'key' is passed, update that field to '<key> (copy)'.
 */
export const createDuplicatedRow = (row, key) => {
    const duplicatedRow = JSON.parse(JSON.stringify(row));
    duplicatedRow["form"]["saved"] = false;

    if (key) {
        duplicatedRow[key] = `${duplicatedRow[key]} (copy)`;
    }

    return duplicatedRow;
};

/**
 * Insert a new row to a config, returning the new array.
 *
 * If index is passed, set the row to that index.
 * Otherwise, add to the end of the configuration array.
 *
 */
export const insertRow = (config, row, index) => {
    const newConfig = JSON.parse(JSON.stringify(config));

    index ? newConfig.splice(index, 0, row) : newConfig.push(row);

    return newConfig;
};

/**
 * Returns the index of the first object that matches all the specified key-value pairs.
 */
export const findRowIndexByKeys = (config, keys = [], values = []) => {
    if (keys.length !== values.length) throw new Error("Keys and values arrays must have the same length.");

    for (let i = 0; i < config.length; i++) {
        const row = config[i];
        let isMatch = true;

        for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            const value = values[j];

            if (row[key] !== value) {
                isMatch = false;
                break;
            }
        }

        if (isMatch) {
            return i;
        }
    }

    return -1; // Return -1 if no match is found
};

/**
 * Return a Date object.
 *
 * Parsing:
 * - 2022-12-11T17:06:15.475445+02:00 (ISO 8601 format)
 * - 20230113T173000 (slicing format)
 * - 1677649423 (Unix timestamp in seconds)
 */
export const convertToDate = (date) => {
    let convertedDate;

    if (date != null && date !== undefined) {
        if (typeof date === "string") {
            if (date.includes("-")) {
                convertedDate = new Date(Date.parse(date));
            } else if (date.includes("T")) {
                convertedDate = new Date(date.slice(0, 4), date.slice(4, 6) - 1, date.slice(6, 8), date.slice(9, 11), date.slice(11, 13), date.slice(13, 15));
            } else if (/^\d{15}$/.test(date)) {
                convertedDate = new Date(date.slice(0, 4), date.slice(4, 6) - 1, date.slice(6, 8), date.slice(9, 11), date.slice(11, 13), date.slice(13, 15));
            } else if (/^\d{13}$/.test(date)) {
                convertedDate = new Date(parseInt(date));
            }
        }

        if (typeof date === "number") {
            convertedDate = new Date(date * 1000);
        }
    }

    return convertedDate;
};

/**
 * Return date as a formatted locale string.
 */
export const formatDate = (date, options = {}) => {
    const { onlyDate = false } = options;
    let formattedDate = "N/A";

    if (!(date instanceof Date)) {
        date = convertToDate(date);
    }

    if (isValidDate(date)) {
        if (onlyDate) {
            formattedDate = date.toLocaleDateString();
        } else {
            formattedDate = date.toLocaleString();
        }
    }

    return formattedDate;
};

/**
 * Boolean whether possible to parse date.
 */
const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
};

/**
 * Capitalize the first letter of a string.
 */
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * Return null if dealing with an empty string.
 */
export const nullIfEmptyString = (value) => {
    if (value === "") return null;
    return value;
};

/**
 * Remove all keys from an object if the value of said key is null.
 * Return the object.
 */
export const removeNullValues = (obj = {}) => {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    }

    return obj;
};

/**
 * Remove all keys from an object if the value of said key is an empty string.
 * Return the object.
 */
export const removeEmptyStrings = (obj = {}) => {
    for (const key in obj) {
        if (obj[key] === "") {
            delete obj[key];
        }
    }

    return obj;
};

/**
 * Compare two nested objects that may contain objects and arrays.
 */
export const areObjectsSimilar = (obj1 = [], obj2 = []) => {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    // Check if the values for each key are the same.
    for (let key of keys1) {
        const value1 = obj1[key];
        const value2 = obj2[key];

        if (typeof value1 === "object" && typeof value2 === "object") {
            // Recursive call for nested objects.
            if (!areObjectsSimilar(value1, value2)) {
                return false;
            }
        } else if (Array.isArray(value1) && Array.isArray(value2)) {
            // Check if arrays are equal.
            if (!areArraysEqual(value1, value2)) {
                return false;
            }
        } else if (value1 !== value2) {
            // Check if values are equal.
            return false;
        }
    }

    return true;
};

/**
 * Compare two arrays.
 */
export const areArraysEqual = (arr1 = [], arr2 = []) => {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
        if (!areObjectsSimilar(arr1[i], arr2[i])) {
            return false;
        }
    }

    return true;
};

/**
 * Sorts an array of objects based on a specified key in either ascending or descending order.
 */
export const sortArrayByKey = (array = [], key = "key", order = "asc") => {
    const sortedArray = JSON.parse(JSON.stringify(array));

    sortedArray.sort((a, b) => {
        const aValue = a[key];
        const bValue = b[key];

        if (order === "asc") {
            if (aValue < bValue) {
                return -1;
            }
            if (aValue > bValue) {
                return 1;
            }
        } else if (order === "desc") {
            if (aValue > bValue) {
                return -1;
            }
            if (aValue < bValue) {
                return 1;
            }
        }

        return 0;
    });

    return sortedArray;
};

/**
 * Combine two objects by iterating through the keys of obj1 and updating the corresponding keys in obj2.
 */
export const combineObjects = (obj1 = {}, obj2 = {}) => {
    const result = JSON.parse(JSON.stringify(obj2));

    for (const key in obj1) {
        if (obj1.hasOwnProperty(key) && typeof obj1[key] !== "undefined") {
            result[key] = obj1[key];
        }
    }

    return result;
};

/**
 * Returns an object containing only the keys whose values have changed between obj1 and obj2.
 */
export const getModifiedValues = (obj1 = {}, obj2 = {}) => {
    const modifiedValues = {};

    for (const key in obj1) {
        if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
            if (obj1[key] !== obj2[key]) {
                modifiedValues[key] = obj2[key];
            }
        } else if (obj1.hasOwnProperty(key)) {
            modifiedValues[key] = obj1[key];
        }
    }

    for (const key in obj2) {
        if (!obj1.hasOwnProperty(key)) {
            modifiedValues[key] = obj2[key];
        }
    }

    return modifiedValues;
};

/**
 * Takes an array of objects and returns an array of their key values.
 */
export const getValueFromObjectArrayByKey = (array = [], key = "id") => {
    return array.map((obj) => obj[key]);
};

/**
 * Returns whether an object exists in an array based on a specified key.
 * E.g. check if an asset with some id exists in an array.
 */
export const isObjExistsInArray = (array = [], obj = {}, key = "id") => {
    return array.some((item) => item[key] === obj[key]);
};

/**
 * Returns the index of an object which exists in an array, found based on a specified key.
 * If not found, returns -1.
 */
export const findObjIndexInArray = (array = [], obj = {}, key = "id") => {
    if (isObjExistsInArray(array, obj, key)) {
        return array.findIndex((item) => item[key] === obj[key]);
    }

    return -1;
};

/**
 * Returns whether all objects in an array have the same value for a specified key.
 */
export const isSameValueForKey = (array = [], key = "id") => {
    if (isEmpty(array)) return false;

    // Get the value of the key from the first object in the array
    const value = array[0][key];

    // Check if all objects in the array have the same value for the key
    return array.every((obj) => obj[key] === value);
};
