import axios from "axios";

import * as Paths from "../../config/constants/Paths.js";

export const api = axios.create();

const getValueFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

const setValueToLocalStorage = (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
};

// Add an access token to every HTTP request.
api.interceptors.request.use(
    (config) => {
        const token = getValueFromLocalStorage("accessToken");

        if (token !== "") {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// Deal with expired access tokens.
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401) {
            if (originalRequest._retry) {
                console.warn("Refresh token expired!");
            } else {
                console.warn("Access token expired, trying to refresh it.");

                originalRequest._retry = true;

                try {
                    const newAccessToken = await refreshToken();

                    // Update the original request with the new access token.
                    originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

                    // Retry the original request.
                    return api(originalRequest);
                } catch (e) {
                    console.error("Axios error:", e);
                }
            }
        }

        return Promise.reject(error);
    }
);

/**
 * Send a query to get a new access token using the refresh token.
 * Save both tokens to localstorage.
 */
const refreshToken = async () => {
    const refreshToken = getValueFromLocalStorage("refreshToken");

    const response = await axios.post(Paths.API_AUTH_REFRESH_TOKEN_PATH, {
        token: refreshToken,
    });

    const newAccessToken = response.data.accessToken;
    const newRefreshToken = response.data.refreshToken;

    setValueToLocalStorage("accessToken", newAccessToken);
    setValueToLocalStorage("refreshToken", newRefreshToken);

    return newAccessToken;
};
