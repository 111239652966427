import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";

/**
 * X-DataGrid custom toolbar for tables.
 * Includes buttons for filtering and searching.
 */
export function TableToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
        </GridToolbarContainer>
    );
}
