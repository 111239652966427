import { Container, Grid, Stack } from "@mui/material";

import ConstructionIcon from "@mui/icons-material/Construction";

import PaperFragment from "../misc/PaperFragment";
import CustomButton from "../input/CustomButton";
import CustomImage from "../misc/CustomImage";
import CustomForm from "../form/CustomForm";

import { getLanguage } from "../../config/language/language";

/**
 * A placeholder page for when a page is under construction and not yet ready.
 */
export default function UnderConstruction(props) {
    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm title="Page under construction" subtitle="We're currently working on this page's features. Please check back later!" icon={<ConstructionIcon />} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <CustomImage cover url={`${process.env.PUBLIC_URL}/images/ai_generated/under_construction.jpg`} maxHeight="300px" border="1px solid <theme>" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <PaperFragment
                        center
                        title="Return to app"
                        value={
                            <Stack direction="column">
                                <CustomButton {...props} title={getLanguage().BUTTON_HOME} path="/home" fullWidth />
                                <CustomButton {...props} title="Portfolios" path="/portfolio-tracker/portfolios" fullWidth />
                                <CustomButton {...props} title="Assets" path="/market-analysis/fundamental/assets" fullWidth />
                            </Stack>
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
