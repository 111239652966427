import * as React from "react";

import { Grid } from "@mui/material";

import DefaultChart from "../../../../helper-components/chart/DefaultChart.js";
import PaperFragment from "../../../../helper-components/misc/PaperFragment.js";
import StackedHeader from "../../../../helper-components/misc/StackedHeader.js";

import { formatDate } from "../../../../helper-functions/utils/validation/object.js";
import { companyOverviewOptions, cashAndDebtOptions, sharesOptions } from "../../../../helper-functions/chart/asset.js";

/**
 * An overview page for an asset.
 * Only for stocks.
 *
 * Includes:
 * - a widget showing the recent price action
 * - a description of the asset
 * - some basic info about the asset
 */
export default function FundamentalAnalysis(props) {
    const { cashFlow = {}, income = {}, balanceSheet = {} } = props;

    // Use these states to re-render the charts when the screen size changes.
    const screenWidth = props.browser.screenWidth;
    const openAppDrawer = props.sidebar.openAppDrawer;

    const maxValues = 99;

    const [selectedType, setSelectedType] = React.useState("quarterly");

    const handleSelectedType = (type) => setSelectedType(type);

    const getRawValues = (data = {}, key, type = "quarterly") => {
        const keys = key.split(".");
        const value = data?.[type]?.map((statement) => {
            let result = statement;

            for (const k of keys) {
                result = result?.[k];
            }

            return result;
        });

        return value?.slice(0, maxValues) || [];
    };

    const formatDateValues = (rawValues = []) => rawValues?.map((value) => formatDate(value, { onlyDate: true }));

    const buttons = [
        {
            title: "Quarterly",
            onClick: () => handleSelectedType("quarterly"),
            variant: selectedType === "quarterly" ? "contained" : "outlined",
            size: "small",
        },
        {
            title: "Annual",
            onClick: () => handleSelectedType("annual"),
            variant: selectedType === "annual" ? "contained" : "outlined",
            size: "small",
        },
    ];

    // Shows profits, expenses, and revenue as a bar chart which allows negative values.
    const companyOverview = React.useMemo(() => {
        const fiscalDate = formatDateValues(getRawValues(income, "fiscalDate", selectedType)).reverse();
        const revenue = getRawValues(income, "data.sales", selectedType).reverse();
        const expenses = getRawValues(income, "data.costOfGoods", selectedType)
            .map((value) => -value)
            .reverse();
        const profit = getRawValues(income, "data.grossProfit", selectedType).reverse();

        const options = companyOverviewOptions(fiscalDate, profit, expenses, revenue);

        return <DefaultChart {...props} options={options} data={fiscalDate} />;
    }, [income, selectedType, openAppDrawer, screenWidth]);

    // Shows cash (line) and debt (bar).
    const cashAndDebtChart = React.useMemo(() => {
        const fiscalDate = formatDateValues(getRawValues(balanceSheet, "fiscalDate", selectedType)).reverse();
        const cash = getRawValues(balanceSheet, "data.assets.totalAssets", selectedType).reverse();
        const debt = getRawValues(balanceSheet, "data.liabilities.totalLiabilities", selectedType).reverse();

        const options = cashAndDebtOptions(fiscalDate, cash, debt);

        return <DefaultChart {...props} options={options} data={fiscalDate} />;
    }, [balanceSheet, selectedType, openAppDrawer, screenWidth]);

    // Shows shares outstanding alongside stock compensation, issuance, and buybacks.
    const sharesChart = React.useMemo(() => {
        const fiscalDate = formatDateValues(getRawValues(cashFlow, "fiscalDate", selectedType)).reverse();
        const sharesOutstanding = getRawValues(income, "data.basicSharesOutstanding", selectedType).reverse();
        const stockCompensation = getRawValues(cashFlow, "data.operatingActivities.stockBasedCompensation", selectedType).reverse();
        const stockIssuance = getRawValues(cashFlow, "data.financingActivities.commonStockIssuance", selectedType).reverse();
        const stockBuyBack = getRawValues(cashFlow, "data.financingActivities.commonStockRepurchase", selectedType)
            .map((value) => -value)
            .reverse();

        const options = sharesOptions(fiscalDate, sharesOutstanding, stockCompensation, stockIssuance, stockBuyBack);

        return <DefaultChart {...props} options={options} data={fiscalDate} />;
    }, [cashFlow, income, selectedType, openAppDrawer, screenWidth]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <PaperFragment
                    height="450px"
                    title={<StackedHeader {...props} title="Company overview" subtitle="Compare expenses to income." buttons={buttons} />}
                    value={companyOverview}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <PaperFragment
                    p={2}
                    height="450px"
                    title={<StackedHeader {...props} title="Cash and debt" subtitle="View the cash accumulation alongside debts." buttons={buttons} />}
                    value={cashAndDebtChart}
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <PaperFragment
                    p={2}
                    height="450px"
                    title={<StackedHeader {...props} title="Shares" subtitle="View details about share flows." buttons={buttons} />}
                    value={sharesChart}
                />
            </Grid>
        </Grid>
    );
}
