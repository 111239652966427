import * as React from "react";

import { Container, Grid } from "@mui/material";

import PaidIcon from "@mui/icons-material/Paid";
import DeleteIcon from "@mui/icons-material/Delete";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import StraightIcon from "@mui/icons-material/Straight";
import MergeIcon from "@mui/icons-material/Merge";
import PeopleIcon from "@mui/icons-material/People";

import CustomForm from "../../../helper-components/form/CustomForm.js";
import StyledTable from "../../../helper-components/table/StyledTable.js";
import PaperFragment from "../../../helper-components/misc/PaperFragment.js";
import StackedHeader from "../../../helper-components/misc/StackedHeader.js";
import NavigationFooter from "../../../helper-components/misc/NavigationFooter.js";

import SignalCombineTutorial from "../signal-pages/components/SignalCombineTutorial.js";

import { getCategory } from "../../../helper-functions/utils/asset/utils.js";
import { combinedSubscribedColumns, combinedUserColumns, defaultTableOptions, getRowId, handleAlertSelection, rowSelection } from "../../../helper-functions/table/alert.js";

import { deleteAlerts, queryAlerts } from "../../../helper-functions/api/alertQueries.js";

import * as Enums from "../../../config/constants/Enums.js";
import { getLanguage } from "../../../config/language/language.js";

/**
 * The combined alerts page.
 *
 * Includes:
 * - helper tooltips
 * - a table of the combined alerts
 * - forms to modify existing alerts
 */
export default function CombinedAlerts(props) {
    const category = getCategory(Enums.COMBINED_ALERT);

    const combinedAlerts = props.alerts.combined;

    const { owner, subscribed } = combinedAlerts;

    const { selected: selectedOwner } = owner;

    const showTutorialDialog = () => {
        props.dialog.enqueueDialog({
            id: "signals-tutorial",
            title: "Combine signals",
            component: <SignalCombineTutorial />,
        });
    };

    const showAlertDeleteDialog = () => {
        props.dialog.enqueueDialog({
            title: "Delete alert(s)",
            message: `Are you sure you want to delete the selected alert(s)?
            This will delete the alert for all subscribers as well.

            This action is irreversible.`,
            actionTitle: "Delete",
            actionColor: "error",
            callback: (confirm) => handleAlertDelete(confirm),
        });
    };

    const handleAlertDelete = (confirm) => {
        if (!confirm) return;

        const body = selectedOwner.map((alert) => ({ signalId: alert.signalId, timestamp: alert.timestamp }));
        deleteAlerts(props, { state: owner, path: category.paths.delete, body });
    };

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().COMBINED_ALERTS_TITLE} subtitle={getLanguage().COMBINED_ALERTS_SUBTITLE} icon={<PaidIcon />} />
                </Grid>

                <PaperFragment
                    title={
                        <StackedHeader
                            {...props}
                            title="Owner alerts"
                            subtitle="View combined alerts created by your own signals."
                            icons={[{ icon: <DeleteIcon />, tooltip: "Delete selected alerts", onClick: showAlertDeleteDialog, disabled: selectedOwner.length < 1 }]}
                            buttons={[
                                {
                                    icon: <HelpOutlineIcon />,
                                    title: "Combine signals",
                                    onClick: showTutorialDialog,
                                    variant: "outlined",
                                },
                            ]}
                        />
                    }
                    value={
                        <StyledTable
                            {...props}
                            {...defaultTableOptions}
                            states={owner}
                            columns={combinedUserColumns(props)}
                            query={(options) => queryAlerts(props, { ...options, path: category.paths.owner, state: owner })}
                            empty={getLanguage().NO_ALERTS_FOUND}
                            handleRowSelection={handleAlertSelection}
                            rowSelection={rowSelection}
                            getRowId={(row) => getRowId(row)}
                            checkboxSelection
                        />
                    }
                />

                <PaperFragment
                    title={
                        <StackedHeader
                            {...props}
                            title="Subscribed alerts"
                            subtitle="View combined alerts you have subscribed to."
                            buttons={[
                                {
                                    icon: <ShortcutIcon />,
                                    title: "Signals",
                                    onClick: () => props.browser.navigate(`/market-analysis/technical/signals/combined`),
                                    variant: "outlined",
                                },
                            ]}
                        />
                    }
                    value={
                        <StyledTable
                            {...props}
                            {...defaultTableOptions}
                            states={subscribed}
                            columns={combinedSubscribedColumns(props)}
                            query={(options) => queryAlerts(props, { ...options, path: category.paths.subscribed, state: subscribed })}
                            empty={getLanguage().NO_SUBSCRIBED_ALERTS_FOUND}
                            getRowId={(row) => getRowId(row)}
                            handleRowSelection={handleAlertSelection}
                            rowSelection={rowSelection}
                            checkboxSelection
                        />
                    }
                />

                <NavigationFooter
                    rows={2}
                    options={[
                        {
                            path: `/market-analysis/technical/alerts/external`,
                            title: `External alerts`,
                            icon: <StraightIcon />,
                        },
                        // {
                        //     path: `/market-analysis/technical/alerts/combined`,
                        //     title: `Combined alerts`,
                        //     icon: <MergeIcon />,
                        // },
                        {
                            path: `/market-analysis/technical/signals/community`,
                            title: `Find community signals`,
                            icon: <PeopleIcon />,
                        },
                        {
                            path: `/market-analysis/technical/alerts`,
                            title: `Alerts dashboard`,
                            icon: <RequestQuoteIcon />,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
