import * as React from "react";

import { Box, Grid, Typography } from "@mui/material";

import CustomInput from "../../../../helper-components/input/CustomInput.js";
import CustomButton from "../../../../helper-components/input/CustomButton.js";
import TransparentFragment from "../../../../helper-components/misc/TransparentFragment.js";

import { addFormToObject, getValueFromObjectArrayByKey, isSameValueForKey, setValue } from "../../../../helper-functions/utils/validation/object.js";
import { createUploadObject } from "../../../../helper-functions/utils/misc/upload.js";
import { validateRow } from "../../../../helper-functions/utils/validation/utils.js";
import { shareSignals } from "../../../../helper-functions/api/signalQueries.js";

import * as Enums from "../../../../config/constants/Enums.js";
import { getLanguage } from "../../../../config/language/language.js";

/**
 * A form to modify the visibility and code fields for signal(s).
 *
 * Required props:
 * - state
 * - category
 */
export default function SignalShareForm(props) {
    const { category, state } = props.componentProps;

    const selected = state.selected;
    const validation = category.validation;

    const [signal, setSignal] = React.useState(addFormToObject(JSON.parse(JSON.stringify(category.obj))));

    // Populate form fields during startup.
    React.useEffect(() => {
        const newSignal = JSON.parse(JSON.stringify(signal));

        const ids = getValueFromObjectArrayByKey(selected);
        const isSameVisibility = isSameValueForKey(selected, "visibility");
        const isSameCode = isSameValueForKey(selected, "code");

        const firstVisibility = selected?.[0]?.visibility ?? "";
        const firstCode = selected?.[0]?.code ?? "";

        newSignal.ids = ids;
        newSignal.visibility = isSameVisibility ? firstVisibility : "";
        newSignal.code = isSameCode ? firstCode : "";
        setSignal(newSignal);
    }, [selected]);

    /**
     * Executed when typing something into an input field.
     */
    const onChange = (obj, key, value) => {
        const newSignal = JSON.parse(JSON.stringify(signal));
        setValue(newSignal, key, value);

        validateRow(obj, validation, newSignal, ["asset", "name", "orderType", "description", "status"]);
        setSignal(newSignal);
    };

    /**
     * Set ids, visibility and optional subscription code.
     */
    const handleSubmit = () => {
        if (!isValid()) {
            props.showSnackbar({ message: getLanguage().SNACKBAR_ERROR_SIGNAL_INVALID, color: "error" });
            return;
        }

        const body = createUploadObject(signal, ["form"], ["ids", "visibility", "code"]);
        body.code = signal.visibility === Enums.SIGNAL_VISIBILITY_PROTECTED ? signal.code : null;

        shareSignals(props, { state, body });
    };

    const createVisibilityHelperText = () => {
        const { visibility } = signal;

        if (visibility === Enums.SIGNAL_VISIBILITY_PRIVATE) return `Selected signals will be visible only to you.`;
        if (visibility === Enums.SIGNAL_VISIBILITY_PROTECTED) return `Only users with the code can subscribe to these signals.`;
        if (visibility === Enums.SIGNAL_VISIBILITY_PUBLIC) return `Anyone can subscribe to these signals.`;
    };

    const isValid = () => {
        if (["", undefined, null].includes(signal.visibility)) {
            return false;
        }

        if (signal.visibility === Enums.SIGNAL_VISIBILITY_PROTECTED && signal.code === "") {
            return false;
        }

        return true;
    };

    return (
        <Box className="box">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TransparentFragment
                        p={0}
                        m={0}
                        center
                        title={<Typography className="lg-font sm-margin">Visibility</Typography>}
                        value={
                            <>
                                <CustomInput field="visibility" obj={signal} select={Enums.SIGNAL_VISIBILITY_TYPES} onChange={onChange} toggle />
                                <Typography className="italic xs-font sm-margin">{createVisibilityHelperText()}</Typography>

                                {signal.visibility === Enums.SIGNAL_VISIBILITY_PROTECTED && (
                                    <CustomInput field="code" obj={signal} onChange={onChange} label={getLanguage().FORM_CODE_LABEL} noFullWidth type="password" />
                                )}
                            </>
                        }
                    />
                </Grid>
            </Grid>

            <CustomButton {...props} title={getLanguage().BUTTON_SHARE_SIGNAL} onClick={handleSubmit} className="button" fullWidth />
        </Box>
    );
}
