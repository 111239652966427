import * as React from "react";

import { DateTimePicker } from "@mui/x-date-pickers";
import { TextField, TableCell, Checkbox, InputAdornment, InputLabel, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { FormControl, Select, MenuItem, FormHelperText, Typography, Button } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import CustomButton from "./CustomButton";

/**
 * Combined input component.
 * Text input field, dropdown list, button, or text.
 * Used in multiple forms.
 *
 * Required props:
 * name
 * row
 * onChange
 */
export default function CustomInput(props) {
    // Configuration.
    const name = props.field;
    const row = props.obj;
    const onChange = props.onChange;
    const onIconClick = props.onIconClick;

    // Types of input.
    const select = props.select;
    const button = props.button;
    const checkbox = props.checkbox;
    const icon = props.icon; // Combined text input field with a service selection pop-up.
    const toggle = props.toggle; // Toggle buttons, 'select' required.
    const datetime = props.datetime;
    const text = props.text;
    const inputType = props.type ? props.type : "text";

    // Style.
    const label = props.label;
    const placeholder = props.placeholder;
    const size = props.size || "medium";
    const align = props.align || "center";
    const multiline = props.multiline || false;
    const textTransform = props.textTransform;
    const minRows = props.minRows;
    const maxRows = props.maxRows;
    const error = props.error;
    const helperText = props.helperText;
    const userDisabled = props.disabled;
    const hidden = props.hidden;
    const admin = props.admin; // Only admins can modify, otherwise disabled.

    // Misc.
    const index = props.index;
    const cell = props.cell ?? false;
    const fullWidth = props.noFullWidth ? false : true;

    const formError = row?.form?.[name]?.error ?? false;
    const formText = row?.form?.[name]?.helperText ?? "";

    // Password-related fields and helper functions.
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const decidePasswordAdornment = () => {
        if (inputType === "password") {
            return <CustomButton icon={showPassword ? <VisibilityOff /> : <Visibility />} onClick={handleClickShowPassword} sx={{ p: 0 }} />;
        }
    };

    const decideFieldType = () => {
        if (inputType === "password") {
            return showPassword ? "text" : "password";
        }

        return inputType;
    };

    const decideDisabled = () => {
        let disabled = false;

        if (row && row.form && row.form.saved) disabled = true;
        if (props?.server?.waitingForResponse) disabled = true;
        if (admin && !props.role.isAdmin) disabled = true;

        return disabled || userDisabled;
    };

    const disabled = decideDisabled();
    const fieldType = decideFieldType();
    const endAdornment = props.endAdornment || decidePasswordAdornment();

    /**
     * Return the final component.
     */
    const returnComponent = () => {
        if (hidden) return;
        if (!cell) return createComponent();

        return (
            <TableCell colSpan={props.colSpan} align={align}>
                {createComponent()}
            </TableCell>
        );
    };

    /**
     * Decide which component to create.
     */
    const createComponent = () => {
        if (hidden) return;

        if (button) return buttonComponent();
        if (select && toggle) return toggleButtonComponent();
        if (select) return selectComponent();
        if (checkbox) return checkboxComponent();
        if (icon) return inputWithIconComponent();
        if (datetime) return datetimeComponent();
        if (text !== undefined) return textComponent(); // "0" is falsy.

        return inputComponent();
    };

    const handleOnChange = (e) => {
        if (index === undefined) {
            onChange(row, name, e.target.value);
        } else {
            onChange(row[index], name, e.target.value);
        }
    };

    const handleOnDateChange = (value) => {
        onChange(row, name, value);
    };

    const handleIconClick = (e) => {
        if (index === undefined) {
            onIconClick(row, name, e.target.value);
        } else {
            onIconClick(row[index], name, e.target.value);
        }
    };

    const handleOnClick = (e) => {
        if (index === undefined) {
            onChange(row, name, e.target.checked);
        } else {
            onChange(row[index], name, e.target.checked);
        }
    };

    const handleButton = (e) => {
        if (index === undefined) {
            onChange(row);
        } else {
            onChange(index);
        }
    };

    const handleToggleButton = (e, value) => {
        onChange(row, name, value);
    };

    const selectComponent = () => {
        const selection = typeof select === "function" ? select(row, name) : select;

        return (
            <FormControl fullWidth={fullWidth} error={formError}>
                <InputLabel>{label}</InputLabel>

                <Select value={row[name]} disabled={disabled} onChange={handleOnChange} size={size} label={label}>
                    <MenuItem value="">N/A</MenuItem>
                    {selection.map((value) => (
                        <MenuItem key={value} value={value} disabled={props.validSelection ? props.validSelection(row, name) : false}>
                            {textTransform === "uppercase" && isNaN(value) ? value.toUpperCase() : value}
                        </MenuItem>
                    ))}
                </Select>

                <FormHelperText>{formText}</FormHelperText>
            </FormControl>
        );
    };

    const inputComponent = () => {
        return (
            <TextField
                // Value.
                value={row[name]}
                disabled={disabled}
                onChange={handleOnChange}
                // Style.
                fullWidth={fullWidth}
                multiline={multiline}
                minRows={minRows}
                maxRows={maxRows}
                size={size}
                type={fieldType}
                // Input style.
                label={label}
                placeholder={placeholder}
                autoComplete={inputType !== "text" ? undefined : name} // Type="tel" doesn't work w/ autocomplete
                inputProps={{ style: { textTransform: textTransform }, pattern: props.pattern }}
                InputProps={{ startAdornment: props.startAdornment, endAdornment: endAdornment }}
                // Errors.
                error={error || formError}
                helperText={helperText || formText}
            />
        );
    };

    const textComponent = () => {
        const txt = row && row[name] ? row[name] : text;

        return (
            <Typography className="custom-input-text" style={props.style}>
                {txt}
            </Typography>
        );
    };

    const buttonComponent = () => {
        return (
            <Button onClick={handleButton} variant="outlined" disabled={disabled} size={size} className="sm-padding">
                {text}
            </Button>
        );
    };

    const toggleButtonComponent = () => {
        return (
            <ToggleButtonGroup color="primary" value={row[name]} exclusive onChange={handleToggleButton} aria-label="toggle button">
                {select.map((item) => (
                    <ToggleButton key={item} value={item} disabled={disabled} size={size}>
                        {item}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );
    };

    const checkboxComponent = () => {
        return (
            <FormControl error={formError} variant="standard">
                <Checkbox checked={row[name]} onChange={handleOnClick} disabled={disabled} color={formError ? "error" : "primary"} size="small" />
                <FormHelperText sx={{ textAlign: "center" }}>{formText}</FormHelperText>
            </FormControl>
        );
    };

    const inputWithIconComponent = () => {
        return (
            <TextField
                // Value.
                value={row[name]}
                disabled={disabled}
                onChange={handleOnChange}
                // Style.
                fullWidth={fullWidth}
                multiline={multiline}
                minRows={minRows}
                maxRows={maxRows}
                size={size}
                type={fieldType}
                // Input style.
                label={label}
                placeholder={placeholder}
                autoComplete={inputType !== "text" ? undefined : name} // Type="tel" doesn't work w/ autocomplete
                inputProps={{ style: { textTransform: textTransform }, pattern: props.pattern }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" sx={{ m: 0, cursor: "pointer" }}>
                            <SearchIcon onClick={handleIconClick} />
                        </InputAdornment>
                    ),
                }} // Errors.
                error={error || formError}
                helperText={helperText || formText}
            />
        );
    };

    const datetimeComponent = () => {
        return (
            <DateTimePicker
                value={row[name]}
                disabled={disabled}
                onChange={handleOnDateChange}
                format="DD/MM/YYYY HH:mm"
                label={label}
                ampm={false}
                disableFuture
                slotProps={{
                    textField: {
                        helperText: helperText || formText,
                    },
                }}
                sx={{ width: "100%" }}
                error={error || formError}
            />
        );
    };

    return returnComponent();
}
