import { setUniqueArrayState } from "./utils";

/**
 * Handle the response from the API.
 * Set the data to the state.
 */
export const handleAddFeedback = (props, response) => {
    const setState = props.feedback.setData;
    const data = response.elements;

    setUniqueArrayState(setState, data);
};
