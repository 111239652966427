import * as React from "react";

import { isMobile } from "react-device-detect";
import { ThemeProvider } from "@emotion/react";
import { createAppTheme } from "../../styles";

import { Grid, Paper, Typography, Stack, Divider, Container, Box } from "@mui/material";

import CustomImage from "../../helper-components/misc/CustomImage";
import CustomButton from "../../helper-components/input/CustomButton";
import PaperFragment from "../../helper-components/misc/PaperFragment";

import { getLanguage } from "../../config/language/language";

/**
 * A landing page describing our mission and a brief overline of the app.
 *
 * If the user happens upon this page, all the external main components, like sidebar and drawer, are hidden.
 * NB! The theme for this page is dark, regardless of the user's settings.
 */
export default function Welcome(props) {
    const theme = React.useMemo(() => createAppTheme("dark", isMobile), []);

    React.useEffect(() => {
        props.browser.setShowMainPageComponents(false);

        return () => props.browser.setShowMainPageComponents(true);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box className="welcome-page">
                <Container maxWidth="xl" sx={{ top: "0px" }}>
                    <Grid container spacing={1}>
                        {/* Header and call to action. */}
                        <Grid container className="welcome-header-image">
                            <Grid item xs={12} sm={8} md={7}>
                                <Paper className="welcome-header-text center">
                                    <Typography variant="h4" className="xxxl-font">
                                        {getLanguage().WELCOME_TITLE}
                                    </Typography>
                                    <br />
                                    <Typography className="pre-line xl-font">{getLanguage().WELCOME_SUBTITLE}</Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={8} md={7}>
                                <Paper className="welcome-header-button center">
                                    <Typography className="pre-line xl-font">{getLanguage().WELCOME_SUBTITLE_BUTTON}</Typography>
                                    <CustomButton title="Sign up for free" path="/signup" className="button xl-font" />
                                </Paper>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider className="sm-margin" />
                        </Grid>

                        {/* Features list. */}
                        <Grid container className="lg-margin">
                            {/* Market analysis */}
                            <Grid item xs={12} sm={5}>
                                <CustomImage
                                    contain
                                    url={`${process.env.PUBLIC_URL}/images/screenshots/welcome-image-1.png`}
                                    subtitle={`Dashboard charts to visualize the markets`}
                                    height="300px"
                                    border="1px solid <theme>"
                                />
                            </Grid>

                            <Grid item xs={12} sm={7}>
                                <PaperFragment
                                    title={<Typography className="pre-wrap xxl-font md-margin-top-bottom">{getLanguage().WELCOME_TEXT_1_TITLE}</Typography>}
                                    value={<Typography className="pre-wrap xl-font">{getLanguage().WELCOME_TEXT_1_SUBTITLE}</Typography>}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider className="md-margin" />
                            </Grid>

                            {/* Trading. */}
                            <Grid item xs={12} sm={7}>
                                <PaperFragment
                                    title={<Typography className="pre-wrap xxl-font md-margin-top-bottom">{getLanguage().WELCOME_TEXT_2_TITLE}</Typography>}
                                    value={<Typography className="pre-wrap xl-font">{getLanguage().WELCOME_TEXT_2_SUBTITLE}</Typography>}
                                />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <CustomImage
                                    contain
                                    url={`${process.env.PUBLIC_URL}/images/screenshots/welcome-image-2.png`}
                                    subtitle={`View and create buy and sell signals your assets`}
                                    height="350px"
                                    border="1px solid <theme>"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider className="md-margin" />
                            </Grid>

                            {/* Portfolios. */}
                            <Grid item xs={12} sm={5}>
                                <CustomImage
                                    contain
                                    url={`${process.env.PUBLIC_URL}/images/screenshots/welcome-image-3.png`}
                                    subtitle={`Visualize your assets' performance over time`}
                                    height="300px"
                                    border="1px solid <theme>"
                                />
                            </Grid>

                            <Grid item xs={12} sm={7}>
                                <PaperFragment
                                    title={<Typography className="pre-wrap xxl-font md-margin-top-bottom">{getLanguage().WELCOME_TEXT_3_TITLE}</Typography>}
                                    value={<Typography className="pre-wrap xl-font">{getLanguage().WELCOME_TEXT_3_SUBTITLE}</Typography>}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider className="md-margin" />
                            </Grid>

                            {/* AI tools. */}
                            <Grid item xs={12} md={8}>
                                <PaperFragment
                                    title={<Typography className="pre-wrap xxl-font md-margin-top-bottom">{getLanguage().WELCOME_TEXT_4_TITLE}</Typography>}
                                    value={<Typography className="pre-wrap xl-font">{getLanguage().WELCOME_TEXT_4_SUBTITLE}</Typography>}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomImage
                                    contain
                                    url={`${process.env.PUBLIC_URL}/images/screenshots/welcome-image-4.png`}
                                    subtitle={`Get up-to-date investing info from our AI-chatbot`}
                                    height="400px"
                                    border="1px solid <theme>"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider className="xs-margin" />
                        </Grid>

                        {/* Footer. */}
                        <Grid container className="lg-margin">
                            <Grid item xs={12}>
                                <PaperFragment
                                    center
                                    title={<Typography className="pre-wrap xxl-font md-margin-top-bottom">{getLanguage().WELCOME_TEXT_5_TITLE}</Typography>}
                                    value={<Typography className="pre-wrap xl-font italic ">{getLanguage().WELCOME_TEXT_5_SUBTITLE}</Typography>}
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <PaperFragment
                                    title={<Typography className="pre-wrap xxl-font md-margin-top-bottom">{getLanguage().WELCOME_TEXT_6_TITLE}</Typography>}
                                    value={<Typography className="pre-wrap xl-font">{getLanguage().WELCOME_TEXT_6_SUBTITLE}</Typography>}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <PaperFragment
                                    center
                                    title={<Typography className="xxl-font md-margin-top-bottom">{getLanguage().WELCOME_SIGN_UP}</Typography>}
                                    value={
                                        <Stack spacing={1.5}>
                                            <CustomButton {...props} title={getLanguage().BUTTON_SIGNUP} className="xl-font" path="/signup" fullWidth />
                                            <CustomButton {...props} title={getLanguage().BUTTON_SIGNIN} className="xl-font" path="/signin" fullWidth />
                                            <CustomButton {...props} title={getLanguage().BUTTON_HOME} className="xl-font" path="/home" fullWidth />
                                        </Stack>
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Divider className="md-margin" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </ThemeProvider>
    );
}
