import * as Paths from "../../../config/constants/Paths.js";
import { CURRENCY_CODES } from "../../../config/constants/Enums.js";

const forexRegex = new RegExp(`^(${CURRENCY_CODES.join("|")})+$`);
const cryptoRegex = new RegExp(`^[A-Z]+(${CURRENCY_CODES.join("|")})$`);

/**
 * Outputs "forex", "stock", "crypto" or "other" depending on the input ticker parameter.
 *
 * EURUSD -> forex
 * TSLA -> stock
 * BTCUSD -> crypto
 * US10Y, TSLA/MSTR, etc. -> other
 */
export const classifyTicker = (ticker) => {
    if (forexRegex.test(ticker)) {
        return "FOREX";
    } else if (ticker.match(/^[A-Z]{1,4}$/)) {
        return "STOCK";
    } else if (cryptoRegex.test(ticker)) {
        return "CRYPTO";
    }

    return "OTHER";
};

/**
 * If the ticker string ends with some currency element, slice it off.
 * BTCUSD -> BTC
 */
export const removeFiatDenominatorFromTicker = (ticker) => {
    if (ticker !== undefined) {
        for (let i = CURRENCY_CODES.length - 1; i >= 0; i--) {
            if (ticker.endsWith(CURRENCY_CODES[i])) {
                ticker = ticker.slice(0, -CURRENCY_CODES[i].length);
            }
        }
    }

    return ticker;
};

/**
 * Return the query address to get the image for the asset.
 * Return undefined if unavailable.
 */
export const getImageUrlForTicker = (type, ticker) => {
    if (type === "CRYPTO") {
        return Paths.CRYPTO_TICKER_URL.replace("<ticker>", removeFiatDenominatorFromTicker(ticker).replace("/", "").toUpperCase());
    } else if (type === "STOCK") {
        return Paths.STOCK_TICKER_URL.replace("<ticker>", ticker.toLowerCase());
    }

    return undefined;
};

/**
 * Return the formatted ticker for AlphaVantage API queries.
 * E.g. COIN, CRYPTO:BTC, FOREX:USD
 */
export const getAlphaVantageTicker = (type, ticker) => {
    if (!type || !ticker) return;

    if (type === "CRYPTO") {
        return "CRYPTO:" + removeFiatDenominatorFromTicker(ticker).toUpperCase();
    } else if (type === "FOREX") {
        return "FOREX:" + removeFiatDenominatorFromTicker(ticker).toUpperCase();
    }

    return ticker.toUpperCase();
};

/**
 * Filter the tickers by classification type, e.g. "stock" or "equity".
 * Return the sorted and filtered array.
 */
export const sortAndFilterTickersByClassification = (tickers, type) => {
    const filteredTickers = tickers.filter(function (ticker) {
        return classifyTicker(ticker) === type;
    });

    // Sort the filteredTickers alphabetically.
    const sortedTickers = filteredTickers.sort(function (a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    });

    return sortedTickers;
};
