import { useEffect, useState } from "react";

import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";

import { setState } from "../utils/misc/utils.js";

import * as General from "../../config/constants/General.js";
import * as Paths from "../../config/constants/Paths.js";

/**
 * WebSocket hook, used for fast-paced two-way communication.
 * Allows us to subscribe to different topic endpoints in order to get real-time info and communicate with the server.
 *
 * NB!
 * The WebSocket connection phase is important - if we add the JWT as a header, we can receive our user-specific updates.
 * Otherwise, we still get connected (even to the user-specific endpoint), but with a random session id, and thus we won't receive any user-specic updates.
 *
 * TODO: WebSockets are now ubiquitous, thus SockJS might not be needed:
 * https://stomp-js.github.io/guide/stompjs/rx-stomp/using-stomp-with-sockjs.html
 */
export default function useWebSocket(isLoggedIn, accessToken, isConnectionToServerEstablished) {
    // Hard-code the server WebSocket path for the local development version, as for some reason ws:// doesn't seem to be proxied.
    const correctPort = General.DEVELOPMENT_ENV ? General.HARDCODED_SERVER_PORT : General.SERVER_PORT;
    const url = `${General.SERVER_IP}${correctPort ? ":" + correctPort : ""}${Paths.WEBSOCKET_PATH}`;
    const wsUrl = `${General.SERVER_IP.replace("http", "ws")}${correctPort ? ":" + correctPort : ""}${Paths.WEBSOCKET_PATH}/`;

    const [topics, setTopics] = useState([]);
    const [webSocketSessionId, setWebSocketSessionId] = useState("");
    const [webSocketClient, setWebSocketClient] = useState(null);

    // Connect to the backend WebSocket, either publicly, or user-specific.
    useEffect(() => {
        establishWebSocketConnection();
    }, [isLoggedIn, isConnectionToServerEstablished, webSocketClient]);

    /**
     * Create a WebSocket client and connect to the server.
     *
     * If no connection w/ server - return.
     * If logged in (but client was already set) - reset the connection in order to reconnect using user credentials.
     */
    const establishWebSocketConnection = () => {
        if (!isConnectionToServerEstablished) return;
        if (webSocketClient) return;

        if (isLoggedIn && accessToken !== "") {
            console.log("CONNECT to user-specific WebSocket...");
            connectToWebSocket({ Authorization: `Bearer ${accessToken}` });
        } else {
            console.log("CONNECT to public WebSocket...");
            connectToWebSocket();
        }

        return () => {
            webSocketClient && webSocketClient.disconnect();
        };
    };

    /**
     * Send a CONNECT message.
     */
    const connectToWebSocket = (authHeader = {}) => {
        const client = Stomp.over(function () {
            return new SockJS(url);
        });

        client.connect(authHeader, (frame) => {
            if (client.webSocket._transport.url) {
                console.log("wsUrl", wsUrl);
                console.log("client.webSocket._transport.url", client.webSocket._transport.url);
                setWebSocketSessionId(getSessionId(client.webSocket._transport.url));
            }

            setWebSocketClient(client);
        });
    };

    /**
     * Get the session id after connecting to the WebSocket.
     * That will be out user identifier.
     **/
    const getSessionId = (url) => {
        return url
            .replace(wsUrl, "")
            .replace("/websocket", "")
            .replace(/^[0-9]+\//, "");
    };

    /**
     * Subscribe to a channel (topic).
     * The topic, along with the callback will be saved in the topics array.
     **/
    const subscribeToWebSocketTopic = (topic, callback) => {
        if (!webSocketClient) return;

        // TODO: does this below create errors in backend?
        if (topics.some((entry) => entry.name === topic)) {
            console.log(`${topic} already subscribed to, cancelling subscription request!`);
            return;
        }

        webSocketClient.subscribe(topic, (frame) => {
            callback(JSON.parse(frame.body));
        });

        setState(setTopics, { name: topic });
    };

    /**
     * Send some data to the websocket enpoint.
     **/
    const sendMessageToWebSocket = (endpoint, body) => {
        if (!webSocketClient) return;

        webSocketClient.send(endpoint, {}, JSON.stringify(body));
    };

    /**
     * Unsubscibe from a channel (topic).
     * The topic, along with the callback will be removed from the topics array.
     **/
    const unsubscribeFromWebSocketTopic = (topic) => {
        if (!webSocketClient) return;

        webSocketClient.unsubscribe(topic);
        setTopics(topics.filter((t) => t.name !== topic));
    };

    /**
     * Execute some function if the WebSocket connection was unexpectedly closed.
     **/
    const executeFunctionsAfterWebSocketClose = (callback) => {
        if (!webSocketClient) return;

        webSocketClient.onWebSocketClose = () => {
            console.warn("WebSocket connection closed!");
            callback();
        };
    };

    /**
     * Execute some function if the server returned a message with an ERROR header.
     **/
    const executeFunctionsAfterWebSocketError = (callback) => {
        if (!webSocketClient) return;

        webSocketClient.onStompError = (frame) => {
            console.error("Broker reported error: " + frame.headers["message"]);
            callback();
        };
    };

    /**
     * Disconnect from the WebSocket connection and reset any variables.
     */
    const resetWebSocket = () => {
        if (!webSocketClient) return;
        console.warn("Resetting WebSocket connection...");

        webSocketClient.disconnect();
        setTopics([]);
        setWebSocketSessionId("");
        setWebSocketClient(null);
    };

    // These parameters can be used outside of this function.
    const ws = {
        webSocketClient,
        webSocketSessionId,
        topics,
        subscribeToWebSocketTopic,
        unsubscribeFromWebSocketTopic,
        sendMessageToWebSocket,
        executeFunctionsAfterWebSocketClose,
        executeFunctionsAfterWebSocketError,
        resetWebSocket,
    };

    return ws;
}
