import * as React from "react";

import { Container, Grid, Typography } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";

import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import CustomForm from "../../helper-components/form/CustomForm.js";
import CustomImage from "../../helper-components/misc/CustomImage.js";
import PaperFragment from "../../helper-components/misc/PaperFragment.js";
import NavigationFooter from "../../helper-components/misc/NavigationFooter.js";

import { isLoggedIn } from "../../helper-functions/utils/misc/utils.js";

import { getLanguage } from "../../config/language/language.js";

/**
 * A simple "about us" page, which includes what this app in meant to do.
 */
export default function About(props) {
    const loggedIn = isLoggedIn(props);

    return (
        <Container maxWidth="xl" className="container">
            {/* Intro text. */}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().ABOUT_TITLE} subtitle={getLanguage().ABOUT_SUBTITLE} icon={<InfoIcon />} />
                </Grid>

                <Grid item xs={12}>
                    <PaperFragment title="Introduction" value={<Typography className="pre-line">{getLanguage().ABOUT_TEXT_1}</Typography>} />
                </Grid>

                {/* Trading. */}
                <Grid item xs={12} sm={7}>
                    <PaperFragment
                        title={<Typography className="pre-wrap lg-font xs-margin-top-bottom">{getLanguage().WELCOME_TEXT_2_TITLE}</Typography>}
                        value={<Typography className="pre-wrap">{getLanguage().WELCOME_TEXT_2_SUBTITLE}</Typography>}
                    />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <CustomImage
                        cover
                        url={`${process.env.PUBLIC_URL}/images/screenshots/welcome-image-2.png`}
                        subtitle={`View and create buy and sell signals your assets`}
                        height="200px"
                        border="1px solid <theme>"
                    />
                </Grid>

                {/* Portfolios. */}
                <Grid item xs={12} sm={5}>
                    <CustomImage
                        cover
                        url={`${process.env.PUBLIC_URL}/images/screenshots/welcome-image-3.png`}
                        subtitle={`Visualize your assets' performance over time`}
                        height="200px"
                        border="1px solid <theme>"
                    />
                </Grid>

                <Grid item xs={12} sm={7}>
                    <PaperFragment
                        title={<Typography className="pre-wrap lg-font xs-margin-top-bottom">{getLanguage().WELCOME_TEXT_3_TITLE}</Typography>}
                        value={<Typography className="pre-wrap">{getLanguage().WELCOME_TEXT_3_SUBTITLE}</Typography>}
                    />
                </Grid>

                {/* AI tools. */}
                <Grid item xs={12} md={7}>
                    <PaperFragment
                        title={<Typography className="pre-wrap lg-font xs-margin-top-bottom">{getLanguage().WELCOME_TEXT_4_TITLE}</Typography>}
                        value={<Typography className="pre-wrap">{getLanguage().WELCOME_TEXT_4_SUBTITLE}</Typography>}
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <CustomImage
                        cover
                        url={`${process.env.PUBLIC_URL}/images/screenshots/welcome-image-4.png`}
                        subtitle={`Get up-to-date investing info from our AI-chatbot`}
                        height="200px"
                        border="1px solid <theme>"
                    />
                </Grid>

                {/* Stonks. */}
                <Grid item xs={12}>
                    <PaperFragment
                        center
                        value={
                            <CustomImage
                                contain
                                transparent
                                url={`${process.env.PUBLIC_URL}/images/other/market_not_brr.gif`}
                                subtitle={getLanguage().ABOUT_TEXT_2}
                                height="150px"
                            />
                        }
                    />
                </Grid>

                {loggedIn && (
                    <NavigationFooter
                        rows={1}
                        options={[
                            {
                                path: `/market-analysis/fundamental/assets`,
                                title: `Assets`,
                                icon: <ListAltIcon />,
                            },
                            {
                                path: `/market-analysis/technical/signals`,
                                title: `Signals`,
                                icon: <QueryStatsIcon />,
                            },
                            {
                                path: `/portfolio-tracker/portfolios`,
                                title: `Portfolios`,
                                icon: <CasesRoundedIcon />,
                            },
                        ]}
                    />
                )}

                <NavigationFooter
                    rows={2}
                    options={[
                        {
                            path: `/home`,
                            title: `Dashboard`,
                            icon: <DashboardIcon />,
                        },
                        {
                            path: `/about`,
                            title: `About`,
                            disabled: true,
                            icon: <InfoIcon />,
                        },
                        {
                            path: `/signin`,
                            title: `Sign in`,
                            icon: <AccountCircleIcon />,
                            disabled: loggedIn,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
