import * as React from "react";

import { Container, Grid } from "@mui/material";

import MediaCard from "../../../../helper-components/misc/MediaCard";
import CustomStepper from "../../../../helper-components/form/CustomStepper";
import TransparentFragment from "../../../../helper-components/misc/TransparentFragment";
import CustomButton from "../../../../helper-components/input/CustomButton";
import { regenerateUserSecret } from "../../../../helper-functions/api/userQueries";

/**
 * A tutorial component showing how to regenerate the user's secret key.
 *
 * Uses the CustomStepper component to display the steps.
 */
export default function RegenerateSecretTutorial(props) {
    // Create the step components.
    const step1 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <TransparentFragment
                                value={`Regenerating the secret key allows you to update the credentials used in authenticating the TradingView alerts.

                                This is useful if you suspect that the key has been compromised, i.e. seen by other people.`}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step2 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} alignContent="flex-start">
                        <TransparentFragment value={`NB! The old key will be invalidated, so you will need to update the URL in all your existing TradingView alerts.`} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard
                            {...props}
                            summary="Take caution not to share the URL with anyone else, as it contains a secret key!"
                            bannerImage="/images/tutorials/tradingview_1_5.png"
                            bannerHeight={220}
                            contentHeight={90}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step3 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TransparentFragment value={`Click "Regenerate" to create the new key. You can view it in the Account page, or whenever you copy the webhook URL.`} />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomButton title="Regenerate" variant="outlined" color="error" fullWidth onClick={() => handleRegenerate()} />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    // Define the steps and the final step.
    const steps = [
        { label: "Introduction", optional: false, component: step1() },
        { label: "Consequences", optional: false, component: step2() },
    ];

    const final = { component: step3() };

    const handleRegenerate = () => {
        regenerateUserSecret(props);
    };

    return <CustomStepper steps={steps} final={final} />;
}
