import React from "react";

import { getPathFromUrl } from "../utils/page/utils";

/**
 * Dialog hook to show larger messages to the user.
 * Allows for customizability, incl. callbacks.
 *
 * Uses a queue-system so multiple dialogs won't overwrite each other.
 */
export function useDialog() {
    const [queue, setQueue] = React.useState([]);
    const [setupCompleted, setSetupCompleted] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");

    const [actionColor, setActionColor] = React.useState("");
    const [actionTitle, setActionTitle] = React.useState("");

    const [cancelColor, setCancelColor] = React.useState("");
    const [cancelTitle, setCancelTitle] = React.useState("");

    const [minHeight, setMinHeight] = React.useState(null);
    const [component, setComponent] = React.useState(null);
    const [componentProps, setComponentProps] = React.useState({});
    const [onClick, setOnClick] = React.useState(null);
    const [id, setId] = React.useState(null);

    /**
     * Add a new dialog pop-up the the queue.
     */
    const enqueueDialog = (options = {}) => {
        setQueue((prevQueue) => [...prevQueue, options]);
    };

    /**
     * Close the dialog pop-up if the id matches the current dialog.
     * If no id is provided, the dialog will be closed regardless.
     */
    const closeDialog = (options = {}) => {
        const { id: dialogId } = options;

        if (dialogId && id) {
            if (dialogId === id) {
                setId(null);
                setOpen(false);
            } else {
                console.warn(`Id ${id} does not match the current dialog id ${dialogId}, not closing!`);
            }
        } else {
            setId(null);
            setOpen(false);
        }
    };

    /**
     * Composite function to show a dialog pop-up along with the specified data.
     *
     * Don't show the dialog if the current path is the same as the hidePath.
     */
    const showDialog = (options = {}) => {
        const { id, title, message, actionTitle = "OK", actionColor = "secondary", cancelTitle = "Cancel", cancelColor = "primary" } = options;
        const { callback, component, componentProps, minHeight, hidePath } = options;

        const path = getPathFromUrl();
        if (hidePath && path === hidePath) return;

        // Content.
        setTitle(title);
        setMessage(message);
        setComponent(component);
        setComponentProps(componentProps);

        // Style.
        setActionTitle(actionTitle);
        setActionColor(actionColor);

        setCancelColor(cancelColor);
        setCancelTitle(cancelTitle);

        // Misc.
        setMinHeight(minHeight);
        setOnClick(() => callback);
        setOpen(true);
        setId(id);
    };

    // Show the next dialog from the queue.
    React.useEffect(() => {
        if (queue.length === 0) {
            setSetupCompleted(false);
        }

        if (queue.length > 0) {
            const options = queue[0];
            showDialog(options);
            setSetupCompleted(true);
        }
    }, [queue]);

    // Remove the first element from the dialog queue.
    React.useEffect(() => {
        if (!setupCompleted) return;

        if (queue.length > 0 && open === false) {
            setQueue((prevQueue) => prevQueue.slice(1));
        }
    }, [open]);

    return {
        open,
        title,
        message,
        actionColor,
        actionTitle,
        cancelColor,
        cancelTitle,
        onClick,
        component,
        componentProps,
        minHeight,
        enqueueDialog,
        closeDialog,
        setOpen,
        setId,
    };
}
