import Chart from "./Chart";

import { isEmpty } from "../../helper-functions/utils/validation/object";

/**
 * Chart which displays a bar chart.
 * Utilizes the underlying Chart component.
 *
 * Props:
 * - data
 * - options
 */
export default function DefaultChart(props) {
    const { data, options = {} } = props;

    return <Chart {...props} options={options} show={!isEmpty(data)} />;
}
