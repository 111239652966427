import React from "react";

import { defaultInitialState } from "../../table/utils.js";

import * as General from "../../../config/constants/General.js";

/**
 * Hook to define states related to different endpoints alongside pagination.
 * Allows for easy state creation and logging.
 *
 * Alerts structure:
 * [
 *   {"signalId": 1, "timestamp": "2024-01-29T16:50:57.825203Z", ...},
 *   {"signalId": 1, "timestamp": "2024-01-28T16:50:57.825203Z", ...}
 *   { ... }
 * ]
 *
 * Signals structure:
 * {
 *   1: {"id": 1, "asset": "TSLA", ...},
 *   2: {"id": 2, "asset": "BTCUSD", ...},
 *   3: { ... }
 * }
 *
 * Users (admin) structure:
 * { 7: {"id": 2, "username": ...}, 9: {"id": 5, "username": ...}, 14: { ... } }
 */
export const useEndpointStatesWithPagination = (name = "Unknown", options = {}) => {
    const { logData = false, logPagination = false, pageSize: size = General.DEFAULT_TABLE_ROWS_PER_PAGE } = options;
    const { initialSorting, initialFilter, initialState = [] } = options;

    const initialTableState = defaultInitialState({ sorting: initialSorting, filter: initialFilter });

    // Data.
    const [data, setData] = React.useState(initialState);
    const [selected, setSelected] = React.useState(initialState);

    // Pagination.
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(size);

    const [filter, setFilter] = React.useState(initialTableState.filter.filterModel);
    const [sort, setSort] = React.useState(initialTableState.sorting.sortModel);
    const [visibility, setVisibility] = React.useState(initialTableState.columns.columnVisibilityModel);

    const [queriedUntil, setQueriedUntil] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);

    React.useEffect(() => {
        if (!logData) return;

        console.log(`=== ${name} (DATA) ===`);
        console.log("data", JSON.parse(JSON.stringify(data)));
        console.log("selected", JSON.parse(JSON.stringify(selected)));
        console.log("\n");
    }, [data, selected]);

    React.useEffect(() => {
        if (!logPagination) return;

        console.log(`=== ${name} (PAGINATION) ===`);
        console.log("page", JSON.parse(JSON.stringify(page)));
        console.log("pageSize", JSON.parse(JSON.stringify(pageSize)));
        console.log("filter", JSON.parse(JSON.stringify(filter)));
        console.log("sort", JSON.parse(JSON.stringify(sort)));
        console.log("sort", JSON.parse(JSON.stringify(visibility)));
        console.log("queriedUntil", JSON.parse(JSON.stringify(queriedUntil)));
        console.log("rowCount", JSON.parse(JSON.stringify(rowCount)));
        console.log("\n");
    }, [page, pageSize, filter, sort, visibility, queriedUntil, rowCount]);

    return {
        data,
        setData,
        selected,
        setSelected,

        page,
        setPage,
        pageSize,
        setPageSize,

        filter,
        setFilter,
        sort,
        setSort,
        visibility,
        setVisibility,

        queriedUntil,
        setQueriedUntil,
        rowCount,
        setRowCount,
    };
};
