import * as React from "react";

import { Box, Grid } from "@mui/material";

import CustomButton from "../../helper-components/input/CustomButton.js";
import CustomInput from "../../helper-components/input/CustomInput.js";

import { addFormToObject, setValue } from "../../helper-functions/utils/validation/object.js";
import { validateRow } from "../../helper-functions/utils/validation/utils.js";
import { createUploadObject } from "../../helper-functions/utils/misc/upload.js";
import { getCategory } from "../../helper-functions/utils/asset/utils.js";

import { createPortfolio } from "../../helper-functions/api/portfolioQueries.js";

import * as Enums from "../../config/constants/Enums.js";
import { getLanguage } from "../../config/language/language.js";

/**
 * A form to create a new asset.
 */
export default function PortfolioAddForm(props) {
    const category = getCategory(Enums.PORTFOLIOS);

    const validation = category.validation;
    const isValid = category.isValid;

    const [portfolio, setPortfolio] = React.useState(addFormToObject(JSON.parse(JSON.stringify(category.obj))));

    React.useEffect(() => {
        if (portfolio) setValue(portfolio, "currency", "USD")
    }, [portfolio])

    /**
     * Executed when typing something into an input field.
     */
    const onChange = (obj, key, value) => {
        const newPortfolio = JSON.parse(JSON.stringify(portfolio));
        setValue(newPortfolio, key, value);

        validateRow(obj, validation, newPortfolio);
        setPortfolio(newPortfolio);
    };

    /**
     * Executed when clicking on form button.
     */
    const handleSubmit = () => {
        if (!isValid(portfolio)) {
            props.showSnackbar({ message: getLanguage().SNACKBAR_ERROR_PORTFOLIO_INVALID, color: "error" });
            return;
        }

        const body = createUploadObject(portfolio, ["form"], ["name"]);
        createPortfolio(props, { body });
    };

    return (
        <Box className="box">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomInput field="name" obj={portfolio} onChange={onChange} label="Name" />
                </Grid>
            </Grid>

            <CustomButton {...props} title={getLanguage().BUTTON_ADD_PORTFOLIO} onClick={handleSubmit} className="button" fullWidth />
        </Box>
    );
}
