import { Box, Typography } from "@mui/material";

/**
 * Customizable and pre-formatted MUI component.
 *
 * Props:
 * - title
 * - value
 * - style props (center, p, m, flexDirection, height, width, minWidth, maxHeight)
 */
export default function TransparentFragment(props) {
    const title = props.title;
    const value = props.value;

    const { center, className } = props;
    const { p = 1, m = 1, flexDirection = "column" } = props;
    const { height = "inherit", width = "inherit", minWidth = "inherit", maxHeight = "inherit" } = props;

    return (
        <Box
            className={className}
            sx={{
                p: p,
                m: m,
                display: "flex",
                flexDirection: flexDirection,
                alignItems: center ? "center" : "inherit",
                textAlign: center ? "center" : "inherit",
                minWidth: minWidth,
                maxHeight: maxHeight,
                height: height,
                width: width,
            }}>
            {title ? (
                typeof title === "string" ? (
                    <Typography pb={1} variant="h5">
                        {title}
                    </Typography>
                ) : (
                    title
                )
            ) : undefined}

            {value}
        </Box>
    );
}
