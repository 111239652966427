import * as React from "react";

import { Toolbar, Typography, Divider, SwipeableDrawer } from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import CustomButton from "../../../../helper-components/input/CustomButton.js";
import Sidebar from "../sidebar/Sidebar.js";
import { Drawer } from "./Drawer";

import { getLanguage } from "../../../../config/language/language.js";

/**
 * The master sidebar component, so to speak.
 * See https://mui.com/material-ui/react-drawer/#mini-variant-drawer
 */
export default function AppDrawer(props) {
    const isMobile = props.browser.isMobile;

    const openAppDrawer = props.sidebar.openAppDrawer;
    const toggleAppDrawer = props.sidebar.toggleAppDrawer;

    const toolbar = (
        <>
            <Toolbar className="drawer-toolbar">
                <Typography variant="subtitle1" mr={1}>
                    <strong>{openAppDrawer ? getLanguage().SIDEBAR_TITLE : ""}</strong>
                </Typography>
                <CustomButton icon={openAppDrawer ? <ChevronLeftIcon /> : <ChevronRightIcon />} onClick={toggleAppDrawer} tooltip="Open / close sidebar" />
            </Toolbar>

            <Divider />

            <Sidebar {...props} />
        </>
    );

    // Render swipeable drawer for mobile.
    if (isMobile) {
        return (
            <SwipeableDrawer open={openAppDrawer} onClose={toggleAppDrawer} onOpen={toggleAppDrawer} className="sticky-sidebar mobile-sidebar">
                {toolbar}
            </SwipeableDrawer>
        );
    }

    // Render mini-icon variant permanent drawer for other devices.
    return (
        <Drawer variant="permanent" open={openAppDrawer} className="sticky-sidebar">
            {toolbar}
        </Drawer>
    );
}
