import * as React from "react";

import { Box, TextField, FormControlLabel, Checkbox, Stack } from "@mui/material";

import CustomButton from "../../../helper-components/input/CustomButton";

import { signUp } from "../../../helper-functions/api/authQueries";
import { focusFirstIfEmptyOtherwiseSecond, inputBackgroundUnblur } from "../../../helper-functions/utils/page/utils";

import { getLanguage } from "../../../config/language/language";

/**
 * Sign up form.
 *
 * Fields:
 * username
 * email
 * password
 */
export default function SignUpForm(props) {
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        const body = {
            email: data.get("email"),
            username: data.get("username"),
            password: data.get("password"),
        };

        signUp(props, body);
        props.user.setUsername(data.get("username"));
    };

    React.useEffect(() => {
        inputBackgroundUnblur(5, 1.5, "password", "signUpBackground");
        focusFirstIfEmptyOtherwiseSecond("username", "email");
    }, []);

    return (
        <Box className="box" component="form" onSubmit={handleSubmit}>
            <TextField required id="username" label="Username" name="username" autoComplete="user" autoFocus fullWidth margin="normal" />

            <TextField required id="email" label="Email Address" name="email" autoComplete="email" fullWidth margin="normal" />

            <TextField required name="password" label="Password" type="password" id="password" autoComplete="new-password" fullWidth margin="normal" />

            <FormControlLabel control={<Checkbox value="acceptTerms" color="primary" id="acceptTerms" required />} label="I accept the terms and conditions." />

            <CustomButton {...props} title={getLanguage().BUTTON_SIGNUP} className="button" type="submit" fullWidth />

            <Stack direction="row" justifyContent="space-between" spacing={1}>
                <CustomButton {...props} title={getLanguage().ALREADY_HAVE_ACCOUNT} path="/signin" variant="text" />
                <CustomButton {...props} title="Terms and conditions" path="/terms-and-conditions" variant="text" />
            </Stack>
        </Box>
    );
}
