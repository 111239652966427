import * as React from "react";

import { Box, Typography } from "@mui/material";

import Table from "./Table.js";
import ComponentLoader from "../misc/ComponentLoader.js";

import * as General from "../../config/constants/General.js";
import { isEmpty } from "../../helper-functions/utils/validation/object.js";

/**
 * A universal wrapper for the Table component.
 * Includes:
 * - query callback when chaning page, filter, or sort
 * - a loader (spinner)
 * - empty text in case of no rows
 *
 * Required:
 * - states (e.g. props.signals.external.owner)
 * - columns (defines the table columns)
 * - query (callback function to query data with 'options' as parameter)
 *
 * Optional:
 * empty (component to show when no entries)
 * dataKey (in case of nested data, look inside the 'states' object)
 *
 * See other props via the Table component.
 */
export default function StyledTable(props) {
    const { states, columns, query } = props;
    const { empty, dataKey } = props;

    const { data: rows } = states;
    const { page, setPage, pageSize, setPageSize } = states;
    const { filter, setFilter, sort, setSort, selected, setSelected, visibility, setVisibility } = states;
    const { queriedUntil, rowCount } = states;

    // const rows = dataKey ? data.map((row) => row[dataKey]) || [] : data;
    const defaultOptions = { page, pageSize, filter, sort };

    const [loading, setLoading] = React.useState(true);

    // After a timeout, show the "no entries" text which replaces the table.
    React.useEffect(() => {
        const loop = setTimeout(() => setLoading(false), General.TABLE_LOADING_MS);

        // Return a function to clear the interval when needed.
        return () => clearTimeout(loop);
    }, []);

    // Hide loader when data is loaded.
    React.useEffect(() => {
        if (rows && !isEmpty(rows)) setLoading(false);
    }, [rows]);

    // Query data when page changes.
    React.useEffect(() => {
        if (page !== 0 && page > queriedUntil) {
            query(defaultOptions);
        }
    }, [page]);

    // Query data with filters and go to first page.
    React.useEffect(() => {
        setPage(0);
        query({ ...defaultOptions, page: 0 });
    }, [pageSize, filter, sort]);

    return (
        <Box>
            {!loading && rows.length !== 0 && (
                <Table
                    {...props}
                    // Table data.
                    rows={rows}
                    columns={columns}
                    // Pagination.
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    rowCount={rowCount}
                    // Filter, sort, selection, column visibility.
                    filter={filter}
                    setFilter={setFilter}
                    sort={sort}
                    setSort={setSort}
                    selected={selected}
                    setSelected={setSelected}
                    visibility={visibility}
                    setVisibility={setVisibility}
                />
            )}

            {/* Show text if empty */}
            <ComponentLoader
                isVisible={loading || rows.length === 0}
                isEmpty={!loading && rows.length === 0}
                empty={<Typography className="pre-wrap">{empty}</Typography>}
                showImg
            />
        </Box>
    );
}
