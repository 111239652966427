import * as React from "react";

import { Checkbox, FormControlLabel, Slider, Stack } from "@mui/material";

import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";

/**
 * Allow the user to modify the audio/notification settings.
 * Saved to localstorage.
 *
 * Elements:
 * Enable checkbox
 * Volume slider
 */
export default function NotificationSettingsForm(props) {
    const { isEnabled, setIsEnabled, volume, setVolume } = props.notifications;

    const handleAudioEnabledChange = (event) => {
        setIsEnabled(event.target.checked);
    };

    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue);
    };

    return (
        <>
            <FormControlLabel
                className="form-label"
                control={<Checkbox checked={isEnabled} onChange={handleAudioEnabledChange} color="primary" />}
                label="Enable notification sound"
            />

            <Stack className="stack" spacing={1} direction="row" alignItems="center">
                <VolumeDown />
                <Slider disabled={!isEnabled} value={volume} min={0} max={1} step={0.1} onChange={handleVolumeChange} />
                <VolumeUp />
            </Stack>
        </>
    );
}
