import * as React from "react";

import { Grid, Container, Typography } from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";

import InfoIcon from "@mui/icons-material/Info";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import TextChart from "../../../helper-components/chart/TextChart";
import CustomForm from "../../../helper-components/form/CustomForm";
import PaperFragment from "../../../helper-components/misc/PaperFragment";
import StackedHeader from "../../../helper-components/misc/StackedHeader";
import NavigationFooter from "../../../helper-components/misc/NavigationFooter";

import { getLanguage } from "../../../config/language/language";

/**
 * An overview page for a user to see their account info.
 */
export default function Account(props) {
    const userData = props?.user?.data || {};
    const { id = "", username = "...", email = "", secret = "", role = {}, score = 0 } = userData;

    const [showSensitive, setShowSensitive] = React.useState(false);
    const [formattedScore, setFormattedScore] = React.useState(99);

    // Animate the score by decrementing it until it reaches the correct value.
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (formattedScore > score) {
                setFormattedScore((prev) => (prev - 1 > score ? prev - 1 : score));
            }
        }, 1);

        return () => clearInterval(interval);
    }, [formattedScore, score]);

    // Hide or show sensitive info.
    const formattedEmail = showSensitive ? email : email.replace(/[A-Za-z0-9]/g, "*");
    const formattedSecret = showSensitive ? secret : secret.replace(/./g, "*");
    const formattedRole = !role?.name ? "N/A" : role.name.split("ROLE_")[1];

    const showSensitiveInfo = () => {
        setShowSensitive(!showSensitive);
    };

    const handleEditDetails = () => {
        props.browser.navigate("/user/settings");
    };

    const getFormattedValue = () => {
        return {
            title: `Score: ${formattedScore}`,
            subtitle: `The community score is based on your activity and contributions.`,
        };
    };

    const formattedValueData = getFormattedValue();
    const currentValueChart = React.useMemo(() => <TextChart data={formattedValueData} subtitleClassName="sm-font italic" />, [formattedValueData]);

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm title="Account" subtitle={getLanguage().ACCOUNT_SUBTITLE.replace("<username>", username)} icon={<AccountCircleIcon />} />
                </Grid>

                {/* Account info. */}
                <Grid item xs={12} sm={7}>
                    <PaperFragment
                        title={
                            <StackedHeader
                                {...props}
                                title="User details"
                                icons={[
                                    { icon: <EditIcon />, tooltip: "Edit details", onClick: handleEditDetails },
                                    { icon: showSensitive ? <VisibilityIcon /> : <VisibilityOffIcon />, tooltip: "Show / hide sensitive info", onClick: showSensitiveInfo },
                                ]}
                            />
                        }
                        value={
                            <Typography className="pre-wrap">
                                {getLanguage()
                                    .ACCOUNT_TEXT_1.replace("<id>", id)
                                    .replace("<username>", username)
                                    .replace("<role>", formattedRole)
                                    .replace("<email>", formattedEmail)
                                    .replace("<secret>", formattedSecret)}
                            </Typography>
                        }
                    />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <PaperFragment {...props} value={currentValueChart} />
                </Grid>

                <Grid item xs={12}>
                    <PaperFragment title="Next steps" value={<Typography className="pre-wrap">{getLanguage().ACCOUNT_TEXT_2}</Typography>} />
                </Grid>

                <NavigationFooter
                    rows={2}
                    options={[
                        {
                            path: `/about`,
                            title: `About`,
                            icon: <InfoIcon />,
                        },
                        {
                            path: `/market-analysis/fundamental/assets`,
                            title: `Assets`,
                            icon: <ListAltIcon />,
                        },
                        {
                            path: `/market-analysis/technical/signals`,
                            title: `Signals`,
                            icon: <QueryStatsIcon />,
                        },
                        {
                            path: `/portfolio-tracker/portfolios`,
                            title: `Portfolios`,
                            icon: <CasesRoundedIcon />,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
