import React from "react";
import * as echarts from "echarts";

import { useTheme } from "@mui/material/styles";

import ComponentLoader from "../misc/ComponentLoader";

import { isEmpty } from "../../helper-functions/utils/validation/object";

/**
 * A multi-purpose chart component.
 * See https://echarts.apache.org/examples/en/index.html
 *
 * Props:
 * - options: defines the chart to be displayed.
 * - visible: whether the chart is visible.
 *
 * NB! For some reason, we need to update the chart after it has rendered in order for the chart to be resized correctly.
 * Thus, useEffect is used.
 */
export default function Chart(props) {
    const { options = {}, show = !isEmpty(options) } = props;

    const chartRef = React.useRef(null);
    const theme = useTheme();

    const defaultDuration = theme.transitions.duration.enteringScreen;
    const screenWidth = props.browser.screenWidth;
    const toggleAppDrawer = props.sidebar.toggleAppDrawer;

    /**
     * Create a new chart instance, and set it to the page.
     * TODO: any way to decrease reloads w/o breaking everything xd?
     */
    const createChart = () => {
        if (chartRef.current && show) {
            const chartInstance = echarts.init(chartRef.current, theme.palette.mode);
            chartInstance.setOption(options);

            return chartInstance;
        }
    };

    /**
     * Resize the chart with a delay to account for animations (e.g. opening / closing sidebar).
     */
    const resizeChart = (instance, duration = defaultDuration) => {
        setTimeout(() => {
            if (instance) {
                instance.resize({ animation: { duration } });
            }
        }, duration);
    };

    // Create a new chart in case of new theme or screen width.
    React.useEffect(() => {
        const instance = createChart();

        if (instance) {
            resizeChart(instance);
            resizeChart(instance, defaultDuration * 1.2);

            return () => {
                instance.dispose();
            };
        }
    }, [theme, screenWidth, toggleAppDrawer, options]);

    return (
        <>
            <ComponentLoader isVisible={!show} />
            {show && <div ref={chartRef} className="chart" />}
        </>
    );
}
