import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";

import Iframe from "../../../../helper-components/misc/Iframe.js";
import TextTable from "../../../../helper-components/table/TextTable.js";
import PaperFragment from "../../../../helper-components/misc/PaperFragment.js";
import StackedHeader from "../../../../helper-components/misc/StackedHeader.js";

import { isEmpty } from "../../../../helper-functions/utils/validation/object.js";
import { formatValue } from "../../../../helper-functions/utils/asset/asset.js";

import { ASSET_STOCK } from "../../../../config/constants/Enums.js";
import { ASSET_FUNDAMENTALS } from "../../../../config/widgets/TradingViewWidgets.js";

/**
 * An overview page for an asset.
 * Only for stocks.
 *
 * Includes:
 * - a widget showing the recent price action
 * - a description of the asset
 * - some basic info about the asset
 */
export default function Statistics(props) {
    const theme = useTheme();

    const { asset = {}, recentStatistics = {}, historicalStatistics = {} } = props;

    const { ticker = "N/A", assetType = "N/A" } = asset;

    const isStock = assetType === ASSET_STOCK;
    const isReady = !isEmpty(asset);

    const [financialsSrc, setFinancialsSrc] = React.useState("");

    // Set the TradingView widgets' state.
    React.useEffect(() => {
        if (!isReady) return;

        setFinancialsSrc(ASSET_FUNDAMENTALS.replace("<theme>", theme.palette.mode).replace("<ticker>", ticker.replace("/", "")).replace("<height>", 900));
    }, [isReady]);

    const multiply = (a, b) => (isNaN(a * b) ? null : (a * b).toFixed(2));

    const formattedValuation = {
        "Market cap": {
            tooltip: `Market capitalization is the total value of a company's outstanding shares of stock. 
            It is calculated by multiplying the current price of a single share by the total number of shares outstanding.`,
            value: formatValue(asset?.currentPrice * recentStatistics?.sharesOutstanding, { currency: asset?.currency?.symbol }),
        },
        "Trailing P/E (ttm)": {
            tooltip: `Trailing P/E (Price-to-Earnings) ratio is a valuation ratio calculated by dividing the current market price of a stock by its earnings per share (EPS) over the past 12 months.
            Lower P/E ratios are typically considered better value, but may indicate slower growth.`,
            value: recentStatistics?.trailingPe?.toFixed(2) ?? "N/A",
        },
        "Forward P/E": {
            tooltip: `Forward P/E (Price-to-Earnings) ratio is a valuation ratio calculated by dividing the current market price of a stock by its estimated earnings per share (EPS) for the next fiscal year.
            It is based on the company's future earnings, which are not guaranteed.`,
            value: recentStatistics?.forwardPe?.toFixed(2) ?? "N/A",
        },
        "PEG ratio": {
            tooltip: `PEG (Price/Earnings to Growth) ratio is a valuation ratio that takes into account the company's earnings growth rate. 
            It is calculated by dividing the P/E ratio by the earnings growth rate.
            A PEG ratio of < 1 is considered fair value.`,
            value: recentStatistics?.pegRatio?.toFixed(2) ?? "N/A",
        },
        "P/S (ttm)": {
            tooltip: `P/S (Price-to-Sales) ratio is a valuation ratio calculated by dividing the current market price of a stock by its revenue per share over the past 12 months.
            A lower P/S ratio may indicate better value.`,
            value: recentStatistics?.priceToSalesTtm?.toFixed(2) ?? "N/A",
        },
        "P/B (mrq)": {
            tooltip: `P/B (Price-to-Book) ratio is a valuation ratio calculated by dividing the current market price of a stock by its book value per share.
            A lower P/B ratio may indicate better value.`,
            value: recentStatistics?.priceToBookMrq?.toFixed(2) ?? "N/A",
        },
        "EV/Revenue": {
            tooltip: `EV/Revenue (Enterprise Value-to-Revenue) ratio is a valuation ratio calculated by dividing the enterprise value of a company by its revenue.
            A lower EV/Revenue ratio may indicate better value.`,
            value: recentStatistics?.enterpriseToRevenue?.toFixed(2) ?? "N/A",
        },
        "EV/EBITDA": {
            tooltip: `EV/EBITDA (Enterprise Value-to-EBITDA) ratio is a valuation ratio calculated by dividing the enterprise value of a company by its EBITDA (Earnings Before Interest, Taxes, Depreciation, and Amortization).
            A lower EV/EBITDA ratio may indicate better value.`,
            value: recentStatistics?.enterpriseToEbitda?.toFixed(2) ?? "N/A",
        },
    };

    const formattedOverview = {
        "Shares outstanding": {
            tooltip: `The total number of shares of a company's stock that are currently owned by all its shareholders, including institutional investors, company insiders, and individual investors.`,
            value: recentStatistics?.sharesOutstanding ?? "N/A",
        },
        Float: {
            tooltip: `The number of shares of a company's stock that are available for trading on the open market, excluding shares held by company insiders and restricted shares.`,
            value: recentStatistics?.floatShares ?? "N/A",
        },
        "Shares short": {
            tooltip: `The total number of shares of a company's stock that have been sold short by investors and have not yet been covered or closed out.
            A high short interest may indicate that investors believe the stock's price will decline.`,
            value: `${multiply(recentStatistics?.shortPercentOfSharesOutstanding, 100) ?? "N/A"}%`,
        },
        "Shares short ratio": {
            tooltip: `The ratio of the number of shares sold short to the average daily trading volume of the stock. It is used to measure the level of investor sentiment towards a stock.`,
            value: recentStatistics?.shortRatio?.toFixed(2) ?? "N/A",
        },
        "Held by insiders": {
            tooltip: `The percentage of a company's outstanding shares that are owned by company insiders, such as executives, directors, and employees.`,
            value: `${recentStatistics?.percentHeldByInsiders?.toFixed(2) ?? "N/A"}%`,
        },
        "Held by institutions": {
            tooltip: `The percentage of a company's outstanding shares that are owned by institutional investors, such as mutual funds, pension funds, and hedge funds.`,
            value: `${recentStatistics?.percentHeldByInstitutions?.toFixed(2) ?? "N/A"}%`,
        },
        "Average volume (10 days)": {
            tooltip: `The average number of shares traded per day over a 10-day period.`,
            value: recentStatistics?.avg10Volume ?? "N/A",
        },
        "Average volume (90 days)": {
            tooltip: `The average number of shares traded per day over a 90-day period.`,
            value: recentStatistics?.avg90Volume ?? "N/A",
        },
    };

    const formattedPriceSummary = {
        "52-Week low": {
            tooltip: `The lowest price at which a stock has traded during the past 52 weeks.`,
            value: formatValue(recentStatistics?.fiftyTwoWeekLow, { currency: asset?.currency?.symbol }),
        },
        "52-Week high": {
            tooltip: `The highest price at which a stock has traded during the past 52 weeks.`,
            value: formatValue(recentStatistics?.fiftyTwoWeekHigh, { currency: asset?.currency?.symbol }),
        },
        "52-Week change": {
            tooltip: `The percentage change in the stock price over the past 52 weeks.
            A positive change indicates that the stock price has increased, while a negative change indicates that the stock price has decreased.`,
            value: `${recentStatistics?.fiftyTwoWeekChange?.toFixed(2) ?? "N/A"}%`,
        },
        Beta: {
            tooltip: `Beta measures the sensitivity of a stock's price movement relative to the overall market.
            A beta of 1 indicates that the stock's price moves in line with the market.
            A beta greater than 1 indicates that the stock is more volatile than the market.`,
            value: recentStatistics?.beta?.toFixed(2) ?? "N/A",
        },
        "50-Day moving average": {
            tooltip: `The average closing price of a stock over the past 50 trading days.`,
            value: formatValue(recentStatistics?.day50Ma, { currency: asset?.currency?.symbol }),
        },
        "200-Day moving average": {
            tooltip: `The average closing price of a stock over the past 200 trading days.`,
            value: formatValue(recentStatistics?.day200Ma, { currency: asset?.currency?.symbol }),
        },
    };

    const formattedDividends = {
        "Forward annual dividend rate": {
            tooltip: `The estimated annual dividend amount to be paid in the future, expressed as a percentage of the current stock price.`,
            value: `${recentStatistics?.forwardAnnualDividendRate?.toFixed(2) ?? "N/A"}%`,
        },
        "Forward annual dividend yield": {
            tooltip: `The estimated annual dividend amount to be paid in the future, expressed as a percentage of the current stock price.`,
            value: recentStatistics?.forwardAnnualDividendYield?.toFixed(2) ?? "N/A",
        },
        "Trailing annual dividend rate": {
            tooltip: `The actual annual dividend amount paid in the past, expressed as a percentage of the current stock price.`,
            value: `${recentStatistics?.trailingAnnualDividendRate?.toFixed(2) ?? "N/A"}%`,
        },
        "Trailing annual dividend yield": {
            tooltip: `The actual annual dividend amount paid in the past, expressed as a percentage of the current stock price.`,
            value: recentStatistics?.trailingAnnualDividendYield?.toFixed(2) ?? "N/A",
        },
        "5-Year annual dividend yield": {
            tooltip: `The average annual dividend yield over the past 5 years, expressed as a percentage of the current stock price.`,
            value: recentStatistics?.fiveYearAverageDividendYield?.toFixed(2) ?? "N/A",
        },
        "Payout ratio": {
            tooltip: `The percentage of earnings that are paid out as dividends to shareholders.`,
            value: recentStatistics?.payoutRatio?.toFixed(2) ?? "N/A",
        },
    };

    return (
        <Grid container spacing={0}>
            {isStock && (
                <>
                    <Grid item xs={12} sm={6}>
                        <PaperFragment
                            p={2}
                            title={<StackedHeader {...props} title="Valuation metrics" subtitle="Common ratios to evaluate the company." />}
                            value={<TextTable data={formattedValuation} />}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <PaperFragment
                            p={2}
                            title={<StackedHeader {...props} title="Overview" subtitle="Details regarding shares and holders." />}
                            value={<TextTable data={formattedOverview} />}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <PaperFragment
                            p={2}
                            title={<StackedHeader {...props} title="Price summary" subtitle="Statistics about recent price-action." />}
                            value={<TextTable data={formattedPriceSummary} />}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <PaperFragment
                            p={2}
                            title={<StackedHeader {...props} title="Dividends" subtitle="Payments to shareholders to distribute profits." />}
                            value={<TextTable data={formattedDividends} />}
                        />
                    </Grid>
                </>
            )}

            {!isStock && (
                <Grid item xs={12}>
                    <PaperFragment
                        p={2}
                        title={<StackedHeader {...props} title="Financials" subtitle="Analysis of financial statements and ratios to determine the viability of an asset." />}
                        value={<Iframe {...props} id="financials" height={900} loaded={isReady} srcDoc={financialsSrc} />}
                    />
                </Grid>
            )}
        </Grid>
    );
}
