import { combineObjects, findObjIndexInArray, isObjExistsInArray, objHasErrors, objHasValue } from "../validation/object.js";
import { setUniqueArrayState } from "../misc/utils.js";
import { getAssetByKey } from "./asset.js";
import { isCombined } from "./utils.js";

/**
 * Return a new state for signals.
 */
export const handleAddSignals = (props, state, response) => {
    const setState = state.setData;
    const data = response.elements;

    setUniqueArrayState(setState, data);
};

/**
 * Return a signal by a key.
 */
export const getSignalById = (props, id) => {
    for (const type in props.signals) {
        const state = props.signals[type];

        for (const key in state) {
            const data = state?.[key]?.data;

            if (data) {
                const signal = data.find((signal) => signal.id === id);
                if (signal) return signal;
            }
        }
    }

    return null;
};

/**
 * Return an array of combined signals which contain the child id as one of the ids.
 */
export const getCombinedSignalsByChildId = (props, id) => {
    const res = [];

    for (const type in props.signals) {
        const state = props.signals[type];

        for (const key in state) {
            const data = state?.[key]?.data;

            if (data) {
                const combinedSignals = data.filter((signal) => isCombined(signal) && signal.ids.includes(id));
                res.push(...combinedSignals);
            }
        }
    }

    return res;
};

/**
 * Return external and combined signals by a key.
 * E.g. "user", "subscribed", etc.
 */
export const getCombined = (props, key) => {
    const external = props.signals.external[key];
    const combined = props.signals.combined[key];

    return combineObjects(external, combined);
};

/**
 * Return whether the required row fields are all empty.
 */
const signalHasValues = (obj) => {
    return objHasValue(obj, "assetId") && objHasValue(obj, "name") && objHasValue(obj, "orderType") && objHasValue(obj, "visibility") && objHasValue(obj, "status");
};

const externalSignalHasValues = (obj) => {
    return signalHasValues(obj);
};

const combinedSignalHasValues = (obj) => {
    return signalHasValues(obj) && objHasValue(obj, "ids");
};

/**
 * Return whether some row is valid (has values and no errors).
 */
export const externalSignalIsValid = (obj) => {
    return externalSignalHasValues(obj) && !objHasErrors(obj);
};

export const combinedSignalIsValid = (obj) => {
    return combinedSignalHasValues(obj) && !objHasErrors(obj);
};

/**
 * Create a string of numbers from 1 to length, separated by commas.
 * E.g. "1, 2, 3"
 */
export const createNumberString = (length) => Array.from({ length }, (_, i) => i + 1).join(", ");

/**
 * Create a string describing the selected signal.
 * E.g. "(1) [TSLA / BUY] Signal name"
 */
export const formatSelectedNameOverview = (props, id, index) => {
    const signal = getSignalById(props, id);
    const asset = getAssetByKey(props, "id", signal?.assetId);

    return `(${index + 1}) [${asset?.ticker || "N/A"} / ${signal?.orderType || "N/A"}] ${signal?.name || "N/A"}`;
};

/**
 * Create a string describing the selected signal.
 * E.g. "[BTC / BUY / EXTERNAL] Signal name"
 */
export const formatListName = (props, signal = {}, selected = []) => {
    const isSelected = isObjExistsInArray(selected, signal);
    const selectedIndex = findObjIndexInArray(selected, signal);
    const asset = getAssetByKey(props, "id", signal?.assetId);
    const ticker = asset?.ticker || "N/A";

    return `[${ticker} / ${signal?.orderType || "N/A"} / ${isCombined(signal) ? "COMBINED" : "EXTERNAL"}] ${signal?.name || "N/A"} ${isSelected ? `(${selectedIndex + 1})` : ``}`;
};

export const formatListDescription = (props, signal = {}) => {
    return signal?.description || "N/A";
};

/**
 * Return an array of signal ids that should be excluded from the signal selectin list.
 */
export const decideExcludedIds = (props, selectedSignal = {}) => {
    const selectedSignalId = selectedSignal.id;
    const combinedSignals = getCombinedSignalsByChildId(props, selectedSignalId).map((signal) => signal.id);

    return [...combinedSignals, selectedSignalId];
};
