/**
 * For first-time users, return true if the the welcome page should be shown.
 */
export function checkWelcomePage(props) {
    return props.user.username === "" && props.user.accessToken === "" && !userOnPath(["/welcome"]);
}

/**
 * Un-blur an element the more the user writes to an input field.
 */
export function inputBackgroundUnblur(maxBlur, coef, inputId, backgroundId) {
    const inputEl = document.getElementById(inputId);
    const backgroundEl = document.getElementById(backgroundId);
    backgroundEl.style.filter = `blur(${maxBlur}px)`;

    inputEl.addEventListener("input", (e) => {
        const input = e.target.value;
        const blurValue = maxBlur - input.length / coef;
        backgroundEl.style.filter = `blur(${blurValue < 0 ? 0 : blurValue}px)`;
    });
}

/**
 * Focus an input element.
 * If the first one is empty, focus that one.
 * Otherwise, focus on the second one.
 */
export function focusFirstIfEmptyOtherwiseSecond(firstId, secondId) {
    const firstEl = document.getElementById(firstId);
    const secondEl = document.getElementById(secondId);

    if (firstEl.value === undefined || firstEl.value === "") {
        firstEl.focus();
    } else {
        secondEl.focus();
    }
}

/**
 * Show a dialog to the user asking if they want to take a tour of the platform.
 */
export const handleTourGuide = (props, force = false) => {
    if (props.browser.showTourGuide || force) {
        const username = props?.user?.data?.username;

        props.dialog.enqueueDialog({
            title: `Welcome, ${username || "user"}!`,
            message: `Would you like to take a tour of the platform?

            You can always start the tour later by clicking the question mark icon in the top right corner.`,
            actionTitle: "Show me around",
            actionColor: "success",
            callback: (confirm) => {
                props.browser.setShowTourGuide(false);
                props.browser.setRunTourGuide(confirm);
            },
        });
    }
};

export function scrollToBottom() {
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
    });
}

export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}

export function scrollToValue(value) {
    window.scrollTo({
        top: value,
        behavior: "smooth",
    });
}

export function scrollByValue(value) {
    window.scrollTo({
        top: window.scrollY - value,
        behavior: "instant",
    });
}

export function scrollToRef(ref) {
    if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
    }
}

export const moveToLastPage = (config, rowsPerPage, setPage) => {
    const lastPage = getLastPage(config, rowsPerPage);
    setPage(lastPage);
};

export const getLastPage = (config, rowsPerPage) => {
    const lastPage = Math.ceil(config.length / rowsPerPage) - 1;
    return Math.max(0, lastPage);
};

/**
 * Calculate which page the new index is on.
 */
export const calculatePage = (rowsPerPage, index) => {
    const page = Math.floor(index / rowsPerPage);

    return page;
};

/**
 * If page and newPage don't match up, move to the new page and scroll up.
 */
export const moveToNewPage = (page, setPage, newPage) => {
    if (page !== newPage) {
        setPage(newPage);
        scrollToTop();
    }
};

/**
 * Return whether the user is currently on a specific page.
 */
export const userOnPath = (paths = []) => {
    const currentPath = window.location.pathname;
    const currentHash = window.location.hash;

    return paths.some((path) => currentPath.includes(path) || currentHash.includes(path));
};

/**
 * Find and return URL query values according to a key.
 * If no URL is provided, use the current URL.
 */
export const getKeyFromQuery = (key, url) => {
    const currentURL = url || window.location.href;
    const urlObject = new URL(currentURL.replace("#/", "")); // Remove hash.
    return urlObject.searchParams.get(key);
};

/**
 * Find and return URL query values according to a key.
 * If no URL is provided, use the current URL.
 */
export const getPathFromUrl = (url) => {
    const currentURL = url || window.location.href;
    const urlObject = new URL(currentURL);

    return urlObject.pathname;
};

/**
 * Remove the search query parameter from the URL without refreshing the page.
 */
export const removeQueryParametersFromUrl = (props) => {
    const navigate = props.browser.navigate;
    const location = props.browser.location;

    const { pathname, hash } = location;
    const newLocation = {
        pathname,
        hash,
    };

    navigate(newLocation);
};
