import { Link } from "react-router-dom";

import { Button, IconButton, TableCell, Tooltip } from "@mui/material";
import { includeObjectsByKeys, removeNullValues } from "../../helper-functions/utils/validation/object";
import { getPathFromUrl } from "../../helper-functions/utils/page/utils";

/**
 * Wrapper component for a button.
 *
 * Required props:
 * title
 * path (button with link)
 * icon (icon with callback)
 * onClick (button or icon with callback)
 *
 * Optional props:
 * className
 * variant
 * color
 * size
 * disabled
 * fullWidth
 */
export default function CustomButton(props) {
    const { title, path, onClick, icon, admin, tooltip, hidden, cell, disabled: userDisabled, ...otherProps } = props;

    const combinedButtonProps = removeNullValues(includeObjectsByKeys(otherProps, ["className", "variant", "color", "size", "width", "fullWidth", "type", "sx", "p", "m"]));
    const prevPathname = getPathFromUrl();

    // React components get discarded during includeObjectsByKeys(), define them here.
    const startIcon = props.startIcon;
    const endIcon = props.endIcon;

    const decideDisabled = () => {
        let disabled = false;

        if (props?.server?.waitingForResponse) disabled = true;
        if (admin && !props.role.isAdmin) disabled = true;

        return disabled || userDisabled;
    };

    const disabled = decideDisabled();

    // Default props in case of no user-given parameters.
    const defaultButtonProps = {
        className: "xs-margin",
        variant: "contained",
        color: "primary",
        size: "medium",
        disabled,
    };

    const defaultIconButtonProps = {
        color: "default",
        size: "small",
        disabled,
    };

    /**
     * Return the final component.
     */
    const returnComponent = () => {
        if (hidden) return;
        if (!cell) return createComponent();

        return (
            <TableCell colSpan={props.colSpan} align={props.align}>
                {createComponent()}
            </TableCell>
        );
    };

    /**
     * Return the final component according to the props.
     */
    const createComponent = () => {
        if (icon) return iconButton();
        if (!path) return clickButton();
        return linkButton();
    };

    const iconStyles = { ...defaultIconButtonProps, ...combinedButtonProps };
    const buttonStyles = { ...defaultButtonProps, ...combinedButtonProps };

    const iconButton = () => {
        if (tooltip) {
            return (
                <Tooltip title={tooltip} placement="bottom" arrow>
                    <span>
                        <IconButton {...iconStyles} disabled={disabled} onClick={onClick}>
                            {icon}
                        </IconButton>
                    </span>
                </Tooltip>
            );
        }

        return (
            <IconButton {...iconStyles} disabled={disabled} onClick={onClick}>
                {icon}
            </IconButton>
        );
    };

    const clickButton = () => {
        return (
            <Button {...buttonStyles} disabled={disabled} onClick={onClick} startIcon={startIcon} endIcon={endIcon}>
                {title}
            </Button>
        );
    };

    const linkButton = () => {
        if (props.disabled) {
            return (
                <Button {...buttonStyles} disabled startIcon={startIcon} endIcon={endIcon}>
                    {title}
                </Button>
            );
        }

        return (
            <Link to={path} state={{ prevPathname }} className="hide-link">
                <Button {...buttonStyles} startIcon={startIcon} endIcon={endIcon}>
                    {title}
                </Button>
            </Link>
        );
    };

    return returnComponent();
}
