import { formatValue } from "../asset/asset";
import { capitalizeFirstLetter, formatDate, objHasErrors, objHasValue } from "../validation/object";
import { setDeleteArrayState, setUniqueArrayState, setUniqueObjState } from "./utils";

// ==============
// PORTFOLIOS
// ==============

/**
 * Handle the main portfolios overview response from the API.
 */
export const handleAddPortfolios = (props, response) => {
    const setData = props.portfolios.setData;
    const data = response.elements;

    setUniqueArrayState(setData, data);
};

export const getPortfolioByKey = (props, key = "id", value) => {
    return props.portfolios.data.filter((portfolio) => portfolio[key] === value)?.[0];
};

export const getPortfolioAssetByKey = (props, key = "id", value) => {
    return props.portfolioAssets.data.filter((portfolioAsset) => portfolioAsset[key] === value)?.[0];
};

export const getPortfolioTradeByKey = (props, key = "id", value) => {
    return props.portfolioTrades.data.filter((portfolioAsset) => portfolioAsset[key] === value)?.[0];
};

/**
 * Handle the portfolio details response from the API.
 * Set the state on the Portfolio page.
 */
export const handleAddPortfolio = (props, response) => {
    const setData = props.portfolio.setData;
    const data = response;

    setData(data);
};

/**
 * Handle the portfolio creation response from the API.
 */
export const handleCreatePortfolio = (props, response) => {
    const states = props.portfolios;
    const setData = states.setData;
    const data = [{ ...response, value: 0, change: 0 }];

    states.setRowCount((prev) => prev + 1);
    setUniqueArrayState(setData, data);
};

/**
 * Handle the portfolio update response from the API.
 */
export const handleUpdatePortfolio = (props, response) => {
    const setData = props.portfolio.setData;
    const data = response;

    setUniqueObjState(setData, data);
};

/**
 * Handle the deletion of selected portfolios.
 * Uses the 'ids' parameter to remove the selected portfolios.
 */
export const handleDeletePortfolio = (props, ids = []) => {
    const setData = props.portfolios.setData;

    setDeleteArrayState(setData, ids);
};

// ==============
// PORTFOLIO ASSETS
// ==============

/**
 * Handle the portfolio assets response from the API.
 */
export const handleAddPortfolioAssets = (props, response) => {
    const setData = props.portfolioAssets.setData;
    const data = response.elements;

    setUniqueArrayState(setData, data);
};

/**
 * Handle the portfolio asset response from the API.
 */
export const handleAddPortfolioAsset = (props, response) => {
    const setData = props.portfolioAssets.setData;
    const data = response;

    setUniqueArrayState(setData, [data]);
};

/**
 * Handle the creation of new assets in a portfolio.
 * NB! response.body is an array, and can include multiple assets.
 */
export const handleCreatePortfolioAsset = (props, response) => {
    const states = props.portfolioAssets;
    const setData = states.setData;

    states.setRowCount(states.rowCount + response.length);

    response.forEach((asset) => {
        const data = [{ ...asset, value: 0, change: 0 }];

        setUniqueArrayState(setData, data);
    });
};

/**
 * Handle the deletion of selected portfolio asset(s).
 * Uses the 'assetIds' parameter to remove the selected assets.
 */
export const handleDeletePortfolioAssets = (props, assetIds = []) => {
    const setData = props.portfolioAssets.setData;

    setDeleteArrayState(setData, assetIds, { keys: ["asset.id"] });
};

// ==============
// PORTFOLIO TRADES
// ==============

/**
 * Handle the portfolio trades response from the API.
 */
export const handleAddPortfolioTrades = (props, response) => {
    const setData = props.portfolioTrades.setData;
    const data = response.elements;

    setUniqueArrayState(setData, data);
};

/**
 * Handle the creation of a new trade in a portfolio.
 */
export const handleCreatePortfolioTrade = (props, response) => {
    const states = props.portfolioTrades;
    const setData = states.setData;
    const data = [{ ...response, value: 0, change: 0 }];

    states.setRowCount((prev) => prev + 1);
    setUniqueArrayState(setData, data);
};

/**
 * Handle the deletion of selected portfolio asset(s).
 * Uses the 'tradeIds' parameter to remove the selected assets.
 */
export const handleDeletePortfolioTrades = (props, tradeIds = []) => {
    const setData = props.portfolioTrades.setData;

    setDeleteArrayState(setData, tradeIds);
};

// ==============
// MISC.
// ==============

/**
 *  Replace any placeholders in the API path with actual values.
 */
export const replacePlaceholders = (path, options = {}) => {
    return path.replace("{portfolioId}", options.portfolioId).replace("{assetId}", options.assetId);
};

/**
 * Return whether the required row fields are all empty.
 */
const portfoliosHasValues = (obj) => {
    return objHasValue(obj, "name");
};

/**
 * Return whether some row is valid (has values and no errors).
 */
export const portfoliosIsValid = (obj) => {
    return portfoliosHasValues(obj) && !objHasErrors(obj);
};

/**
 * Return whether the required row fields are all empty.
 */
const portfolioTradeHasValues = (obj) => {
    return objHasValue(obj, "price") && objHasValue(obj, "amount") && objHasValue(obj, "_asset");
};

/**
 * Return whether some row is valid (has values and no errors).
 */
export const portfolioTradeIsValid = (obj) => {
    return portfolioTradeHasValues(obj) && !objHasErrors(obj);
};

/**
 * Navigate to the portfolio details page.
 */
export const redirectToPortfolio = (props, id) => {
    if (id === undefined) return;

    props.browser.navigate(`/portfolio-tracker/portfolios/${id}`);
};

// ==============
// CHARTS
// Format values for use in charts.
// ==============

export const getFormattedValue = (portfolioData) => {
    const { currency = "USD", value = "N/A", change = "N/A", changePercentage = "N/A", updatedOn } = portfolioData;

    return {
        title: `Value: ${formatValue(value, { currency })}`,
        subtitle: `Change: ${formatValue(change, { currency })} (${changePercentage > 0 ? "+" + changePercentage : changePercentage}%)
        Last update: ${formatDate(updatedOn)}`,
    };
};

export const getFormattedDistribution = (portfolioData) => {
    const { distributions = [] } = portfolioData;

    return distributions.map((item) => item && { ...item, name: capitalizeFirstLetter(item.name) });
};

export const getFormattedHistorySimple = (portfolioData) => {
    const { history = [], value = "N/A" } = portfolioData;

    return [
        ...history.map((item) => item && { ...item, value: item.average.toFixed(2), timestamp: formatDate(item.timestamp) }),
        {
            timestamp: formatDate(new Date()),
            value,
            currency: portfolioData.currency,
        },
    ];
};

export const getFormattedHistoryAdvanced = (portfolioData) => {
    const { history = [] } = portfolioData;

    return [...history.map((item) => item && { timestamp: formatDate(item.timestamp), value: [item.open, item.close, item.low, item.high] })];
};

export const getFormattedPerformance = (portfolioAssetData) => {
    return portfolioAssetData.map((item) => ({
        value: [item.changePercentage, item.asset.ticker],
        itemStyle: {
            color: item.changePercentage < 0 ? "rgba(221, 58, 40, 0.9)" : "rgba(55, 195, 63, 0.9)",
        },
    }));
};
