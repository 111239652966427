import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Container, Grid, Typography } from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";

import CustomForm from "../../helper-components/form/CustomForm";
import CustomButton from "../../helper-components/input/CustomButton";
import ComponentLoader from "../../helper-components/misc/ComponentLoader";

import { verifyEmail } from "../../helper-functions/api/authQueries";

import { getLanguage } from "../../config/language/language";

export const EmailVerification = (props) => {
    const location = useLocation();

    const [failed, setFailed] = React.useState(false);

    // Send a request to the server to verify the email.
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const hashParam = params.get("hash");

        if (!hashParam) {
            props.showSnackbar({ message: `No verification hash found!`, color: "error" });
            return;
        }

        verifyEmail({ ...props, setFailed }, hashParam);
    }, []);

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomForm
                        title={getLanguage().VERIFY_EMAIL_TITLE}
                        subtitle={getLanguage().VERIFY_EMAIL_SUBTITLE}
                        icon={<EmailIcon />}
                        form={
                            <ComponentLoader
                                height="100%"
                                isVisible={true}
                                isEmpty={failed}
                                showImg
                                empty={
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography className="pre-wrap">{getLanguage().VERIFY_EMAIL_FAILED}</Typography>
                                        </Grid>

                                        <Grid item xs={12} mt={2} mb={2}>
                                            <CustomButton {...props} title={getLanguage().BUTTON_SIGNIN} path="/signin" />
                                        </Grid>
                                    </Grid>
                                }
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    );
};
