import * as React from "react";

import { Container, Grid } from "@mui/material";

import MediaCard from "../../../../helper-components/misc/MediaCard";
import CustomStepper from "../../../../helper-components/form/CustomStepper";
import TransparentFragment from "../../../../helper-components/misc/TransparentFragment";

/**
 * A tutorial component showing how to subscribe and share signals.
 *
 * Uses the CustomStepper component to display the steps.
 */
export default function SignalShareTutorial(props) {
    // Create the step components.
    const step1 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                        <TransparentFragment
                            value={`You can share only signals which you are the owner of.

                            Thus, make sure you've configured TradingView to send alerts to this application, or combined signals from multiple sources.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <MediaCard
                            {...props}
                            summary="Click on the Share icon to open up the form."
                            bannerImage="/images/tutorials/share_signals_1.png"
                            bannerHeight={200}
                            contentHeight={50}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step2 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TransparentFragment
                            value={`Define the visibility of the signal.

                            There are three options: public, protected, and private.

                            Public signals can be subscribed to by anyone, protected signals can be subscribed to by anyone with a code, and private signals can only accessible by you.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard
                            {...props}
                            summary="In this example, let's share the signal with only a few friends, setting a secret code."
                            bannerImage="/images/tutorials/share_signals_2.png"
                            bannerHeight={200}
                            contentHeight={70}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step3 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={7}>
                        <TransparentFragment
                            value={`The signal can be shared using the edit form as well.

                            The basic principle is the same - define the visibility of the signal and set a secret code if needed.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <MediaCard {...props} summary="An example of an edit form." bannerImage="/images/tutorials/share_signals_3.png" bannerHeight={220} contentHeight={40} />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const step4 = () => {
        return (
            <Container style={{ margin: 0, padding: 0 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TransparentFragment
                            value={`You're all set!
                            Based on the visibility settings, the combined signal will now be visible and subscribable in the community signals table.
                            
                            NB! The quality of the signal is important - others can downvote your signal if it doesn't provide alpha.`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MediaCard
                            {...props}
                            summary="Shared signals are visible on the 'Community Signals' page."
                            bannerImage="/images/tutorials/share_signals_4.png"
                            bannerHeight={160}
                            contentHeight={60}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    // Define the steps and the final step.
    const steps = [
        { label: "Configure signals", optional: false, component: step1() },
        { label: "Use the share form", optional: false, component: step2() },
        { label: "Use the edit form", optional: false, component: step3() },
    ];

    const final = { component: step4() };

    return <CustomStepper steps={steps} final={final} />;
}
