import { getFetch, postFetch } from "../utils/misc/api.js";
import { addExternalData } from "../utils/misc/external.js";

import * as Paths from "../../config/constants/Paths.js";
import { getLanguage } from "../../config/language/language.js";

/**
 * Query the sentiment of some asset.
 *
 * E.g. https://www.alphavantage.co/query?function=NEWS_SENTIMENT&tickers=IBM&limit=5&apikey=demo
 */
export const queryNewsSentiment = (props, formattedTicker, asset) => {
    if (props.external.newsSentiment[asset] !== undefined) {
        console.log("News sentiment already queried for this asset!");
        return;
    }

    postFetch(
        Paths.API_NEWS_SENTIMENT_PATH,
        {
            formattedTicker: formattedTicker,
            asset: asset,
        },
        { showMessage: false }
    ).then(function (response) {
        if (response.ok) {
            addExternalData(props, "NEWS_SENTIMENT", asset, response.body);
        } else {
            const message = response?.body?.message ?? getLanguage().NO_EXTERNAL_NEWS_FOUND;
            props.showSnackbar({ message, color: "error" });
        }
    });
};

/**
 * Query the daily price history of some asset.
 * TODO: set actual endpoint
 *
 * E.g.
 * https://www.alphavantage.co/query?function=TIME_SERIES_DAILY_ADJUSTED&symbol=IBM&apikey=demo
 * https://www.alphavantage.co/query?function=DIGITAL_CURRENCY_DAILY&symbol=BTC&market=USD&apikey=demo
 */
export const queryDailyPriceHistory = (props, formattedTicker, asset) => {
    const path = `${process.env.PUBLIC_URL}/demo-data/${asset.toLowerCase()}.json`;

    if (props.external.dailyPriceHistory[asset] === undefined) {
        getFetch(path).then(function (response) {
            if (response.ok) {
                addExternalData(props, "DAILY_PRICE_HISTORY", asset, response.body);
            } else {
                const message = response?.body?.message ?? getLanguage().NO_EXTERNAL_DAILY_PRICE_HISTORY_FOUND;
                props.showSnackbar({ message, color: "error" });
            }
        });
    }
};

/**
 * Query the daily price history of some asset.
 * TODO: set actual endpoint
 */
export const queryEarningsPerShareHistory = (props, formattedTicker, asset) => {
    const path = `${process.env.PUBLIC_URL}/demo-data/${asset.toLowerCase()}_eps.json`;

    if (props.external.dailyPriceHistory[asset] === undefined) {
        getFetch(path).then(function (response) {
            if (response.ok) {
                addExternalData(props, "EARNINGS_PER_SHARE", asset, response.body);
            } else {
                const message = response.body?.message ?? getLanguage().NO_EXTERNAL_DAILY_PRICE_HISTORY_FOUND;
                props.showSnackbar({ message, color: "error" });
            }
        });
    }
};
