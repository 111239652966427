import * as React from "react";

import { Box } from "@mui/material";

import RegenerateSecretTutorial from "./RegenerateSecretTutorial.js";
import CustomButton from "../../../../helper-components/input/CustomButton.js";

/**
 * Allow the user itself or the admin to delete some user.
 * Require password confirmation.
 */
export default function UserSecretForm(props) {
    const showTutorialDialog = () => {
        props.dialog.enqueueDialog({
            id: "regenerate-secret-tutorial",
            title: "Regenerate secret",
            component: <RegenerateSecretTutorial />,
        });
    };

    return (
        <Box className="box">
            <CustomButton {...props} title="Regenerate key..." onClick={showTutorialDialog} className="button" type="submit" fullWidth color="info" />
        </Box>
    );
}
