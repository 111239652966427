import * as React from "react";

import { Toolbar, Typography, Drawer, Stack, Divider, ListSubheader, Pagination } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import SidebarNotification from "./SidebarNotification";
import CustomButton from "../../../../helper-components/input/CustomButton";

import { isLoggedIn } from "../../../../helper-functions/utils/misc/utils";
import { deleteAllRead, markAllNotificationsRead, queryReadNotifications, queryUnreadNotifications } from "../../../../helper-functions/api/notificationQueries";

import * as General from "../../../../config/constants/General";
import { getLanguage } from "../../../../config/language/language";

/**
 * A sidebar opening up on the right side of the page after clicking on the notifications icon on the appbar.
 *
 * Includes both unread and read notifications.
 * Features pagination and buttons for marking all as read and deleting all read.
 */
export default function NotificationDrawer(props) {
    const { openNotificationDrawer: sidebarOpened } = props.sidebar;
    const { read, unread } = props.notifications;
    const { isLoggedIn: loggedIn } = props.server;

    const { data: readData, page: readPage, setPage: setReadPage } = read;
    const { data: unreadData, page: unreadPage, setPage: setUnreadPage } = unread;

    // Query the notifications on mount and page change.
    React.useEffect(() => {
        if (!isLoggedIn(props)) return;

        queryUnreadNotifications(props, { state: unread });
        queryReadNotifications(props, { state: read });
    }, [loggedIn]);

    // Query the unread notifications on page change.
    React.useEffect(() => {
        if (!isLoggedIn(props)) return;
        if (!sidebarOpened) return;

        queryUnreadNotifications(props, { state: unread });
    }, [unreadPage, sidebarOpened]);

    // Query the read notifications on page change.
    React.useEffect(() => {
        if (!isLoggedIn(props)) return;
        if (!sidebarOpened) return;

        queryReadNotifications(props, { state: read });
    }, [readPage, sidebarOpened]);

    /**
     * The notification drawer open/close.
     */
    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        props.sidebar.toggleNotificationDrawer();
    };

    const createReadNotifications = () => {
        return createNotifications(readData);
    };

    const createUnreadNotifications = () => {
        return createNotifications(unreadData);
    };

    /**
     * Returns a component filled with the notifications.
     *
     * Features pagination.
     */
    const createNotifications = (notifications) => {
        if (notifications.length === 0) {
            return <Typography variant="subtitle2">No notifications.</Typography>;
        }

        return notifications.map((notification) => {
            return <SidebarNotification {...props} read={read} unread={unread} key={notification.id} notification={notification} />;
        });
    };

    const handleUnreadChange = (event, value) => {
        setUnreadPage(value - 1);
    };

    const handleReadChange = (event, value) => {
        setReadPage(value - 1);
    };

    const readAllNotifications = () => {
        markAllNotificationsRead(props, { readState: read, unreadState: unread });
    };

    const deleteAllReadNotifications = () => {
        deleteAllRead(props, { state: read });
    };

    const drawerTheme = {
        backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[200] : t.palette.grey[900]),
    };

    return (
        <Drawer
            className="notification-drawer"
            anchor="right"
            open={props.sidebar.openNotificationDrawer}
            onClose={toggleDrawer(false)}
            PaperProps={{
                sx: { width: General.PAGE_DRAWER_WIDTH_PX },
                className: "hide-scrollbar",
            }}>
            <Toolbar className="notification-toolbar">
                <CustomButton {...props} icon={<ChevronRightIcon />} onClick={props.sidebar.toggleNotificationDrawer} />

                <Typography variant="subtitle1" ml={1}>
                    <strong>{getLanguage().DRAWER_NOTIFICATION_TITLE}</strong>
                </Typography>
            </Toolbar>

            <Divider />

            <ListSubheader sx={drawerTheme} disableSticky>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    {getLanguage().SIDEBAR_UNREAD}

                    <CustomButton {...props} icon={<DoneIcon />} tooltip="Mark all notifications as read" onClick={readAllNotifications} />
                </Stack>
            </ListSubheader>

            <Stack className="notification-drawer-stack" spacing={1}>
                {createUnreadNotifications()}
                <Pagination count={Math.ceil(unread.rowCount / General.NOTIFICATION_PAGE_SIZE)} page={unreadPage + 1} onChange={handleUnreadChange} size="small" />
            </Stack>

            <Divider />

            <ListSubheader sx={drawerTheme} disableSticky>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    {getLanguage().SIDEBAR_READ}

                    <CustomButton {...props} icon={<CloseIcon />} tooltip="Delete all read notifications" onClick={deleteAllReadNotifications} />
                </Stack>
            </ListSubheader>

            <Stack className="notification-drawer-stack" spacing={1}>
                {createReadNotifications()}
                <Pagination count={Math.ceil(read.rowCount / General.NOTIFICATION_PAGE_SIZE)} page={readPage + 1} onChange={handleReadChange} size="small" />
            </Stack>
        </Drawer>
    );
}
