import { getValue } from "./object";

/**
 * In use for fields that don't require any special validation rules.
 */
export const noValidation = (prevConfig, row, value) => {
    return prevConfig;
};

/**
 * Loop through the row fields, and execute each validation function.
 * Return the config object.
 */
export const validateRow = (prevConfig, validation, row, exclude, obj) => {
    for (const type of Object.keys(validation)) {
        if (type !== exclude) {
            validation[type].check(prevConfig, row, getValue(row, type), obj);
        }
    }
};

/**
 * Loop through all of the configuration rows, and execute each validation function.
 * Return the updated object.
 */
export const validateRows = (prevConfig, validation, exclude, obj) => {
    const config = JSON.parse(JSON.stringify(prevConfig));

    for (const row of config) {
        validateRow(config, validation, row, exclude, obj);
    }

    return config;
};

/**
 * Return whether all the configurations in an array are valid.
 */
export const allConfigsValidated = (validationResults) => {
    for (const validation of validationResults) {
        if (validation.error) return false;
    }

    return true;
};

/**
 * Return the first error message of the validated config array.
 */
export const getConfigValidationsErrorMessage = (validationResults) => {
    for (const validation of validationResults) {
        if (validation.error) return validation.message;
    }
};
