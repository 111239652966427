import * as React from "react";
import { Link } from "react-router-dom";

import { Alert, AlertTitle, Typography } from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import CustomButton from "../../../../helper-components/input/CustomButton";

import { formatDate } from "../../../../helper-functions/utils/validation/object";
import { deleteOne, markOneRead } from "../../../../helper-functions/api/notificationQueries";
import { isNotificationUnread } from "../../../../helper-functions/utils/misc/notification";

/**
 * Alert component.
 * Similar to a snackbar, but static and more configurable.
 *
 * Differentiates between read and unread notifications, having a different icon and onClick function.
 */
export default function SidebarNotification(props) {
    const read = props.read;
    const unread = props.unread;

    const notification = props.notification;
    const { description, title, redirectUri: link, timestamp } = notification;

    const isUnread = isNotificationUnread(notification);
    const icon = isUnread ? <DoneIcon fontSize="inherit" /> : <CloseIcon fontSize="inherit" />;

    return (
        <Alert
            className="notification"
            variant={isUnread ? "filled" : "outlined"}
            icon={false}
            severity="success"
            action={
                <CustomButton
                    {...props}
                    icon={icon}
                    tooltip={isUnread ? "Mark as read" : "Delete"}
                    onClick={() => {
                        isUnread ? markOneRead(props, { readState: read, unreadState: unread }) : deleteOne(props, { state: read });
                    }}
                    color="inherit"
                    size="small"
                />
            }>
            <Link to={link} className="hide-link">
                <AlertTitle>{title}</AlertTitle>
                <Typography className="sm-font">{description}</Typography>
                <Typography className="xs-font align-right xs-margin">{formatDate(timestamp)}</Typography>
            </Link>
        </Alert>
    );
}
