import * as React from "react";

import useSound from "use-sound";
import { useLocation } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Box } from "@mui/material";

import { createAppTheme } from "./styles";

import MainPage from "./general/main-pages/MainPage";
import PageLoader from "./helper-components/misc/PageLoader";

import { useDialog } from "./helper-functions/hooks/useDialog";
import { useSnackbar } from "./helper-functions/hooks/useSnackbar";
import { useLocalStorage } from "./helper-functions/hooks/useLocalStorage";
import { useNavigateWithPrev } from "./helper-functions/hooks/useNavigation";
import useWindowDimensions from "./helper-functions/hooks/useWindowDimensions";
import useWebSocket from "./helper-functions/hooks/useWebSocket";

import { useUserStates } from "./helper-functions/hooks/states/useUserStates";
import { useEndpointStatesWithPagination } from "./helper-functions/hooks/states/useEndpointStatesWithPagination";

import { getKeyFromQuery } from "./helper-functions/utils/page/utils";
import { disableConsoleLogging } from "./helper-functions/utils/misc/utils";

import * as General from "./config/constants/General";
import * as Objects from "./config/constants/Objects";
import { getLanguage } from "./config/language/language";

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {},
});

/**
 * Every single component visible to the user is inside this one.
 * This is the master-component, so to say.
 */
export default function App() {
    const preferDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

    // Find URL parameter: debug.
    const debug = getKeyFromQuery("debug");

    // Variables can be saved in a browser's localstorage.
    const [mode, setMode] = useLocalStorage("colorMode", preferDark ? "dark" : "light");

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        [setMode]
    );

    const theme = React.useMemo(() => createAppTheme(mode, isMobile), [mode]);

    // Log some data.
    console.log(``);
    console.log(`==== ${getLanguage().WEBSITE_NAME} ====`);
    console.log(`Version: ${General.PAGE_VERSION}`);
    console.log(`Running in ${isMobile ? "mobile" : "desktop"} view`);
    console.log(`================`);
    console.log(``);

    if (!General.DEVELOPMENT_ENV && debug !== "true") disableConsoleLogging();

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <FrontendGoBrrr />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

/**
 * Every single component visible to the user is inside this one.
 *
 * Define any variables (states) used throughout the app here.
 * All such variables will be passed to the Main.js as props.
 * NB! The spread operator doesn't deep copy - use JSON parse and stringify.
 */
function FrontendGoBrrr() {
    const location = useLocation();

    // Thanks to useState, <setVariable> changes <variable> dynamically.
    const [isPageLoading, setIsPageLoading] = React.useState(General.ENABLE_PAGE_LOADER);
    const [isConnectionToServerEstablished, setIsConnectionToServerEstablished] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false); // Logged in depends on localstorage variables and JWT validity.
    const [userInteractedWithPage, setUserInteractedWithPage] = React.useState(false); // TODO: BUG: if user logs in, don't play sound.
    const [showMainPageComponents, setShowMainPageComponents] = React.useState(true);
    const [waitingForResponse, setWaitingForResponse] = React.useState(false); // Disable input elements until response is received.

    // Webhook disclaimer when first copying the URL.
    const [webhookDisclaimer, setWebhookDisclaimer] = useLocalStorage("webhookDisclaimer", true);

    // Tour guide is a step-by-step guide for new users.
    const [showTourGuide, setShowTourGuide] = useLocalStorage("tourGuide", true);
    const [runTourGuide, setRunTourGuide] = React.useState(false);

    // DATA STRUCTURES:
    // pagination: { "PUBLIC_EXTERNAL_SIGNALS": { "TSLA": -1 }, "PUBLIC_USER_SIGNALS": { "SOLUSD": -1, "TSLA": -1 } }
    // notifications: { 1: { message: "New TSLA alert @ 19:03 February 2023", link: "/user/asset/external/TSLA", color: "green"}, ... }

    const [pagination, setPagination] = React.useState({});

    // Technical analysis.
    const assets = useEndpointStatesWithPagination("Assets", {
        logData: true,
        logPagination: true,
        initialSorting: { field: "ticker" },
        initialFilter: { items: [{ columnField: "status", operatorValue: "isAnyOf", value: ["ENABLED", "LOADING"] }] },
    });

    // Alerts.
    const externalOwnerAlerts = useEndpointStatesWithPagination("External owner alerts", { logData: true, logPagination: true, initialSorting: { field: "timestamp" } });
    const combinedOwnerAlerts = useEndpointStatesWithPagination("Combined owner alerts", { logData: true, logPagination: true, initialSorting: { field: "timestamp" } });

    const externalSubscribedAlerts = useEndpointStatesWithPagination("External subscribed alerts", { logData: true, logPagination: true, initialSorting: { field: "timestamp" } });
    const combinedSubscribedAlerts = useEndpointStatesWithPagination("Combined subscribed alerts", { logData: true, logPagination: true, initialSorting: { field: "timestamp" } });

    const externalAssetAlerts = useEndpointStatesWithPagination("Asset alerts (external)", { logData: true, logPagination: true, initialSorting: { field: "timestamp" } });
    const combinedAssetAlerts = useEndpointStatesWithPagination("Asset alerts (combined)", { logData: true, logPagination: true, initialSorting: { field: "timestamp" } });

    // Signals.
    const externalPublicSignals = useEndpointStatesWithPagination("External public signals", { logData: true, logPagination: true, initialSorting: { field: "lastAlert" } });
    const combinedPublicSignals = useEndpointStatesWithPagination("Combined public signals", { logData: true, logPagination: true, initialSorting: { field: "lastAlert" } });

    const externalProtectedSignals = useEndpointStatesWithPagination("External protected signals", { logData: true, logPagination: true, initialSorting: { field: "lastAlert" } });
    const combinedProtectedSignals = useEndpointStatesWithPagination("Combined protected signals", { logData: true, logPagination: true, initialSorting: { field: "lastAlert" } });

    const externalOwnerSignals = useEndpointStatesWithPagination("External owner signals", { logData: true, logPagination: true, initialSorting: { field: "lastAlert" } });
    const combinedOwnerSignals = useEndpointStatesWithPagination("Combined owner signals", { logData: true, logPagination: true, initialSorting: { field: "lastAlert" } });

    const externalSubscribedSignals = useEndpointStatesWithPagination("External subscribed signals", {
        logData: true,
        logPagination: true,
        initialSorting: { field: "lastAlert" },
    });
    const combinedSubscribedSignals = useEndpointStatesWithPagination("Combined subscribed signals", {
        logData: true,
        logPagination: true,
        initialSorting: { field: "lastAlert" },
    });

    const externalAssetSignals = useEndpointStatesWithPagination("Asset signals (external)", { logData: true, logPagination: true, initialSorting: { field: "lastAlert" } });
    const combinedAssetSignals = useEndpointStatesWithPagination("Asset signals (combined)", { logData: true, logPagination: true, initialSorting: { field: "lastAlert" } });

    // Other misc. states.
    const feedback = useEndpointStatesWithPagination("Feedback", { logData: true, logPagination: true, initialSorting: { field: "createdOn" } });
    const publicPosts = useEndpointStatesWithPagination("Posts (public)", { logData: true, logPagination: true, initialSorting: { field: "createdOn" } });
    const followingPosts = useEndpointStatesWithPagination("Posts (following)", { logData: true, logPagination: true, initialSorting: { field: "createdOn" } });
    const privatePosts = useEndpointStatesWithPagination("Posts (private)", { logData: true, logPagination: true, initialSorting: { field: "createdOn" } });

    // Portfolios
    const portfolios = useEndpointStatesWithPagination("Portfolios", { logData: true, logPagination: true, initialSorting: { field: "updatedOn" }, pageSize: 10 });

    // Users.
    const user = useUserStates("User", { logData: true, logOther: false });
    const users = useEndpointStatesWithPagination("Users (admin)", { logData: true, logPagination: true, initialSorting: { field: "lastLogin" } });

    // External APIs.
    const [newsSentiment, setNewsSentiment] = React.useState({});
    const [dailyPriceHistory, setDailyPriceHistory] = React.useState({});
    const [earningsPerShareHistory, setEarningsPerShareHistory] = React.useState({});

    // Notifications states & related settings.
    const readNotifications = useEndpointStatesWithPagination("Read notifications", { logData: true, logPagination: true, pageSize: General.NOTIFICATION_PAGE_SIZE });
    const unreadNotifications = useEndpointStatesWithPagination("Unread notifications", { logData: true, logPagination: true, pageSize: General.NOTIFICATION_PAGE_SIZE });

    const [notificationsFile, setNotificationsFile] = useLocalStorage("notificationsFile", Object.keys(Objects.SOUND_PATH_AND_NAMES)[0]);
    const [notificationsEnabled, setNotificationsEnabled] = useLocalStorage("notificationsEnabled", true);
    const [notificationsVolume, setNotificationsVolume] = useLocalStorage("notificationsVolume", 0.5);

    // Combined hooks.
    const { screenHeight, screenWidth } = useWindowDimensions();
    const { showSnackbar } = useSnackbar();
    const { navigate } = useNavigateWithPrev();
    const { ...dialogProps } = useDialog();

    // Roles, depends on the JWT claim.
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isUser, setIsUser] = React.useState(false);

    // WebSockets (using SockJS).
    const ws = useWebSocket(isLoggedIn, user.accessToken, isConnectionToServerEstablished);

    // Audio.
    const [notificationSoundDriver] = useSound(`${process.env.PUBLIC_URL}/sounds/${notificationsFile}`, {
        volume: notificationsVolume,
        onload: () => {
            console.log("Audio driver loaded!");
        },
    });

    // Menu sidebar.
    const [openAppDrawer, setOpenAppDrawer] = React.useState(screenWidth > General.AUTO_OPEN_SIDEBAR_PX);
    const toggleAppDrawer = () => setOpenAppDrawer(!openAppDrawer);
    const [goBackOption, setGoBackOption] = React.useState(null);
    const [goBackOptionRedirect, setGoBackOptionRedirect] = React.useState(null);

    // Notification sidebar.
    const [openNotificationDrawer, setOpenNotificationDrawer] = React.useState(false);
    const toggleNotificationDrawer = () => setOpenNotificationDrawer(!openNotificationDrawer);

    // USERS
    React.useEffect(() => {
        console.log("SERVER & ROLES");
        console.log("isConnectionToServerEstablished", JSON.parse(JSON.stringify(isConnectionToServerEstablished)));
        console.log("isLoggedIn", JSON.parse(JSON.stringify(isLoggedIn)));
        console.log("isUser", JSON.parse(JSON.stringify(isUser)));
        console.log("isAdmin", JSON.parse(JSON.stringify(isAdmin)));
        console.log("\n");
    }, [users, isConnectionToServerEstablished, isLoggedIn, isUser, isAdmin]);

    // PAGINATION
    React.useEffect(() => {
        console.log("PAGINATION");
        console.log("pagination", JSON.parse(JSON.stringify(pagination)));
        console.log("\n");
    }, [pagination]);

    // EXTERNAL
    React.useEffect(() => {
        console.log("EXTERNAL");
        console.log("newsSentiment", JSON.parse(JSON.stringify(newsSentiment)));
        console.log("dailyPriceHistory", JSON.parse(JSON.stringify(dailyPriceHistory)));
        console.log("earningsPerShareHistory", JSON.parse(JSON.stringify(earningsPerShareHistory)));
        console.log("\n");
    }, [newsSentiment, dailyPriceHistory, earningsPerShareHistory]);

    // WEBSOCKETS
    React.useEffect(() => {
        console.log("WEBSOCKETS");
        console.log("webSocketSessionId", JSON.parse(JSON.stringify(ws.webSocketSessionId)));
        console.log("topics", JSON.parse(JSON.stringify(ws.topics)));
        console.log("\n");
    }, [ws.webSocketSessionId, ws.topics]);

    return (
        // Box is a general container where every other component is inside of, kind of like a div.
        // This specifc box also deals with light/dark mode.
        <Box>
            {/* Loading spinner. */}
            <PageLoader page show={isPageLoading} />

            {/* Actual page after loading in this div. */}
            <div hidden={isPageLoading}>
                {/* Dialog pop-up to confirm critical changes. */}

                {/* Pass all props (variables) down to the child components. */}
                <MainPage
                    notifications={{
                        unread: unreadNotifications,
                        read: readNotifications,
                        file: notificationsFile,
                        setFile: setNotificationsFile,
                        isEnabled: notificationsEnabled,
                        setIsEnabled: setNotificationsEnabled,
                        volume: notificationsVolume,
                        setVolume: setNotificationsVolume,
                    }}
                    audio={{
                        playNotificationSound: notificationSoundDriver,
                    }}
                    server={{
                        isConnectionEstablished: isConnectionToServerEstablished,
                        setIsConnectionEstablished: setIsConnectionToServerEstablished,
                        isLoggedIn,
                        setIsLoggedIn,
                        waitingForResponse,
                        setWaitingForResponse,
                    }}
                    // Market analysis.
                    assets={assets}
                    alerts={{
                        external: {
                            owner: externalOwnerAlerts,
                            subscribed: externalSubscribedAlerts,
                            asset: externalAssetAlerts,
                        },
                        combined: {
                            owner: combinedOwnerAlerts,
                            subscribed: combinedSubscribedAlerts,
                            asset: combinedAssetAlerts,
                        },
                    }}
                    signals={{
                        external: {
                            public: externalPublicSignals,
                            protected: externalProtectedSignals,
                            owner: externalOwnerSignals,
                            subscribed: externalSubscribedSignals,
                            asset: externalAssetSignals,
                        },
                        combined: {
                            public: combinedPublicSignals,
                            protected: combinedProtectedSignals,
                            owner: combinedOwnerSignals,
                            subscribed: combinedSubscribedSignals,
                            asset: combinedAssetSignals,
                        },
                    }}
                    // Portfolios.
                    portfolios={portfolios}
                    // User.
                    user={user}
                    role={{
                        isUser,
                        setIsUser,
                        isAdmin,
                        setIsAdmin,
                    }}
                    // Other misc. states.
                    users={users}
                    feedback={feedback}
                    posts={{
                        public: publicPosts,
                        following: followingPosts,
                        private: privatePosts,
                    }}
                    external={{
                        newsSentiment,
                        setNewsSentiment,
                        dailyPriceHistory,
                        setDailyPriceHistory,
                        earningsPerShareHistory,
                        setEarningsPerShareHistory,
                    }}
                    // Browser.
                    browser={{
                        navigate,
                        location,
                        screenHeight,
                        screenWidth,
                        isBrowser,
                        isMobile,
                        isPageLoading,
                        setIsPageLoading,
                        userInteractedWithPage,
                        setUserInteractedWithPage,
                        showMainPageComponents,
                        setShowMainPageComponents,
                        showTourGuide,
                        setShowTourGuide,
                        runTourGuide,
                        setRunTourGuide,
                        webhookDisclaimer,
                        setWebhookDisclaimer,
                    }}
                    sidebar={{
                        openAppDrawer,
                        toggleAppDrawer,
                        openNotificationDrawer,
                        toggleNotificationDrawer,
                        goBackOption,
                        setGoBackOption,
                        goBackOptionRedirect,
                        setGoBackOptionRedirect,
                    }}
                    // Pagination for alerts, signals, and users.
                    pagination={pagination}
                    setPagination={setPagination}
                    //WebSockets.
                    ws={ws}
                    // Snackbar.
                    showSnackbar={showSnackbar}
                    // Confirmation dialog.
                    dialog={dialogProps}
                />
            </div>
        </Box>
    );
}
