import React from "react";

import { Typography } from "@mui/material";

/**
 * Chart which displays some text.
 * Utilizes the underlying Chart component.
 *
 * Props:
 * - data
 */
export default function TextChart(props) {
    const { data, titleClassName = "", subtitleClassName = "" } = props;
    const { title, subtitle } = data;

    return (
        <>
            <Typography className={`impression-text center ${titleClassName}`}>{title}</Typography>
            <Typography className={`pre-line sm-margin center ${subtitleClassName}`} variant="h7">
                {subtitle}
            </Typography>
        </>
    );
}
