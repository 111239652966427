import React from "react";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";

/**
 * A simple text-based table.
 * View supported data structure below.
 */
export default function TextTable(props) {
    const { data = {}, headers, firstRowBold = false } = props;

    const keys = Object.keys(data);
    const isValuesAsArray = Array.isArray(data[keys[0]]?.value ?? data[keys[0]]);
    const colSpan = isValuesAsArray ? (data[keys[0]]?.value ?? data[keys[0]]).length + 1 : 1;

    const createHeaderStyle = (index) => {
        if (firstRowBold && index === 0) {
            return "table-header";
        }

        return "";
    };

    const createNestedStyle = () => {
        return "table-nested";
    };

    /**
     * Display the value(s) - either from an array, or as a simple key-value.
     */
    const decideComponent = (key, obj, index) => {
        if (Array.isArray(obj?.value)) {
            return formatArrayValueRow(key, obj, index);
        }

        if (typeof obj === "object" && !obj?.value && !obj?.tooltip) {
            return formatNestedKeyValueRow(key, obj, index);
        }

        return formatKeyValueRow(key, obj, index);
    };

    /**
     * E.g. { key: { tooltip: "", value: "" } }
     * Key - Value
     */
    const formatKeyValueRow = (key, obj, index) => {
        const style = createHeaderStyle(index);

        return (
            <TableRow key={key}>
                <Tooltip title={obj?.tooltip ? <div className="pre-line">{obj?.tooltip}</div> : undefined} enterDelay={400} leaveDelay={200} arrow placement="top">
                    <TableCell className={`table-text-cell ${style}`} align="left">
                        {key}
                    </TableCell>
                </Tooltip>

                <TableCell className={`table-text-cell ${style}`} align="right">
                    {obj?.value}
                </TableCell>
            </TableRow>
        );
    };

    /**
     * E.g. { key: { tooltip: "", value: [value1, value2] } }
     * Key - Value 1 - Value 2
     */
    const formatArrayValueRow = (key, obj, index, nested = false) => {
        const style = nested ? createNestedStyle() : createHeaderStyle(index);

        return (
            <TableRow key={key}>
                <Tooltip title={obj?.tooltip ? <div className="pre-line">{obj?.tooltip}</div> : undefined} enterDelay={400} leaveDelay={200} arrow placement="top">
                    <TableCell className={`table-text-cell ${style}`} align="left">
                        {key}
                    </TableCell>
                </Tooltip>

                {obj?.value.map((value, i) => (
                    <TableCell key={`${value}-${i}`} className={`table-text-cell ${style}`} align="right">
                        {value}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    /**
     * E.g. { key1: { key2: { tooltip: "", value: ["", ""] },  key3: { ... }, _total: { tooltip: "", value: ["", ""] } } }
     * Key1 - totalValue1 - totalValue2
     * Key2 - value1 - value2
     *
     * _total is optional.
     */
    const formatNestedKeyValueRow = (key, obj, index) => {
        const style = createHeaderStyle(index);

        const keys = Object.keys(obj).filter((key) => key !== "_total");
        const totalValue = obj?._total?.value || [];

        return (
            <React.Fragment key={key}>
                <TableRow>
                    <Tooltip title={obj?.tooltip ? <div className="pre-line">{obj?.tooltip}</div> : undefined} enterDelay={400} leaveDelay={200} arrow placement="top">
                        <TableCell className={`table-text-cell ${style}`} colSpan={totalValue.length === 0 ? colSpan : 1} align="left">
                            {key}
                        </TableCell>
                    </Tooltip>

                    {totalValue.map((value, i) => (
                        <TableCell key={`${value}-${i}`} className={`table-text-cell ${style}`} align="right">
                            {value}
                        </TableCell>
                    ))}
                </TableRow>

                {keys.map((key, i) => formatArrayValueRow(key, obj[key], i, true))}
            </React.Fragment>
        );
    };

    return (
        <TableContainer>
            <Table sx={{ m: 0, p: 0 }}>
                <TableHead>
                    <TableRow>{headers}</TableRow>
                </TableHead>

                <TableBody>{keys.map((key, index) => decideComponent(key, data[key], index))}</TableBody>
            </Table>
        </TableContainer>
    );
}
