import { Radio, Stack } from "@mui/material";

import PublicIcon from "@mui/icons-material/Public";
import GroupIcon from "@mui/icons-material/Group";
import LockIcon from "@mui/icons-material/Lock";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import StraightIcon from "@mui/icons-material/Straight";
import MergeIcon from "@mui/icons-material/Merge";
import LoopIcon from "@mui/icons-material/Loop";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";

import TickerImage from "../../helper-components/misc/TickerImage.js";
import CustomButton from "../../helper-components/input/CustomButton.js";

import { defaultIsRowSelectable } from "./utils.js";
import { getAssetByKey, getAssetValueByKey } from "../utils/asset/asset.js";
import { convertToDate, formatDate } from "../utils/validation/object";

import * as Enums from "../../config/constants/Enums.js";

const renderLogo = (props, params) => {
    const asset = getAssetByKey(props, "id", params.row.assetId);
    return <TickerImage asset={asset} height="100%" />;
};

const renderVisibility = (params) => {
    if (params.row.visibility === Enums.SIGNAL_VISIBILITY_PUBLIC) {
        return <PublicIcon />;
    } else if (params.row.visibility === Enums.SIGNAL_VISIBILITY_PROTECTED) {
        return <GroupIcon />;
    } else if (params.row.visibility === Enums.SIGNAL_VISIBILITY_PRIVATE) {
        return <LockIcon />;
    }

    return <QuestionMarkIcon />;
};

const renderType = (params) => {
    if (params.row.ids) {
        return <MergeIcon />;
    }

    return <StraightIcon />;
};

const renderSingleSelect = (props, params) => {
    const selectable = defaultIsRowSelectable(props, params);

    return (
        <Stack direction="row" alignItems="center">
            <Radio checked={props.selected.id === params.row.id} onChange={() => props.setSelected(params.row)} disabled={!selectable} color="primary" />
        </Stack>
    );
};

const renderStatus = (params) => {
    const status = params.row.status;

    if (status === "LOADING") {
        return <CustomButton icon={<LoopIcon className="spin-slow cursor-default" />} tooltip={status} />;
    }

    if (status === "DISABLED") {
        return <CustomButton icon={<BlockIcon className="cursor-default" />} tooltip={status} />;
    }

    return <CustomButton icon={<DoneIcon className="cursor-default" />} tooltip={status} />;
};

export const defaultStyle = { align: "center", headerAlign: "center", flex: 0.1, minWidth: 75, fontSize: 10 };
export const noEditStyle = { editable: false, selectable: false, sortable: false };

// Alerts & signals.
export const assetId = { ...defaultStyle, field: "assetId", headerName: "Asset" };
export const signalId = { ...defaultStyle, field: "signalId", headerName: "Signal" };
export const name = { ...defaultStyle, field: "name", headerName: "Name", flex: 0.15, minWidth: 75 };
export const description = { ...defaultStyle, field: "description", headerName: "Description", flex: 0.2, minWidth: 150 };
export const orderType = { ...defaultStyle, field: "orderType", headerName: "Order" };
export const visibility = { ...defaultStyle, field: "visibility", headerName: "Visibility", minWidth: 50, renderCell: renderVisibility };
export const status = { ...defaultStyle, field: "status", headerName: "Status", flex: 0.05, minWidth: 65, renderCell: renderStatus };
export const rating = { ...defaultStyle, field: "rating", headerName: "Likes", description: "Ratings by subscribers, higher is better", flex: 0.05 };
export const signals = {
    ...defaultStyle,
    field: "ids",
    headerName: "Signals",
    description: "Number of signals combined",
    flex: 0.05,
    valueGetter: (params) => params.row.ids.length,
    valueFormatter: (params) => params.value,
};
export const type = {
    ...defaultStyle,
    field: "type",
    headerName: "Type",
    description: "External or combined item",
    minWidth: 50,
    valueGetter: (params) => (params.row.ids ? "combined" : "external"),
    renderCell: renderType,
};
export const time = (key = "lastAlert") => {
    return {
        ...defaultStyle,
        field: key,
        type: "date",
        headerName: "Latest alert",
        description: "Converted to local time.",
        align: "right",
        flex: 0.15,
        minWidth: 175,
        valueGetter: (params) => convertToDate(params.row[key]),
        valueFormatter: (params) => formatDate(params.value),
    };
};
export const select = (props, paths) => {
    return {
        ...defaultStyle,
        field: "select",
        headerName: "Select",
        sortable: false,
        minWidth: 50,
        renderCell: (params) => renderSingleSelect(props, params),
    };
};
export const assetLogo = (props) => {
    return {
        ...defaultStyle,
        ...noEditStyle,
        flex: 0.05,
        field: "asset",
        headerName: "Logo",
        description: "Logo of the asset",
        minWidth: 50,
        valueGetter: (params) => getAssetValueByKey(props, params.row.assetId, "ticker"),
        renderCell: (params) => renderLogo(props, params),
    };
};
