/**
 * Fullscreen page spinner / loader.
 */
export default function PageLoader(props) {
    return (
        <div hidden={!props.show}>
            <div className="page-loader"></div>
        </div>
    );
}
