import jwtDecode from "jwt-decode";

import { createCriteriaParameters, standardDeleteQuery, standardGetQuery, standardPostQuery, standardPutQuery } from "../utils/misc/api.js";
import { logUserOut } from "../utils/user/auth.js";
import { handleAddUsers } from "../utils/user/utils.js";

import * as Paths from "../../config/constants/Paths.js";
import { getLanguage } from "../../config/language/language.js";

/**
 * Request users from the server with pagination, sort and feedback.
 *
 * Options: page, pageSize, filter, sort
 */
export const queryUsers = (props, options = {}) => {
    const path = Paths.API_USERS_ALL_PATH + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddUsers(props, response.body);

        const states = props.users;
        if (options.page) states.setQueriedUntil(options.page);
        if (response.body.total) states.setRowCount(response.body.total);
    });
};

/**
 * Query user info and save to state, based on the JWT.
 */
export const loadCurrentUser = (props) => {
    console.log("Querying info about user...");

    const accessToken = props.user.accessToken;

    if (accessToken !== "") {
        const jwt = jwtDecode(accessToken);

        standardGetQuery(props, Paths.USER_USERS_PATH + `/${jwt.id}`, (response) => {
            props.user.setData(response.body);
        });
    }
};

/**
 * Update user's info.
 *
 * Parameters:
 * type - the type of endpoint to query (e.g. General.API_USERS_UPDATE_PATH)
 * body - JSON-encoded object sent alongside the request
 * username - the new username
 *
 * After a successful response, query the user's info again.
 */
export const updateUser = (props, path, body) => {
    standardPutQuery(props, path, body, (response) => {
        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_ACCOUNT_UPDATED });

        loadCurrentUser(props);
    });
};

/**
 * Regenerate the user's secret.
 * No body needed.
 *
 * After a successful response, query the user's info again.
 */
export const regenerateUserSecret = (props) => {
    standardPostQuery(props, Paths.API_USERS_SECRET_PATH, {}, (response) => {
        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SECRET_UPDATED });

        loadCurrentUser(props);
    });
};

/**
 * Let the user delete themselves, or let the admin do it.
 * If user itself, log out afterwards.
 *
 * Parameters:
 * type - the type of endpoint to query (e.g. General.API_USERS_DELETE_PATH)
 * body - JSON-encoded object sent alongside the request
 */
export const deleteUsers = (props, path, body) => {
    standardDeleteQuery(props, path, body, (response) => {
        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_ACCOUNT_DELETED });

        if (props.isAdminComponent) {
            props.setSelected([]);
        } else {
            props.user.setUsername("");
            logUserOut(props, true, "/signup");
        }
    });
};
