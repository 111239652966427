import * as React from "react";

import ComponentLoader from "./ComponentLoader.js";

/**
 * Iframe is a webpage inside a webpage.
 *
 * This custom component also has a loader / spinner.
 * After the iframe has been loaded, it will be hidden.
 */
export default function Iframe(props) {
    const loaded = props.loaded ?? true;

    const [iframeLoaded, setIframeLoaded] = React.useState(false);

    React.useEffect(() => {
        setIframeLoaded(false);
    }, [props?.browser?.location?.pathname]);

    const visible = iframeLoaded && loaded;

    return (
        <>
            <ComponentLoader isVisible={!visible} width={props.loaderWidth} />

            <iframe
                className={visible ? "loaded-iframe" : "non-loaded-iframe"}
                id={props.id}
                title={props.title}
                srcDoc={props.srcDoc}
                src={props.src}
                style={{ width: props.width ?? "100%", height: props.height ?? "100%" }}
                scrolling="no"
                onLoad={() => {
                    setIframeLoaded(true);
                }}
            />
        </>
    );
}
