import * as React from "react";

import { Container, Divider, Grid, Typography } from "@mui/material";

import QueryStatsIcon from "@mui/icons-material/QueryStats";

import MediaCard from "../../../helper-components/misc/MediaCard.js";
import CustomForm from "../../../helper-components/form/CustomForm.js";
import CustomImage from "../../../helper-components/misc/CustomImage.js";
import CustomButton from "../../../helper-components/input/CustomButton.js";
import PaperFragment from "../../../helper-components/misc/PaperFragment.js";

import TradingViewTutorial from "./components/TradingViewTutorial.js";
import SignalCombineTutorial from "./components/SignalCombineTutorial.js";
import SignalShareTutorial from "./components/SignalShareTutorial.js";
import SignalSubscribeTutorial from "./components/SignalSubscribeTutorial.js";

import { getLanguage } from "../../../config/language/language.js";

/**
 * Overview page for signals.
 *
 * Features:
 * - Media cards with a brief overview of the different signals.
 * - Navigation to the different signal pages.
 * - How-to guides related to signals.
 */
export default function Signals(props) {
    const showTradingViewDialog = () => {
        props.dialog.enqueueDialog({
            id: "signals-tutorial-external",
            title: "Receive alerts via TradingView",
            component: <TradingViewTutorial />,
        });
    };

    const showSignalCombineDialog = () => {
        props.dialog.enqueueDialog({
            id: "signals-tutorial-combine",
            title: "Combine signals",
            component: <SignalCombineTutorial />,
        });
    };

    const showSignalShareDialog = () => {
        props.dialog.enqueueDialog({
            id: "signals-tutorial-share",
            title: "Share signals with others",
            component: <SignalShareTutorial />,
        });
    };

    const showSignalSubscribeDialog = () => {
        props.dialog.enqueueDialog({
            id: "signals-tutorial-subscribe",
            title: "Subscribe to community signals",
            component: <SignalSubscribeTutorial />,
        });
    };

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().SIGNALS_TITLE} subtitle={getLanguage().SIGNALS_SUBTITLE} icon={<QueryStatsIcon />} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <MediaCard
                        {...props}
                        title="External signals"
                        summary="View and create signals configured to arrive from TradingView."
                        bannerImage="/images/ai_generated/external_signals.jpg"
                        bannerHeight={200}
                        contentHeight={120}
                        url="/market-analysis/technical/signals/external"
                        fullClick
                        raised
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <MediaCard
                        {...props}
                        title="Combined signals"
                        summary="Combine signals from multiple sources to create a new comprehensive signal."
                        bannerImage="/images/ai_generated/combined_signals.jpg"
                        bannerHeight={200}
                        contentHeight={120}
                        url="/market-analysis/technical/signals/combined"
                        fullClick
                        raised
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                    <MediaCard
                        {...props}
                        title="Community signals"
                        summary="Subscribe to signals created by other users to use them in your own strategies."
                        bannerImage="/images/ai_generated/community_signals.jpg"
                        bannerHeight={200}
                        contentHeight={120}
                        url="/market-analysis/technical/signals/community"
                        fullClick
                        raised
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider className="sm-margin" />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <CustomImage
                        cover
                        url={`${process.env.PUBLIC_URL}/images//screenshots/tradingview_chart_1.png`}
                        height="100%"
                        componentHeight="275px"
                        border="1px solid <theme>"
                    />
                </Grid>

                <Grid container item xs={12} sm={8} alignContent="flex-start">
                    <Grid item xs={12}>
                        <PaperFragment title={getLanguage().SIGNALS_TEXT_1_TITLE} value={<Typography className="pre-line">{getLanguage().SIGNALS_TEXT_1_SUBTITLE}</Typography>} />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomButton onClick={showTradingViewDialog} title="Show tutorial" fullWidth />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Divider className="xs-margin" />
                </Grid>

                <Grid container item xs={12} sm={7} alignContent="flex-start">
                    <Grid item xs={12}>
                        <PaperFragment title={getLanguage().SIGNALS_TEXT_2_TITLE} value={<Typography className="pre-line">{getLanguage().SIGNALS_TEXT_2_SUBTITLE}</Typography>} />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomButton onClick={showSignalCombineDialog} title="Show tutorial" fullWidth />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <CustomImage
                        cover
                        url={`${process.env.PUBLIC_URL}/images/screenshots/combined_signals_table.png`}
                        height="100%"
                        componentHeight="250px"
                        border="1px solid <theme>"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider className="xs-margin" />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <CustomImage cover url={`${process.env.PUBLIC_URL}/images/tutorials/share_signals_4.png`} height="100%" componentHeight="250px" border="1px solid <theme>" />
                </Grid>

                <Grid container item xs={12} sm={7} alignContent="flex-start">
                    <Grid item xs={12}>
                        <PaperFragment title={getLanguage().SIGNALS_TEXT_3_TITLE} value={<Typography className="pre-line">{getLanguage().SIGNALS_TEXT_3_SUBTITLE}</Typography>} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CustomButton onClick={showSignalShareDialog} title="Show tutorial (share)" fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CustomButton onClick={showSignalSubscribeDialog} title="Show tutorial (subscribe)" fullWidth />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
