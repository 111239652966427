import * as React from "react";

import { Container, Grid } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WebhookIcon from "@mui/icons-material/Webhook";

import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MergeIcon from "@mui/icons-material/Merge";
import PeopleIcon from "@mui/icons-material/People";

import CustomForm from "../../../helper-components/form/CustomForm.js";
import StyledTable from "../../../helper-components/table/StyledTable.js";
import StackedHeader from "../../../helper-components/misc/StackedHeader.js";
import PaperFragment from "../../../helper-components/misc/PaperFragment.js";
import NavigationFooter from "../../../helper-components/misc/NavigationFooter.js";

import SignalAddForm from "./components/SignalAddForm.js";
import SignalShareForm from "./components/SignalShareForm.js";
import SignalUpdateForm from "./components/SignalUpdateForm.js";

import { getCategory } from "../../../helper-functions/utils/asset/utils.js";
import { getValueFromObjectArrayByKey } from "../../../helper-functions/utils/validation/object.js";
import { externalSubscribedColumns, externalUserColumns, defaultTableOptions } from "../../../helper-functions/table/signal.js";
import { deleteSignals, disableSignals, enableSignals, querySignals, unsubscribeSignals } from "../../../helper-functions/api/signalQueries.js";

import * as Enums from "../../../config/constants/Enums.js";
import { getLanguage } from "../../../config/language/language.js";
import { PUBLIC_TRADINGVIEW_ALERTS_PATH } from "../../../config/constants/Paths.js";

/**
 * The external signals page.
 *
 * Features:
 * - a table of user signals
 * - a table of subscribed signals
 * - forms and dialogs to modify signals
 */
export default function ExternalSignals(props) {
    const category = getCategory(Enums.EXTERNAL_SIGNAL);

    const userData = props?.user?.data;

    const externalSignals = props.signals.external;
    const externalAlerts = props.alerts.external;

    const { owner, subscribed } = externalSignals;
    const { selected: selectedOwner } = owner;
    const { selected: selectedSubscribed } = subscribed;

    const showSignalAddDialog = () => {
        props.dialog.enqueueDialog({
            id: "signal-create",
            title: "Create signal",
            message: `Create a new external signal via the application.

            View the tutorial on the 'Signals' page on how to configure the TradingView side.`,
            component: <SignalAddForm />,
            componentProps: { category, state: owner },
        });
    };

    const showSignalEditDialog = () => {
        props.dialog.enqueueDialog({
            id: "signal-edit",
            title: "Edit signal",
            message: `Update the selected signal's fields.
            Keep in mind, if the signal is already public, then only few fields are allowed to be updated!`,
            component: <SignalUpdateForm />,
            componentProps: { category, state: owner },
        });
    };

    const showSignalShareDialog = () => {
        props.dialog.enqueueDialog({
            id: "signal-share",
            title: "Share signal(s)",
            message: `Share the selected signal(s) with others.
            Subscribed users can then receive the same alerts and use the signals for their own strategies.`,
            component: <SignalShareForm />,
            componentProps: { category, state: owner },
        });
    };

    const showSignalDeleteDialog = () => {
        props.dialog.enqueueDialog({
            title: "Delete signal(s)",
            message: `Are you sure you want to delete the selected signal(s)?
            This action will also delete any associated alerts.

            NB! This action might also affect any combined signals and alerts you have created using the signals.`,
            actionTitle: "Delete",
            actionColor: "error",
            callback: (confirm) => handleSignalDelete(confirm),
        });
    };

    const showSignalUnsubscribeDialog = () => {
        props.dialog.enqueueDialog({
            title: "Unsubscribe from signal(s)",
            message: `Are you sure you want to unsubscribe from the selected signal(s)?
            The selected signals will be removed from your combined signals list, alongside the alerts.

            NB! This action might also affect any combined signals and alerts you have created using the signals.`,
            actionTitle: "Unsubscribe",
            actionColor: "error",
            callback: (confirm) => handleSignalUnsubscribe(confirm),
        });
    };

    const showWebhookDialog = () => {
        const showDisclaimer = props.browser.webhookDisclaimer;
        const setShowDisclaimer = props.browser.setWebhookDisclaimer;

        if (!showDisclaimer) {
            handleCopyUrl();
            return;
        }

        props.dialog.enqueueDialog({
            title: "TradingView webhook URL",
            message: `NB! This URL is unique to your account and should not be shared with others.

            If you suspect someone else has access to this URL, generate a new one via the 'Account' page.`,
            actionTitle: "Understood",
            actionColor: "success",
            callback: (confirm) => {
                if (!confirm) return;

                setShowDisclaimer(false);
                handleCopyUrl();
            },
        });
    };

    /**
     * Copy the template which integrates a TradingView alert with a signal to the clipboard.
     */
    const handleCopyTemplate = () => {
        const selectedSignal = selectedOwner[0];

        if (!selectedSignal) props.showSnackbar({ message: "No signal selected", color: "error" });
        if (!userData?.secret) {
            props.showSnackbar({ message: "Secret not found, please log in again!", color: "error" });
            return;
        }

        const template = getLanguage()
            .TRADINGVIEW_MESSAGE_OPTIONS.replace("<signalId>", selectedSignal.id)
            .replace("<name>", selectedSignal?.name || "")
            .replace("<description>", selectedSignal?.description?.replace(/\n/g, "\\n") || "");
        navigator.clipboard.writeText(template);

        props.showSnackbar({ message: "Template copied to clipboard", color: "info" });
    };

    const handleCopyUrl = () => {
        const url = PUBLIC_TRADINGVIEW_ALERTS_PATH.replace("<secret>", userData?.secret || "UNKN");
        navigator.clipboard.writeText(url);

        props.showSnackbar({ message: "Webhook URL copied to clipboard", color: "info" });
    };

    const handleSignalDelete = (confirm) => {
        if (!confirm) return;

        const ids = getValueFromObjectArrayByKey(selectedOwner);
        deleteSignals(props, { signalsState: owner, alertsState: externalAlerts.owner, body: { ids } });
    };

    const handleSignalEnable = () => {
        const ids = getValueFromObjectArrayByKey(selectedOwner);

        enableSignals(props, { state: owner, body: { ids } });
    };

    const handleSignalDisable = () => {
        const ids = getValueFromObjectArrayByKey(selectedOwner);

        disableSignals(props, { state: owner, body: { ids } });
    };

    const handleSignalUnsubscribe = (confirm) => {
        if (!confirm) return;

        const ids = getValueFromObjectArrayByKey(selectedOwner);
        unsubscribeSignals(props, { signalsState: subscribed, alertsState: externalAlerts.subscribed, body: { ids } });
    };

    const selectedSignalsHasStatus = (status, len = 0) => selectedOwner.filter((row) => row.status === status).length > len;

    return (
        <Container maxWidth="xl" className="container">
            <Grid container>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().EXTERNAL_SIGNALS_TITLE} subtitle={getLanguage().EXTERNAL_SIGNALS_SUBTITLE} icon={<RequestQuoteIcon />} />
                </Grid>

                <PaperFragment
                    title={
                        <StackedHeader
                            {...props}
                            title="Owner signals"
                            subtitle="View signals configured to arrive from TradingView."
                            icons={[
                                { icon: <DeleteIcon />, tooltip: "Delete selected signals", onClick: showSignalDeleteDialog, disabled: selectedOwner.length < 1 },
                                {
                                    icon: <ShareIcon />,
                                    tooltip: "Share selected signals",
                                    onClick: showSignalShareDialog,
                                    disabled: selectedOwner.length < 1 && !selectedSignalsHasStatus(Enums.STATUS_ENABLED),
                                },
                                { icon: <EditIcon />, tooltip: "Edit selected signal", onClick: showSignalEditDialog, disabled: selectedOwner.length !== 1 },
                                {
                                    icon: <ContentCopyIcon />,
                                    tooltip: "Copy TradingView template for selected signal",
                                    onClick: handleCopyTemplate,
                                    disabled: selectedOwner.length !== 1,
                                },
                                { icon: <WebhookIcon />, tooltip: "Copy TradingView webhook URL", onClick: showWebhookDialog },
                                {
                                    icon: <PauseCircleOutlineIcon />,
                                    tooltip: "Disable selected signals",
                                    onClick: handleSignalDisable,
                                    disabled: selectedOwner.length < 1 || selectedSignalsHasStatus(Enums.STATUS_DISABLED),
                                },
                                {
                                    icon: <PlayCircleOutlineIcon />,
                                    tooltip: "Enable selected signals",
                                    onClick: handleSignalEnable,
                                    disabled: selectedOwner.length < 1 || selectedSignalsHasStatus(Enums.STATUS_ENABLED),
                                },
                            ]}
                            buttons={[
                                {
                                    icon: <AddIcon />,
                                    title: selectedOwner.length === 0 ? "Add new signal" : "Duplicate signal",
                                    onClick: showSignalAddDialog,
                                    disabled: selectedOwner.length > 1,
                                },
                            ]}
                        />
                    }
                    value={
                        <StyledTable
                            {...props}
                            {...defaultTableOptions}
                            states={owner}
                            columns={externalUserColumns(props)}
                            query={(options) => querySignals(props, { ...options, path: category.paths.owner, state: owner })}
                            empty={getLanguage().NO_SIGNALS_FOUND}
                            checkboxSelection
                        />
                    }
                />

                <PaperFragment
                    title={
                        <StackedHeader
                            {...props}
                            title="Subscribed signals"
                            subtitle="View external signals you have subscribed to."
                            icons={[
                                {
                                    icon: <RemoveCircleOutlineIcon />,
                                    tooltip: "Unsubscribe from selected signals",
                                    onClick: showSignalUnsubscribeDialog,
                                    disabled: selectedSubscribed.length < 1,
                                },
                            ]}
                            buttons={[
                                {
                                    icon: <ShortcutIcon />,
                                    title: "Find more signals",
                                    onClick: () => props.browser.navigate(`/market-analysis/technical/signals/community`),
                                    variant: "outlined",
                                },
                            ]}
                        />
                    }
                    value={
                        <StyledTable
                            {...props}
                            {...defaultTableOptions}
                            states={subscribed}
                            columns={externalSubscribedColumns(props)}
                            query={(options) => querySignals(props, { ...options, path: category.paths.subscribed, state: subscribed })}
                            empty={getLanguage().NO_SUBSCRIBED_SIGNALS_FOUND}
                            checkboxSelection
                        />
                    }
                />

                <NavigationFooter
                    rows={2}
                    options={[
                        // {
                        //     path: `/market-analysis/technical/signals/external`,
                        //     title: `External signals`,
                        // icon: <StraightIcon />,
                        // },
                        {
                            path: `/market-analysis/technical/signals/combined`,
                            title: `Combined signals`,
                            icon: <MergeIcon />,
                        },
                        {
                            path: `/market-analysis/technical/signals/community`,
                            title: `Community signals`,
                            icon: <PeopleIcon />,
                        },
                        {
                            path: `/market-analysis/technical/signals`,
                            title: `Signals dashboard`,
                            icon: <RequestQuoteIcon />,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
