import React from "react";
import { useLocalStorage } from "../useLocalStorage";

/**
 * Hook to define states related to the logged in user.
 */
export const useUserStates = (name = "User", options = {}) => {
    const { logData = false, logOther = false } = options;

    // Data.
    const [data, setData] = React.useState([]);

    // Variables can be saved in a browser's localstorage.
    const [username, setUsername] = useLocalStorage("username", "");
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
    const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", "");

    React.useEffect(() => {
        if (!logData) return;

        console.log(`=== ${name} (DATA) ===`);
        console.log("data", JSON.parse(JSON.stringify(data)));
        console.log("\n");
    }, [data]);

    React.useEffect(() => {
        if (!logOther) return;

        console.log(`=== ${name} (OTHER) ===`);
        console.log("username", JSON.parse(JSON.stringify(username)));
        console.log("accessToken", JSON.parse(JSON.stringify(accessToken)));
        console.log("refreshToken", JSON.parse(JSON.stringify(refreshToken)));
        console.log("\n");
    }, []);

    return {
        data,
        setData,

        username,
        setUsername,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
    };
};
