import { createCriteriaParameters, standardDeleteQuery, standardGetQuery, standardPostQuery } from "../utils/misc/api.js";
import { handleAddAssets, handleAddAsset } from "../utils/asset/asset.js";

import * as Paths from "../../config/constants/Paths.js";
import { getLanguage } from "../../config/language/language.js";

/**
 * Request assets' data from the server.
 *
 * Options: page, pageSize, filter, sort
 */
export const queryAssets = (props, options = {}) => {
    const path = Paths.USER_ASSETS_PATH + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddAssets(props, response.body);

        // Don't modify the pagination if specified.
        if (options?.modifyPagination === false) return;

        const states = props.assets;
        if (options.page) states.setQueriedUntil(options.page);
        if (response.body.total) states.setRowCount(response.body.total);
    });
};

export const resetAssetData = (props, id) => {
    const path = `${Paths.ADMIN_ASSETS_PATH}/${id}/reset`;

    standardDeleteQuery(props, path, {}, (response) => {
        props.showSnackbar({ message: "Asset's data has been reset" });
        props.browser.navigate("/market-analysis/fundamental/assets");
    });
};

/**
 * Request asset data from the server.
 *
 * Options: id
 */
export const queryAsset = (props, options = {}) => {
    const { id } = options;

    const path = Paths.USER_ASSETS_PATH + `/${id}`;

    standardGetQuery(props, path, (response) => {
        handleAddAsset(props, response.body);

        const states = props.assets;
        states.setRowCount((prev) => prev + 1);
    });
};

/**
 * Request asset's profile from the server.
 *
 * Options: id, state
 */
export const queryProfile = (props, options = {}) => {
    const { setState, id } = options;

    const path = Paths.USER_ASSETS_PATH + `/${id}/profile`;

    standardGetQuery(
        props,
        path,
        (response) => {
            setState(response.body);
        },
        options
    );
};

/**
 * Request asset's recent fundamentals from the server.
 *
 * Options: id, state
 */
export const queryRecentStatistics = (props, options = {}) => {
    const { setState, id } = options;

    const path = Paths.USER_ASSETS_PATH + `/${id}/statistics/latest`;

    standardGetQuery(props, path, (response) => {
        setState(response.body);
    });
};

/**
 * Request asset's historical fundamentals from the server.
 *
 * Options: id, state
 */
export const queryHistoricalStatistics = (props, options = {}) => {
    const { setState, id } = options;

    const path = Paths.USER_ASSETS_PATH + `/${id}/statistics/historic`;

    standardGetQuery(props, path, (response) => {
        setState(response.body);
    });
};

/**
 * Request asset's historical fundamentals from the server.
 *
 * Options: id, state
 */
export const queryBalanceStatements = (props, options = {}) => {
    const { setState, id } = options;

    const path = Paths.USER_ASSETS_PATH + `/${id}/statements/balance`;

    standardGetQuery(
        props,
        path,
        (response) => {
            setState(response.body);
        },
        { showMessage: false }
    );
};

/**
 * Request asset's historical fundamentals from the server.
 *
 * Options: id, state
 */
export const queryIncomeStatements = (props, options = {}) => {
    const { setState, id } = options;

    const path = Paths.USER_ASSETS_PATH + `/${id}/statements/income`;

    standardGetQuery(
        props,
        path,
        (response) => {
            setState(response.body);
        },
        { showMessage: false }
    );
};

/**
 * Request asset's historical fundamentals from the server.
 *
 * Options: id, state
 */
export const queryCashFlowStatements = (props, options = {}) => {
    const { setState, id } = options;

    const path = Paths.USER_ASSETS_PATH + `/${id}/statements/cash-flow`;

    standardGetQuery(
        props,
        path,
        (response) => {
            setState(response.body);
        },
        { showMessage: false }
    );
};

/**
 * Request a new asset to be added.
 */
export const addAsset = (props, options = {}) => {
    const { path = Paths.USER_ASSETS_PATH, body } = options;

    standardPostQuery(props, path, body, (response) => {
        handleAddAsset(props, response.body);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_ASSET_ADDED });

        const states = props.assets;
        states.setRowCount((prev) => prev + 1);
    });
};
