import * as React from "react";

import { Box, TextField, FormControlLabel, Checkbox, Grid, Rating } from "@mui/material";

import CustomButton from "../input/CustomButton";

import { sendFeedback } from "../../helper-functions/api/miscQueries";
import { getLanguage } from "../../config/language/language.js";

export default function FeedbackForm(props) {
    const [ratingValue, setRatingValue] = React.useState(0);
    const [publicChecked, setPublicChecked] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const rating = data.get("rating");
        const feedback = data.get("feedback");
        const sharePublicly = data.get("public") ? true : false;

        if (ratingValue < 1 || ratingValue > 5) {
            props.showSnackbar({ color: "warning", message: "Rating must be between 1 and 5." });
            return;
        }

        if (feedback === undefined || feedback === null || feedback.trim() === "") {
            props.showSnackbar({ color: "warning", message: "Feedback cannot be empty." });
            return;
        }

        const body = { rating, feedback, sharePublicly };

        sendFeedback(props, body);
        clearForm();
    };

    const clearForm = () => {
        document.getElementById("feedbackForm").reset();
        setRatingValue(0);
        setPublicChecked(false);
    };

    return (
        <Box className="box" id="feedbackForm" component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Rating
                        name="rating"
                        value={ratingValue}
                        onChange={(e, newValue) => {
                            setRatingValue(newValue);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField name="feedback" label="Feedback" placeholder={getLanguage().FORM_FEEDBACK_PLACEHOLDER} multiline minRows={4} fullWidth />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        name="public"
                        label={getLanguage().FORM_FEEDBACK_SHARE_LABEL}
                        control={
                            <Checkbox
                                checked={publicChecked}
                                onChange={(event) => {
                                    setPublicChecked(event.target.checked);
                                }}
                                color="primary"
                            />
                        }
                    />
                </Grid>
            </Grid>

            <CustomButton {...props} title={getLanguage().BUTTON_SEND_FEEDBACK} className="button" type="submit" fullWidth />
        </Box>
    );
}
