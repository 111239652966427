import * as React from "react";

import { Box, Stepper, Step, StepLabel, Typography, Divider } from "@mui/material";

import CustomButton from "../input/CustomButton";

/**
 * A custom stepper (tutorial) component.
 *
 * The steps are defined in the 'steps' array, alongside the components.
 * The final step is defined in the 'final' object.
 */
export default function CustomStepper(props) {
    const { steps = [], final = {} } = props;

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    /**
     * Return a component for the currently active step, based on the configuration.
     */
    const createActiveStepComponent = () => {
        const step = steps[activeStep];

        return (
            <>
                {step.component}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <CustomButton title="Back" variant="text" disabled={activeStep === 0} onClick={handleBack} />

                    <Box sx={{ flex: "1 1 auto" }} />

                    {isStepOptional(activeStep) && <CustomButton title="Skip" variant="text" onClick={handleSkip} />}

                    <CustomButton title={activeStep === steps.length - 1 ? "Finish" : "Next"} variant="text" onClick={handleNext} />
                </Box>
            </>
        );
    };

    /**
     * Return a component for the finished step.
     */
    const createFinishedComponent = () => {
        const step = final;

        return (
            <>
                {step.component}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />

                    <CustomButton title="Reset" variant="text" color="primary" onClick={handleReset} />
                </Box>
            </>
        );
    };

    // Stepper logic below.
    const isStepOptional = (step) => {
        return step.optional;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);

            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    if (isStepOptional(step)) labelProps.optional = <Typography variant="caption">Optional</Typography>;
                    if (isStepSkipped(index)) stepProps.completed = false;

                    return (
                        <Step key={step.label} {...stepProps}>
                            <StepLabel {...labelProps}>{step.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <Divider className="sm-margin" />

            {activeStep === steps.length ? createFinishedComponent() : createActiveStepComponent()}
        </Box>
    );
}
