import * as React from "react";
import { Link } from "react-router-dom";

import { ColorModeContext } from "../../../../App.js";

import MuiAppBar from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import { Toolbar, Typography, IconButton, Badge } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import CustomButton from "../../../../helper-components/input/CustomButton.js";

import { isLoggedIn } from "../../../../helper-functions/utils/misc/utils.js";
import { logUserOut } from "../../../../helper-functions/utils/user/auth.js";
import { handleTourGuide } from "../../../../helper-functions/utils/page/utils.js";

import { getLanguage } from "../../../../config/language/language.js";
import * as General from "../../../../config/constants/General.js";

// Create a custom styled app bar.
const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: General.PAGE_DRAWER_WIDTH_PX,
        width: `calc(100% - ${General.PAGE_DRAWER_WIDTH_PX}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

// Create a custom styled toolbar.
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // Override media queries injected by theme.mixins.toolbar
    "@media all": {
        minHeight: 64,
    },
}));

/**
 * The app bar component.
 * Uses styled components for the app bar and toolbar.
 *
 * Includes the title, description, and buttons for the app.
 *
 * The buttons include:
 * - Menu icon
 * - Theme switch icon
 * - Notifications icon
 * - Log in / Sign up icon
 * - Log out icon
 */
export default function Appbar(props) {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);

    const isMobile = props.browser.isMobile;

    const loggedIn = isLoggedIn(props);
    const username = props?.user?.username; // Localstorage username.

    const openAppDrawer = props.sidebar.openAppDrawer;
    const toggleAppDrawer = props.sidebar.toggleAppDrawer;
    const toggleNotificationDrawer = props.sidebar.toggleNotificationDrawer;

    const notificationCount = props.notifications.unread.rowCount;

    return (
        <StyledAppBar position="fixed" open={openAppDrawer && !isMobile}>
            <StyledToolbar sx={{ zIndex: isMobile ? 9999 : "initial" }} className="appbar-toolbar ">
                <IconButton
                    className="appbar-menu-icon"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleAppDrawer}
                    sx={{
                        ...(openAppDrawer && !isMobile && { display: "none" }),
                    }}>
                    <MenuIcon />
                </IconButton>

                <Typography className="appbar-title tour-guide-1" component="h1" variant="h5" color="inherit" noWrap>
                    {getLanguage().TOOLBAR_TITLE}
                </Typography>

                {/* Show either a log in or a sign up button w/ nested ternary operator. */}
                {!loggedIn ? (
                    username ? (
                        <Link to="/signin" className="hide-link">
                            <CustomButton {...props} icon={<LoginIcon />} tooltip="Sign in" color="inherit" className="appbar-icon-button" />
                        </Link>
                    ) : (
                        <Link to="/signup" className="hide-link">
                            <CustomButton {...props} icon={<LoginIcon />} tooltip="Sign up" color="inherit" className="appbar-icon-button" />
                        </Link>
                    )
                ) : undefined}

                {loggedIn && (
                    <CustomButton
                        {...props}
                        icon={<HelpOutlineIcon />}
                        tooltip="Tour of the site"
                        onClick={() => handleTourGuide(props, true)}
                        color="inherit"
                        className="appbar-icon-button"
                    />
                )}

                {loggedIn && (
                    <Link to="/user/account" className="hide-link tour-guide-2">
                        <CustomButton {...props} icon={<AccountCircleIcon />} tooltip="Account" color="inherit" className="appbar-icon-button" />
                    </Link>
                )}

                <CustomButton
                    {...props}
                    icon={theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
                    tooltip="Change theme"
                    onClick={colorMode.toggleColorMode}
                    color="inherit"
                    className="appbar-icon-button"
                />

                {loggedIn && (
                    <CustomButton
                        {...props}
                        icon={
                            <Badge badgeContent={notificationCount} color={theme.palette.mode === "dark" ? "primary" : "secondary"}>
                                <NotificationsIcon />
                            </Badge>
                        }
                        tooltip="Notifications"
                        onClick={toggleNotificationDrawer}
                        color="inherit"
                        className="appbar-icon-button"
                    />
                )}

                {loggedIn && (
                    <CustomButton
                        {...props}
                        icon={<LogoutIcon />}
                        tooltip="Log out"
                        onClick={() => {
                            logUserOut(props, true, "/signin");
                        }}
                        color="inherit"
                        className="appbar-icon-button"
                    />
                )}
            </StyledToolbar>
        </StyledAppBar>
    );
}
