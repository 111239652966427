import React from "react";

import { List, ListItemText, Typography, ListItemButton, Divider, Stack, Box, ListItemIcon, Checkbox } from "@mui/material";

import Search from "../../../../helper-components/input/Search";
import CustomButton from "../../../../helper-components/input/CustomButton";
import ComponentLoader from "../../../../helper-components/misc/ComponentLoader";

import { isObjExistsInArray } from "../../../../helper-functions/utils/validation/object";

/**
 * A selection component to choose a market (stock region, crypto, forex) from a list.
 * Includes a search bar and a list of markets alongside some text info about the currently selected market.
 *
 * Optional props:
 * - multiselect: select multiple items
 * - exclude: hide certain items from the list
 */
export default function MarketSelection(props) {
    const { callback, multiselect = false } = props; // Because modals don't re-render if the props' state changes, use this callback.

    const { data = [], exclude: initalExcluded = [], selected: initalSelected = [] } = props.componentProps;
    const exclude = [...initalExcluded];

    const [selected, setSelected] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);

    const [searchText, setSearchText] = React.useState("");
    const [prevSearchText, setPrevSearchText] = React.useState("");

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        filterItems(searchText);
    }, [data]);

    // After a timeout, show the "no entries" text.
    React.useEffect(() => {
        if (filtered.length !== 0) return;
        if (filtered.length === 0) setLoading(true);

        const loop = setTimeout(() => setLoading(false), 1500);
        return () => clearTimeout(loop);
    }, [filtered]);

    /**
     * Set the filtered rows according to the search text.
     * Query assets from the server with this search param.
     */
    const filterItems = (searchText) => {
        const searchWords = searchText.trim().toLowerCase().split(",");

        const availableItems = data.filter((market) => !exclude.includes(market.name));

        const filteredItems = availableItems.filter((market) => {
            const name = market.name?.toLowerCase() ?? "";

            const nameFound = searchWords.some((word) => name.includes(word));

            return nameFound;
        });

        setSelected(searchText === prevSearchText ? initalSelected : []);
        setFiltered(filteredItems);
    };

    /**
     * Executed when clicking on a list element.
     * Handles both single and multi-select.
     */
    const handleSelectionChange = (market) => {
        const isSelected = isObjExistsInArray(selected, market, "name");

        if (multiselect) {
            setSelected((prev) => {
                if (isSelected) {
                    return prev.filter((item) => item.name !== market.name);
                } else {
                    return [...prev, market];
                }
            });
        } else {
            setSelected(isSelected ? [] : [market]);
        }
    };

    /**
     *  A list element with a checkbox.
     */
    const listElement = (market, index) => {
        const isSelected = isObjExistsInArray(selected, market, "name");

        return (
            <ListItemButton key={index} selected={isSelected} onClick={() => handleSelectionChange(market)} dense>
                <ListItemIcon>
                    <Checkbox edge="start" checked={isSelected} onChange={() => handleSelectionChange(market)} disableRipple />
                </ListItemIcon>

                <ListItemText primary={`${market?.name || "N/A"}`} primaryTypographyProps={{ fontSize: "85%" }} className="dialog-selection-list-element" />
            </ListItemButton>
        );
    };

    /**
     *  Return info about the currently selected element(s).
     */
    const getCurrentSelectionInfo = () => {
        if (selected.length === 0) {
            return `Currently selected: N/A`;
        }

        if (selected.length === 1) {
            const selectedItem = selected[0];

            return `Currently selected: ${selectedItem?.name || "N/A"}`;
        }

        return `Currently selected (${selected.length}):
        ${selected.map((asset) => asset.ticker).join(", ")}`;
    };

    return (
        <Box className="box">
            <Search {...props} callback={filterItems} searchText={searchText} setSearchText={setSearchText} fullWidth />

            {/* Scrollable list of selection elements */}
            <List className="dialog-selection-list hide-scrollbar">
                <ComponentLoader isVisible={filtered.length === 0} isEmpty={false} height="75px" width="100%" />

                {!loading && filtered.length === 0 ? (
                    <ListItemText className="center" primary={`No results found!`} />
                ) : (
                    filtered.map((market, index) => listElement(market, index))
                )}

                {filtered.length > 0 && (
                    <Typography className="center italic sm-font sm-margin">Reached the end of the list - use the search field above to query more.</Typography>
                )}
            </List>

            <Divider className="sm-margin" />

            {/* Text info about the currently selected element */}
            <Typography className="pre-line" variant="body2" color="textSecondary">
                {getCurrentSelectionInfo()}
            </Typography>

            <Stack direction="row" justifyContent="end" alignItems="end">
                <CustomButton {...props} title="Cancel" onClick={() => callback(false, selected)} color="error" variant="text" />
                <CustomButton {...props} title="Select" onClick={() => callback(true, selected)} color="info" variant="text" />
            </Stack>
        </Box>
    );
}
