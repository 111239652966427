import * as React from "react";

import { Box, Grid, TextField, Typography } from "@mui/material";

import AssetSelection from "./AssetSelection.js";
import SignalSelection from "./SignalSelection.js";
import CustomInput from "../../../../helper-components/input/CustomInput.js";
import CustomButton from "../../../../helper-components/input/CustomButton.js";
import ConfirmationDialog from "../../../../helper-components/input/ConfirmationDialog.js";

import { createNumberString, formatSelectedNameOverview, getSignalById } from "../../../../helper-functions/utils/asset/signal.js";
import { addFormToObject, combineObjects, isEmpty, setValue } from "../../../../helper-functions/utils/validation/object.js";
import { formatAssetFormDescription, isCombinedByType } from "../../../../helper-functions/utils/asset/utils.js";
import { createUploadObject } from "../../../../helper-functions/utils/misc/upload.js";
import { setUniqueObjState } from "../../../../helper-functions/utils/misc/utils.js";
import { validateRow } from "../../../../helper-functions/utils/validation/utils.js";
import { getAssetByKey } from "../../../../helper-functions/utils/asset/asset.js";

import { addSignal, queryAllSignals } from "../../../../helper-functions/api/signalQueries.js";
import { useDialog } from "../../../../helper-functions/hooks/useDialog.js";

import * as Enums from "../../../../config/constants/Enums.js";
import { getLanguage } from "../../../../config/language/language.js";

/**
 * A form to create a new external or combined signal.
 *
 * Required props:
 * - state
 * - category
 */
export default function SignalAddForm(props) {
    const { category, state } = props.componentProps;

    const selectedSignal = state.selected?.[0];

    const validation = category.validation;
    const isValid = category.isValid;
    const path = category.paths.add;

    const [signal, setSignal] = React.useState(addFormToObject(selectedSignal ? combineObjects(selectedSignal, category.obj) : JSON.parse(JSON.stringify(category.obj))));
    const [selected, setSelected] = React.useState([]); // Use temporary state instead of props state (props state update not accessible).
    const [loading, setLoading] = React.useState(true); // Don't overwrite form fields during startup.

    const dialog = useDialog();
    const isCombined = isCombinedByType(category.type);

    // Update form fields during startup (only if a signal is to be duplicated).
    React.useEffect(() => {
        if (!selectedSignal) {
            setLoading(false);
            return;
        }

        const newSignal = {};
        const asset = getAssetByKey(props, "id", selectedSignal?.assetId);

        newSignal._assetId = formatAssetFormDescription(asset);
        newSignal.code = selectedSignal.code ?? ""; // MUI doesn't like 'null' values.

        if (isCombined) {
            newSignal._ids = `${selectedSignal.ids.length} signals selected`;

            if (selectedSignal.condition === Enums.CONDITION_CHAIN) {
                newSignal._value = createNumberString(selectedSignal.ids.length);
            }

            if (selectedSignal.condition === Enums.CONDITION_TIMEFRAME) {
                newSignal._value = selectedSignal.value;
            }

            // Query all signals that are not already in the state.
            const unknownSignalIds = selectedSignal.ids.filter((id) => isEmpty(getSignalById(props, id)));

            if (unknownSignalIds.length > 0) {
                console.warn(`Signals not found, querying: ${unknownSignalIds}`);

                queryAllSignals(props, {
                    queryOwner: true,
                    querySubscribed: true,
                    filter: { items: [{ columnField: "id", operatorValue: "isAnyOf", value: unknownSignalIds }] },
                });
            }

            // Set temporary signals selection.
            setSelected(
                selectedSignal.ids.map((id) => {
                    const signal = getSignalById(props, id);

                    return signal || { id };
                })
            );
        }

        setUniqueObjState(setSignal, newSignal);
        setLoading(false);
    }, []);

    // Update form fields after selecting signals.
    React.useEffect(() => {
        if (selectedSignal && loading) return;

        const newSignal = JSON.parse(JSON.stringify(signal));

        // Default value.
        if (newSignal.status === "") newSignal.status = Enums.STATUS_ENABLED;

        // Update any special, i.e. human-readable fields (_...) whenever selected signals change.
        if (isCombined) {
            newSignal.ids = selected.map((item) => item.id);
            newSignal._ids = `${selected.length} signals selected`;

            if (newSignal.condition === Enums.CONDITION_CHAIN) {
                newSignal.value = newSignal.ids.join(", ");
                newSignal._value = createNumberString(newSignal.ids.length);
            }

            // Create a default name by combining the duplicate words from each of the selected signals
            // E.g. TSLA 4h Trend + TSLA 1h Trend -> TSLA Trend
            if (newSignal.name === "" && selected.length > 0) {
                const selectedNames = selected.map((item) => item.name);
                const words = selectedNames.map((name) => name.split(" "));
                const commonWords = words.reduce((prev, curr) => prev.filter((word) => curr.includes(word)));
                newSignal.name = commonWords.join(" ");
            }
        }

        setUniqueObjState(setSignal, newSignal);
        if (!loading) validateRow(signal, validation, newSignal, undefined, { newSignal });
    }, [selected]);

    /**
     * Executed when typing something into an input field.
     */
    const onChange = (obj, key, value) => {
        const newSignal = JSON.parse(JSON.stringify(signal));
        setValue(newSignal, key, value);
        setSpecialValues(key, newSignal, value);

        validateRow(obj, validation, newSignal, undefined, { newSignal });
        setSignal(newSignal);
    };

    /**
     * Executed when clicking on form button.
     */
    const handleSubmit = () => {
        if (!isValid(signal)) {
            props.showSnackbar({ message: getLanguage().SNACKBAR_ERROR_SIGNAL_INVALID, color: "error" });
            return;
        }

        const body = createUploadObject(signal, ["form", "id", "_assetId", "_ids", "_value", "lastAlert", "ownerId"]);

        // Create 2 signals if it's a BUY & SELL signal.
        if (signal.orderType === "BUY & SELL") {
            addSignal(props, { state, path, body: { ...body, orderType: Enums.ORDER_BUY } });
            addSignal(props, { state, path, body: { ...body, orderType: Enums.ORDER_SELL } });
        } else {
            addSignal(props, { state, path, body });
        }
    };

    const handleAssetClick = (row, field, value) => {
        dialog.enqueueDialog({
            id: "signal-create",
            title: "Select asset",
            message: `Each signal requires an asset to be linked to.
            Please select a suitable asset from the list.

            If you can't find the asset you are looking for, request an asset to be added via the form below.`,
            component: <AssetSelection callback={(confirm, selected) => handleAssetClickConfirmation(confirm, row, selected)} />,
            componentProps: { selected: [getAssetByKey(props, "id", signal?.assetId)] },
        });
    };

    const handleSignalClick = (row, field, value) => {
        dialog.enqueueDialog({
            id: "signal-select",
            title: "Select signals",
            message: `Please select two or more signals from the list below to combine them into a single signal.`,
            component: <SignalSelection callback={(confirm, selected) => handleSignalClickConfirmation(confirm, row, selected)} multiselect />,
            componentProps: { selected: [...new Set(signal.ids)].map((id) => getSignalById(props, id)) },
        });
    };

    const handleAssetClickConfirmation = (confirm, row, selected = []) => {
        dialog.closeDialog({ id: "signal-create" }); // Manually close the dialog with a certain id.

        if (!confirm) return;

        const selectedAsset = selected[0] || {};
        row._assetId = formatAssetFormDescription(selectedAsset); // Set the human-readable fields.

        onChange(row, "assetId", selectedAsset?.id || "");
    };

    const handleSignalClickConfirmation = (confirm, row, selected = []) => {
        dialog.closeDialog({ id: "signal-select" }); // Manually close the dialog with a certain id.

        if (!confirm) return;

        setSelected(JSON.parse(JSON.stringify(selected)));

        // Automatically set the asset if all signals have the same one.
        if (isSameAssetSelected(selected)) {
            const asset = getAssetByKey(props, "id", selected[0]?.assetId);
            row._assetId = formatAssetFormDescription(asset); // Set the human-readable fields.
            row.assetId = asset?.id ?? "";
        }

        row.ids = selected.map((item) => item.id);

        onChange(row, "_ids", `${selected.length} signals selected`);
    };

    const createVisibilityHelperText = () => {
        const { visibility } = signal;

        if (signal?.form?.visibility?.error) return signal.form.visibility.helperText;
        if (visibility === Enums.SIGNAL_VISIBILITY_PRIVATE) return `Signal will be visible only to you.`;
        if (visibility === Enums.SIGNAL_VISIBILITY_PROTECTED) return `Only users with the code can subscribe to this signal.`;
        if (visibility === Enums.SIGNAL_VISIBILITY_PUBLIC) return `Anyone can subscribe to this signal.`;
    };

    const createConditionHelperText = () => {
        const { condition } = signal;

        if (condition === Enums.CONDITION_TIMEFRAME) return `New alert will be created if all signals are triggered within this timeframe.`;
        if (condition === Enums.CONDITION_CHAIN) return `New alert will be created if all signals are triggered in a row.`;
    };

    const createValueHelperText = () => {
        const { condition } = signal;

        if (condition === Enums.CONDITION_TIMEFRAME) return `Timeframe`;
        if (condition === Enums.CONDITION_CHAIN) return `Arranged by order of selection`;
    };

    const createOrderTypeHelperText = () => {
        const { orderType } = signal;

        if (signal?.form?.orderType?.error) return signal.form.orderType.helperText;
        if (orderType === "BUY & SELL") return `Two signals will be created: one for buy and one for sell signals.`;
    };

    const isSameAssetSelected = (selected) => {
        const assetSet = new Set(selected.map((item) => item?.assetId));
        return assetSet.size === 1;
    };

    /**
     * Special cases.
     *
     * 1) Clear value field if condition modified.
     * 2) Automatically set chain value if condition is CHAIN
     * 3) For most cases, value === _value.
     */
    const setSpecialValues = (key, newSignal, value) => {
        // 1)
        if (key === "condition") {
            newSignal.value = "";
            newSignal._value = "";
        }

        // 2)
        if (key === "condition" && value === Enums.CONDITION_CHAIN) {
            newSignal.value = newSignal.ids.join(", ");
            newSignal._value = createNumberString(newSignal.ids.length);
        }

        // 3)
        if (key === "_value") {
            newSignal.value = value;
        }
    };

    return (
        <Box className="box">
            <Grid container spacing={2}>
                <ConfirmationDialog {...props} options={dialog} />

                {isCombined && (
                    <Grid item xs={12} className="center">
                        {signal.ids.map((id, index) => {
                            const name = formatSelectedNameOverview(props, id, index);

                            return <TextField key={id} className="xs-font" value={name} size="small" margin="dense" disabled />;
                        })}
                    </Grid>
                )}

                {isCombined && (
                    <Grid item xs={12} sm={6}>
                        <CustomInput field="_ids" obj={signal} onChange={onChange} onIconClick={handleSignalClick} label="Signals" icon disabled />
                    </Grid>
                )}

                <Grid item xs={12} sm={6}>
                    <CustomInput field="_assetId" obj={signal} onChange={onChange} onIconClick={handleAssetClick} label={getLanguage().FORM_ASSET_LABEL} icon disabled />
                </Grid>

                <Grid item xs={12} sm={true}>
                    <CustomInput field="name" obj={signal} onChange={onChange} label={getLanguage().FORM_NAME_LABEL} />
                </Grid>

                <Grid item xs={12}>
                    <CustomInput field="description" obj={signal} onChange={onChange} label={getLanguage().FORM_DESCRIPTON_LABEL} multiline minRows={2} />
                </Grid>

                <Grid item xs={12} sm={6} textAlign="center" style={{ paddingTop: 0 }}>
                    <Typography className="italic sm-font xs-margin">{"Order type"}</Typography>
                    <CustomInput field="orderType" obj={signal} select={isCombined ? Enums.ORDER_TYPES : [...Enums.ORDER_TYPES, "BUY & SELL"]} onChange={onChange} toggle />
                    <Typography className="italic xs-font xs-margin" color={signal?.form?.orderType?.error ? "#f44336" : "inherit"}>
                        {createOrderTypeHelperText()}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} textAlign="center" style={{ paddingTop: 0 }}>
                    <Typography className="italic sm-font xs-margin">{"Visibility type"}</Typography>
                    <CustomInput field="visibility" obj={signal} select={Enums.SIGNAL_VISIBILITY_TYPES} onChange={onChange} toggle />
                    <Typography className="italic xs-font xs-margin" color={signal?.form?.visibility?.error ? "#f44336" : "inherit"}>
                        {createVisibilityHelperText()}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={signal.visibility === Enums.SIGNAL_VISIBILITY_PROTECTED ? 6 : 12}>
                    <CustomInput field="status" obj={signal} select={Enums.SIGNAL_STATUS_TYPES} onChange={onChange} label={getLanguage().FORM_STATUS_TYPE_LABEL} />
                </Grid>

                {signal.visibility === Enums.SIGNAL_VISIBILITY_PROTECTED && (
                    <Grid item xs={12} sm={6}>
                        <CustomInput field="code" obj={signal} onChange={onChange} label={getLanguage().FORM_CODE_LABEL} type="password" />
                    </Grid>
                )}

                {isCombined && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <CustomInput field="condition" obj={signal} select={Enums.CONDITION_TYPES} onChange={onChange} label="Alert condition type" />
                            <Typography className="italic xs-font xs-margin">{createConditionHelperText()}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <CustomInput
                                field="_value"
                                obj={signal}
                                onChange={onChange}
                                label={createValueHelperText()}
                                disabled={["", Enums.CONDITION_CHAIN].includes(signal.condition)}
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            <CustomButton {...props} title={getLanguage().BUTTON_ADD_SIGNAL} onClick={handleSubmit} className="button" fullWidth />
        </Box>
    );
}
