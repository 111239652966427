import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";

import Iframe from "../../../../helper-components/misc/Iframe";

import { queryAlerts } from "../../../../helper-functions/api/alertQueries.js";
import { isEmpty } from "../../../../helper-functions/utils/validation/object.js";
import { setUniqueArrayState } from "../../../../helper-functions/utils/misc/utils.js";
import { useEndpointStatesWithPagination } from "../../../../helper-functions/hooks/states/useEndpointStatesWithPagination.js";
import { combinedUserColumns, defaultTableOptions, getRowId } from "../../../../helper-functions/table/alert.js";

import PaperFragment from "../../../../helper-components/misc/PaperFragment.js";
import StackedHeader from "../../../../helper-components/misc/StackedHeader.js";
import StyledTable from "../../../../helper-components/table/StyledTable.js";

import { getLanguage } from "../../../../config/language/language.js";
import { ASSET_CHART } from "../../../../config/widgets/TradingViewWidgets.js";
import { USER_EXTERNAL_ALERTS_PATH, USER_COMBINED_ALERTS_PATH } from "../../../../config/constants/Paths.js";

/**
 * Technical analysis page for an asset.
 *
 * Includes:
 * - a table of alerts
 * - a chart
 */
export default function TechnicalAnalysis(props) {
    const theme = useTheme();

    const { asset = {} } = props;
    const { ticker, id: assetId } = asset;

    const externalAlerts = props.alerts.external;
    const combinedAlerts = props.alerts.combined;

    const { asset: externalAssetAlerts } = externalAlerts;
    const { asset: combinedAssetAlerts } = combinedAlerts;

    const widgetTicker = asset?.assetType === "FOREX" ? `FX_IDC:${ticker.replace("/", "")}` : ticker.replace("/", "");

    const chartWidgetSrc = ASSET_CHART.replace("<theme>", theme.palette.mode).replace("<ticker>", widgetTicker).replace("<height>", 700);

    // Only query those alerts which are related to this asset.

    const combine = useEndpointStatesWithPagination("Combined alerts (all)", { logData: true, logPagination: true, initialSorting: { field: "timestamp" } });

    // Combine all external and combined alerts into one comprehensive state object.
    React.useEffect(() => {
        const externalData = externalAssetAlerts.data;
        const combinedData = combinedAssetAlerts.data;

        if (!externalData || !combinedData) return;

        const combined = [...externalData, ...combinedData];

        setUniqueArrayState(combine.setData, combined, { keys: ["signalId", "timestamp"] });
        combine.setRowCount(combined.length); // NB! Also set the row count for pagination.
    }, [externalAssetAlerts.data, combinedAssetAlerts.data]);

    // Query alerts related to this asset.
    React.useEffect(() => {
        queryAlertsRelatedToAsset();

        // Clear the alerts when the component unmounts.
        return () => {
            externalAssetAlerts.setData([]);
            combinedAssetAlerts.setData([]);
            console.log("Cleared asset alerts.");
        };
    }, [assetId]);

    const queryAlertsRelatedToAsset = () => {
        if (!assetId) return;

        queryAlerts(props, { path: `${USER_EXTERNAL_ALERTS_PATH}/asset/${assetId}`, state: externalAssetAlerts });
        queryAlerts(props, { path: `${USER_COMBINED_ALERTS_PATH}/asset/${assetId}`, state: combinedAssetAlerts });
    };

    return (
        <Grid container spacing={1}>
            <PaperFragment
                title={<StackedHeader {...props} title="Alerts" subtitle="View all accessible alerts related to this asset." />}
                value={
                    <StyledTable
                        {...props}
                        {...defaultTableOptions}
                        states={combine}
                        columns={combinedUserColumns(props)}
                        query={(options) => queryAlertsRelatedToAsset()}
                        empty={getLanguage().NO_ALERTS_FOUND}
                        getRowId={(row) => getRowId(row)}
                    />
                }
            />

            <PaperFragment
                height={750}
                title={<StackedHeader {...props} title="Chart" subtitle="View the latest price action." />}
                value={<Iframe {...props} id="tradingview-asset-chart" loaded={!isEmpty(asset)} srcDoc={chartWidgetSrc} />}
            />
        </Grid>
    );
}
