import * as React from "react";

import { Container, Divider, Grid } from "@mui/material";

import EngineeringIcon from "@mui/icons-material/Engineering";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import FeedbackIcon from "@mui/icons-material/Feedback";
import GroupIcon from "@mui/icons-material/Group";

import UserDeleteForm from "../settings/components/UserDeleteForm.js";

import CustomForm from "../../../helper-components/form/CustomForm.js";
import StyledTable from "../../../helper-components/table/StyledTable.js";
import AccordionForm from "../../../helper-components/form/AccordionForm.js";
import TransparentFragment from "../../../helper-components/misc/TransparentFragment.js";

import { columns as feedbackColumns, defaultTableOptions as defaultFeedbackTableOptions } from "../../../helper-functions/table/feedback.js";
import { columns as userColumns, defaultTableOptions as defaultUserTableOptions } from "../../../helper-functions/table/user.js";
import { queryFeedback } from "../../../helper-functions/api/miscQueries.js";
import { queryUsers } from "../../../helper-functions/api/userQueries.js";

import { getLanguage } from "../../../config/language/language.js";

/**
 * An administrative page.
 * Requires admin privileges.
 *
 * Components include:
 * - users table
 * - modifying user's info
 * - feedback table
 */
export default function Admin(props) {
    const users = props.users;
    const feedback = props.feedback;

    const { selected: selectedUsers, setSelected: setSelectedUsers } = users;

    const usersTable = [
        {
            summary: <CustomForm title="Users" subtitle="View the users of the platform." icon={<GroupIcon />} transparent />,
            details: (
                <TransparentFragment
                    value={
                        <StyledTable
                            {...props}
                            {...defaultUserTableOptions}
                            states={users}
                            columns={userColumns(props)}
                            query={(options) => queryUsers(props, options)}
                            empty="No users found!"
                        />
                    }
                />
            ),
        },
    ];

    const feedbackTable = [
        {
            summary: <CustomForm title="Feedback" subtitle="View the feedback given by the users of the platform." icon={<FeedbackIcon />} transparent />,
            details: (
                <TransparentFragment
                    value={
                        <StyledTable
                            {...props}
                            {...defaultFeedbackTableOptions}
                            states={feedback}
                            columns={feedbackColumns(props)}
                            query={(options) => queryFeedback(props, options)}
                            empty="No feedback found!"
                        />
                    }
                />
            ),
        },
    ];

    const userRoles = [
        {
            summary: <CustomForm title="User role" subtitle="Upgrade of downgrade the role of the selected user." icon={<AddModeratorIcon />} transparent />,
            details: <></>,
        },
    ];

    const userDelete = [
        {
            summary: <CustomForm title="Delete user" subtitle="Delete the selected user(s). This action is irreversible." icon={<RemoveCircleIcon />} transparent />,
            details: <UserDeleteForm {...props} selectedData={selectedUsers} setSelected={setSelectedUsers} isAdminComponent />,
        },
    ];

    return (
        <Container maxWidth="xl" className="container">
            <Grid container>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().ADMIN_TITLE} subtitle={getLanguage().ADMIN_SUBTITLE} icon={<EngineeringIcon />} />
                </Grid>

                <AccordionForm {...props} values={usersTable} padding={0} />

                {/* Show user modification components once a user has been selected from the table. */}
                <AccordionForm {...props} values={userRoles} show={selectedUsers.length === 1} md={6} />
                <AccordionForm {...props} values={userDelete} show={selectedUsers.length > 0} md={true} />

                <Grid item xs={12}>
                    <Divider className="sm-margin" />
                </Grid>

                <AccordionForm {...props} values={feedbackTable} padding={0} />
            </Grid>
        </Container>
    );
}
