// Chart definitions (options).
import { formatValue } from "../utils/asset/asset";

export const diversificationOptions = {
    backgroundColor: "transparent",

    title: {
        text: "Diversification",
        subtext: "Measured by all the current asset values in the portfolio",
    },
    tooltip: {
        trigger: "item",
        formatter: function (params) {
            return params.seriesName + "<br/>" + params.name + ": " + formatValue(params.value, { currency: params.data?.currency }) + " (" + params.percent + "%)";
        },
    },
    legend: {
        top: "bottom",
        orient: "vertical",
        left: "right",
    },

    series: [
        {
            name: "Proportion",
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            data: [],
        },
    ],
};

export const historyLineChartOptions = {
    backgroundColor: "transparent",

    title: {
        text: "Portfolio's history",
        subtext: "Historic prices of your portfolio",
    },
    tooltip: {
        trigger: "axis",
        formatter: function (params) {
            const data = params[0].data;
            return data.timestamp + "<br/>" + formatValue(data?.value, { currency: data?.currency });
        },
    },

    xAxis: {
        type: "category",
    },
    yAxis: {},
    grid: {
        left: "4%",
        right: "4%",
        top: "20%",
        bottom: "5%",
        containLabel: true,
    },

    dataset: [
        {
            id: "dataset",
            source: [],
        },
    ],

    series: [
        {
            type: "line",
            datasetId: "dataset",
            showSymbol: false,
            encode: {
                x: "timestamp",
                y: "value",
                itemName: "timestamp",
                tooltip: ["value"],
            },
        },
    ],
};

export const assetPerformanceOptions = {
    backgroundColor: "transparent",

    title: {
        text: "Asset performance",
        subtext: "Performance of all assets in the portfolio since inception",
    },
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow",
        },
        formatter: (params) => {
            const value = params[0].value[0].toFixed(2);
            const ticker = params[0].value[1];

            return `${ticker}<br/>Change: ${value}%`;
        },
    },

    xAxis: {
        type: "value",
        axisLabel: {
            formatter: "{value}%",
        },
    },
    yAxis: {
        type: "category",
    },
    grid: {
        left: "2%",
        right: "5%",
        top: "20%",
        bottom: "2%",
        containLabel: true,
    },

    series: [
        {
            type: "bar",
            data: [],
        },
    ],
};

export const historyCandlestickChartOptions = {
    backgroundColor: "transparent",

    title: {
        text: "Portfolio history",
        subtext: "Historic prices of your portfolio",
    },
    tooltip: {
        formatter: function (params) {
            var value = params[0].value;
            var dateString = new Date(params[0].axisValue).toLocaleDateString();

            return `Date: ${dateString}<br/>Open: $${value[1]}<br/>Close: $${value[2]}<br/>Lowest: $${value[3]}<br/>Highest: $${value[4]}`;
        },
        trigger: "axis",
        axisPointer: {
            type: "cross",
            label: {
                backgroundColor: "#6a7985",
            },
        },
    },

    xAxis: {
        data: [],
        axisPointer: {
            label: {
                show: false, // Hide the axis label when hovering, but keep the tooltip functionality.
            },
        },
        axisLabel: {
            formatter: function (value) {
                return new Date(value).toLocaleDateString();
            },
        },
    },
    yAxis: {
        min: "dataMin",
        max: "dataMax",
        axisLabel: {
            formatter: "${value}",
        },
    },
    grid: {
        left: "2%",
        right: "2%",
        top: "25%",
        bottom: "2%",
        containLabel: true,
    },

    series: [
        {
            data: [], // echarts: [open, close, lowest, highest]
            type: "candlestick",
            itemStyle: {
                color: "#37bb49",
                color0: "#c23531",
                borderColor: "#37bb49",
                borderColor0: "#c23531",
            },
        },
    ],
};
