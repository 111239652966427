import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

/**
 * useNavigate hook to always include the previous pathname in the state.
 */
export const useNavigateWithPrev = () => {
    const routerNavigate = useNavigate();
    const routerLocation = useLocation();

    const prevPathname = React.useRef();

    // Update prevLocationRef when location changes.
    React.useEffect(() => {
        prevPathname.current = routerLocation.pathname;
    }, [routerLocation]);

    // Navigate to the specified path with the previous location in the state.
    const navigate = (path, state = {}) => {
        routerNavigate(path, { state: { ...state, prevPathname: prevPathname.current } });
    };

    return { navigate };
};
