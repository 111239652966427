import { setUniqueArrayState } from "../misc/utils";
import { isEmpty } from "../validation/object";

/**
 * Set localstorage variables or states based on received user data.
 */
export const parseUserData = (props) => {
    const data = props.user.data;

    if (!data || isEmpty(data)) return;

    const username = data.username;
    props.user.setUsername(username);
};

/**
 * Handle the response from the API.
 * Set the data to the state.
 */
export const handleAddUsers = (props, response) => {
    const setState = props.users.setData;
    const data = response.elements;

    setUniqueArrayState(setState, data);
};
