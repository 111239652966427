import { createCriteriaParameters, standardDeleteQuery, standardGetQuery, standardPatchQuery, standardPostQuery, standardPutQuery } from "../utils/misc/api.js";
import { setDeleteArrayState } from "../utils/misc/utils.js";
import { handleAddSignals } from "../utils/asset/signal.js";
import { getCategory } from "../utils/asset/utils.js";

import * as Paths from "../../config/constants/Paths.js";
import * as Enums from "../../config/constants/Enums.js";
import { getLanguage } from "../../config/language/language.js";

/**
 * Request signals' data from the server.
 *
 * Options:
 * - path: the path to the API
 * - state: the states to set the data to (e.g. props.alerts.external.user)
 * - page, pageSize, filter, sort
 */
export const querySignals = (props, options = {}) => {
    const { path, state } = options;

    const fullPath = path + createCriteriaParameters(options);

    standardGetQuery(
        props,
        fullPath,
        (response) => {
            handleAddSignals(props, state, response.body);

            if (options.page) state.setQueriedUntil(options.page);
            if (response.body.total) state.setRowCount(response.body.total);
        },
        { showMessage: false }
    );
};

/**
 * Query all category signals from the server.
 * E.g. owner, subscribed, public, protected.
 */
export const queryAllSignals = (props, options) => {
    const { queryExternal = true, queryCombined = true } = options;
    const { queryOwner = false, querySubscribed = false, queryProtected = false, queryPublic = false } = options;

    const combinedCategory = getCategory(Enums.COMBINED_SIGNAL);
    const externalCategory = getCategory(Enums.EXTERNAL_SIGNAL);

    const externalSignals = props.signals.external;
    const combinedSignals = props.signals.combined;

    const { owner: externalOwner, subscribed: externalSubscribed } = externalSignals;
    const { owner: combinedOwner, subscribed: combinedSubscribed } = combinedSignals;

    const { public: externalPublic, protected: externalProtected } = externalSignals;
    const { public: combinedPublic, protected: combinedProtected } = combinedSignals;

    if (queryExternal) {
        if (queryPublic) querySignals(props, { ...options, path: externalCategory.paths.public, state: externalPublic });
        if (queryProtected) querySignals(props, { ...options, path: externalCategory.paths.protected, state: externalProtected });
        if (querySubscribed) querySignals(props, { ...options, path: externalCategory.paths.subscribed, state: externalSubscribed });
        if (queryOwner) querySignals(props, { ...options, path: externalCategory.paths.owner, state: externalOwner });
    }

    if (queryCombined) {
        if (queryPublic) querySignals(props, { ...options, path: combinedCategory.paths.public, state: combinedPublic });
        if (queryProtected) querySignals(props, { ...options, path: combinedCategory.paths.protected, state: combinedProtected });
        if (querySubscribed) querySignals(props, { ...options, path: combinedCategory.paths.subscribed, state: combinedSubscribed });
        if (queryOwner) querySignals(props, { ...options, path: combinedCategory.paths.owner, state: combinedOwner });
    }
};

/**
 * Body: Sends the whole signal object.
 * On success: Overwrite the owner signal state.
 */
export const addSignal = (props, options = {}) => {
    const { path, state, body } = options;

    standardPostQuery(props, path, body, (response) => {
        const { setRowCount } = state;

        handleAddSignals(props, state, { elements: [response.body] });
        setRowCount((prev) => prev + 1);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNAL_ADDED });
        props.dialog.closeDialog({ id: "signal-create" }); // Manually close the dialog with a certain id.
    });
};

/**
 * Body: Sends the whole signal object.
 * On success: Overwrite the owner signal state.
 */
export const updateSignal = (props, options = {}) => {
    const { path, state, body } = options;

    standardPutQuery(props, path, body, (response) => {
        const { setSelected } = state;

        handleAddSignals(props, state, { elements: [response.body] });
        setSelected([]);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNAL_UPDATED });
        props.dialog.closeDialog({ id: "signal-edit" }); // Manually close the dialog with a certain id.
    });
};

/**
 * Body: { ids: [1, 2, 3], visibility: "PROTECTED", code: "1234"}
 * On success: Set visibility and code and overwrite the owner signals state.
 */
export const shareSignals = (props, options = {}) => {
    const { path = Paths.API_SIGNAL_SHARE_PATH, state, body } = options;

    standardPatchQuery(props, path, body, (response) => {
        const { selected, setSelected } = state;
        const newSelected = selected.map((item) => ({ ...item, visibility: body.visibility, code: body.code }));

        handleAddSignals(props, state, { elements: newSelected });
        setSelected([]);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_ALERTS_UPDATED, duration: 1500 });
        props.dialog.closeDialog({ id: "signal-share" }); // Manually close the dialog with a certain id.
    });
};

/**
 * Body: { ids: [1, 2, 3] }
 * On success: Set status to enabled and overwrite the owner signals state.
 */
export const enableSignals = (props, options = {}) => {
    const { path = Paths.API_SIGNAL_ENABLE_PATH, state, body } = options;

    standardPatchQuery(props, path, body, (response) => {
        const { selected, setSelected } = state;
        const newSelected = selected.map((item) => ({ ...item, status: Enums.STATUS_ENABLED }));

        handleAddSignals(props, state, { elements: newSelected });
        setSelected([]);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNALS_ENABLED, duration: 1500 });
    });
};

/**
 * Body: { ids: [1, 2, 3] }
 * On success: Set status to disabled and overwrite the owner signals state.
 */
export const disableSignals = (props, options = {}) => {
    const { path = Paths.API_SIGNAL_DISABLE_PATH, state, body } = options;

    standardPatchQuery(props, path, body, (response) => {
        const { selected, setSelected } = state;
        const newSelected = selected.map((item) => ({ ...item, status: Enums.STATUS_DISABLED }));

        handleAddSignals(props, state, { elements: newSelected });
        setSelected([]);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNALS_DISABLED, duration: 1500 });
    });
};

/**
 * Body: { ids: [1, 2, 3] }
 * On success: Delete the selected signals and alerts from the subscribed states.
 */
export const deleteSignals = (props, options = {}) => {
    const { path = Paths.API_SIGNAL_DELETE_PATH, alertsState, signalsState, body } = options;

    standardDeleteQuery(props, path, body, (response) => {
        const { setData: setSignalData, setRowCount: setSignalRowCount, setSelected } = signalsState;
        const { setData: setAlertData, setRowCount: setAlertRowCount, data: alertsData } = alertsState;

        const ids = body.ids;
        const alertsRelated = alertsData.filter((alert) => ids.includes(alert.signalId));

        setSelected([]);

        setDeleteArrayState(setSignalData, ids);
        setDeleteArrayState(setAlertData, ids, { keys: ["signalId"] });

        setSignalRowCount((prev) => prev - ids.length);
        setAlertRowCount((prev) => prev - alertsRelated.length);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNAL_DELETED, duration: 1500 });
    });
};

/**
 * Body: { ids: [1, 2, 3] }
 * On success: Delete the selected signals and alerts from the subscribed states.
 */
export const unsubscribeSignals = (props, options = {}) => {
    const { path = Paths.API_SIGNAL_UNSUBSCRIBE_PATH, alertsState, signalsState, body } = options;

    standardPatchQuery(props, path, body, (response) => {
        const { setData: setSignalData, setRowCount: setSignalRowCount, setSelected } = signalsState;
        const { setData: setAlertData, setRowCount: setAlertRowCount, data: alertsData } = alertsState;

        const ids = body.ids;
        const alertsRelated = alertsData.filter((alert) => ids.includes(alert.signalId));

        setSelected([]);

        setDeleteArrayState(setSignalData, ids);
        setDeleteArrayState(setAlertData, ids, { keys: ["signalId"] });

        setSignalRowCount((prev) => prev - ids.length);
        setAlertRowCount((prev) => prev - alertsRelated.length);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNAL_UNSUBSCRIBED, duration: 1500 });
    });
};

/**
 * Body: { ids: [1, 2, 3], code: "1234" }
 * On success: Query subscribed signals and alerts.
 */
export const subscribeSignals = (props, options = {}) => {
    const { path = Paths.API_SIGNAL_SUBSCRIBE_PATH, state, body } = options;

    standardPatchQuery(props, path, body, (response) => {
        const { setSelected } = state;

        queryAllSignals(props, { querySubscribed: true });
        setSelected([]);

        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_SIGNAL_SUBSCRIBED, duration: 1500 });
        props.dialog.closeDialog({ id: "signal-subscribe" }); // Manually close the dialog with a certain id.
    });
};
