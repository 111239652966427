import { createTheme, responsiveFontSizes } from "@mui/material/styles";

/**
 * The project's theme is here.
 * Can also modify all of the MUI components.
 */
export const createAppTheme = (mode, isMobile) => {
    const baseTheme = createTheme({
        palette: {
            mode,
            primary: {
                main: mode === "light" ? "#1976d2" : "#90caf9",
            },
            secondary: {
                main: mode === "light" ? "#9c27b0" : "#ce93d8",
            },
        },
        typography: {
            fontSize: isMobile ? 11 : 15,
        },
    });

    // After creating the baseTheme, we can use varibles like palette.primary.main.
    const dynamicTheme = createTheme(baseTheme, {
        components: {
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        backgroundColor: baseTheme.palette.primary.main,
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === "light" ? "#ffffff" : "#121212",
                    },
                },
            },
        },
        transitions: {
            ...baseTheme.transitions,
            create: (property) => `background-color 1s ease, ${baseTheme.transitions.create(property)}`,
        },
    });

    return responsiveFontSizes(dynamicTheme, { factor: 1.5 });
};
