import React from "react";

import Grid from "@mui/material/Grid";

import ControlledAccordion from "../misc/ControlledAccordion.js";

/**
 * Universal dropdown component.
 *
 * Props:
 * - values: [{summary: <>, details: <>}, ...]
 */
export default function AccordionForm(props) {
    const { xs = 12, md = 12, lg } = props;
    const { mt = 0.5, mb = 0.5, padding = 2 } = props;
    const { show = true } = props;

    return (
        show && (
            <Grid item xs={xs} md={md} lg={lg} mt={mt} mb={mb}>
                <ControlledAccordion {...props} padding={padding} />
            </Grid>
        )
    );
}
