import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Grid, Stack, Typography } from "@mui/material";

import NewsSentiment from "./NewsSentiment.js";
import Iframe from "../../../../helper-components/misc/Iframe.js";
import CustomButton from "../../../../helper-components/input/CustomButton.js";
import StackedHeader from "../../../../helper-components/misc/StackedHeader.js";
import PaperFragment from "../../../../helper-components/misc/PaperFragment.js";

import { formatValue } from "../../../../helper-functions/utils/asset/asset.js";
import { formatDate, isEmpty } from "../../../../helper-functions/utils/validation/object.js";

import { ASSET_STOCK } from "../../../../config/constants/Enums.js";
import { ASSET_INFO, ASSET_PROFILE } from "../../../../config/widgets/TradingViewWidgets.js";

/**
 * An overview page for an asset.
 *
 * Includes:
 * - a widget showing the recent price action
 * - a description of the asset
 * - some basic info about the asset
 */
export default function Overview(props) {
    const theme = useTheme();

    const limitDescription = 500;

    const { asset = {}, profile = {} } = props;

    const { id: assetId, ticker = "N/A", assetType = "N/A", currentPrice = "N/A", priceUpdatedOn = "N/A", exchange = {}, currency = {} } = asset;
    const { currencySymbol = currency?.symbol || "N/A", currencyName = currency?.name || "N/A" } = asset;
    const { exchangeName = exchange?.name || "N/A", exchangeMicCode = exchange?.micCode || "N/A" } = asset;

    const isStock = assetType === ASSET_STOCK;
    const isReady = !isEmpty(asset);

    const tradingViewWidgetTicker = asset?.assetType === "FOREX" ? `FX_IDC:${ticker.replace("/", "")}` : ticker.replace("/", "");
    const alphaVantageWidgetTicker = ticker.replace("/", "");

    const [assetProfileSrc, setAssetProfileSrc] = React.useState("");
    const [assetInfoSrc, setAssetInfoSrc] = React.useState("");
    const [readMore, setReadMore] = React.useState(false);

    // Set the TradingView widgets' state.
    React.useEffect(() => {
        if (!isReady) return;

        setAssetInfoSrc(ASSET_INFO.replace("<theme>", theme.palette.mode).replace("<ticker>", `${tradingViewWidgetTicker}`));
        setAssetProfileSrc(ASSET_PROFILE.replace("<theme>", theme.palette.mode).replace("<ticker>", `${tradingViewWidgetTicker}`).replace("<height>", 400));
    }, [exchange, ticker, theme.palette.mode]);

    // Create the description text.
    const desc = profile?.data?.description || "Company description not available";
    const description = readMore ? desc : desc.slice(0, limitDescription) + "...";

    // Stock-specific text.
    const ASSET_OVERVIEW_TEXT_STOCK = `
    Sector: ${profile?.data?.sector || "N/A"}
    Industry: ${profile?.data?.industry || "N/A"}
    Employees: ${profile?.data?.employees || "N/A"}
    CEO: ${profile?.data?.ceo || "N/A"}
    `;

    const ASSET_OVERVIEW_TEXT = `Asset ID: ${assetId}
    Asset type: ${assetType}
    ${isStock ? ASSET_OVERVIEW_TEXT_STOCK : ""}
    Exchange: ${exchangeName} ${exchangeMicCode === exchangeName ? "" : `(${exchangeMicCode})`}
    Currency: ${currencySymbol} (${currencyName})

    Current price: ${formatValue(currentPrice, { currency: currencySymbol })}
    Last updated: ${formatDate(priceUpdatedOn)}
    `;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
                <PaperFragment title={ticker} p={2} value={<Typography className="pre-line">{ASSET_OVERVIEW_TEXT}</Typography>} />
            </Grid>

            <Grid item xs={12} md={7}>
                <PaperFragment height={260} value={<Iframe {...props} id="assetInfo" loaded={isReady} srcDoc={assetInfoSrc} />} />
            </Grid>

            {isStock && (
                <Grid item xs={12}>
                    <PaperFragment
                        title="Profile"
                        p={2}
                        value={
                            <>
                                <Typography className="pre-line">{description}</Typography>

                                <Stack direction="row" justifyContent="center" spacing={1} mt={2}>
                                    <CustomButton
                                        title={readMore ? "Read less " : "Read more"}
                                        onClick={() => setReadMore(!readMore)}
                                        disabled={!description || description.length <= limitDescription}
                                        variant="outline"
                                    />
                                    <CustomButton title="View company webpage" onClick={() => window.open(profile?.data?.website, "_blank")} disabled={!profile?.data?.website} />
                                </Stack>
                            </>
                        }
                    />
                </Grid>
            )}

            {/* Fallback to TradingView wiget in case of no data */}
            {!isStock && (
                <Grid item xs={12}>
                    <PaperFragment height={300} value={<Iframe {...props} id="assetProfile" loaded={isReady} srcDoc={assetProfileSrc} />} />
                </Grid>
            )}

            {isReady && (
                <Grid item xs={12}>
                    <PaperFragment
                        p={2}
                        height="95vh"
                        minHeight={750}
                        title={<StackedHeader {...props} title="News & sentiment" subtitle="View the latest news and the sentiment related to this asset." />}
                        value={<NewsSentiment {...props} ticker={alphaVantageWidgetTicker} assetType={assetType} />}
                    />
                </Grid>
            )}
        </Grid>
    );
}
