import { setUniqueArrayState } from "../misc/utils";
import { objHasErrors, objHasValue } from "../validation/object";
import { getSignalById } from "./signal";

/**
 * Handle the response from the API.
 * Set the data to the state.
 *
 * Response is an array.
 */
export const handleAddAssets = (props, response) => {
    const setState = props.assets.setData;
    const data = response.elements;

    setUniqueArrayState(setState, data);
};

/**
 * Handle the response from the API.
 * Set the data to the state.
 *
 * Response is a single object, not an array.
 */
export const handleAddAsset = (props, response) => {
    const setState = props.assets.setData;
    const data = [response];

    setUniqueArrayState(setState, data);
};

/**
 * Make sure of no duplicates.
 */
export const isUnique = (state, asset) => {
    return !state.some((a) => a.id === asset.id);
};

/**
 * Return the first asset by its key.
 */
export const getAssetByKey = (props, key = "id", value) => {
    return getAssetsByKey(props, key, value)?.[0] || {};
};

/**
 * Return all assets by their key.
 */
export const getAssetsByKey = (props, key = "id", value) => {
    return props.assets.data.filter((asset) => asset[key] === value) || [];
};

/**
 *  Return an asset's value using a signal it's related to.
 */
export const getAssetValueByKeyUsingSignal = (props, id, key) => {
    const signal = getSignalById(props, id);
    if (!signal) return "N/A";

    const asset = getAssetByKey(props, "id", signal.assetId);
    return asset?.[key] ?? "N/A";
};

/**
 * Return an asset's value by its id.
 */
export const getAssetValueByKey = (props, id, key) => {
    const asset = getAssetByKey(props, "id", id);
    return asset?.[key] ?? "N/A";
};

/**
 * Return whether the required row fields are all empty.
 */
const assetHasValues = (obj) => {
    return objHasValue(obj, "ticker") && objHasValue(obj, "currency") && objHasValue(obj, "assetType");
};

/**
 * Return whether some row is valid (has values and no errors).
 */
export const assetIsValid = (obj) => {
    return assetHasValues(obj) && !objHasErrors(obj);
};

/**
 * Navigate to the asset details page.
 */
export const redirectToAsset = (props, id) => {
    if (id === undefined) return;

    props.browser.navigate(`/market-analysis/fundamental/assets/${id}`);
};

/**
 * Return all asset ids saved to the state which have the specified status(es).
 */
export const getAssetIdsByStatus = (props, status = []) => {
    const state = props.assets;

    const disabledAssets = state.data.filter((asset) => status.includes(asset.status));
    const disabledIds = disabledAssets.map((asset) => asset.id);

    return disabledIds;
};

/**
 * Format a value using the options obj.
 *
 * Options:
 * { currency: "USD" } - add a symbol for the currency, e.g. $1,000.
 * { nullOnError: true } - return null if the value is not a number instead of "N/A".
 * { abbreviate: true } - abbreviate the number, e.g. 1,000,000 -> 1M.
 */
export const formatValue = (value, options = {}) => {
    const { currency } = options;
    const { nullOnError = false, abbreviate = false } = options;

    if (isNaN(parseInt(value)) || value === "" || value === "N/A" || value === undefined || value === null) return nullOnError ? null : "N/A";

    try {
        const currencyOptions = currency ? { style: "currency", currency } : {};
        const abbreviationOptions = abbreviate ? { notation: "compact", compactDisplay: "short" } : {};
        return new Intl.NumberFormat(undefined, { ...abbreviationOptions, ...currencyOptions }).format(value);
    } catch (e) {
        console.error(e);
    }

    return nullOnError ? null : "N/A";
};
