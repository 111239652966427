import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";

import ListAltIcon from "@mui/icons-material/ListAlt";
import LanguageIcon from "@mui/icons-material/Language";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

import MarketSelection from "./components/MarketSelection.js";
import Iframe from "../../../helper-components/misc/Iframe.js";
import CustomForm from "../../../helper-components/form/CustomForm.js";
import PaperFragment from "../../../helper-components/misc/PaperFragment.js";
import StackedHeader from "../../../helper-components/misc/StackedHeader.js";
import NavigationFooter from "../../../helper-components/misc/NavigationFooter.js";

import { getLanguage } from "../../../config/language/language.js";
import { SCREENER_MARKETS } from "../../../config/constants/Enums.js";
import { SCREENER } from "../../../config/widgets/TradingViewWidgets";

/**
 * The assets page.
 *
 * Features:
 * Showcasing all assets
 * Includes basic info alongside graphs
 * For to request new assets
 */
export default function Screener(props) {
    const theme = useTheme();

    const colorMode = theme.palette.mode;
    const isMobile = props.browser.isMobile;
    const defaultStockMarket = "USA (NASDAQ, NYSE, NYSE ARCA, OTC)";

    /**
     * E.g. { name: "Australia (ASX)", value: "australia", ... }
     */
    const findMarket = (name) => SCREENER_MARKETS.find((m) => m?.name === name);

    const [loaded, setLoaded] = React.useState(false);
    const [screenerSrc, setScreenerSrc] = React.useState("");
    const [market, setMarket] = React.useState(findMarket(defaultStockMarket));

    // Set the TradingView widgets' state.
    React.useEffect(() => {
        if (!market?.value) return;

        setScreenerSrc(
            SCREENER.replace("<theme>", colorMode)
                .replace("<market>", market.value)
                .replace("<column>", market.defaultColumn)
                .replace("<screen>", market.defaultScreen)
                .replace("<height>", isMobile ? 500 : 950)
        );
    }, [colorMode, market]);

    // Loading animation when changing the market.
    React.useEffect(() => {
        setLoaded(false);
        setTimeout(() => setLoaded(true), 1500);
    }, [screenerSrc]);

    const showMarketSelectionDialog = () => {
        props.dialog.enqueueDialog({
            id: "market-select",
            title: getLanguage().SCREENER_MARKET_SELECT_TITLE,
            message: getLanguage().SCREENER_MARKET_SELECT_SUBTITLE,
            component: <MarketSelection callback={(confirm, selected) => handleMarketClickConfirmation(confirm, selected)} />,
            componentProps: { selected: [market], data: SCREENER_MARKETS },
        });
    };

    const handleMarketClickConfirmation = (confirm, selected = []) => {
        props.dialog.closeDialog({ id: "market-select" }); // Manually close the dialog with a certain id.
        if (!confirm) return;

        const market = selected[0];
        if (!market) return;

        setMarket(market);
    };

    return (
        <Container maxWidth="xl" className="container">
            <Grid container>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().SCREENER_TITLE} subtitle={getLanguage().SCREENER_SUBTITLE} icon={<TravelExploreIcon />} />
                </Grid>

                <Grid item xs={12}>
                    <PaperFragment
                        height={isMobile ? 550 : 1000}
                        title={
                            <StackedHeader
                                {...props}
                                title="Market screener"
                                subtitle={`View assets according to the selected market region and other filters.
                                Selected market: ${market?.name || "N/A"}`}
                                buttons={[
                                    {
                                        icon: <LanguageIcon />,
                                        title: "Select market",
                                        onClick: showMarketSelectionDialog,
                                        variant: "outlined",
                                    },
                                ]}
                            />
                        }
                        value={<Iframe {...props} id="tradingview-screener" loaded={screenerSrc && loaded} srcDoc={screenerSrc} />}
                    />
                </Grid>

                <NavigationFooter
                    rows={1}
                    options={[
                        {
                            path: `/market-analysis/fundamental/assets`,
                            title: `Assets`,
                            icon: <ListAltIcon />,
                        },
                        {
                            path: `/home`,
                            title: `Dashboard`,
                            icon: <DashboardIcon />,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
