import * as React from "react";

import { Container, Grid, Stack, Typography } from "@mui/material";

import ForumIcon from "@mui/icons-material/Forum";
import PublicIcon from "@mui/icons-material/Public";
import GroupIcon from "@mui/icons-material/Group";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";

import Search from "../../helper-components/input/Search.js";
import MediaCard from "../../helper-components/misc/MediaCard.js";
import CustomForm from "../../helper-components/form/CustomForm.js";
import CustomButton from "../../helper-components/input/CustomButton.js";
import ComponentLoader from "../../helper-components/misc/ComponentLoader.js";

import { getFallbackPosts } from "../../helper-functions/utils/misc/posts.js";
import { formatDate } from "../../helper-functions/utils/validation/object.js";
import { useLocalStorage } from "../../helper-functions/hooks/useLocalStorage.js";
import { deletePost, queryFollowingPosts, queryPrivatePosts, queryPublicPosts } from "../../helper-functions/api/postQueries.js";

import { getLanguage } from "../../config/language/language.js";
import { PUBLIC_POSTS_PATH } from "../../config/constants/Paths.js";
import { POST_VISIBILITY_PRIVATE, POST_VISIBILITY_PROTECTED, POST_VISIBILITY_PUBLIC } from "../../config/constants/Enums.js";

/**
 * A page featuring educational posts about investing and other financial topics.
 */
export default function Posts(props) {
    const publicPosts = props?.posts?.public;
    const privatePosts = props?.posts?.private;
    const followingPosts = props?.posts?.following;

    const fallbackData = getFallbackPosts();
    const fallbackRowCount = fallbackData.length;

    const { data: publicData, page: publicPage, setPage: setPublicPage, rowCount: publicRowCount } = publicPosts;
    const { data: privateData, page: privatePage, setPage: setPrivatePage, rowCount: privateRowCount } = privatePosts;
    const { data: followingData, page: followingPage, setPage: setFollowingPage, rowCount: followingRowCount } = followingPosts;

    const [filtered, setFiltered] = React.useState([]);
    const [combinedPostsData, setCombinedPostsData] = React.useState([]);

    const [searchText, setSearchText] = React.useState("");
    const [prevSearchText, setPrevSearchText] = React.useState("");

    const [loading, setLoading] = React.useState(true);

    const [viewType, setViewType] = useLocalStorage("postViewType", "grid");

    React.useEffect(() => {
        queryPublicPosts(props, { publicPage });
        queryPrivatePosts(props, { privatePage });
        queryFollowingPosts(props, { followingPage });
    }, []);

    React.useEffect(() => {
        if (publicData.length === 0 && privateData.length === 0 && followingData.length === 0) return; // Don't show static fallback data if there is no real data.
        setCombinedPostsData([...publicData, ...privateData, ...followingData, ...fallbackData]);
    }, [publicData, privateData, followingData]);

    React.useEffect(() => {
        filterItems(searchText);
    }, [combinedPostsData]);

    // After a timeout, show the "no entries" text.
    React.useEffect(() => {
        if (filtered.length !== 0) return;
        if (filtered.length === 0) setLoading(true);

        const loop = setTimeout(() => setLoading(false), 1500);
        return () => clearTimeout(loop);
    }, [filtered]);

    /**
     * Set the filtered rows according to the search text.
     * Query assets from the server with this search param.
     *
     */
    const filterItems = (searchText) => {
        const searchWords = searchText.trim().toLowerCase().split(",");
        handleQuery(searchWords);

        const filteredItems = combinedPostsData.filter((post) => {
            const title = post.title?.toLowerCase() ?? "";
            const content = post.content?.toLowerCase() ?? "";
            const authorUsername = post.author?.username?.toLowerCase() ?? "";

            const titleFound = searchWords.some((word) => title.includes(word));
            const contentFound = searchWords.some((word) => content.includes(word));
            const authorUsernameFound = searchWords.some((word) => authorUsername.includes(word));

            return titleFound || contentFound || authorUsernameFound;
        });

        const sorted = getSortedFilteredItems(filteredItems);

        setFiltered(sorted);
    };

    /**
     * Query the posts from the server with the search parameter.
     * Doesn't query if the search text is empty or the search text has not changed.
     */
    const handleQuery = (searchWords) => {
        if ((searchWords && searchWords.length === 0) || searchWords.includes("")) return;
        if (searchText === prevSearchText) return; // Fix for infinite query loop.

        setPublicPage(0);
        setPrivatePage(0);
        setFollowingPage(0);

        setPrevSearchText(searchText);

        queryPublicPosts(props, { page: 0, filter: { items: [], quickFilterValues: searchWords } });
        queryPrivatePosts(props, { page: 0, filter: { items: [], quickFilterValues: searchWords } });
        queryFollowingPosts(props, { page: 0, filter: { items: [], quickFilterValues: searchWords } });
    };

    /**
     * Sort the filtered items according to the preferred currencies and exchanges.
     */
    const getSortedFilteredItems = (filteredItems) => {
        return filteredItems.sort((a, b) => new Date(b.updatedOn || b.createdOn) - new Date(a.updatedOn || a.createdOn));
    };

    const handleQueryMore = () => {
        queryPublicPosts(props, { page: publicPage + 1 });
        queryPrivatePosts(props, { page: privatePage + 1 });
        queryFollowingPosts(props, { page: followingPage + 1 });

        setPublicPage((prev) => prev + 1);
        setPrivatePage((prev) => prev + 1);
        setFollowingPage((prev) => prev + 1);
    };

    const showDeleteDialog = (post) => {
        props.dialog.enqueueDialog({
            title: "Delete post",
            message: `Are you sure you want to delete the selected post?

            Title: ${post.title}`,
            actionTitle: "Delete",
            actionColor: "error",
            callback: (confirm) => handleDelete(confirm, post),
        });
    };

    const handleDelete = (confirm, post) => {
        if (!confirm) return;

        deletePost(props, { post });
    };

    const renderVisibilityIcon = (post) => {
        const { visibility } = post;

        if (visibility === POST_VISIBILITY_PUBLIC) {
            return <PublicIcon />;
        } else if (visibility === POST_VISIBILITY_PROTECTED) {
            return <GroupIcon />;
        } else if (visibility === POST_VISIBILITY_PRIVATE) {
            return <LockIcon />;
        }
    };

    const renderVisibilityTooltip = (post) => {
        const { visibility } = post;

        if (visibility === POST_VISIBILITY_PUBLIC) {
            return "Available to everyone";
        } else if (visibility === POST_VISIBILITY_PROTECTED) {
            return `Available to followers of ${post.author.username}`;
        } else if (visibility === POST_VISIBILITY_PRIVATE) {
            return `Available only to yourself`;
        }
    };

    /**
     * Return the text without markdown and image tags.
     * Cut the text due to unexplainable width issues.
     */
    const removeMarkdown = (text) => {
        const width = props.browser.screenWidth;
        const imageRegex = /!\[image\]\(\/api\/public\/tools\/posts\/image\/[a-zA-Z0-9]+\.[a-zA-Z]+\)/g;
        const removedImageText = text.replaceAll(imageRegex, "");

        return removedImageText.replaceAll("#", "").substring(0, parseInt(width / 1.5));
    };

    return (
        <Container maxWidth="xl" className="container">
            {/* Intro text. */}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm
                        title={getLanguage().POSTS_TITLE}
                        subtitle={getLanguage().POSTS_SUBTITLE}
                        icon={<ForumIcon />}
                        form={
                            <Grid item xs={12} mt={2}>
                                <Search {...props} callback={filterItems} searchText={searchText} setSearchText={setSearchText} fullWidth />

                                <Stack direction="row" justifyContent="center" spacing={1}>
                                    <CustomButton {...props} title="Add new post" onClick={() => props.browser.navigate("/investment-tools/posts/create")} size="small" />
                                    <CustomButton icon={<GridViewIcon />} onClick={() => setViewType("grid")} color={viewType === "grid" ? "primary" : "default"} />
                                    <CustomButton icon={<ViewListIcon />} onClick={() => setViewType("row")} color={viewType === "row" ? "primary" : "default"} />
                                </Stack>
                            </Grid>
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <ComponentLoader isVisible={filtered.length === 0} isEmpty={false} height="75px" width="100%" />
                </Grid>

                {!loading && filtered.length === 0 ? (
                    <Grid item xs={12}>
                        <Typography className="center italic sm-font sm-margin">No results found!</Typography>
                    </Grid>
                ) : (
                    filtered.map((post, index) => {
                        let imageSrc;

                        // Differentiate between local (fallback) and server images.
                        if (post?.fallback) {
                            imageSrc = post?.bannerImagePath ?? null;
                        } else {
                            imageSrc = post?.bannerImagePath ? `${PUBLIC_POSTS_PATH}/image/${post.bannerImagePath}` : null;
                        }

                        return viewType === "grid" ? (
                            <Grid item xs={12} sm={6} xl={4} key={index}>
                                <MediaCard
                                    {...props}
                                    title={post.title}
                                    summary={removeMarkdown(post.content)}
                                    bannerImage={imageSrc}
                                    summaryClassName="md-font media-card-summary pre-line"
                                    sourceClassName="sm-font"
                                    actionsClassName="xs-font"
                                    bannerHeight="25vh"
                                    contentHeight="100%"
                                    sentiment={post?.author?.username}
                                    timePublished={formatDate(post.updatedOn || post.createdOn)}
                                    url={`/investment-tools/posts/view/${post.id}`}
                                    fullClick
                                    actions={
                                        <Stack direction="row">
                                            <CustomButton {...props} icon={renderVisibilityIcon(post)} tooltip={renderVisibilityTooltip(post)} disabled />
                                            {post?.author?.id === props?.user?.data?.id && !post.fallback && (
                                                <>
                                                    <CustomButton
                                                        {...props}
                                                        icon={<EditIcon />}
                                                        tooltip="Edit post"
                                                        onClick={() => props.browser.navigate(`/investment-tools/posts/edit/${post.id}`)}
                                                    />
                                                    <CustomButton {...props} icon={<DeleteIcon />} tooltip="Delete post" onClick={() => showDeleteDialog(post)} />
                                                </>
                                            )}
                                        </Stack>
                                    }
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={12} key={index}>
                                <MediaCard
                                    {...props}
                                    title={post.title}
                                    summary={removeMarkdown(post.content)}
                                    bannerImage={false}
                                    summaryClassName="md-font media-card-summary pre-line"
                                    sourceClassName="sm-font"
                                    actionsClassName="xs-font"
                                    contentHeight="100%"
                                    sentiment={post?.author?.username}
                                    timePublished={formatDate(post.updatedOn || post.createdOn)}
                                    url={`/investment-tools/posts/view/${post.id}`}
                                    fullClick
                                    actions={
                                        <Stack direction="row">
                                            <CustomButton {...props} icon={renderVisibilityIcon(post)} tooltip={renderVisibilityTooltip(post)} disabled />
                                            {post?.author?.id === props?.user?.data?.id && !post.fallback && (
                                                <>
                                                    <CustomButton
                                                        {...props}
                                                        icon={<EditIcon />}
                                                        tooltip="Edit post"
                                                        onClick={() => props.browser.navigate(`/investment-tools/posts/edit/${post.id}`)}
                                                    />
                                                    <CustomButton {...props} icon={<DeleteIcon />} tooltip="Delete post" onClick={() => showDeleteDialog(post)} />
                                                </>
                                            )}
                                        </Stack>
                                    }
                                />
                            </Grid>
                        );
                    })
                )}

                {filtered.length > 0 && (
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                            <CustomButton
                                {...props}
                                title="Query more"
                                onClick={handleQueryMore}
                                size="small"
                                variant="outlined"
                                disabled={filtered.length >= publicRowCount + privateRowCount + followingRowCount + fallbackRowCount}
                            />
                        </Stack>
                    </Grid>
                )}

                {filtered.length > 0 && (
                    <Grid item xs={12}>
                        <Typography className="center italic sm-font sm-margin">Reached the end of the list - use the search field above to query other posts.</Typography>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
