import * as React from "react";

import { Container, Grid, Typography } from "@mui/material";

import RequestPageIcon from "@mui/icons-material/RequestPage";

import Search from "../../helper-components/input/Search.js";
import MediaCard from "../../helper-components/misc/MediaCard.js";
import CustomForm from "../../helper-components/form/CustomForm.js";
import ComponentLoader from "../../helper-components/misc/ComponentLoader.js";

import { formatDate } from "../../helper-functions/utils/validation/object.js";

import { getLanguage } from "../../config/language/language.js";

/**
 * A page featuring educational articles about investing and other financial topics.
 */
export default function Resources(props) {
    // Show these static articles even if the server is down.
    const fallbackData = [
        {
            title: "Country calculator",
            summary: `This InvestingEdge-exclusive spreadsheet was created for answering one of the hardest questions in one's life: "which countries align best with my values and needs, and where would I like to live?"

            This calculator queries over 85 different parameters from multiple sources via an API, and normalizes around 80 of them to get a final composite score. The final score is then used to rank the countries.`,
            banner_image: "/images/resources/living_calc.png",
            url: "https://drive.google.com/drive/folders/1Tm4P-xr71djUAaC5o2rqlEsX2ovbM-S_?usp=sharing",
            time_published: "2024-05-17T16:55:00Z",
        },
        {
            title: "Options calculator",
            summary: `This calculator is designed to help you optimize your options trading strategies. 

            It allows you to input your own data and see how different strategies would perform under different market conditions.`,
            banner_image: "/images/resources/optionstrat.png",
            url: "https://optionstrat.com/optimize",
            time_published: "2024-05-17T16:55:00Z",
        },
        {
            title: "Nakamoto portfolio",
            summary: `This tool is designed to help you explore different portfolios and see how they would perform under different market conditions.

            See how a portfolio would perform if you had invested in bitcoin as well!`,
            banner_image: "/images/resources/nakamoto_portfolio.png",
            url: "https://nakamotoportfolio.com/apps/portfolio_explorer",
            time_published: "2024-05-17T16:55:00Z",
        },
        {
            title: "Sovereign CDS",
            summary: `View the latest Sovereign credit default swap (CDS) spreads for different countries.

            A CDS is a financial derivative that allows an investor to "swap" or offset their credit risk with that of another investor.
            A high CDS spread indicates that the market believes the country is more likely to default on its debt.`,
            banner_image: "/images/resources/cds.png",
            url: "https://www.worldgovernmentbonds.com/sovereign-cds/",
            time_published: "2024-05-17T16:55:00Z",
        },
    ];

    const [filtered, setFiltered] = React.useState([]);

    const [searchText, setSearchText] = React.useState("");

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        filterItems(searchText);
    }, []);

    // After a timeout, show the "no entries" text.
    React.useEffect(() => {
        if (filtered.length !== 0) return;
        if (filtered.length === 0) setLoading(true);

        const loop = setTimeout(() => setLoading(false), 1500);
        return () => clearTimeout(loop);
    }, [filtered]);

    /**
     * Set the filtered rows according to the search text.
     * Query assets from the server with this search param.
     *
     */
    const filterItems = (searchText) => {
        const searchWords = searchText.trim().toLowerCase().split(",");

        const filteredItems = fallbackData.filter((article) => {
            const title = article.title?.toLowerCase() ?? "";
            const summary = article.summary?.toLowerCase() ?? "";

            const titleFound = searchWords.some((word) => title.includes(word));
            const summaryFound = searchWords.some((word) => summary.includes(word));

            return titleFound || summaryFound;
        });

        const sorted = getSortedFilteredItems(filteredItems);

        setFiltered(sorted);
    };

    /**
     * Sort the filtered items according to the preferred currencies and exchanges.
     */
    const getSortedFilteredItems = (filteredItems) => {
        return filteredItems.sort((a, b) => new Date(b.time_published) - new Date(a.time_published));
    };

    return (
        <Container maxWidth="xl" className="container">
            {/* Intro text. */}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm
                        title={getLanguage().RESOURCES_TITLE}
                        subtitle={getLanguage().RESOURCES_SUBTITLE}
                        icon={<RequestPageIcon />}
                        form={
                            <Grid item xs={12} mt={2}>
                                <Search {...props} callback={filterItems} searchText={searchText} setSearchText={setSearchText} fullWidth />
                            </Grid>
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <ComponentLoader isVisible={filtered.length === 0} isEmpty={false} height="75px" width="100%" />
                </Grid>

                {!loading && filtered.length === 0 ? (
                    <Grid item xs={12}>
                        <Typography className="center italic sm-font sm-margin">No results found!</Typography>
                    </Grid>
                ) : (
                    filtered.map((article, index) => {
                        return (
                            <Grid item sm={12} xl={6} key={index}>
                                <MediaCard
                                    {...props}
                                    title={article.title}
                                    summary={article.summary}
                                    bannerImage={article.banner_image}
                                    url={article.url}
                                    summaryClassName="sm-font media-card-summary-full pre-line"
                                    sourceClassName="sm-font"
                                    actionsClassName="xs-font"
                                    urlTitle="Open link"
                                    bannerHeight="35vh"
                                    contentHeight="100%"
                                    source={article.source}
                                    timePublished={formatDate(article.time_published)}
                                />
                            </Grid>
                        );
                    })
                )}

                {filtered.length > 0 && (
                    <Grid item xs={12}>
                        <Typography className="center italic sm-font sm-margin">Reached the end of the list - use the search field above to query other resources.</Typography>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
