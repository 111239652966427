// // // // //
// CONTROLLER PATHS
// Basic controller sources to query info from the backend.
// A path is composed of: [ROLE]_[CATEGORY]_[SUBCATEGORY]
// Example: USER_ASSETS_PATH = "/api/user/markets/fundamental/assets"
// // // // //

// ==================== ROLES ====================

const PUBLIC_PATH = "/api/public";
const USER_PATH = "/api/user";
const MOD_PATH = "/api/moderator";
const ADMIN_PATH = "/api/admin";

// ==================== CATEGORIES ====================

const PORTFOLIO_PATH = "/portfolios";
const MARKETS_PATH = "/markets";
const TOOLS_PATH = "/tools";

// ================ MARKET SUBCATEGORIES ==============

// FUNDAMENTAL ANALYSIS.
const FUNDAMENTAL_ANALYSIS = MARKETS_PATH + "/fundamental";

// Assets.
const ASSETS_PATH = FUNDAMENTAL_ANALYSIS + "/assets";

export const PUBLIC_ASSETS_PATH = PUBLIC_PATH + ASSETS_PATH;
export const USER_ASSETS_PATH = USER_PATH + ASSETS_PATH;
export const ADMIN_ASSETS_PATH = ADMIN_PATH + ASSETS_PATH;

// Time series.
const TIME_SERIES_PATH = FUNDAMENTAL_ANALYSIS + "/time-series";

export const USER_TIME_SERIES_PATH = USER_PATH + TIME_SERIES_PATH;
export const ADMIN_TIME_SERIES_PATH = ADMIN_PATH + TIME_SERIES_PATH;

// TECHNICAL ANALYSIS.
const TECHNICAL_ANALYSIS = MARKETS_PATH + "/technical";

// Helper variables.
export const EXTERNAL_PATH = "/external";
export const COMBINED_PATH = "/combined";

// Alerts.
const ALERTS_PATH = TECHNICAL_ANALYSIS + "/alerts";

const EXTERNAL_ALERTS_PATH = ALERTS_PATH + EXTERNAL_PATH;
const COMBINED_ALERTS_PATH = ALERTS_PATH + COMBINED_PATH;

export const USER_ALERTS_PATH = USER_PATH + ALERTS_PATH;
export const PUBLIC_TRADINGVIEW_ALERTS_PATH = "https://investingedge.pro/api/public/markets/technical/alerts/external/v1/<secret>";

export const USER_EXTERNAL_ALERTS_PATH = USER_PATH + EXTERNAL_ALERTS_PATH;
export const ADMIN_EXTERNAL_ALERTS_PATH = ADMIN_PATH + EXTERNAL_ALERTS_PATH;

export const USER_COMBINED_ALERTS_PATH = USER_PATH + COMBINED_ALERTS_PATH;
export const ADMIN_COMBINED_ALERTS_PATH = ADMIN_PATH + COMBINED_ALERTS_PATH;

// Signals.
const SIGNALS_PATH = TECHNICAL_ANALYSIS + "/signals";

const EXTERNAL_SIGNALS_PATH = SIGNALS_PATH + EXTERNAL_PATH;
const COMBINED_SIGNALS_PATH = SIGNALS_PATH + COMBINED_PATH;

export const USER_SIGNALS_PATH = USER_PATH + SIGNALS_PATH;

export const USER_EXTERNAL_SIGNALS_PATH = USER_PATH + EXTERNAL_SIGNALS_PATH;
export const ADMIN_EXTERNAL_SIGNALS_PATH = ADMIN_PATH + EXTERNAL_SIGNALS_PATH;

export const USER_COMBINED_SIGNALS_PATH = USER_PATH + COMBINED_SIGNALS_PATH;
export const ADMIN_COMBINED_SIGNALS_PATH = ADMIN_PATH + COMBINED_SIGNALS_PATH;

// EXTERNAL API.
const EXTERNAL_API_PATH = MARKETS_PATH + "/external";

export const USER_EXTERNAL_API_PATH = USER_PATH + EXTERNAL_API_PATH;
export const ADMIN_EXTERNAL_API_PATH = ADMIN_PATH + EXTERNAL_API_PATH;

// ================ PORTFOLIO SUBCATEGORIES ==============

export const USER_PORTFOLIOS_PATH = USER_PATH + PORTFOLIO_PATH;
export const USER_PORTFOLIO_PATH = USER_PORTFOLIOS_PATH + "/{portfolioId}";
export const USER_PORTFOLIO_ASSETS_PATH = USER_PORTFOLIO_PATH + "/assets";
export const USER_PORTFOLIO_TRADES_PATH = USER_PORTFOLIO_ASSETS_PATH + "/trades";
export const USER_PORTFOLIO_ASSET_PATH = USER_PORTFOLIO_ASSETS_PATH + "/{assetId}";
export const USER_PORTFOLIO_ASSET_TRADES_PATH = USER_PORTFOLIO_ASSET_PATH + "/trades";

// ================ OTHER SUBCATEGORIES ==============

// Authentication.
export const AUTH_PATH = "/api/auth";

// User.
const USERS_PATH = "/users";

export const USER_USERS_PATH = USER_PATH + USERS_PATH;
export const ADMIN_USERS_PATH = ADMIN_PATH + USERS_PATH;

// Feedback.
const FEEDBACK_PATH = "/feedback";
export const USER_FEEDBACK_PATH = USER_PATH + FEEDBACK_PATH;
export const ADMIN_FEEDBACK_PATH = ADMIN_PATH + FEEDBACK_PATH;

// Forum posts.
const POSTS_PATH = TOOLS_PATH + "/posts";
export const PUBLIC_POSTS_PATH = PUBLIC_PATH + POSTS_PATH;
export const USER_POSTS_PATH = USER_PATH + POSTS_PATH;
export const ADMIN_POSTS_PATH = ADMIN_PATH + POSTS_PATH;

// Notification.
const NOTIFICATION_PATH = "/notifications";
export const USER_NOTIFICATION_PATH = USER_PATH + NOTIFICATION_PATH;

// ================ WEBSOCKETS ==============

export const WS_ADMIN_PATH = "/topic/admin";
export const WS_PUBLIC_PATH = "/topic/public";
export const WS_USER_PATH = "queue";

// ==============================
// ==============================
// ==============================
// ==============================

// // // // //
// API PATHS
// Specific endpoint names to communicate with the backend.
// // // // //

// Auth.
export const API_AUTH_JWT_VALIDATION_PATH = `${AUTH_PATH}/validate`;
export const API_AUTH_REFRESH_TOKEN_PATH = `${AUTH_PATH}/refresh`;
export const API_AUTH_LOGIN_PATH = `${AUTH_PATH}/signin`;
export const API_AUTH_SIGNUP_PATH = `${AUTH_PATH}/signup`;
export const API_VERIFY_EMAIL = `${AUTH_PATH}/verification`;
export const API_RESEND_EMAIL = `${AUTH_PATH}/verification/resend`;
export const API_PASSWORD_RESET = `${AUTH_PATH}/password-reset`;

// Users.
export const API_USERS_ALL_PATH = `${ADMIN_USERS_PATH}`;

export const API_USERS_UPDATE_PATH = `${USER_USERS_PATH}`;
export const API_USERS_SECRET_PATH = `${USER_USERS_PATH}/secret`;
export const API_USERS_DELETE_PATH = `${USER_USERS_PATH}`;

// Alerts.
export const API_EXTERNAL_ALERT_SUBSCRIBED_PATH = `${USER_EXTERNAL_ALERTS_PATH}/subscribed`;
export const API_EXTERNAL_ALERT_OWNER_PATH = `${USER_EXTERNAL_ALERTS_PATH}/owner`;

export const API_COMBINED_ALERT_SUBSCRIBED_PATH = `${USER_COMBINED_ALERTS_PATH}/subscribed`;
export const API_COMBINED_ALERT_OWNER_PATH = `${USER_COMBINED_ALERTS_PATH}/owner`;

export const API_ALERT_DELETE_PATH = `${USER_ALERTS_PATH}`;

// Signals.
export const API_EXTERNAL_SIGNAL_PUBLIC_PATH = `${USER_EXTERNAL_SIGNALS_PATH}/public`;
export const API_EXTERNAL_SIGNAL_PROTECTED_PATH = `${USER_EXTERNAL_SIGNALS_PATH}/protected`;
export const API_EXTERNAL_SIGNAL_SUBSCRIBED_PATH = `${USER_EXTERNAL_SIGNALS_PATH}/subscribed`;
export const API_EXTERNAL_SIGNAL_OWNER_PATH = `${USER_EXTERNAL_SIGNALS_PATH}/owner`;
export const API_EXTERNAL_SIGNAL_UPDATE_PATH = `${USER_EXTERNAL_SIGNALS_PATH}`;
export const API_EXTERNAL_SIGNAL_ADD_PATH = `${USER_EXTERNAL_SIGNALS_PATH}`;

export const API_COMBINED_SIGNAL_PUBLIC_PATH = `${USER_COMBINED_SIGNALS_PATH}/public`;
export const API_COMBINED_SIGNAL_PROTECTED_PATH = `${USER_COMBINED_SIGNALS_PATH}/protected`;
export const API_COMBINED_SIGNAL_SUBSCRIBED_PATH = `${USER_COMBINED_SIGNALS_PATH}/subscribed`;
export const API_COMBINED_SIGNAL_OWNER_PATH = `${USER_COMBINED_SIGNALS_PATH}/owner`;
export const API_COMBINED_SIGNAL_UPDATE_PATH = `${USER_COMBINED_SIGNALS_PATH}`;
export const API_COMBINED_SIGNAL_ADD_PATH = `${USER_COMBINED_SIGNALS_PATH}`;

export const API_SIGNAL_SHARE_PATH = `${USER_SIGNALS_PATH}/share`;
export const API_SIGNAL_DELETE_PATH = `${USER_SIGNALS_PATH}`;
export const API_SIGNAL_DISABLE_PATH = `${USER_SIGNALS_PATH}/disable`;
export const API_SIGNAL_ENABLE_PATH = `${USER_SIGNALS_PATH}/enable`;
export const API_SIGNAL_SUBSCRIBE_PATH = `${USER_SIGNALS_PATH}/subscribe`;
export const API_SIGNAL_UNSUBSCRIBE_PATH = `${USER_SIGNALS_PATH}/unsubscribe`;

// External.
export const API_NEWS_SENTIMENT_PATH = `${USER_EXTERNAL_API_PATH}/news/sentiment`;

// Misc.
export const API_DOCUMENTATION_PATH = `/api/docs/documentation.html`;

// // // // //
// WEBSOCKET PATHS
// Endpoints to connect to the websocket.
// // // // //

export const WEBSOCKET_PATH = "/ws";
export const WS_OWNER_PATH = "/user/<sessionId>/queue";

// // // // //
// MISC. PATHS
// Endpoints to external sources, without going through the backend.
// // // // //

// Ticker images.
export const CRYPTO_TICKER_URL = "https://s3-symbol-logo.tradingview.com/crypto/XTVC<ticker>.svg";
export const STOCK_TICKER_URL = "https://logos.stockanalysis.com/<ticker>.svg";
