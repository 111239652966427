import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FeedbackIcon from "@mui/icons-material/Feedback";
import FeedIcon from "@mui/icons-material/Feed";
import ListAltIcon from "@mui/icons-material/ListAlt";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonIcon from "@mui/icons-material/Person";
import PieChartIcon from "@mui/icons-material/PieChart";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SettingsIcon from "@mui/icons-material/Settings";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ArticleIcon from "@mui/icons-material/Article";
import PolicyIcon from "@mui/icons-material/Policy";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ForumIcon from "@mui/icons-material/Forum";

import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import WorkIcon from "@mui/icons-material/Work";
import ConstructionIcon from "@mui/icons-material/Construction";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import { assetIsValid } from "../../helper-functions/utils/asset/asset";
import { combinedSignalIsValid, externalSignalIsValid } from "../../helper-functions/utils/asset/signal";
import { portfolioTradeIsValid, portfoliosIsValid } from "../../helper-functions/utils/misc/portfolio";
import { isAdmin, isLoggedIn } from "../../helper-functions/utils/misc/utils";

import { VALIDATION as ASSET_VALIDATION } from "../../helper-functions/utils/validation/asset";
import { PORTFOLIO_VALIDATION, PORTFOLIO_TRADE_VALIDATION } from "../../helper-functions/utils/validation/portfolios";
import { COMBINED_VALIDATION as COMBINED_SIGNAL_VALIDATION, EXTERNAL_VALIDATION as EXTERNAL_SIGNAL_VALIDATION } from "../../helper-functions/utils/validation/signal";

import * as Enums from "./Enums";
import * as Paths from "./Paths";

// // // // //
// OBJECTS
// Constants that are used throughout the application.
// NB! Should mimic the backend objects (DTOs).
// // // // //

// Form objects.
export const FORM_ELEMENT = {
    error: false,
    helperText: "",
};

// Configuration objects.
const ASSET_OBJ = {
    id: "",
    name: "",
    ticker: "",

    exchange: "",
    currency: "",
    assetType: "",
    createdOn: "",

    status: "",
};

const PORTFOLIO_OBJ = {
    id: "",
    name: "",

    currency: "",
    value: "",
    change: "",
    changePercentage: "",
    updatedOn: "",

    distributions: [],
    history: [],
};

const PORTFOLIO_ASSET_OBJ = {
    id: "",

    value: "",
    change: "",
    changePercentage: "",

    asset: {
        ...ASSET_OBJ,
    },
};

const PORTFOLIO_TRADE_OBJ = {
    id: "",

    price: "",
    amount: "",
    timestamp: "",

    asset: {
        ...ASSET_OBJ,
    },

    // Temporary formatted fields.
    _asset: "",
    _assetId: "",
};

const SIGNAL_OBJ = {
    id: "",
    assetId: "",
    ownerId: "",

    name: "",
    description: "",
    lastAlert: "",
    orderType: "",
    visibility: "",

    status: "",
    code: "",

    // Temporary formatted fields.
    _assetId: "",
};

export const EXTERNAL_SIGNAL_OBJ = {
    ...SIGNAL_OBJ,
};

export const COMBINED_SIGNAL_OBJ = {
    ...SIGNAL_OBJ,
    ids: [],

    condition: "",
    value: "",

    // Temporary formatted fields.
    _ids: "",
    _value: "",
};

const ALERT_OBJ = {
    signalId: "",
    timestamp: "",
};

export const EXTERNAL_ALERT_OBJ = {
    ...ALERT_OBJ,
    value: "",
};

export const COMBINED_ALERT_OBJ = {
    ...ALERT_OBJ,
};

export const USER_OBJ = {
    id: "",
    username: "",
    email: "",
    timeAtUserCreation: "",
    timeAtUserLogin: "",
    role: {},
};

// SOUND FILE NAMES & READABLE NAMES
// Key: filename
// Value: description
export const SOUND_PATH_AND_NAMES = {
    "computer.mp3": "Computer beep",
    "chirp.mp3": "Chirp",
    "glass.mp3": "Glass clink",
    "pop.mp3": "Pop",
    "bruh.mp3": "Bruh #1",
};

// Category constants, defining things like endpoints or validation function.
export const CATEGORIES = {
    asset: {
        type: Enums.ASSET,
        obj: ASSET_OBJ,

        isValid: assetIsValid,
        validation: ASSET_VALIDATION,

        paths: {
            add: Paths.USER_ASSETS_PATH,
            owner: Paths.USER_ASSETS_PATH,
        },
    },
    portfolios: {
        type: Enums.PORTFOLIOS,
        obj: PORTFOLIO_OBJ,

        isValid: portfoliosIsValid,
        validation: PORTFOLIO_VALIDATION,
    },
    portfolioAsset: {
        type: Enums.PORTFOLIO,
        obj: PORTFOLIO_ASSET_OBJ,

        isValid: portfoliosIsValid,
        validation: PORTFOLIO_VALIDATION,
    },
    portfolioTrade: {
        type: Enums.PORTFOLIO_TRADE,
        obj: PORTFOLIO_TRADE_OBJ,

        isValid: portfolioTradeIsValid,
        validation: PORTFOLIO_TRADE_VALIDATION,
    },
    signal: {
        external: {
            type: Enums.EXTERNAL_SIGNAL,
            obj: EXTERNAL_SIGNAL_OBJ,

            isValid: externalSignalIsValid,
            validation: EXTERNAL_SIGNAL_VALIDATION,

            paths: {
                public: Paths.API_EXTERNAL_SIGNAL_PUBLIC_PATH,
                protected: Paths.API_EXTERNAL_SIGNAL_PROTECTED_PATH,
                subscribed: Paths.API_EXTERNAL_SIGNAL_SUBSCRIBED_PATH,
                owner: Paths.API_EXTERNAL_SIGNAL_OWNER_PATH,
                asset: Paths.USER_EXTERNAL_SIGNALS_PATH,
                add: Paths.API_EXTERNAL_SIGNAL_ADD_PATH,
                update: Paths.API_EXTERNAL_SIGNAL_UPDATE_PATH,
                share: Paths.API_SIGNAL_SHARE_PATH,
                subscribe: Paths.API_SIGNAL_SUBSCRIBE_PATH,
                unsubscribe: Paths.API_SIGNAL_UNSUBSCRIBE_PATH,
                enable: Paths.API_SIGNAL_ENABLE_PATH,
                disable: Paths.API_SIGNAL_DISABLE_PATH,
                delete: Paths.API_SIGNAL_DELETE_PATH,
            },
        },
        combined: {
            type: Enums.COMBINED_SIGNAL,
            obj: COMBINED_SIGNAL_OBJ,

            isValid: combinedSignalIsValid,
            validation: COMBINED_SIGNAL_VALIDATION,

            paths: {
                public: Paths.API_COMBINED_SIGNAL_PUBLIC_PATH,
                protected: Paths.API_COMBINED_SIGNAL_PROTECTED_PATH,
                subscribed: Paths.API_COMBINED_SIGNAL_SUBSCRIBED_PATH,
                owner: Paths.API_COMBINED_SIGNAL_OWNER_PATH,
                asset: Paths.USER_EXTERNAL_SIGNALS_PATH,
                add: Paths.API_COMBINED_SIGNAL_ADD_PATH,
                share: Paths.API_SIGNAL_SHARE_PATH,
                update: Paths.API_COMBINED_SIGNAL_UPDATE_PATH,
                subscribe: Paths.API_SIGNAL_SUBSCRIBE_PATH,
                unsubscribe: Paths.API_SIGNAL_UNSUBSCRIBE_PATH,
                enable: Paths.API_SIGNAL_ENABLE_PATH,
                disable: Paths.API_SIGNAL_DELETE_PATH,
                delete: Paths.API_SIGNAL_DELETE_PATH,
            },
        },
    },
    alert: {
        external: {
            type: Enums.EXTERNAL_SIGNAL,
            obj: EXTERNAL_ALERT_OBJ,

            paths: {
                subscribed: Paths.API_EXTERNAL_ALERT_SUBSCRIBED_PATH,
                owner: Paths.API_EXTERNAL_ALERT_OWNER_PATH,
                delete: Paths.API_ALERT_DELETE_PATH,
            },
        },
        combined: {
            type: Enums.COMBINED_SIGNAL,
            obj: COMBINED_ALERT_OBJ,

            paths: {
                subscribed: Paths.API_COMBINED_ALERT_SUBSCRIBED_PATH,
                owner: Paths.API_COMBINED_ALERT_OWNER_PATH,
                delete: Paths.API_ALERT_DELETE_PATH,
            },
        },
    },
    user: {
        owner: {
            obj: USER_OBJ,

            paths: {
                update: Paths.API_USERS_UPDATE_PATH,
                delete: Paths.API_USERS_DELETE_PATH,
            },
        },
        admin: {
            obj: USER_OBJ,

            paths: {
                all: Paths.API_USERS_ALL_PATH,
                update: Paths.API_USERS_UPDATE_PATH,
                delete: Paths.API_USERS_DELETE_PATH,
            },
        },
    },
};

// // // // //
// SIDEBAR CATEGORIES
// Used to define sidebar elements.
// // // // //

export const SIDEBAR_MARKET_ANALYSIS = {
    id: "MARKET_ANALYSIS",
    name: "Market analysis",
    description: "Analysis of fundamentals and technicals to determine asset viability and trading opportunities",
    path: "/market-analysis",
    icon: <CandlestickChartIcon />,
    categories: {
        assets: {
            id: "ASSETS",
            path: "/fundamental/assets",
            name: "Assets",
            description: "View detailed analysis of assets available on the platform",
            icon: <ListAltIcon />,
        },
        screener: {
            id: "SCREENER",
            path: "/fundamental/screener",
            name: "Screener",
            description: "Search and filter for new assets",
            icon: <TravelExploreIcon />,
        },
        signals: {
            id: "SIGNALS",
            path: "/technical/signals",
            name: "Signals",
            description: "Configure buy and sell signals",
            icon: <QueryStatsIcon />,
        },
        alerts: {
            id: "ALERTS",
            path: "/technical/alerts",
            name: "Alerts",
            description: "View the buy and sell alerts",
            icon: <NotificationsActiveIcon />,
        },
    },
};

export const SIDEBAR_PORTFOLIOS = {
    id: "PORTOLIO_TRACKER",
    name: "Portfolio tracker",
    description: "Track your portfolios' performance",
    path: "/portfolio-tracker",
    icon: <WorkIcon />,
    categories: {
        portfolios: {
            id: "PORTFOLIOS",
            path: "/portfolios",
            name: "Portfolios",
            description: "View and manage your portfolios",
            icon: <CasesRoundedIcon />,
        },
        analysis: {
            id: "PORTFOLIO_ANALYSIS",
            path: "/analysis",
            name: "Analysis",
            description: "Analyze your portfolios, and receive recommendations and insights",
            icon: <PieChartIcon />,
        },
    },
};

export const SIDEBAR_TOOLS = {
    id: "INVESTMENT_TOOLS",
    name: "Investment tools",
    description: "Various tools for managing your investments",
    path: "/investment-tools",
    icon: <ConstructionIcon />,
    categories: {
        chatbot: {
            id: "CHATBOT",
            path: "/chatbot",
            name: "AI chatbot",
            description: "Chat with a data-aware chatbot to receive advice and recommendations",
            icon: <SmartToyIcon />,
        },
        recap: {
            id: "NEWS_RECAP",
            path: "/news-recap",
            name: "News recap",
            description: "View a recap of the latest news and events in the financial world",
            icon: <FeedIcon />,
            visible: false,
        },
        resources: {
            id: "RESOURCES",
            path: "/resources",
            name: "Resources",
            description: "Access various resources to help with your investing journey",
            icon: <RequestPageIcon />,
        },
        posts: {
            id: "POSTS",
            path: "/posts",
            name: "Posts",
            description: "View and create posts to share your thoughts and ideas with the community",
            icon: <ForumIcon />,
        },
        daytrading: {
            id: "DAYTRADING",
            path: "/daytrading-dashboard",
            name: "Daytrading dashboard",
            description: "Real-time trading insights to help with short-term trading",
            icon: <DashboardIcon />,
            visible: false,
        },
    },
};

export const SIDEBAR_USER = {
    id: "USER",
    name: "User",
    description: "User settings and account info",
    path: "/user",
    icon: <ManageAccountsIcon />,
    visible: isLoggedIn,
    categories: {
        account: {
            id: "ACCOUNT",
            path: "/account",
            name: "Account",
            description: "View account info",
            icon: <PersonIcon />,
        },
        settings: {
            id: "SETTINGS",
            path: "/settings",
            name: "Settings",
            description: "Configure account settings",
            icon: <SettingsIcon />,
        },
    },
};

export const SIDEBAR_ADMIN = {
    id: "ADMIN",
    name: "Administration",
    description: "Settings and tools for managing the platform",
    path: "",
    icon: <AdminPanelSettingsIcon />,
    visible: isAdmin,
    categories: {
        admin: {
            id: "USERS_FEEDBACK",
            path: "/admin",
            name: "Users & feedback",
            description: "Manage platform users and feedback",
            icon: <EngineeringIcon />,
        },
    },
};

export const SIDEBAR_OTHER = {
    id: "OTHER",
    name: "Other",
    description: "Miscellaneous items",
    path: "",
    categories: {
        feedback: {
            id: "FEEDBACK",
            path: "/feedback",
            name: "Feedback",
            description: "Provide feedback on the platform",
            icon: <FeedbackIcon />,
            visible: isLoggedIn,
        },
        terms: {
            id: "TERMS_CONDITIONS",
            path: "/terms-and-conditions",
            name: "Terms and conditions",
            description: "Read the terms and conditions of the platform",
            icon: <ArticleIcon />,
        },
        privacy: {
            id: "PRIVACY_POLICY",
            path: "/privacy-policy",
            name: "Privacy policy",
            description: "Read the privacy policy of the platform",
            icon: <PolicyIcon />,
        },
        // snek: {
        //     id: "SNEK",
        //     path: "/snek",
        //     name: "`?? ?`",
        //     description: "Easter egg",
        //     icon: <GestureIcon />,
        //     visible: isBrowser,
        // },
    },
};

export const SIDEBAR_CATEGORIES = [SIDEBAR_MARKET_ANALYSIS, SIDEBAR_PORTFOLIOS, SIDEBAR_TOOLS, SIDEBAR_USER, SIDEBAR_ADMIN, SIDEBAR_OTHER];
