import { createCriteriaParameters, standardDeleteQuery, standardGetQuery, standardPutQuery } from "../utils/misc/api";
import { handleAddNotifications, handleDeleteAll, handleDeleteOne, handleMarkAllRead, handleMarkOneRead } from "../utils/misc/notification";

import { USER_NOTIFICATION_PATH } from "../../config/constants/Paths";

/**
 * Query unread notifications from API.
 */
export const queryUnreadNotifications = (props, options = {}) => {
    const { path = USER_NOTIFICATION_PATH + "/unread", state } = options;

    const fullPath = path + createCriteriaParameters(state);

    standardGetQuery(props, fullPath, (response) => {
        handleAddNotifications(props, state, response.body);

        if (response.body.total) state.setRowCount(response.body.total);
    });
};

/**
 * Query read notifications from API.
 */
export const queryReadNotifications = (props, options = {}) => {
    const { path = USER_NOTIFICATION_PATH + "/read", state } = options;

    const fullPath = path + createCriteriaParameters(state);

    standardGetQuery(props, fullPath, (response) => {
        handleAddNotifications(props, state, response.body);

        if (response.body.total) state.setRowCount(response.body.total);
    });
};

/**
 * Delete all read notifications from API.
 */
export const deleteAllRead = (props, options = {}) => {
    const { path = USER_NOTIFICATION_PATH, state } = options;

    standardDeleteQuery(props, path, {}, () => {
        handleDeleteAll(state);
    });
};

/**
 * Mark all notifications read in the API.
 */
export const markAllNotificationsRead = (props, options = {}) => {
    const { path = USER_NOTIFICATION_PATH, readState, unreadState } = options;

    standardPutQuery(props, path, {}, () => {
        handleMarkAllRead(props, readState, unreadState);
    });
};

/**
 * Mark a notification read in the API.
 */
export const markOneRead = (props, options = {}) => {
    const { path = `${USER_NOTIFICATION_PATH}/${props.notification.id}`, readState, unreadState } = options;

    standardPutQuery(props, path, {}, () => {
        handleMarkOneRead(props, readState, unreadState);
    });
};

/**
 * Delete a notification from API.
 */
export const deleteOne = (props, options = {}) => {
    const { path = `${USER_NOTIFICATION_PATH}/${props.notification.id}`, state } = options;

    standardDeleteQuery(props, path, {}, () => {
        handleDeleteOne(props, state);
    });
};
