// These are the rules by which the form input fields will be checked against.

import dayjs from "dayjs";
import { getValue, setFormValues } from "./object.js";

/**
 * Criteria:
 * 1) Required
 * 2) No special characters
 */
const nameValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "") {
        return setFormValues(row, "name", true, "Required");
    }

    // 2)
    if (!/^[a-z0-9./_-\s'&()]+$/gi.test(value)) {
        return setFormValues(row, "name", true, "Special characters not allowed");
    }

    return setFormValues(row, "name", false, "");
};

/**
 * Criteria:
 * 1) Required
 */
const _assetValidation = (prevConfig, row, _assetId) => {
    const assetId = getValue(row, "_assetId");

    // 1)
    if (assetId === "" || assetId === undefined) {
        return setFormValues(row, "_asset", true, "Required");
    }

    return setFormValues(row, "_asset", false, "");
};

/**
 * Criteria:
 * 1) Required
 * 2) Must be a known currency
 */
const currencyValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "") {
        return setFormValues(row, "currency", true, "Required");
    }

    return setFormValues(row, "currency", false, "");
};

/**
 *  Criteria:
 * 1) Required
 * 2) Must be a number
 */
const priceValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "") {
        return setFormValues(row, "price", true, "Required");
    }

    // 2)
    if (isNaN(value)) {
        return setFormValues(row, "price", true, "Must be a number");
    }

    return setFormValues(row, "price", false, "");
};

/**
 * Criteria:
 * 1) Required
 * 2) Must be a number
 */
const amountValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "") {
        return setFormValues(row, "amount", true, "Required");
    }

    // 2)
    if (isNaN(value)) {
        return setFormValues(row, "amount", true, "Must be a number");
    }

    return setFormValues(row, "amount", false, "");
};

/**
 * Criteria:
 * 1) Must exist
 * 2) Must be valid
 * 3) Must be in the past
 */
const timestampValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "" || value === null) {
        return setFormValues(row, "timestamp", true, "Required");
    }

    // 2)
    if (!dayjs(value).isValid()) {
        return setFormValues(row, "timestamp", true, "Invalid");
    }

    // 3)
    if (dayjs(value).isAfter(dayjs())) {
        return setFormValues(row, "timestamp", true, "Must be in the past");
    }

    return setFormValues(row, "timestamp", false, "");
};

// The main constant, directing to the specific validation function.
export const PORTFOLIO_VALIDATION = {
    name: {
        check: nameValidation,
    },
    currency: {
        check: currencyValidation,
    },
};

export const PORTFOLIO_TRADE_VALIDATION = {
    _asset: {
        check: _assetValidation,
    },
    price: {
        check: priceValidation,
    },
    amount: {
        check: amountValidation,
    },
    timestamp: {
        check: timestampValidation,
    },
};
