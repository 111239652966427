// // // // //
// GENERAL
// Misc. constants
// // // // //
export const PAGE_VERSION = "v1.0.5";
export const ENABLE_PAGE_LOADER = true; // Show spinner animation.
export const PAGE_LOADING_MS = 1200;

// // // // //
// PAGE ELEMENTS
// Toolbar, drawer, and other HTML elements.
// Also includes pagination.
// // // // //
export const AUTO_OPEN_SIDEBAR_PX = 1000;
export const HIDE_TOOLBAR_DESCRIPTION_PX = 730;
export const PAGE_DRAWER_WIDTH_PX = 270;
export const ELEMENT_MIN_WIDTH_PX = 285;
export const SIDEBAR_TEXT_MIN_WIDTH = 35;

export const TABLE_ALL_TIMEFRAMES_VISIBLE = "all";
export const TABLE_LOADING_MS = 2000; // Loading widget before deciding whether to show the table (i.e. no alerts)
export const TABLE_HEIGHT_DESKTOP_PX = 420;
export const TABLE_HEIGHT_MOBILE_PX = 325;
export const TABLE_ROW_HEIGHT_DESKTOP_PX = 50;
export const TABLE_ROW_HEIGHT_MOBILE_PX = 30;
export const TABLE_ROWS_PER_PAGE = [5, 10, 20];
export const DEFAULT_TABLE_ROWS_PER_PAGE = 5;

export const SERVER_PAGINATION = 10; // NB! Keep this same w/ backend! How many rows are returned from the database each query.
export const ASSET_TABLE_PAGE_SIZE = 5; // How many rows are shown in the table before needing to go to the next page.
export const USER_TABLE_PAGE_SIZE = 5;
export const FEEDBACK_TABLE_PAGE_SIZE = 5;
export const NOTIFICATION_PAGE_SIZE = 3; // How many notifications are visible in the sidebar at once.

// // // // //
// SERVER
// Network parameters.
// // // // //
export const DEVELOPMENT_ENV = window.location.port === "3000";
export const SERVER_IP = window.location.protocol + "//" + window.location.hostname; // http://193.40.255.27
export const SERVER_PORT = window.location.port; // NGINX deals with choosing to which port the request will be sent.
export const HARDCODED_SERVER_PORT = "8080"; // For queries to server-side pages (e.g. OpenAPI (Swagger) docs)
