import { useSnackbar as useNotistackSnackbar, closeSnackbar } from "notistack";

import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

/**
 * Snackbar hook to show small messages to the user.
 * Allows for customizability, e.g. color or duration.
 */
export function useSnackbar() {
    const { enqueueSnackbar } = useNotistackSnackbar();

    /**
     * Add action(s) to an individual snackbar using the key returned by enqueueSnackbar.
     */
    const action = (snackbarId) => (
        <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(snackbarId)}>
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    /**
     * Composite function to show the snackbar along with the specified data.
     *
     * Options:
     * - color (default: success): the color of the snackbar
     * - duration (default: 3000): how long the snackbar will be shown
     * - message: the message to show
     */
    const showSnackbar = (options = {}) => {
        const { color = "success", duration = 3000, message } = options;

        enqueueSnackbar(message, {
            variant: color,
            autoHideDuration: duration,
            action,
        });
    };

    return { showSnackbar };
}
