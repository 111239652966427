import * as React from "react";

import Joyride, { STATUS } from "react-joyride";
import { ErrorBoundary } from "react-error-boundary";

import { Box, Toolbar } from "@mui/material";

import Appbar from "./components/drawer/Appbar";
import AppDrawer from "./components/drawer/AppDrawer";
import NotificationDrawer from "./components/notifications/NotificationDrawer";
import { PageRoutes } from "./components/pages/PageRoutes.js";

import ConfirmationDialog from "../../helper-components/input/ConfirmationDialog.js";
import UnexpectedError from "../../helper-components/pages/UnexpectedError";
import Copyright from "../../helper-components/misc/Copyright";

import { executeFunctionsAfterConnectionEstablished, executeFunctionsAfterLogin, executeFunctionsAfterPageLoad } from "../../helper-functions/utils/misc/api.js";
import { executeFunctionsAfterUserHasInteractedWithPage } from "../../helper-functions/utils/misc/api.js";
import { initializeWebSocketDestinations } from "../../helper-functions/utils/webSocket";
import { handleTourGuide, scrollToTop } from "../../helper-functions/utils/page/utils.js";

import { loadCurrentUser } from "../../helper-functions/api/userQueries";
import { parseUserData } from "../../helper-functions/utils/user/utils.js";
import { querySignals } from "../../helper-functions/api/signalQueries.js";
import { getCategory } from "../../helper-functions/utils/asset/utils.js";
import { queryAssets } from "../../helper-functions/api/assetQueries.js";

import { COMBINED_SIGNAL, EXTERNAL_SIGNAL, TOUR_GUIDE_STEPS } from "../../config/constants/Enums.js";

/**
 * Main page, which includes:
 * sidebar
 * toolbar
 * routes to all sub-pages, which will be shown in the center of the page.
 *
 * Add any periodic queries or functions executed after page reload here.
 */
export default function MainPage(props) {
    const { userInteractedWithPage, setUserInteractedWithPage, showMainPageComponents, setShowTourGuide, runTourGuide, setRunTourGuide } = props.browser;
    const { isLoggedIn, isConnectionEstablished } = props.server;
    const { pathname } = props.browser.location;

    const { assets, signals, alerts } = props;

    const assetData = assets.data;

    const combinedPrivateSignals = [...signals.external.owner.data, ...signals.external.subscribed.data, ...signals.combined.owner.data, ...signals.combined.subscribed.data];
    const combinedPublicSignals = [...signals.external.protected.data, ...signals.external.public.data, ...signals.combined.protected.data, ...signals.combined.public.data];
    const combinedSignals = [...combinedPrivateSignals, ...combinedPublicSignals];

    // Platform tour guide steps.
    const handleJoyrideCallback = (data) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setShowTourGuide(false);
            setRunTourGuide(false);
        }
    };

    // Temporary state to keep track of already queried asset ids.
    const [alreadyQueriedAssetIds, setAlreadyQueriedAssetIds] = React.useState([]);
    const [alreadyQueriedExternalSignalOwnerIds, setAlreadyQueriedExternalSignalOwnerIds] = React.useState([]);
    const [alreadyQueriedExternalSignalSubscribedIds, setAlreadyQueriedExternalSignalSubscribedIds] = React.useState([]);
    const [alreadyQueriedExternalSignalAssetIds, setAlreadyQueriedExternalSignalAssetIds] = React.useState([]);

    const [alreadyQueriedCombinedSignalOwnerIds, setAlreadyQueriedCombinedSignalOwnerIds] = React.useState([]);
    const [alreadyQueriedCombinedSignalSubscribedIds, setAlreadyQueriedCombinedSignalSubscribedIds] = React.useState([]);
    const [alreadyQueriedCombinedSignalAssetIds, setAlreadyQueriedCombinedSignalAssetIds] = React.useState([]);

    // If a signal references an unknown asset, query it.
    React.useEffect(() => {
        if (combinedSignals.length > 0) {
            const assetIds = combinedSignals.map((signal) => signal.assetId);
            const unknownAssetIds = [...new Set(assetIds.filter((id) => !alreadyQueriedAssetIds.includes(id) && !assetData.find((asset) => asset.id === id)))];

            if (unknownAssetIds.length > 0) {
                console.log("Found unknown assets, querying: ", unknownAssetIds);

                setAlreadyQueriedAssetIds((prev) => [...prev, ...unknownAssetIds]);
                queryAssets(props, { filter: { items: [{ columnField: "id", operatorValue: "isAnyOf", value: unknownAssetIds }] }, pageSize: unknownAssetIds.length });
            }
        }
    }, [combinedSignals]);

    // If an alert references an unknown signal, query it.
    React.useEffect(() => {
        const signalTypes = ["owner", "subscribed", "asset"];
        const queriedIds = [alreadyQueriedExternalSignalOwnerIds, alreadyQueriedExternalSignalSubscribedIds, alreadyQueriedExternalSignalAssetIds];
        const setQueriedIds = [setAlreadyQueriedExternalSignalOwnerIds, setAlreadyQueriedExternalSignalSubscribedIds, setAlreadyQueriedExternalSignalAssetIds];

        signalTypes.forEach((type, index) => {
            querySignalsIfUnknown("external", type, EXTERNAL_SIGNAL, queriedIds[index], setQueriedIds[index]);
        });
    }, [alerts.external, signals.external]);

    React.useEffect(() => {
        const signalTypes = ["owner", "subscribed", "asset"];
        const queriedIds = [alreadyQueriedCombinedSignalOwnerIds, alreadyQueriedCombinedSignalSubscribedIds, alreadyQueriedCombinedSignalAssetIds];
        const setQueriedIds = [setAlreadyQueriedCombinedSignalOwnerIds, setAlreadyQueriedCombinedSignalSubscribedIds, setAlreadyQueriedCombinedSignalAssetIds];

        signalTypes.forEach((type, index) => {
            querySignalsIfUnknown("combined", type, COMBINED_SIGNAL, queriedIds[index], setQueriedIds[index]);
        });
    }, [alerts.combined, signals.combined]);

    /**
     * If an alert references an unknown signal, query it.
     *
     * Parameters:
     * - type: external or combined
     * - subType: owner, subscribed, etc.
     * - category: e.g. Enums.EXTERNAL_SIGNAL
     */
    const querySignalsIfUnknown = (type, subType, signalCategory, queriedIds = [], setQueriedIds = () => {}) => {
        const alertsData = alerts[type][subType].data;
        const signalsState = signals[type][subType];
        const signalsData = signalsState.data;

        if (alertsData.length > 0) {
            const signalIds = alertsData.map((alert) => alert.signalId);
            const unknownSignalIds = [...new Set(signalIds.filter((id) => !queriedIds.includes(id) && !signalsData.find((signal) => signal.id === id)))];

            if (unknownSignalIds.length > 0) {
                console.log("Found unknown signals, querying: ", unknownSignalIds);
                const category = getCategory(signalCategory);

                setQueriedIds((prev) => [...prev, ...unknownSignalIds]);

                querySignals(props, {
                    state: signalsState,
                    path: category.paths[subType],
                    filter: { items: [{ columnField: "id", operatorValue: "isAnyOf", value: unknownSignalIds }] },
                });
            }
        }
    };

    // Scroll to the top of the page when the route changes.
    React.useEffect(() => {
        scrollToTop();
    }, [pathname]);

    // useEffect gets automatically executed when page is loaded.
    React.useEffect(() => {
        executeFunctionsAfterPageLoad(props);
    }, []);

    React.useEffect(() => {
        executeFunctionsAfterConnectionEstablished(props);
    }, [isConnectionEstablished]);

    // Allow querying of all alerts/signals if the user logged in.
    React.useEffect(() => {
        executeFunctionsAfterLogin(props);
    }, [isLoggedIn]);

    // Query user info and save to state.
    React.useEffect(() => {
        loadCurrentUser(props);
    }, [props.user.accessToken]);

    // Parse user data, e.g. set localstorage variables.
    React.useEffect(() => {
        parseUserData(props);
        if (props.user?.data?.username) handleTourGuide(props);
    }, [props.user.data]);

    // Subscribe to the websocket topics to get real-time info.
    React.useEffect(() => {
        initializeWebSocketDestinations(props);
    }, [props.ws.webSocketClient, userInteractedWithPage]);

    // Add event listeners for user interactions.
    React.useEffect(() => {
        window.addEventListener("click", handleInteraction);
        window.addEventListener("keydown", handleInteraction);
        window.addEventListener("touchstart", handleInteraction);

        return () => {
            window.removeEventListener("click", handleInteraction);
            window.removeEventListener("keydown", handleInteraction);
            window.removeEventListener("touchstart", handleInteraction);
        };
    }, []);

    React.useEffect(() => {
        executeFunctionsAfterUserHasInteractedWithPage(props);
    }, [userInteractedWithPage, isLoggedIn]);

    const handleInteraction = () => {
        if (userInteractedWithPage) return;
        setUserInteractedWithPage(true);
    };

    const drawerTheme = {
        backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[100] : t.palette.grey[900]),
    };

    // Load the voiceflow chat widget.
    React.useEffect(() => {
        if (!isLoggedIn) return;
        if (document.getElementById("voiceflow-chat")) return;
        if (pathname.includes("/chatbot")) return;

        console.log("Loading voiceflow chat widget...");

        const id = "66c2fec3b8d94e5f7fb8cd68";
        const script = document.createElement("script");

        script.onload = function () {
            window.voiceflow.chat.load({
                verify: { projectID: id },
                url: "https://general-runtime.voiceflow.com",
                versionID: "production",
                render: {
                    mode: "overlay",
                },
                autostart: false,
            });
        };

        script.async = true;
        script.type = "text/javascript";
        script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";

        document.body.appendChild(script);

        const voiceflowInfoStr = localStorage.getItem(`voiceflow-session-${id}`);
        const voiceflowInfoObj = voiceflowInfoStr ? JSON.parse(voiceflowInfoStr) : null;

        // Push introductory messages to the chat widget to draw attention if the user hasn't interacted with it yet.
        if (!voiceflowInfoObj?.status) {
            setTimeout(() => {
                window.voiceflow.chat.proactive.push({ type: "text", payload: { message: "Hi there! I'm your personal AI-assistant 👋" } });
            }, 15000);

            setTimeout(() => {
                window.voiceflow.chat.proactive.push({ type: "text", payload: { message: "Ask me anything about investing 💸💰 or the Investing Edge platform 📰" } });
            }, 17500);
        }

        return () => {
            document.body.removeChild(script);
        };
    }, [isLoggedIn, pathname]);

    React.useEffect(() => {
        if (!window?.voiceflow?.chat) return;

        if (pathname.includes("/chatbot")) {
            window.voiceflow.chat.hide();
        } else {
            window.voiceflow.chat.show();
        }
    }, [pathname]);

    return (
        // This component includes the toolbar, drawer and other components that remain the same everywhere you are.
        <Box className="main-page-box">
            <Joyride
                steps={TOUR_GUIDE_STEPS}
                run={runTourGuide}
                styles={{
                    options: {
                        primaryColor: "#9c27b0",
                        zIndex: 9999,
                    },
                }}
                continuous
                showProgress
                showSkipButton
                spotlightClicks
                disableScrolling
                scrollToFirstStep
                callback={handleJoyrideCallback}
            />

            {showMainPageComponents && (
                <>
                    <Appbar {...props} />
                    <AppDrawer {...props} />
                    <NotificationDrawer {...props} />
                </>
            )}

            <ConfirmationDialog {...props} options={props.dialog} />

            <Box className="main-component-box" sx={drawerTheme}>
                {showMainPageComponents && <Toolbar />}

                <ErrorBoundary
                    FallbackComponent={({ error, resetErrorBoundary }) => {
                        return <UnexpectedError {...props} error={error} resetErrorBoundary={resetErrorBoundary} />;
                    }}>
                    <PageRoutes {...props} />
                </ErrorBoundary>

                {showMainPageComponents && <Copyright />}
            </Box>
        </Box>
    );
}
