import * as React from "react";

import { Box, TextField, Grid } from "@mui/material";

import CustomButton from "../../../../helper-components/input/CustomButton";

import { updateUser } from "../../../../helper-functions/api/userQueries";

import * as Paths from "../../../../config/constants/Paths.js";
import { getLanguage } from "../../../../config/language/language.js";

/**
 * Allow the user to update their account info.
 * Require password confirmation.
 */
export default function UserUpdateForm(props) {
    const user = props?.user?.data || {};

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const body = {
            id: user.id,
            password: data.get("password"),
            newUsername: data.get("newUsername"),
            newPassword: data.get("newPassword"),
            newEmail: data.get("newEmail"),
        };

        if (isNewPasswordOk(data)) {
            updateUser(props, Paths.API_USERS_UPDATE_PATH, body);
        }
    };

    // Password matching check.
    const isNewPasswordOk = (data) => {
        if (data.get("newPassword") !== data.get("newPasswordConfirm")) {
            props.showSnackbar({ message: getLanguage().SNACKBAR_ERROR_PASSWORD_MATCH, color: "error" });
            return false;
        }

        return true;
    };

    return (
        <Box className="box" component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField fullWidth id="newUsername" label="New username" placeholder={user.username} name="newUsername" autoComplete="user" />
                </Grid>

                <Grid item xs={12}>
                    <TextField fullWidth id="newEmail" label="New email" placeholder={user.email} name="newEmail" autoComplete="email" />
                </Grid>

                <Grid item xs={12}>
                    <TextField fullWidth id="newPassword" label="New password" type="password" name="newPassword" autoComplete="new-password" />
                </Grid>

                <Grid item xs={12}>
                    <TextField fullWidth id="newPasswordConfirm" label="Confirm new password" type="password" name="newPasswordConfirm" autoComplete="new-password" />
                </Grid>

                <Grid item xs={12}>
                    <TextField required fullWidth name="password" label="Current password" type="password" id="password" autoComplete="password" />
                </Grid>
            </Grid>

            <CustomButton {...props} title={getLanguage().BUTTON_UPDATE_ACCOUNT} onClick={props.resetErrorBoundary} className="button" type="submit" fullWidth />
        </Box>
    );
}
