import { combineObjects } from "../validation/object";
import { setUniqueArrayState } from "../misc/utils";

/**
 * Return a new state for alerts.
 *
 * Parameters:
 * State (e.g. props.alerts.external.user)
 */
export const handleAddAlerts = (props, state, response) => {
    const setState = state.setData;
    const data = response.elements;

    // Format the data (there seems to be a discrepancy between the HTTP and websocket 'timestamp' fields).
    for (const alert of data) {
        alert.timestamp = parseFloat(alert.timestamp.toFixed(6));
    }

    setUniqueArrayState(setState, data, { keys: ["signalId", "timestamp"] });
};

/**
 * Return external and combined alerts by a key.
 * E.g. "user", "subscribed", etc.
 */
export const getCombined = (props, key) => {
    const external = props.alerts.external[key];
    const combined = props.alerts.combined[key];

    return combineObjects(external, combined);
};
