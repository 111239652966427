import * as React from "react";
import { Box, Grid, TextField } from "@mui/material";

import CustomButton from "../../../helper-components/input/CustomButton";

import { resetPassword } from "../../../helper-functions/api/authQueries";

import { getLanguage } from "../../../config/language/language";

export const PasswordResetForm = (props) => {
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        const body = {
            newPassword: data.get("password"),
            confirmPassword: data.get("confirmPassword"),
        };

        resetPassword(props, body, props.token);
    };

    return (
        <Box className="box" component="form" onSubmit={handleSubmit}>
            <TextField required name="password" label="Password" type="password" id="password" margin="normal" fullWidth />

            <TextField required name="confirmPassword" label="Confirm password" type="password" id="confirmPassword" margin="normal" fullWidth />

            <Grid container>
                <CustomButton {...props} title={getLanguage().BUTTON_RESET_PASSWORD} type="submit" fullWidth />
            </Grid>
        </Box>
    );
};
