import * as React from "react";
import Typography from "@mui/material/Typography";

import * as General from "../../config/constants/General.js";
import { getLanguage } from "../../config/language/language.js";

export default function Copyright() {
    return (
        <Typography className="copyright" variant="body2" color="text.secondary" align="center">
            {getLanguage().COPYRIGHT} {new Date().getFullYear()}
            {".\n"}
            {General.PAGE_VERSION}
            {"\n\n"}
            {getLanguage().DISCLAIMER}
        </Typography>
    );
}
