import * as React from "react";
import dayjs from "dayjs";

import { Box, Grid } from "@mui/material";

import CustomButton from "../../helper-components/input/CustomButton.js";
import CustomInput from "../../helper-components/input/CustomInput.js";
import AssetSelection from "../../markets/technical-analysis/signal-pages/components/AssetSelection.js";
import ConfirmationDialog from "../../helper-components/input/ConfirmationDialog.js";

import { validateRow } from "../../helper-functions/utils/validation/utils.js";
import { createUploadObject } from "../../helper-functions/utils/misc/upload.js";
import { getAssetByKey, getAssetIdsByStatus } from "../../helper-functions/utils/asset/asset.js";
import { createPortfolioTrade } from "../../helper-functions/api/portfolioQueries.js";
import { addFormToObject, setValue } from "../../helper-functions/utils/validation/object.js";
import { formatAssetFormDescription, getCategory } from "../../helper-functions/utils/asset/utils.js";

import { useDialog } from "../../helper-functions/hooks/useDialog.js";

import * as Enums from "../../config/constants/Enums.js";
import { getLanguage } from "../../config/language/language.js";

/**
 * A form to create a new asset.
 */
export default function PortfolioTradeAddForm(props) {
    const { portfolioId, portfolioAssets, portfolioTrades } = props.componentProps;

    const dialog = useDialog();

    const category = getCategory(Enums.PORTFOLIO_TRADE);
    const validation = category.validation;
    const isValid = category.isValid;

    // TODO: get the latest asset price from the API, set it automatically to the "price" field.
    const [trade, setTrade] = React.useState({ ...addFormToObject(JSON.parse(JSON.stringify(category.obj))), timestamp: dayjs(new Date()) });

    if (!portfolioId) return;

    console.warn("PortfolioTradeAddForm", trade);

    /**
     * Executed when typing something into an input field.
     */
    const onChange = (obj, key, value) => {
        const newTrade = { ...trade };
        setValue(newTrade, key, value);

        validateRow(obj, validation, newTrade);
        setTrade(newTrade);
    };

    const handleIconClick = (row, field, value) => {
        const disabledAssetIds = getAssetIdsByStatus(props, ["DISABLED"]);

        dialog.enqueueDialog({
            id: "trade-create-asset",
            title: "Select asset",
            message: `Please select an asset from the list.

            If you can't find the asset you are looking for, request an new asset to be added via the 'Assets' page.`,
            component: <AssetSelection callback={(confirm, selected) => handleIconClickConfirmation(confirm, row, selected)} />,
            componentProps: { selected: [getAssetByKey(props, "id", trade?._assetId)], exclude: disabledAssetIds, excludeStatuses: ["DISABLED"] },
        });
    };

    const handleIconClickConfirmation = (confirm, row, selected) => {
        dialog.closeDialog({ id: "trade-create-asset" }); // Manually close the dialog with a certain id.

        if (!confirm) return;

        // Set the human-readable field.
        const selectedAsset = selected[0] || {};
        row._asset = formatAssetFormDescription(selectedAsset); // Set the human-readable fields.

        onChange(row, "_assetId", selectedAsset.id);
    };

    /**
     * Executed when clicking on form button.
     */
    const handleSubmit = () => {
        if (!isValid(trade)) {
            props.showSnackbar({ message: getLanguage().SNACKBAR_ERROR_TRADE_INVALID, color: "error" });
            return;
        }

        const body = createUploadObject(trade, ["form", "id", "asset", "_asset", "_assetId"]);
        createPortfolioTrade({ ...props, portfolioAssets, portfolioTrades }, { portfolioId, assetId: trade._assetId, body });
    };

    return (
        <Box className="box">
            <Grid container spacing={2}>
                <ConfirmationDialog {...props} options={dialog} />

                <Grid item xs={12}>
                    <CustomInput field="_asset" obj={trade} onChange={onChange} onIconClick={handleIconClick} label={getLanguage().FORM_ASSET_LABEL} icon disabled />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomInput field="price" obj={trade} onChange={onChange} label="Asset price" />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomInput field="amount" obj={trade} onChange={onChange} label="Amount of shares" />
                </Grid>

                <Grid item xs={12}>
                    <CustomInput field="timestamp" obj={trade} onChange={onChange} label="Select date & time (DD/MM/YYYY HH:mm)" datetime />
                </Grid>
            </Grid>

            <CustomButton {...props} title={getLanguage().BUTTON_ADD_TRADE} onClick={handleSubmit} className="button" fullWidth />
        </Box>
    );
}
