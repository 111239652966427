import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from "@mui/material";

/**
 * Accordion component with a title and optional description.
 * Will close the previous component if clicked on another one.
 *
 * Required props:
 * values (incl. different key-value pairs)
 *
 * NB! Accordion summary is left-padded in order to compensate for the expand icon.
 */
export default function ControlledAccordion(props) {
    const values = props.values;
    const startClosed = props.startClosed;
    const padding = props?.padding || 0;

    const expand = props?.browser?.expand;
    const setExpand = props?.browser?.setExpand;

    const startExpanded = values.length === 1 && !startClosed ? "0" : false;

    const [expanded, setExpanded] = React.useState(startExpanded);

    React.useEffect(() => {
        setExpanded(startExpanded);
    }, [startExpanded]);

    React.useEffect(() => {
        if (typeof expand !== "boolean") return;

        setExpanded(expand ? "0" : false);
        setExpand(null);
    }, [expand]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const createComponent = (value, index) => {
        if (value.divider) return <Divider key={index} className="md-margin" />;

        return (
            <Accordion
                key={index}
                ref={props.customRef}
                className="accordion"
                expanded={expanded === String(index)}
                onChange={handleChange(String(index))}
                sx={{ border: value.borderColor ? `1px solid ${value.borderColor}` : undefined }}>
                <AccordionSummary className="accordion-summary" expandIcon={<ExpandMoreIcon />}>
                    {value.summary}
                </AccordionSummary>

                <AccordionDetails sx={{ p: 0, pl: padding, pr: padding }}>{value.details}</AccordionDetails>
            </Accordion>
        );
    };

    return <Box sx={{ m: 0.75 }}>{values.map((value, index) => createComponent(value, index))}</Box>;
}
