import React from "react";

import Chart from "./Chart";

import { isEmpty } from "../../helper-functions/utils/validation/object";

/**
 * A candlestick chart, visalizing the price of some asset over time.
 * Utilizes the underlying Chart component.
 */
export default function CandlestickChart(props) {
    const { data, options = {} } = props;

    return (
        <Chart
            {...props}
            options={{
                ...options,
                xAxis: {
                    ...options.xAxis,
                    data: data.map((item) => item.timestamp),
                },
                series: [
                    {
                        ...options.series[0],
                        data,
                    },
                ],
            }}
            show={!isEmpty(data)}
        />
    );
}
