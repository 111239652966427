import { Navigate, Route, Routes } from "react-router-dom";

// Misc. introduction.
import Home from "../../../home-page/Home";
import About from "../../../about-page/About";
import Welcome from "../../../welcome-page/Welcome";

// Market analysis.
import Asset from "../../../../markets/fundamental-analysis/asset-pages/Asset";
import Assets from "../../../../markets/fundamental-analysis/asset-pages/Assets";
import Screener from "../../../../markets/fundamental-analysis/asset-pages/Screener";

import Signals from "../../../../markets/technical-analysis/signal-pages/Signals";
import ExternalSignals from "../../../../markets/technical-analysis/signal-pages/ExternalSignals";
import CombinedSignals from "../../../../markets/technical-analysis/signal-pages/CombinedSignals";
import CommunitySignals from "../../../../markets/technical-analysis/signal-pages/CommunitySignals";

import Alerts from "../../../../markets/technical-analysis/alert-pages/Alerts";
import ExternalAlerts from "../../../../markets/technical-analysis/alert-pages/ExternalAlerts";
import CombinedAlerts from "../../../../markets/technical-analysis/alert-pages/CombinedAlerts";

// Portfolios.
import Portfolios from "../../../../portfolio/Portfolios";
import Portfolio from "../../../../portfolio/Portfolio";

// Investing tools.
import Resources from "../../../../tools/resources-page/Resources";
import Chatbot from "../../../../tools/chatbot-page/Chatbot";
import Posts from "../../../../tools/posts-page/Posts";

// User.
import Account from "../../../user-pages/account/Account";
import Settings from "../../../user-pages/settings/Settings";

// Auth.
import SignUp from "../../../auth-pages/SignUp";
import SignIn from "../../../auth-pages/SignIn";
import { PasswordReset } from "../../../auth-pages/PasswordReset";
import { EmailVerification } from "../../../auth-pages/EmailVerification";

// Other.
import Admin from "../../../user-pages/admin/Admin";
import Feedback from "../../../../helper-components/pages/Feedback";
import Snek from "../../../../helper-components/pages/Snek";

import NoMatch from "../../../../helper-components/pages/NoMatch";
import UnexpectedError from "../../../../helper-components/pages/UnexpectedError";
import UnderConstruction from "../../../../helper-components/pages/UnderConstruction";

import TermsAndConditions from "../../../legal-pages/TermsAndConditions";
import PrivacyPolicy from "../../../legal-pages/PrivacyPolicy";

import { isAdmin, isLoggedIn } from "../../../../helper-functions/utils/misc/utils";
import { checkWelcomePage } from "../../../../helper-functions/utils/page/utils";
import PostCreator from "../../../../tools/posts-page/PostCreator";
import PostViewer from "../../../../tools/posts-page/PostViewer";
import PostEditor from "../../../../tools/posts-page/PostEditor";

/**
 * Component responsible for our application's routing.
 * These components here are the only things that will change when the user clicks on another tab.
 */
export const PageRoutes = (props) => {
    const loggedIn = isLoggedIn(props);
    const admin = isAdmin(props);

    const redirectToWelcome = checkWelcomePage(props);

    return (
        <Routes>
            {/* Redirects */}
            <Route path="/" element={<Navigate replace to={redirectToWelcome ? "/welcome" : "/home"} />} />

            {/* Main pages */}
            <Route path="/home" element={<Home {...props} />} />
            <Route path="/about" element={<About {...props} />} />
            <Route path="/welcome" element={<Welcome {...props} />} />

            <Route path="/verify" element={<EmailVerification {...props} />} />

            <Route path="/terms-and-conditions" element={<TermsAndConditions {...props} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy {...props} />} />

            {/* Pages accessible to non-logged in users */}
            {!loggedIn && (
                <>
                    <Route path="/signup" element={<SignUp {...props} />} />
                    <Route path="/password-reset" element={<PasswordReset {...props} />} />

                    {/* Any unknown page redirects to the sign in page. */}
                    <Route path="*" element={<SignIn {...props} />} />
                </>
            )}

            {/* Pages accessible to logged in users */}
            {loggedIn && (
                <>
                    {/* Market analysis (TA + FA) */}
                    <Route path="/market-analysis" element={<UnderConstruction {...props} />} />
                    <Route path="/market-analysis/technical" element={<UnderConstruction {...props} />} />
                    <Route path="/market-analysis/fundamental" element={<UnderConstruction {...props} />} />

                    <Route path="/market-analysis/technical/signals" element={<Signals {...props} />} />
                    <Route path="/market-analysis/technical/signals/external" element={<ExternalSignals {...props} />} />
                    <Route path="/market-analysis/technical/signals/combined" element={<CombinedSignals {...props} />} />
                    <Route path="/market-analysis/technical/signals/community" element={<CommunitySignals {...props} />} />

                    <Route path="/market-analysis/technical/alerts" element={<Alerts {...props} />} />
                    <Route path="/market-analysis/technical/alerts/external" element={<ExternalAlerts {...props} />} />
                    <Route path="/market-analysis/technical/alerts/combined" element={<CombinedAlerts {...props} />} />

                    <Route path="/market-analysis/fundamental/screener" element={<Screener {...props} />} />
                    <Route path="/market-analysis/fundamental/assets" element={<Assets {...props} />} />
                    <Route path="/market-analysis/fundamental/assets/:id" element={<Asset {...props} />} />

                    {/* Portfolios */}
                    <Route path="/portfolio-tracker/portfolios" element={<Portfolios {...props} />} />
                    <Route path="/portfolio-tracker/portfolios/:id" element={<Portfolio {...props} />} />
                    <Route path="/portfolio-tracker/analysis" element={<UnderConstruction {...props} />} />

                    {/* Investment tools */}
                    <Route path="/investment-tools/chatbot" element={<Chatbot {...props} />} />
                    <Route path="/investment-tools/resources" element={<Resources {...props} />} />

                    <Route path="/investment-tools/posts" element={<Posts {...props} />} />
                    <Route path="/investment-tools/posts/create" element={<PostCreator {...props} />} />
                    <Route path="/investment-tools/posts/edit/:id" element={<PostEditor {...props} />} />
                    <Route path="/investment-tools/posts/view/:id" element={<PostViewer {...props} />} />

                    <Route path="/investment-tools/news-recap" element={<UnderConstruction {...props} />} />
                    <Route path="/investment-tools/daytrading-dashboard" element={<UnderConstruction {...props} />} />

                    {/* Account */}
                    <Route path="/user/account" element={<Account {...props} />} />
                    <Route path="/user/settings" element={<Settings {...props} />} />

                    {/* Misc. */}
                    <Route path="/feedback" element={<Feedback {...props} />} />

                    {/* Admin pages */}
                    {admin && (
                        <>
                            <Route path="/admin" element={<Admin {...props} />} />
                        </>
                    )}
                </>
            )}

            {/* Misc. */}
            <Route path="/snek" element={<Snek />} />
            <Route path="/error" element={<UnexpectedError {...props} />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
};
