import React from "react";

import { List, ListItemText, Typography, ListItemButton, Divider, Stack, Box, ListItemIcon, Checkbox } from "@mui/material";

import Search from "../../../../helper-components/input/Search";
import CustomButton from "../../../../helper-components/input/CustomButton";
import ComponentLoader from "../../../../helper-components/misc/ComponentLoader";

import { queryAssets } from "../../../../helper-functions/api/assetQueries";
import { isObjExistsInArray } from "../../../../helper-functions/utils/validation/object";
import { formatAssetListDescription } from "../../../../helper-functions/utils/asset/utils";
import { getAssetIdsByStatus } from "../../../../helper-functions/utils/asset/asset";

/**
 * A selection component to choose an asset from a list.
 * Includes a search bar and a list of assets alongside some text info about the currently selected asset.
 *
 * Optional props:
 * - multiselect: select multiple items
 * - exclude: hide certain items from the list
 */
export default function AssetSelection(props) {
    const { callback, multiselect = false } = props; // Because modals don't re-render if the props' state changes, use this callback.

    const { exclude: initalExcluded = [], excludeStatuses = [], selected: initalSelected = [] } = props.componentProps;
    const exclude = [...initalExcluded, ...getAssetIdsByStatus(props, excludeStatuses)]; // NB! Due to props not updating in modals, filter the assets here.

    const data = props.assets.data;

    const [selected, setSelected] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);

    const [searchText, setSearchText] = React.useState("");
    const [prevSearchText, setPrevSearchText] = React.useState("");

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        filterItems(searchText);
    }, [data]);

    // After a timeout, show the "no entries" text.
    React.useEffect(() => {
        if (filtered.length !== 0) return;
        if (filtered.length === 0) setLoading(true);

        const loop = setTimeout(() => setLoading(false), 1500);
        return () => clearTimeout(loop);
    }, [filtered]);

    /**
     * Set the filtered rows according to the search text.
     * Query assets from the server with this search param.
     *
     * TODO: if multi-search not possible, query each searchWords separately
     */
    const filterItems = (searchText) => {
        const searchWords = searchText.trim().toLowerCase().split(",");
        handleQuery(searchWords);

        const availableItems = data.filter((asset) => !exclude.includes(asset.id));

        const filteredItems = availableItems.filter((asset) => {
            const name = asset.name?.toLowerCase() ?? "";
            const ticker = asset.ticker?.toLowerCase() ?? "";
            const altTicker = asset.altTicker?.toLowerCase() ?? "";
            const exchangeName = asset.exchange?.name?.toLowerCase() ?? "";
            const currencySymbol = asset.currency?.symbol?.toLowerCase() ?? "";

            const nameFound = searchWords.some((word) => name.includes(word));
            const tickerFound = searchWords.some((word) => ticker.includes(word));
            const altTickerFound = searchWords.some((word) => altTicker.includes(word));
            const exchangeNameFound = searchWords.some((word) => exchangeName.includes(word));
            const currencySymbolFound = searchWords.some((word) => currencySymbol.includes(word));

            return tickerFound || altTickerFound || nameFound || exchangeNameFound || currencySymbolFound;
        });

        const sorted = getSortedFilteredItems(filteredItems);

        setSelected(searchText === prevSearchText ? initalSelected : []);
        setFiltered(sorted);
    };

    /**
     * Sort the filtered items according to the preferred currencies and exchanges.
     */
    const getSortedFilteredItems = (filteredItems) => {
        const preferredCurrencies = ["USD"];
        const preferredExchanges = ["NASDAQ", "BINANCE", "COINBASE PRO"];

        const sortedFilteredItems = filteredItems.sort((a, b) => {
            const aCurrency = a.currency?.symbol?.toUpperCase();
            const bCurrency = b.currency?.symbol?.toUpperCase();

            const aExchange = a.exchange?.name?.toUpperCase();
            const bExchange = b.exchange?.name?.toUpperCase();

            if (preferredCurrencies.includes(aCurrency) && !preferredCurrencies.includes(bCurrency)) return -1;
            if (!preferredCurrencies.includes(aCurrency) && preferredCurrencies.includes(bCurrency)) return 1;

            if (preferredExchanges.includes(aExchange) && !preferredExchanges.includes(bExchange)) return -1;
            if (!preferredExchanges.includes(aExchange) && preferredExchanges.includes(bExchange)) return 1;

            return 0;
        });

        return sortedFilteredItems;
    };

    /**
     * Query the assets from the server with the search parameter.
     * Doesn't query if the search text is empty or the search text has not changed.
     */
    const handleQuery = (searchWords) => {
        if ((searchWords && searchWords.length === 0) || searchWords.includes("")) return;
        if (searchText === prevSearchText) return; // Fix for infinite query loop.

        setPrevSearchText(searchText);
        queryAssets(props, { filter: { items: [], quickFilterValues: searchWords }, pageSize: 20, modifyPagination: false });
    };

    /**
     * Executed when clicking on a list element.
     * Handles both single and multi-select.
     */
    const handleSelectionChange = (asset) => {
        const isSelected = isObjExistsInArray(selected, asset);

        if (multiselect) {
            setSelected((prev) => {
                if (isSelected) {
                    return prev.filter((item) => item.id !== asset.id);
                } else {
                    return [...prev, asset];
                }
            });
        } else {
            setSelected(isSelected ? [] : [asset]);
        }
    };

    /**
     *  A list element with a checkbox.
     */
    const listElement = (asset, index) => {
        const isSelected = isObjExistsInArray(selected, asset);

        return (
            <ListItemButton key={index} selected={isSelected} onClick={() => handleSelectionChange(asset)} dense>
                <ListItemIcon>
                    <Checkbox edge="start" checked={isSelected} onChange={() => handleSelectionChange(asset)} disableRipple />
                </ListItemIcon>

                <ListItemText
                    primary={`${asset?.name || asset?.ticker}`}
                    secondary={formatAssetListDescription(asset)}
                    primaryTypographyProps={{ fontSize: "85%" }}
                    secondaryTypographyProps={{ fontSize: "75%" }}
                    className="dialog-selection-list-element"
                />
            </ListItemButton>
        );
    };

    /**
     *  Return info about the currently selected element(s).
     */
    const getCurrentSelectionInfo = () => {
        if (selected.length === 0) {
            return `Currently selected: N/A
            Ticker: N/A
            Exchange: N/A
            Asset type: N/A`;
        }

        if (selected.length === 1) {
            const selectedItem = selected[0];

            return `Currently selected: ${selectedItem?.name || "N/A"}
            Ticker: ${selectedItem?.ticker || "N/A"}
            Exchange: ${selectedItem?.exchange?.name || "N/A"}
            Asset type: ${selectedItem?.assetType || "N/A"}`;
        }

        return `Currently selected (${selected.length}):
        ${selected.map((asset) => asset.ticker).join(", ")}`;
    };

    return (
        <Box className="box">
            <Search {...props} callback={filterItems} searchText={searchText} setSearchText={setSearchText} fullWidth />

            {/* Scrollable list of selection elements */}
            <List className="dialog-selection-list hide-scrollbar">
                <ComponentLoader isVisible={filtered.length === 0} isEmpty={false} height="75px" width="100%" />

                {!loading && filtered.length === 0 ? <ListItemText className="center" primary={`No results found!`} /> : filtered.map((asset, index) => listElement(asset, index))}

                {filtered.length > 0 && (
                    <Typography className="center italic sm-font sm-margin">Reached the end of the list - use the search field above to query more.</Typography>
                )}
            </List>

            <Divider className="sm-margin" />

            {/* Text info about the currently selected element */}
            <Typography className="pre-line" variant="body2" color="textSecondary">
                {getCurrentSelectionInfo()}
            </Typography>

            <Stack direction="row" justifyContent="end" alignItems="end">
                <CustomButton {...props} title="Cancel" onClick={() => callback(false, selected)} color="error" variant="text" />
                <CustomButton {...props} title="Select" onClick={() => callback(true, selected)} color="info" variant="text" />
            </Stack>
        </Box>
    );
}
