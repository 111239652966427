import * as React from "react";

export function useLocalStorage(key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = React.useState(() => {
        if (typeof window === "undefined") {
            // Local storage API isn't available in server-rendering environments
            return initialValue;
        }

        try {
            const item = window.localStorage.getItem(key); // Get from local storage by key
            return item ? JSON.parse(item) : initialValue; // Parse stored json or if none return initialValue
        } catch (error) {
            console.log(error); // If error also return initialValue
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore); // Save state

            if (typeof window !== "undefined") {
                // Save to local storage
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            console.log(error); // A more advanced implementation would handle the error case
        }
    };

    return [storedValue, setValue];
}
