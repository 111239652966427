import * as React from "react";

import { Container, Divider, Grid, Typography } from "@mui/material";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import MediaCard from "../../../helper-components/misc/MediaCard.js";
import CustomForm from "../../../helper-components/form/CustomForm.js";
import CustomImage from "../../../helper-components/misc/CustomImage.js";
import CustomButton from "../../../helper-components/input/CustomButton.js";
import PaperFragment from "../../../helper-components/misc/PaperFragment.js";

import { getLanguage } from "../../../config/language/language.js";

/**
 * Overview page for signals.
 *
 * Features:
 * - Media cards with a brief overview of the different signals.
 * - Navigation to the different signal pages.
 * - How-to guides related to signals.
 */
export default function Alerts(props) {
    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().ALERTS_TITLE} subtitle={getLanguage().ALERTS_SUBTITLE} icon={<NotificationsActiveIcon />} />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <MediaCard
                        {...props}
                        title="External alerts"
                        summary="View alerts configured to arrive from TradingView."
                        bannerImage="/images/ai_generated/external_alerts.jpg"
                        bannerHeight={220}
                        contentHeight={100}
                        url="/market-analysis/technical/alerts/external"
                        fullClick
                        raised
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <MediaCard
                        {...props}
                        title="Combined alerts"
                        summary="View alerts which have been combined from multiple sources."
                        bannerImage="/images/ai_generated/combined_alerts.jpg"
                        bannerHeight={220}
                        contentHeight={100}
                        url="/market-analysis/technical/alerts/combined"
                        fullClick
                        raised
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider className="sm-margin" />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <CustomImage cover url={`${process.env.PUBLIC_URL}/images/ai_generated/community_alerts.jpg`} height="100%" componentHeight="275px" border="1px solid <theme>" />
                </Grid>

                <Grid container item xs={12} sm={7} alignContent="flex-start">
                    <Grid item xs={12}>
                        <PaperFragment
                            title={getLanguage().ALERTS_TEXT_1_TITLE}
                            value={<Typography className="pre-line">{getLanguage().ALERTS_TEXT_1_SUBTITLE}</Typography>}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomButton path="/market-analysis/technical/signals" title="Go to Signals page" fullWidth />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
