import { createCriteriaParameters, standardGetQuery, standardPostQuery } from "../utils/misc/api.js";
import { handleAddFeedback } from "../utils/misc/feedback.js";

import * as Paths from "../../config/constants/Paths.js";
import { getLanguage } from "../../config/language/language.js";

/**
 * Send user feedback to the server.
 *
 * Variables:
 * rating (1-5)
 * feedback (text)
 * sharePublicly (bool)
 */
export const sendFeedback = (props, body) => {
    standardPostQuery(props, Paths.USER_FEEDBACK_PATH, body, (response) => {
        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_FEEDBACK_SENT });
    });
};

/**
 * Request feedback from the server with pagination, sort and feedback.
 *
 * Options: page, pageSize, filter, sort
 */
export const queryFeedback = (props, options = {}) => {
    const path = Paths.ADMIN_FEEDBACK_PATH + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddFeedback(props, response.body);

        const states = props.feedback;
        if (options.page) states.setQueriedUntil(options.page);
        if (response.body.total) states.setRowCount(response.body.total);
    });
};
