// // // // //
// ENUMS
// Constants that are used throughout the application, in both frontend and backend.
// NB! Keep these same w/ backend!
// // // // //

// Response types.
export const ASSET = "ASSET";

export const PORTFOLIOS = "PORTFOLIOS";
export const PORTFOLIO = "PORTFOLIO";
export const PORTFOLIO_TRADE = "PORTFOLIO_TRADE";

export const EXTERNAL_ALERT = "EXTERNAL_ALERT";
export const EXTERNAL_SIGNAL = "EXTERNAL_SIGNAL";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const POSTS = "POSTS";

export const COMBINED_ALERT = "COMBINED_ALERT";
export const COMBINED_SIGNAL = "COMBINED_SIGNAL";
export const COMBINED_TICKER = "COMBINED_TICKER";

export const ADMIN_USERS = "ADMIN_USERS";

// Asset types.
export const ASSET_STOCK = "STOCK";
export const ASSET_CRYPTO = "CRYPTO";
export const ASSET_FOREX = "FOREX";
export const ASSET_OTHER = "OTHER";
export const ASSET_TYPES = [ASSET_STOCK, ASSET_CRYPTO, ASSET_FOREX, ASSET_OTHER];

// Order types.
export const ORDER_BUY = "BUY";
export const ORDER_SELL = "SELL";
export const ORDER_TYPES = [ORDER_BUY, ORDER_SELL];

// Visibility types.
export const SIGNAL_VISIBILITY_PRIVATE = "PRIVATE";
export const SIGNAL_VISIBILITY_PROTECTED = "PROTECTED";
export const SIGNAL_VISIBILITY_PUBLIC = "PUBLIC";
export const SIGNAL_VISIBILITY_TYPES = [SIGNAL_VISIBILITY_PUBLIC, SIGNAL_VISIBILITY_PROTECTED, SIGNAL_VISIBILITY_PRIVATE];

export const POST_VISIBILITY_PRIVATE = "PRIVATE";
export const POST_VISIBILITY_PROTECTED = "PROTECTED";
export const POST_VISIBILITY_PUBLIC = "PUBLIC";
export const POST_VISIBILITY_TYPES = [SIGNAL_VISIBILITY_PUBLIC, SIGNAL_VISIBILITY_PROTECTED, SIGNAL_VISIBILITY_PRIVATE];

// Permission types.
export const PERMISSION_OWNER = "OWNER";
export const PERMISSION_SUBSCRIBED = "SUBSCRIBED";
export const PERMISSION_PROTECTED = "PROTECTED";
export const PERMISSION_PUBLIC = "PUBLIC";

// Status types.
export const STATUS_ENABLED = "ENABLED";
export const STATUS_DISABLED = "DISABLED";
export const STATUS_DELETED = "DELETED";
export const STATUS_LOADING = "LOADING";
export const SIGNAL_STATUS_TYPES = [STATUS_ENABLED, STATUS_DISABLED];
export const ASSET_STATUS_TYPES = [STATUS_ENABLED, STATUS_DISABLED, STATUS_DELETED, STATUS_LOADING];

// Action types.
export const ACTION_ADD = "ADD";
export const ACTION_DELETE = "DELETE";
export const ACTION_UPDATE = "UPDATE";

// Condition types.
export const CONDITION_TIMEFRAME = "TIMEFRAME";
export const CONDITION_CHAIN = "CHAIN";
export const CONDITION_TYPES = [CONDITION_TIMEFRAME, CONDITION_CHAIN];

// Auth and privileges.
export const ROLE_USER = "ROLE_USER";
export const ROLE_ADMIN = "ROLE_ADMIN";

// World currency codes.
export const CURRENCY_CODES = ["CAD", "CNY", "EUR", "JPY", "CHF", "USD"];

// Market screener exchanges.
export const SCREENER_MARKETS = [
    { name: "Forex (physical currencies)", value: "forex", defaultColumn: "overview", defaultScreen: "general" },
    { name: "Digital assets (cryptocurrencies)", value: "crypto", defaultColumn: "overview", defaultScreen: "general" },
    { name: "USA (NASDAQ, NYSE, NYSE ARCA, OTC)", value: "america", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Canada (TSX, TSXV, CSE, NEO)", value: "canada", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Argentina (BYMA, BCBA)", value: "argentina", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Australia (ASX)", value: "australia", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Brazil (BMFBOVESPA)", value: "brazil", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Chile (BCS)", value: "chile", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "China (SSE)", value: "china", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Estonia (OMXTSE)", value: "estonia", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Finland (OMXHEX)", value: "finland", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Germany (FWB, SWB, XETR, BER, DUS, HAM, HAN, MUN, TRADEGATE)", value: "germany", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "India (BSE)", value: "india", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Israel (TASE)", value: "israel", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Poland (GPW, NEWCONNECT)", value: "poland", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "South Korea (KRX)", value: "korea", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Switzerland (SIX, BX)", value: "switzerland", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Taiwan (TPEX)", value: "taiwan", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "Turkey (BIST)", value: "turkey", defaultColumn: "overview", defaultScreen: "most_capitalized" },
    { name: "UAE (ADX)", value: "uae", defaultColumn: "overview", defaultScreen: "most_capitalized" },
];

export const TOUR_GUIDE_STEPS = [
    {
        target: ".tour-guide-1",
        content: `The app bar contains the main navigation menu on the left, which allows you to access all the features of the platform.`,
    },
    {
        target: ".tour-guide-2",
        content: `In addition, you can access your account page or notifications from the right side alongside changing the color theme.`,
    },
    {
        target: ".tour-guide-3",
        content: `The platform is divided into different sections, such as the dashboard, market analysis, portfolios, or investment tools. You can access them all from the sidebar.`,
    },
    {
        target: ".tour-guide-sidebar-MARKET_ANALYSIS",
        content: `The market analysis section provides you with an analysis of fundamentals and technicals to determine an asset's viability and trading opportunities.`,
    },
    {
        target: ".tour-guide-sidebar-MARKET_ANALYSIS",
        content: `Search for new assets using the 'Screener' page, or access some asset's detailed values from the 'Assets' page.`,
    },
    {
        target: ".tour-guide-sidebar-MARKET_ANALYSIS",
        content: `Using the 'Signals' page, you can create, combine, or subscribe to buy or sell signals in order to receive trade alerts.`,
    },
    {
        target: ".tour-guide-sidebar-PORTFOLIO_TRACKER",
        content: `The portfolio tracker section allows you to track your investments, view your portfolio's performance, and analyze your trades.`,
    },
    {
        target: ".tour-guide-sidebar-INVESTMENT_TOOLS",
        content: `The investment tools section provides you with various tools to help you make better investment decisions.`,
    },
    {
        target: ".tour-guide-sidebar-INVESTMENT_TOOLS",
        content: `Ask investing-related questions to the AI chatbot using the 'Chatbot' page.`,
    },
    {
        target: ".tour-guide-sidebar-INVESTMENT_TOOLS",
        content: `In addition, view educational content via the 'Education' page, or helpful resources from the 'Resources' page.`,
    },
    {
        target: ".tour-guide-4",
        content: `Access the feedback form or legal documents from the 'Other' section of the sidebar.`,
    },
    {
        target: ".tour-guide-1",
        content: `That was a quick overview of the platform. Happy investing! 🚀`,
    },
];
