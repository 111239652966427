import { isProtectedByType, isPublicByType, isExternalByType, isSubscribedByType, isOwnerByType } from "../asset/utils.js";
import { isAssetByPath, isAlertByPath, isSignalByPath, isUserByPath } from "../asset/utils.js";
import { isArray, isObject } from "../validation/object.js";

import * as Enums from "../../../config/constants/Enums.js";
import * as Paths from "../../../config/constants/Paths.js";

/**
 * Return the correct prop state depending on the path of response.
 * E.g. if the path is /api/user/markets/technical/signals/combined/protected, return props.signals.combined.protected
 *
 * NB! Don't try to deep copy - in cases like deletion, you'll encounter mind-bending bugs.
 */
export const getStateByPath = (props, path) => {
    // Assets.
    if (isAssetByPath(path)) {
        return props.assets;
    }

    // Users.
    if (isUserByPath(path)) {
        return props.users.users;
    }

    // Signals or alerts.
    let external;
    let combined;

    if (isSignalByPath(path)) {
        external = props.signals.external;
        combined = props.signals.combined;
    }

    if (isAlertByPath(path)) {
        external = props.alerts.external;
        combined = props.alerts.combined;
    }

    const state = isExternalByType(path) ? external : combined;

    if (isPublicByType(path)) {
        return state.public;
    } else if (isProtectedByType(path)) {
        return state.protected;
    } else if (isSubscribedByType(path)) {
        return state.subscribed;
    } else if (isOwnerByType(path)) {
        return state.owner;
    }

    console.error("[getStateByPath] No such type found:", path);
    return null;
};

export const isNotification = (props, responseType) => {
    return responseType === Enums.NOTIFICATIONS;
};

export const isPosts = (props, responseType) => {
    return responseType === Enums.POSTS;
};

/**
 * Correlate websocket responses with API Paths.
 * This is needed becasue we (currently) use API paths as keys in many state objects.
 *
 * TODO: any way to refactor?
 */
export const getCorrectPath = (props, responseType, permissionType) => {
    if (permissionType === Enums.PERMISSION_PUBLIC) {
        if (responseType === Enums.EXTERNAL_SIGNAL) {
            return Paths.API_EXTERNAL_SIGNAL_PUBLIC_PATH;
        }

        if (responseType === Enums.COMBINED_SIGNAL) {
            return Paths.API_COMBINED_SIGNAL_PUBLIC_PATH;
        }
    }

    if (permissionType === Enums.PERMISSION_PROTECTED) {
        if (responseType === Enums.EXTERNAL_SIGNAL) {
            return Paths.API_EXTERNAL_SIGNAL_PROTECTED_PATH;
        }

        if (responseType === Enums.COMBINED_SIGNAL) {
            return Paths.API_COMBINED_SIGNAL_PROTECTED_PATH;
        }
    }

    if (permissionType === Enums.PERMISSION_OWNER) {
        if (responseType === Enums.EXTERNAL_ALERT) {
            return Paths.API_EXTERNAL_ALERT_OWNER_PATH;
        } else if (responseType === Enums.EXTERNAL_SIGNAL) {
            return Paths.API_EXTERNAL_SIGNAL_OWNER_PATH;
        }

        if (responseType === Enums.COMBINED_ALERT) {
            return Paths.API_COMBINED_ALERT_OWNER_PATH;
        } else if (responseType === Enums.COMBINED_SIGNAL) {
            return Paths.API_COMBINED_SIGNAL_OWNER_PATH;
        }
    }

    if (permissionType === Enums.PERMISSION_SUBSCRIBED) {
        if (responseType === Enums.EXTERNAL_ALERT) {
            return Paths.API_EXTERNAL_ALERT_SUBSCRIBED_PATH;
        } else if (responseType === Enums.EXTERNAL_SIGNAL) {
            return Paths.API_EXTERNAL_SIGNAL_SUBSCRIBED_PATH;
        }

        if (responseType === Enums.COMBINED_ALERT) {
            return Paths.API_COMBINED_ALERT_SUBSCRIBED_PATH;
        } else if (responseType === Enums.COMBINED_SIGNAL) {
            return Paths.API_COMBINED_SIGNAL_SUBSCRIBED_PATH;
        }
    }

    if (responseType === Enums.ADMIN_USERS) {
        return Paths.API_USERS_ALL_PATH;
    }

    console.error("[getCorrectPath] No such type found:", responseType, permissionType);
    return "UNKN";
};

/**
 * Save the correct alert or signal prop depending on the type of response.
 */
export const setCorrectDataProp = (props, type, obj, options) => {
    console.log("Setting data prop for:", type, "as updatedObj:", obj);

    // Assets.
    if (isAssetByPath(type)) {
        return setState(props.setAssets, obj, options);
    }

    // User states.
    if (isUserByPath(type)) {
        return setState(props.users.setUsers, obj, options);
    }

    // Externalized asset states.
    let external;
    let combined;

    if (isSignalByPath(type)) {
        external = props.signals.external;
        combined = props.signals.combined;
    }

    if (isAlertByPath(type)) {
        external = props.alerts.external;
        combined = props.alerts.combined;
    }

    const state = isExternalByType(type) ? external : combined;

    if (isPublicByType(type)) {
        return setState(state.setPublic, obj, options);
    } else if (isProtectedByType(type)) {
        return setState(state.setProtected, obj, options);
    } else if (isSubscribedByType(type)) {
        return setState(state.setSubscribed, obj, options);
    } else if (isOwnerByType(type)) {
        return setState(state.setOwner, obj, options);
    }

    console.error("[setCorrectDataProp] No such type found:", type);
};

/**
 * Set the state in a way that doesn't accidentally overwrite the previous state.
 * Works with any type of object.
 *
 * Key:
 * Handle adding a new nested object.
 *
 * No key:
 * Handle arrays by concatenating them.
 * Handle objects by merging them.
 * Handle adding a new object to an array.
 * For other data types, replace the previous state with the new one.
 */
export const setState = (setter, obj, options = {}) => {
    const { overwrite = false, key } = options;

    console.warn(`Setting into state:`, obj);

    setter((prevState) => {
        if (overwrite) return obj;

        if (key) {
            return { ...prevState, [key]: obj };
        }

        if (isArray(prevState) && isArray(obj)) {
            return [...prevState, ...obj]; // [..., ..., ...]
        } else if (isObject(prevState) && isObject(obj)) {
            return { ...prevState, ...obj }; // { "1": {}, "2": {}, ...}
        } else if (isArray(prevState) && isObject(obj)) {
            return [...prevState, obj]; // [ {}, {}, ...]
        } else {
            return obj;
        }
    });
};

/**
 * Set the state in a way that doesn't accidentally overwrite the previous state for arrays.
 * Uses the 'keys' parameter to remove duplicates.
 *
 * Example of a state: [{ id: 1, name: "John" }, { id: 2, name: "Jane" }]
 */
export const setUniqueArrayState = (setData, data = [], options = {}) => {
    const { keys = ["id"], insertNewData = true } = options;

    console.warn(`Setting into state:`, data);

    setData((prev) => {
        let nextState = [...prev, ...data];

        // Only update data which was previously also in the state.
        if (!insertNewData) {
            const filteredNewData = data.filter((newData) => {
                return prev.some((oldData) => {
                    return keys.every((key) => oldData[key] === newData[key]);
                });
            });

            nextState = [...prev, ...filteredNewData];
        }

        const uniqueState = Array.from(new Map(nextState.map((el) => [keys.map((key) => el[key]).join("_"), el])).values());

        return uniqueState;
    });
};

/**
 * Set the state in a way that removes data from the previous state for arrays.
 * Uses the 'keys' parameter to find objects to remove.
 *
 * Example: [{ id: 1, timestamp: 123, name: "John" }, { id: 2, timestamp: 987, name: "Jane" }],
 * where data = [1, 2] or [{ timestamp: 123, name: "John" }]
 */
export const setDeleteArrayState = (setData, data = [], options = {}) => {
    const { keys = ["id"] } = options;

    console.warn(`Removing from state using keys ${keys.join(", ")}:`, data);

    if (keys.length === 1) {
        setData((prev) => {
            return prev.filter((item) => {
                return !data.includes(getNestedValue(item, keys[0]));
            });
        });
    } else {
        setData((prev) => {
            return prev.filter((item) => {
                return !data.some((dataItem) => {
                    return keys.every((key) => {
                        return getNestedValue(item, key) === getNestedValue(dataItem, key);
                    });
                });
            });
        });
    }
};

/**
 * Get nested values from an object using dot notation.
 * E.g. getNestedValue({ a: { b: { c: 1 } } }, "a.b.c") => 1
 */
const getNestedValue = (object, key) => {
    const keys = key.split(".");
    return keys.reduce((obj, k) => (obj && obj[k] !== undefined ? obj[k] : undefined), object);
};

/**
 * Set the state in a way that doesn't accidentally overwrite the previous state for objects.
 *
 * Example of a state: { id: 1, name: "John" }
 */
export const setUniqueObjState = (setState, data) => {
    console.warn(`Setting into state:`, data);

    setState((prev) => {
        return { ...prev, ...data };
    });
};

export const isAdmin = (props) => {
    return isLoggedIn(props) && (props?.role?.isAdmin ?? false);
};

export const isLoggedIn = (props) => {
    return isConnected(props) && (props?.server?.isLoggedIn ?? false);
};

export const isConnected = (props) => {
    return props?.server?.isConnectionEstablished ?? false;
};

export const isInteractedWithPage = (props) => {
    return props?.browser?.userInteractedWithPage ?? false;
};

export const isBrowser = (props) => {
    return props?.browser?.isBrowser ?? false;
};

/**
 * Disable logging to the console.
 */
export const disableConsoleLogging = () => {
    if (!window.console) window.console = {};

    const methods = ["log", "debug", "info"];

    for (let i = 0; i < methods.length; i++) {
        console[methods[i]] = function () {};
    }
};
