import * as React from "react";
import { Grid, Typography } from "@mui/material";

import MediaCard from "../../../../helper-components/misc/MediaCard";
import ComponentLoader from "../../../../helper-components/misc/ComponentLoader";

import { queryNewsSentiment } from "../../../../helper-functions/api/externalQueries";
import { getAlphaVantageTicker } from "../../../../helper-functions/utils/asset/ticker";
import { formatDate } from "../../../../helper-functions/utils/validation/object";

/**
 * News sentiment from AlphaVantage.
 * This external API returns live and historical market news & sentiment data derived from over 50 major financial news outlets around the world.
 *
 * Show the article title, image, and summary with a link to the source.
 */
export default function NewsSentiment(props) {
    const { ticker, assetType } = props;

    const formattedTicker = getAlphaVantageTicker(assetType, ticker);
    const tickerSentiment = props?.external?.newsSentiment?.[ticker];
    const feedExists = tickerSentiment?.result?.feed;
    const empty = "No news found!";

    // Query the sentiment every time the asset page changes (unless the asset has already been queried).
    React.useEffect(() => {
        if (!ticker || !assetType) return;

        queryNewsSentiment(props, formattedTicker, ticker);
    }, [ticker, assetType]);

    /**
     * Find the specified ticker sentiment from this structure:
     * [ {}, {}, ...]
     **/
    const findTickerSentimentFromArray = (array) => {
        for (let dict of array) {
            if (dict.ticker === formattedTicker) {
                return dict.ticker_sentiment_label;
            }
        }
    };

    return (
        <>
            <ComponentLoader isVisible={!tickerSentiment} width={props.loaderWidth} />

            {feedExists && (
                <Grid container spacing={1} className="container hide-scrollbar">
                    <Grid item xs={12} mb={1}>
                        <Typography variant="h7">{"Last updated: " + formatDate(tickerSentiment.timeUpdated)}</Typography>
                    </Grid>

                    {tickerSentiment.result.feed.map((article, index) => {
                        const specificTickerSentiment = findTickerSentimentFromArray(article.ticker_sentiment);
                        const sentiment = specificTickerSentiment ? specificTickerSentiment : article.overall_sentiment_label;

                        return (
                            <Grid item xs={true} sm={6} md={4} key={index}>
                                <MediaCard
                                    title={article.title}
                                    summary={article.summary}
                                    bannerImage={article.banner_image}
                                    sentiment={sentiment}
                                    url={article.url}
                                    bannerHeight="20vh"
                                    summaryClassName="sm-font"
                                    sourceClassName="sm-font"
                                    actionsClassName="xs-font"
                                    urlTitle="Read more"
                                    source={article.source}
                                    timePublished={formatDate(article.time_published)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            {tickerSentiment && !feedExists && <Typography variant="h7">{empty}</Typography>}
        </>
    );
}
