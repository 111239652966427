import { formatValue } from "../utils/asset/asset";

export const companyOverviewOptions = (dates = [], profit = [], expenses = [], income = []) => ({
    backgroundColor: "transparent",

    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow",
        },
    },
    legend: {
        data: ["Profit", "Expenses", "Income"],
    },
    grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
    },

    xAxis: [
        {
            type: "value",
            axisLabel: {
                formatter: (value) => formatValue(value, { abbreviate: true }),
            },
        },
    ],
    yAxis: [
        {
            type: "category",
            axisTick: {
                show: false,
            },
            data: dates,
        },
    ],

    series: [
        {
            name: "Profit",
            type: "bar",
            data: profit,
            label: {
                show: true,
                position: "inside",
                formatter: (value) => formatValue(value?.data, { abbreviate: true }),
            },
            emphasis: {
                focus: "series",
            },
            itemStyle: {
                color: "#37bb49",
            },
        },
        {
            name: "Income",
            type: "bar",
            stack: "Total",
            data: income,
            label: {
                show: true,
                formatter: (value) => formatValue(value?.data, { abbreviate: true }),
            },
            emphasis: {
                focus: "series",
            },
            itemStyle: {
                color: "#5695c9",
            },
        },
        {
            name: "Expenses",
            type: "bar",
            stack: "Total",
            data: expenses,
            label: {
                show: true,
                position: "left",
                formatter: (value) => formatValue(value?.data, { abbreviate: true }),
            },
            emphasis: {
                focus: "series",
            },
            itemStyle: {
                color: "#c23531",
            },
        },
    ],
});

// NB! Uncomment lines for two y-axis
export const cashAndDebtOptions = (dates = [], cash = [], debt = []) => ({
    backgroundColor: "transparent",

    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999",
            },
        },
    },
    toolbox: {
        feature: {
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
        },
    },
    legend: {
        data: ["Debt", "Cash"],
    },
    xAxis: [
        {
            type: "category",
            data: dates,
            axisPointer: {
                type: "shadow",
            },
        },
    ],
    yAxis: [
        {
            type: "value",
            name: "Debt",
            axisLabel: {
                formatter: (value) => formatValue(value, { abbreviate: true }),
            },
        },
        // {
        //     type: "value",
        //     name: "Cash",
        //     axisLabel: {
        //         formatter: (value) => formatValue(value, { abbreviate: true }),
        //     },
        // },
    ],
    series: [
        {
            name: "Debt",
            type: "bar",
            data: debt,
            tooltip: {
                valueFormatter: (value) => formatValue(value, { abbreviate: true }),
            },
            itemStyle: {
                color: "#c23531",
            },
        },
        {
            name: "Cash",
            type: "line",
            data: cash,
            // yAxisIndex: 1,
            tooltip: {
                valueFormatter: (value) => formatValue(value, { abbreviate: true }),
            },
            itemStyle: {
                color: "#37bb49",
            },
        },
    ],
});

export const sharesOptions = (dates = [], shares = [], compensation = [], issuance = [], buybacks = []) => ({
    backgroundColor: "transparent",

    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999",
            },
        },
    },
    toolbox: {
        feature: {
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
        },
    },
    legend: {
        data: ["Shares", "Stock-based compensation", "Issuance", "Buybacks"],
    },
    xAxis: [
        {
            type: "category",
            data: dates,
            axisPointer: {
                type: "shadow",
            },
        },
    ],
    yAxis: [
        {
            type: "value",
            name: "Shares",
            axisLabel: {
                formatter: (value) => formatValue(value, { abbreviate: true }),
            },
        },
        {
            type: "value",
            name: "Stock-based compensation",
            axisLabel: {
                formatter: (value) => formatValue(value, { abbreviate: true }),
            },
        },
        {
            type: "value",
            name: "Issuance",
            show: false,
            axisLabel: {
                formatter: (value) => formatValue(value, { abbreviate: true }),
            },
        },
        {
            type: "value",
            name: "Buybacks",
            show: false,
            axisLabel: {
                formatter: (value) => formatValue(value, { abbreviate: true }),
            },
        },
    ],
    series: [
        {
            name: "Shares",
            type: "line",
            data: shares,
            tooltip: {
                valueFormatter: (value) => formatValue(value, { abbreviate: true }),
            },
            itemStyle: {
                color: "#5695c9",
            },
        },
        {
            name: "Stock-based compensation",
            type: "bar",
            data: compensation,
            yAxisIndex: 1,
            tooltip: {
                valueFormatter: (value) => formatValue(value, { abbreviate: true }),
            },
            itemStyle: {
                color: "#c23531",
            },
        },
        {
            name: "Issuance",
            type: "bar",
            data: issuance,
            yAxisIndex: 1,
            tooltip: {
                valueFormatter: (value) => formatValue(value, { abbreviate: true }),
            },
            itemStyle: {
                color: "#d43abb",
            },
        },
        {
            name: "Buybacks",
            type: "bar",
            data: buybacks,
            yAxisIndex: 1,
            tooltip: {
                valueFormatter: (value) => formatValue(value, { abbreviate: true }),
            },
            itemStyle: {
                color: "#37bb49",
            },
        },
    ],
});
