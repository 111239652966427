import InfoIcon from "@mui/icons-material/Info";

import TickerImage from "../../helper-components/misc/TickerImage.js";
import CustomButton from "../../helper-components/input/CustomButton.js";

import { assetLogo, defaultStyle, time as defaultTime, name, noEditStyle } from "./shared.js";

import { getAssetByKey, getAssetValueByKeyUsingSignal } from "../utils/asset/asset.js";
import { getSignalById } from "../utils/asset/signal.js";
import { getJwtValueByKey } from "../utils/user/auth.js";
import { formatDate } from "../utils/validation/object.js";
import { isCombined } from "../utils/asset/utils.js";

// Render column functions.
const renderLogoBySignal = (props, params) => {
    const signal = getSignalById(props, params.row.signalId);
    const asset = getAssetByKey(props, "id", signal?.assetId);

    return <TickerImage asset={asset} height="100%" />;
};

const renderDescriptionBySignal = (props, params) => {
    return <CustomButton {...props} icon={<InfoIcon />} onClick={() => handleInfoIconClick(props, params)} />;
};

/**
 * Show pop-up dialog with alert and signal information.
 */
const handleInfoIconClick = (props, params) => {
    const signal = getSignalById(props, params?.row?.signalId);
    const asset = getAssetByKey(props, "id", signal?.assetId);
    if (!signal || !asset) return;

    props.dialog.enqueueDialog({
        title: `Alert fired on ${formatDate(params.row?.timestamp)} `,
        message: `This alert is related to signal: 
        Name: ${signal?.name}
        Description: ${signal?.description || "N/A"}
        Order: ${signal?.orderType}
        Type: ${isCombined(signal) ? "COMBINED" : "EXTERNAL"}

        The signal is related to asset: 
        ${asset?.ticker} (${asset?.name || "N/A"}).`,
    });
};

// Column style.
const time = defaultTime("timestamp");
const value = { ...defaultStyle, field: "close", headerName: "Price", description: "The value at which the alert was triggered", type: "number" };
const alertName = (props) => ({
    ...name,
    headerName: "Signal",
    description: "The name of the signal related to this alert",
    valueGetter: (params) => getSignalById(props, params.row.signalId)?.name,
});
const actions = (props) => ({
    ...defaultStyle,
    ...noEditStyle,
    field: "description",
    headerName: "Actions",
    description: "Buttons to view or modify items",
    flex: 0.05,
    valueGetter: (params) => getSignalById(props, params.row.signalId)?.description,
    renderCell: (params) => renderDescriptionBySignal(props, params),
});
const alertLogo = (props) => ({
    ...assetLogo(props),
    valueGetter: (params) => getAssetValueByKeyUsingSignal(props, params.row.signalId, "ticker"),
    renderCell: (params) => renderLogoBySignal(props, params),
});

// Table columns.
export const externalUserColumns = (props, paths) => [alertLogo(props), alertName(props), value, time, actions(props)];
export const externalSubscribedColumns = (props, paths) => [alertLogo(props), alertName(props), value, time, actions(props)];

export const combinedUserColumns = (props, paths) => [alertLogo(props), alertName(props), time, actions(props)];
export const combinedSubscribedColumns = (props, paths) => [alertLogo(props), alertName(props), time, actions(props)];

export const comboUserColumns = (props, paths) => [alertLogo(props), alertName(props), time, actions(props)];

// Style and other misc. functions.
export const rowStyle = {
    "& .row-theme-Buy": {
        bgcolor: "rgba(9, 236, 25, 0.08)",
    },
    "& .row-theme-Sell": {
        bgcolor: "rgba(237, 9, 9, 0.08)",
    },
};

export const rowTheme = (props, params) => {
    const signal = getSignalById(props, params.row.signalId);
    if (!signal) return;

    return buyOrSell(signal.orderType);
};

const buyOrSell = (orderType) => {
    let isBuy = orderType.toLowerCase().includes("buy");
    return isBuy ? "Buy" : "Sell";
};

export const isRowSelectable = (props, params) => {
    const signal = getSignalById(params.row.signalId);
    if (!signal) return true;

    if (props.type === "combined-public") {
        const userId = getJwtValueByKey(props, "id");
        return signal.ownerId !== userId;
    }

    return true;
};

export const rowSelection = (selected) => {
    return selected.map((row) => getRowId(row));
};

export const handleAlertSelection = (setSelected, selected, rows) => {
    setSelected(rows.filter((item) => selected.includes(getRowId(item))));
};

export const getRowId = (row) => {
    return `${row.signalId}_${row.timestamp}`;
};

// Default aggregate props of the table.
export const defaultTableOptions = {
    rowTheme,
    rowStyle,
    isRowSelectable,
};
