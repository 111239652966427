// Write any constants here.
const DASHBOARD_ASSET_NAME = "TSLA";

export const DASHBOARD_1_TITLE = "All combined signals";
export const DASHBOARD_1_SUBTITLE = "Amount of signals created by users on our site.";
export const DASHBOARD_1_SERIES_DATA_DATE_VALUE = [
    { date: new Date("2023-05-01"), value: [2800, 80] },
    { date: new Date("2023-04-01"), value: [1800, 70] },
    { date: new Date("2023-03-01"), value: [1200, 60] },
    { date: new Date("2023-02-01"), value: [500, 50] },
    { date: new Date("2023-01-01"), value: [200, 40] },
    { date: new Date("2022-12-01"), value: [110, 30] },
    { date: new Date("2022-11-01"), value: [40, 20] },
    { date: new Date("2022-10-01"), value: [0, 10] },
];

export const DASHBOARD_2_TITLE = `${DASHBOARD_ASSET_NAME} price history`;
export const DASHBOARD_2_SUBTITLE = `In-depth price history of an asset.`;

export const DASHBOARD_3_TITLE = `Combined signals limit`;
export const DASHBOARD_3_SUBTITLE = `Each user has a maximum number of active combined signals.`;
export const DASHBOARD_3_LEGEND = `Active`;
export const DASHBOARD_3_DATA = 10;
export const DASHBOARD_3_MAX = 20;

export const DASHBOARD_4_TITLE = "Users assets performance";
export const DASHBOARD_4_SUBTITLE = "Previous day's price fluctuations.";

export const DASHBOARD_5_TITLE = `IBM earnings per share`;
export const DASHBOARD_5_SUBTITLE = `In-depth EPS history of an asset.`;

export const DASHBOARD_6_TITLE = `Market liquidity`;
export const DASHBOARD_6_SUBTITLE = `The overall direction of the markets, calculated via liquidity and money supply issuance.`;
export const DASHBOARD_6_LEGEND = `Sentiment`;
export const DASHBOARD_6_DATA = 70;
export const DASHBOARD_6_MAX = 100;

export const STYLE_GUIDE_INTRO = `Below are the most commonly used components in our application.
We use Material UI (MUI) components alongside React, and use SASS for extra styling where necessary.`;

export const STYLE_GUIDE_TEXT = `This is some placeholder text.
        This is a new line with a tab.`;

export const STYLE_GUIDE_MEDIA_TEXT = `This is some placeholder text.
his is some placeholder text.
his is some placeholder text.
his is some placeholder text.
his is some placeholder text.
his is some placeholder text.
his is some placeholder text.
his is some placeholder text.
his is some placeholder text.
his is some placeholder text.`;

export const STYLE_GUIDE_TABLE_OBJ = {
    61: {
        id: 61,
        asset: "TSLA",
        orderType: "BUY",
        name: "30m",
        description: null,
        visibility: "PROTECTED",
        status: "DISABLED",
        lastAlert: "2023-08-20T15:26:56.663142Z",
    },
    64: {
        id: 64,
        asset: "BTCUSD",
        orderType: "BUY",
        name: "30m",
        description: null,
        visibility: null,
        status: "ENABLED",
        lastAlert: "2023-04-15T19:47:00.252620Z",
    },
};
