import * as React from "react";

import { Container, Grid, Stack } from "@mui/material";

import ErrorIcon from "@mui/icons-material/Error";

import PaperFragment from "../misc/PaperFragment";
import CustomButton from "../input/CustomButton";
import CustomImage from "../misc/CustomImage";
import CustomForm from "../form/CustomForm";
import PaperText from "../misc/PaperText";

import { getLanguage } from "../../config/language/language";

/**
 * Used with react-error-boundary - if an error occurs, show this page.
 */
export default function UnexpectedError(props) {
    const unknCode = 418;

    const location = props?.browser?.location;
    const code = location?.state?.status || unknCode;

    const errorMessage = props.error ? props.error.message : `No error message given.`;

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().UNEXPECTED_ERROR_TITLE.replace("<code>", code)} subtitle={getLanguage().UNEXPECTED_ERROR_SUBTITLE} icon={<ErrorIcon />} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <CustomImage cover url={`${process.env.PUBLIC_URL}/images/ai_generated/unexpected_error.jpg`} maxHeight="300px" border="1px solid <theme>" />
                </Grid>

                <Grid container item xs={12} sm={6} pt={1} alignContent="flex-start">
                    <Grid item xs={12}>
                        <PaperText center value={errorMessage} />
                    </Grid>

                    <Grid item xs={12}>
                        <PaperFragment
                            center
                            title="Return to app"
                            value={
                                <Stack direction="row">
                                    <CustomButton {...props} title="Refresh page" onClick={() => window.location.reload()} />
                                    <CustomButton
                                        {...props}
                                        title={getLanguage().UNEXPECTED_ERROR_BUTTON_1}
                                        onClick={props.resetErrorBoundary}
                                        path="/feedback"
                                        variant="outlined"
                                    />
                                </Stack>
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
