import React from "react";

import { TextField, Grid, InputAdornment, IconButton, Tooltip } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

import { getKeyFromQuery, removeQueryParametersFromUrl } from "../../helper-functions/utils/page/utils";

import CustomButton from "./CustomButton";

/**
 * Search component that includes a text field and a "Search" button.
 *
 * The component requires the following props:
 * searchText
 * setSearchText
 * callback
 */
export default function Search(props) {
    const searchText = props.searchText;
    const setSearchText = props.setSearchText;

    const callback = props.callback;
    const fullWidth = props.fullWidth || false;

    const existingSearch = getKeyFromQuery("search");
    const tooltipText = "Separate sentences or words by commas ( , )\nShows rows where at least one of the keywords are present.";

    const [clearSearch, setClearSearch] = React.useState(false);

    // Execute search when clicking on the "X" to clear the field.
    React.useEffect(() => {
        if (searchText === "" && clearSearch) {
            setClearSearch(false);
            handleSearch();
        }
    }, [searchText]);

    React.useEffect(() => {
        setTimeout(() => {
            if (existingSearch) {
                setSearchText(existingSearch);
                handleSearch(existingSearch);
            }
        }, 100);
    }, []);

    /**
     * Execute the callback with the search text as a parameter.
     */
    const handleSearch = (text) => {
        if (typeof text === "string") {
            callback(text);
        } else {
            callback(searchText);
        }
    };

    /**
     * Exectued when typing into search field.
     */
    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    /**
     * Exectued when clicking on "X".
     */
    const handleClearSearch = () => {
        removeQueryParametersFromUrl(props);

        setClearSearch(true);
        setSearchText("");
    };

    /**
     * Exectued when pressing "Enter".
     */
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
                <Tooltip title={<div className="pre-line">{tooltipText}</div>} placement="top" arrow>
                    <TextField
                        label="Filter"
                        value={searchText}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        variant="outlined"
                        size="small"
                        fullWidth={fullWidth}
                        className="search-text-field"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {searchText && (
                                        <IconButton edge="end" onClick={handleClearSearch}>
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Tooltip>
            </Grid>

            <Grid item xs={12} md={4}>
                <CustomButton {...props} title="Search" onClick={handleSearch} variant="outlined" fullWidth={fullWidth} />
            </Grid>
        </Grid>
    );
}
