import * as React from "react";

import { Avatar, Paper, Box, Grid, Typography } from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import SignInForm from "./components/SignInForm.js";
import CustomImage from "../../helper-components/misc/CustomImage.js";

import { getLanguage } from "../../config/language/language.js";

/**
 * Sign in page.
 */
export default function SignIn(props) {
    return (
        <Grid container>
            <Grid item xs={true} sm={5} md={7}>
                <CustomImage id="signInBackground" cover transparent height="100%" componentHeight="100%" p={0} m={0} url={`${process.env.PUBLIC_URL}/images/stock_photos/stock_market.png`} />
            </Grid>

            <Grid item xs={12} sm={7} md={5} component={Paper} elevation={6} square>
                <Box className="form-box">
                    <Avatar className="avatar">
                        <LockOutlinedIcon />
                    </Avatar>

                    <Typography component="h1" variant="h5">
                        {getLanguage().BUTTON_SIGNIN}
                    </Typography>

                    <SignInForm {...props} />
                </Box>
            </Grid>
        </Grid>
    );
}
