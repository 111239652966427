import { convertToDate, formatDate } from "../utils/validation/object";
import { defaultStyle } from "./shared";

const id = { ...defaultStyle, field: "id", headerName: "Id", type: "number" };
const rating = { ...defaultStyle, field: "rating", headerName: "Rating", type: "number", flex: 0.2 };
const comment = { ...defaultStyle, field: "comment", headerName: "Comment", flex: 0.3 };
const username = {
    ...defaultStyle,
    field: "username",
    headerName: "Created by",
    flex: 0.2,
    valueGetter: (params) => params.row?.user?.username,
};
const timeAtCreation = {
    ...defaultStyle,
    field: "createdOn",
    type: "date",
    headerName: "Created on",
    description: "Converted to local time.",
    width: 200,
    minWidth: 50,
    flex: 0.2,
    sortable: true,
    valueGetter: (params) => convertToDate(params.row.createdOn),
    valueFormatter: (params) => formatDate(params.value),
};

export const columns = (props) => [id, rating, comment, username, timeAtCreation];

export const isRowSelectable = (props, params) => {
    return true;
};

// Default aggregate props of the table.
export const defaultTableOptions = {
    isRowSelectable,
    checkboxSelection: true,
};
