import { Stack } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import ReviewsIcon from "@mui/icons-material/Reviews";
import RateReviewIcon from "@mui/icons-material/RateReview";

import CustomButton from "../../helper-components/input/CustomButton.js";

import { assetLogo, defaultStyle, noEditStyle, description, signals, time as defaultTime, name, type, visibility, rating } from "./shared.js";
import { getJwtValueByKey } from "../utils/user/auth.js";

import * as Enums from "../../config/constants/Enums.js";

// Combined column render functions.
const renderSubscribedActions = (props, params) => {
    return (
        <Stack direction="row" alignItems="center">
            <CustomButton {...props} tooltip="View info" icon={<InfoIcon />} onClick={() => console.warn("TODO")} />
            <CustomButton {...props} tooltip="Write review" icon={<RateReviewIcon />} onClick={() => console.warn("TODO")} />
        </Stack>
    );
};

const renderCommunityActions = (props, params) => {
    return (
        <Stack direction="row" alignItems="center">
            <CustomButton {...props} icon={<InfoIcon />} tooltip="View info" onClick={() => console.warn("TODO")} />
            <CustomButton {...props} icon={<ReviewsIcon />} tooltip="View reviews" onClick={() => console.warn("TODO")} />
        </Stack>
    );
};

// Column style.
const time = defaultTime("lastAlert");

const subscribedActions = (props) => ({
    ...defaultStyle,
    ...noEditStyle,
    field: "actions",
    headerName: "Actions",
    description: "Rate and comment signals",
    renderCell: (params) => renderSubscribedActions(props, params),
});
const communityActions = (props) => ({
    ...defaultStyle,
    ...noEditStyle,
    field: "actions",
    headerName: "Actions",
    description: "View signal comments",
    minWidth: 100,
    renderCell: (params) => renderCommunityActions(props, params),
});

// Table columns.
export const externalUserColumns = (props) => [assetLogo(props), name, description, time, visibility];
export const externalSubscribedColumns = (props) => [assetLogo(props), name, description, time, visibility, subscribedActions(props)];

export const combinedUserColumns = (props) => [assetLogo(props), name, description, signals, time, visibility];
export const combinedSubscribedColumns = (props) => [assetLogo(props), name, description, signals, time, visibility, subscribedActions(props)];

export const comboUserColumns = (props) => [assetLogo(props), name, description, time, type, visibility];
export const comboPublicColumns = (props) => [assetLogo(props), name, description, type, visibility, rating, communityActions(props)];

// Style and other misc. functions.
export const rowStyle = {
    "& .row-theme-Buy": {
        bgcolor: "rgba(9, 236, 25, 0.08)",
    },
    "& .row-theme-Sell": {
        bgcolor: "rgba(237, 9, 9, 0.08)",
    },
    "& .row-theme-Buy-Disabled": {
        bgcolor: "rgba(9, 236, 25, 0.08)",
        filter: "grayscale(40%)",
        opacity: "0.75",
    },
    "& .row-theme-Sell-Disabled": {
        bgcolor: "rgba(237, 9, 9, 0.08)",
        filter: "grayscale(40%)",
        opacity: "0.75",
    },
};

export const rowTheme = (props, params) => {
    const orderType = buyOrSell(params.row.orderType);

    if (params.row.status === Enums.STATUS_DISABLED) {
        return `${orderType}-Disabled`;
    }

    return orderType;
};

const buyOrSell = (orderType) => {
    let isBuy = orderType.toLowerCase().includes("buy");
    return isBuy ? "Buy" : "Sell";
};

/**
 * 1) Disable the row selection for community signals if...
 * 1.1) The user is the owner of the signal.
 * 1.2) The user is already subscribed to the signal.
 */
export const isRowSelectable = (props, params) => {
    if (props.type === "combined-public") {
        // 1.1)
        const userId = getJwtValueByKey(props, "id");
        if (params.row.ownerId === userId) return false;

        // 1.2)
        const externalSignals = props.signals.external;
        const combinedSignals = props.signals.combined;

        const { subscribed: externalSubscribed } = externalSignals;
        const { subscribed: combinedSubscribed } = combinedSignals;

        if (externalSubscribed.data.find((signal) => signal.id === params.row.id)) return false;
        if (combinedSubscribed.data.find((signal) => signal.id === params.row.id)) return false;
    }

    return true;
};

// Default aggregate props of the table.
export const defaultTableOptions = {
    rowTheme,
    rowStyle,
    isRowSelectable: isRowSelectable,
};
