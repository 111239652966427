import * as React from "react";

import { Box, TextField, Grid, FormControlLabel, Checkbox } from "@mui/material";

import CustomButton from "../../../helper-components/input/CustomButton";

import { resendVerificationEmail, signIn } from "../../../helper-functions/api/authQueries";
import { focusFirstIfEmptyOtherwiseSecond, inputBackgroundUnblur } from "../../../helper-functions/utils/page/utils";

import { getLanguage } from "../../../config/language/language";
import { useState } from "react";

/**
 * Sign in form.
 *
 * Fields:
 * username
 * password
 */
export default function SignInForm(props) {
    const username = props.user.username || "";
    const [lastRequestUsername, setLastRequestUsername] = useState("");
    const [emailVerified, setEmailVerified] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        const body = {
            username: data.get("username"),
            password: data.get("password"),
            remember: data.get("remember") === "on", // Convert to boolean
        };

        signIn({ ...props, setLastRequestUsername, setEmailVerified }, body);
    };

    React.useEffect(() => {
        inputBackgroundUnblur(5, 1.2, "password", "signInBackground");
        focusFirstIfEmptyOtherwiseSecond("username", "password");
    }, []);

    return (
        <Box className="auth-box" component="form" onSubmit={handleSubmit} id="signinForm">
            <TextField defaultValue={username} required name="username" label="Username" id="username" autoComplete="username" margin="normal" fullWidth />

            <TextField required name="password" label="Password" type="password" id="password" autoComplete="current-password" margin="normal" fullWidth />

            <FormControlLabel control={<Checkbox name="remember" color="primary" id="remember" />} label="Remember me" />

            <Grid container>
                <Grid item xs={12}>
                    <CustomButton {...props} title={getLanguage().BUTTON_SIGNIN} type="submit" fullWidth />
                </Grid>

                {lastRequestUsername !== "" && !emailVerified && (
                    <Grid item xs={12}>
                        <CustomButton
                            {...props}
                            title={getLanguage().BUTTON_RESEND_VERIFICATION}
                            variant="outlined"
                            onClick={() => {
                                resendVerificationEmail(props, lastRequestUsername);
                            }}
                            fullWidth
                        />
                    </Grid>
                )}
            </Grid>

            <Grid container>
                <Grid item xs>
                    <CustomButton {...props} title={getLanguage().FORGOT_PASSWORD} path="/password-reset" variant="text" />
                </Grid>

                <Grid item>
                    <CustomButton {...props} title={getLanguage().NO_ACCOUNT} path="/signup" variant="text" />
                </Grid>
            </Grid>
        </Box>
    );
}
