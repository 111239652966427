import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router";

import { Grid, Container } from "@mui/material";

import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import PieChart from "../helper-components/chart/PieChart";
import LineChart from "../helper-components/chart/LineChart";
import TextChart from "../helper-components/chart/TextChart";
import BarChart from "../helper-components/chart/BarChart";
import CandlestickChart from "../helper-components/chart/CandlestickChart";

import CustomForm from "../helper-components/form/CustomForm";
import StyledTable from "../helper-components/table/StyledTable";
import StackedHeader from "../helper-components/misc/StackedHeader";
import PaperFragment from "../helper-components/misc/PaperFragment";

import PortfolioTradeAddForm from "./components/PortfolioTradeAddForm";
import PortfolioUpdateForm from "./components/PortfolioUpdateForm";

import AssetSelection from "../markets/technical-analysis/signal-pages/components/AssetSelection";

import { queryPortfolio, queryPortfolioAssets, queryPortfolioTrades } from "../helper-functions/api/portfolioQueries";
import { createPortfolioAsset, deletePortfolioAssets, deletePortfolioTrades } from "../helper-functions/api/portfolioQueries";

import { isEmpty } from "../helper-functions/utils/validation/object";
import { defaultInitialState } from "../helper-functions/table/utils";
import { useEndpointStatesWithPagination } from "../helper-functions/hooks/states/useEndpointStatesWithPagination";
import { assetColumns, defaultTableOptions, tradeColumns, tradeRowStyle, tradeRowTheme } from "../helper-functions/table/portfolio";
import { assetPerformanceOptions, diversificationOptions, historyCandlestickChartOptions, historyLineChartOptions } from "../helper-functions/chart/portfolio";

import { getFormattedHistoryAdvanced, getFormattedHistorySimple } from "../helper-functions/utils/misc/portfolio";
import { getFormattedDistribution, getFormattedPerformance, getFormattedValue } from "../helper-functions/utils/misc/portfolio";

import { getLanguage } from "../config/language/language";
import { getAssetIdsByStatus } from "../helper-functions/utils/asset/asset";

/**
 * Portfolio page.
 *
 * Features:
 * - Form to update the portfolio's name.
 * - Pie chart of the diversification of the portfolio.
 * - Line chart of the portfolio's history.
 * - Table of assets in the portfolio.
 * - Table of trades in the portfolio.
 */
export default function Portfolio(props) {
    const { id: portfolioId } = useParams();

    const portfolio = useEndpointStatesWithPagination(`Portfolio #${portfolioId}`, { logData: true, initialState: {}, initialSorting: { field: "updatedOn" }, pageSize: 10 });
    const portfolioAssets = useEndpointStatesWithPagination(`Portfolio #${portfolioId} assets`, { logData: true, initialSorting: { field: "createdOn" }, pageSize: 10 });
    const portfolioTrades = useEndpointStatesWithPagination(`Portfolio #${portfolioId} trades`, { logData: true, initialSorting: { field: "timestamp" }, pageSize: 10 });

    const { data: portfolioData = {} } = portfolio;
    const { data: portfolioAssetData = [] } = portfolioAssets;

    const { value = 0 } = portfolioData;
    const simplified = true;

    // Use these states to re-render the charts when the screen size changes.
    const screenWidth = props.browser.screenWidth;
    const openAppDrawer = props.sidebar.openAppDrawer;

    const [formattedValueData, setFormattedValueData] = React.useState({});
    const [formattedDistributionData, setFormattedDistributionData] = React.useState({});
    const [formattedHistorySimpleData, setFormattedHistorySimpleData] = React.useState({});
    const [formattedHistoryAdvancedData, setFormattedHistoryAdvancedData] = React.useState({});
    const [formattedPerformanceData, setFormattedPerformanceData] = React.useState([]);

    /**
     * Used in other components to query the portfolio data again.
     */
    const refreshPortfolio = () => {
        queryPortfolio({ ...props, portfolio }, { portfolioId });
    };

    // Fetch the data related to the portfolio.
    useEffect(() => {
        queryPortfolioTrades({ ...props, portfolioTrades }, { portfolioId });
    }, []);

    // Format the data for the charts.
    useEffect(() => {
        setFormattedValueData(getFormattedValue(portfolioData));
        setFormattedDistributionData(getFormattedDistribution(portfolioData));
        setFormattedHistorySimpleData(getFormattedHistorySimple(portfolioData));
        setFormattedHistoryAdvancedData(getFormattedHistoryAdvanced(portfolioData));
    }, [portfolioData]);

    useEffect(() => {
        setFormattedPerformanceData(getFormattedPerformance(portfolioAssetData));
    }, [portfolioAssetData]);

    useEffect(() => {
        queryPortfolioAssets({ ...props, portfolioAssets }, { portfolioId });
        refreshPortfolio();
    }, [portfolioTrades.data]);

    /**
     * Show a dialog for creating a new portfolio.
     */
    const showAssetSelectionDialog = () => {
        const assetIdsInPortfolio = portfolioAssetData.map((item) => item.asset.id);
        const disabledAssetIds = getAssetIdsByStatus(props, ["DISABLED"]);

        props.dialog.enqueueDialog({
            id: "portfolio-asset",
            title: "Select asset(s)",
            message: `Please select assets to add to the portfolio.

            If you can't find the asset you are looking for, request an new asset to be added via the 'Assets' page.`,
            component: <AssetSelection callback={(confirm, selected) => handleAssetSelection(confirm, selected)} multiselect />,
            componentProps: { exclude: [...assetIdsInPortfolio, ...disabledAssetIds], excludeStatuses: ["DISABLED"] },
        });
    };

    /**
     * Handle the adding of assets to the portfolio.
     */
    const handleAssetSelection = (confirm, selected) => {
        props.dialog.closeDialog({ id: "portfolio-asset" }); // Manually close the dialog with a certain id.

        if (!confirm || isEmpty(selected)) return;

        const assetIds = selected.map((asset) => asset.id);
        createPortfolioAsset({ ...props, portfolioAssets }, { portfolioId, body: { assetIds } });
    };

    /**
     * Show a dialog for deleting selected portfolios.
     */
    const showAssetDeleteDialog = () => {
        props.dialog.enqueueDialog({
            title: "Delete asset(s)",
            message: `Are you sure you want to delete the selected asset(s)?
            Any trades related to those assets will be deleted as well.`,
            actionTitle: "Delete",
            actionColor: "error",
            callback: (confirm) => handleAssetDelete(confirm),
        });
    };

    /**
     * Handle the deletion of selected portfolios.
     */
    const handleAssetDelete = (confirm) => {
        if (!confirm) return;

        const assetIds = portfolioAssets.selected.map((item) => item.asset.id);
        deletePortfolioAssets({ ...props, portfolioAssets, portfolioTrades }, { portfolioId, assetIds, body: { assetIds } });
    };

    /**
     * Show a dialog for creating a new portfolio.
     */
    const showTradeCreationDialog = () => {
        props.dialog.enqueueDialog({
            id: "portfolio-trade-create",
            title: "Create trade",
            message: `Add a new trade to the portfolio.
    
            Insert the asset and the amount of shares you bought or sold.`,
            component: <PortfolioTradeAddForm />,
            componentProps: { portfolioId, portfolioAssets, portfolioTrades },
        });
    };

    /**
     * Show a dialog for deleting selected trades.
     */
    const showTradeDeleteDialog = () => {
        props.dialog.enqueueDialog({
            title: "Delete trade(s)",
            message: `Are you sure you want to delete the selected trade(s)?
            This action will be final.`,
            actionTitle: "Delete",
            actionColor: "error",
            callback: (confirm) => handleTradeDelete(confirm),
        });
    };

    /**
     * Handle the deletion of selected portfolios.
     */
    const handleTradeDelete = (confirm) => {
        if (!confirm) return;

        const tradeIds = portfolioTrades.selected.map((item) => item.id);
        deletePortfolioTrades({ ...props, portfolioTrades }, { portfolioId, tradeIds, body: { ids: tradeIds } });
    };

    const currentValueChart = useMemo(() => <TextChart data={formattedValueData} />, [formattedValueData, openAppDrawer, screenWidth]);
    const diversificationChart = useMemo(
        () => <PieChart {...props} options={diversificationOptions} data={formattedDistributionData} />,
        [formattedDistributionData, diversificationOptions, openAppDrawer, screenWidth]
    );
    const assetPerformanceChart = useMemo(
        () => <BarChart {...props} options={assetPerformanceOptions} data={formattedPerformanceData} />,
        [formattedPerformanceData, assetPerformanceOptions, openAppDrawer, screenWidth]
    );
    const historyChartSimple = useMemo(
        () => <LineChart {...props} options={historyLineChartOptions} data={formattedHistorySimpleData} />,
        [formattedHistorySimpleData, historyLineChartOptions, openAppDrawer, screenWidth]
    );
    const historyChartAdvanced = useMemo(
        () => <CandlestickChart {...props} options={historyCandlestickChartOptions} data={formattedHistoryAdvancedData} />,
        [formattedHistoryAdvancedData, historyLineChartOptions, openAppDrawer, screenWidth]
    );

    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().PORTFOLIO_TITLE} subtitle={getLanguage().PORTFOLIO_SUBTITLE} icon={<CasesRoundedIcon />} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <PaperFragment {...props} value={<PortfolioUpdateForm {...props} selectedPortfolio={portfolio} refreshPortfolio={refreshPortfolio} />} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <PaperFragment {...props} value={currentValueChart} />
                </Grid>

                {value > 0 && (
                    <Grid item xs={12} md={6}>
                        <PaperFragment {...props} height="350px" value={diversificationChart} />
                    </Grid>
                )}

                {value > 0 && (
                    <Grid item xs={12} md={6}>
                        <PaperFragment center height="350px" value={assetPerformanceChart} />
                    </Grid>
                )}

                {simplified ? (
                    <Grid item xs={12}>
                        <PaperFragment {...props} height="350px" value={historyChartSimple} />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <PaperFragment {...props} height="350px" value={historyChartAdvanced} />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <PaperFragment
                        title={
                            <StackedHeader
                                {...props}
                                title="Portfolio assets"
                                subtitle="View all assets in the portfolio."
                                icons={[
                                    {
                                        icon: <DeleteIcon />,
                                        tooltip: "Delete selected assets",
                                        disabled: portfolioAssets.selected.length < 1,
                                        onClick: showAssetDeleteDialog,
                                    },
                                ]}
                                buttons={[
                                    {
                                        icon: <AddIcon />,
                                        title: "Add asset to portfolio",
                                        onClick: showAssetSelectionDialog,
                                    },
                                ]}
                            />
                        }
                        value={
                            <StyledTable
                                {...props}
                                {...defaultTableOptions}
                                states={portfolioAssets}
                                columns={assetColumns({ ...props, portfolioAssets })}
                                query={(options) => queryPortfolioAssets({ ...props, portfolioAssets }, { ...options, portfolioId })}
                                empty={getLanguage().NO_ASSETS_FOUND}
                                initialState={defaultInitialState("ticker")}
                            />
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <PaperFragment
                        title={
                            <StackedHeader
                                {...props}
                                title="Portfolio trades"
                                subtitle="View all trades in the portfolio."
                                icons={[
                                    {
                                        icon: <DeleteIcon />,
                                        tooltip: "Delete selected trades",
                                        disabled: portfolioTrades.selected.length < 1,
                                        onClick: showTradeDeleteDialog,
                                    },
                                ]}
                                buttons={[
                                    {
                                        icon: <AddIcon />,
                                        title: "Add trade to portfolio",
                                        onClick: showTradeCreationDialog,
                                    },
                                ]}
                            />
                        }
                        value={
                            <StyledTable
                                {...props}
                                {...defaultTableOptions}
                                rowTheme={tradeRowTheme}
                                rowStyle={tradeRowStyle}
                                states={portfolioTrades}
                                columns={tradeColumns({ ...props, portfolioTrades })}
                                query={(options) => queryPortfolioTrades({ ...props, portfolioTrades }, { ...options, portfolioId })}
                                empty={getLanguage().NO_TRADES_FOUND}
                                initialState={defaultInitialState("ticker")}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
