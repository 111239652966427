import * as React from "react";

import { Grid } from "@mui/material";

import TextTable from "../../../../helper-components/table/TextTable.js";
import PaperFragment from "../../../../helper-components/misc/PaperFragment.js";
import StackedHeader from "../../../../helper-components/misc/StackedHeader.js";

import CustomInput from "../../../../helper-components/input/CustomInput.js";

import { formatDate, setValue } from "../../../../helper-functions/utils/validation/object.js";
import { formatValue } from "../../../../helper-functions/utils/asset/asset.js";

/**
 * An overview page for an asset.
 * Only for stocks.
 *
 * Includes:
 * - a widget showing the recent price action
 * - a description of the asset
 * - some basic info about the asset
 */
export default function Financials(props) {
    const { asset = {}, cashFlow = {}, balanceSheet = {}, income = {} } = props;

    const maxValues = 4;

    const [statement, setStatement] = React.useState({ statement: "income" });
    const [selectedType, setSelectedType] = React.useState("quarterly");

    const handleSelectedType = (type) => setSelectedType(type);

    const getRawValues = (data = {}, key, type = "quarterly") => {
        const keys = key.split(".");
        const value = data?.[type]?.map((statement) => {
            let result = statement;

            for (const k of keys) {
                result = result?.[k];
            }

            return result;
        });

        return value?.slice(0, maxValues) || [];
    };

    const formatStandardValues = (rawValues = []) => rawValues?.map((value) => formatValue(value, { abbreviate: true }));
    const formatCurrencyValues = (rawValues = []) => rawValues?.map((value) => formatValue(value, { currency: asset?.currency?.symbol, abbreviate: true }));
    const formatDateValues = (rawValues = []) => rawValues?.map((value) => formatDate(value, { onlyDate: true }));

    const formattedIncome = (type = "quarterly") => ({
        "Fiscal date": {
            tooltip: `The date of the report.`,
            value: formatDateValues(getRawValues(income, "fiscalDate", type)),
        },
        "Total reported revenue": {
            tooltip: `This includes all the revenue generated by the company during the specified period.
            A higher number is better.`,
            value: formatCurrencyValues(getRawValues(income, "data.sales", type)),
        },
        "Cost of revenue": {
            tooltip: `This represents the direct costs associated with producing the goods or services sold by the company.
            A lower number is generally better.`,
            value: formatCurrencyValues(getRawValues(income, "data.costOfGoods", type)),
        },
        "Gross profit": {
            tooltip: `This is the revenue minus the cost of revenue.
            It represents the profit generated by the company before deducting operating expenses.
            A higher number is better.`,
            value: formatCurrencyValues(getRawValues(income, "data.grossProfit", type)),
        },
        "Operating expense": {
            "Research and development": {
                tooltip: `These expenses are incurred by the company for the purpose of developing new products or improving existing ones.
                A higher number may indicate increased investment in innovation.`,
                value: formatCurrencyValues(getRawValues(income, "data.operatingExpenses.researchAndDevelopment", type)),
            },
            "General and administrative": {
                tooltip: `These expenses include salaries, rent, utilities, and other costs associated with running the company.
                A lower number is generally better.`,
                value: formatCurrencyValues(getRawValues(income, "data.operatingExpenses.sellingGeneralAndAdministrative", type)),
            },
            "Other expenses": {
                tooltip: `These expenses include items not classified under research and development or general and administrative expenses. 
                A lower number reflects better cost management.`,
                value: formatCurrencyValues(getRawValues(income, "data.operatingExpenses.otherOperatingExpenses", type)),
            },
        },
        "Operating income": {
            tooltip: `This represents the profit generated by the company from its core operations.
            It is calculated by subtracting operating expenses from gross profit.

            A higher number reflects better operational efficiency.`,
            value: formatCurrencyValues(getRawValues(income, "data.operatingIncome", type)),
        },
        "Non operating interest income": {
            "Interest income": {
                tooltip: `This represents the income generated by the company from its investments or interest-bearing assets.
                A higher number is generally better.`,
                value: formatCurrencyValues(getRawValues(income, "data.nonOperatingInterest.income", type)),
            },
            "Interest expense": {
                tooltip: `This represents the cost of borrowing or interest paid on loans or debt. 
                A lower number is generally better.`,
                value: formatCurrencyValues(getRawValues(income, "data.nonOperatingInterest.expense", type)),
            },
        },
        "Other income expense": {
            tooltip: `This includes any additional income or expenses not classified under operating or non-operating categories.
            The impact of these items on the overall financial performance may vary.`,
            value: formatCurrencyValues(getRawValues(income, "data.otherIncomeExpense", type)),
        },
        "Pretax income": {
            tooltip: `This represents the company's income before accounting for taxes.
            It is calculated by subtracting the tax provision from the operating income.

            A higher number is generally better.`,
            value: formatCurrencyValues(getRawValues(income, "data.pretaxIncome", type)),
        },
        "Tax provision": {
            tooltip: `This represents the amount of taxes the company is required to pay based on its pretax income.
            A lower number is generally better.`,
            value: formatCurrencyValues(getRawValues(income, "data.incomeTax", type)),
        },
        "Net income": {
            tooltip: `This represents the company's profit after accounting for all expenses and taxes.
            It is calculated by subtracting the tax provision from the pretax income. 

            A higher number is generally better.`,
            value: formatCurrencyValues(getRawValues(income, "data.netIncome", type)),
        },
        "Basic EPS": {
            tooltip: `The basic EPS (Earnings Per Share). This represents the company's earnings per share based on the basic average shares outstanding.
            It is calculated by dividing the net income by the basic average shares outstanding.

            A higher number reflects better earnings performance.`,
            value: formatCurrencyValues(getRawValues(income, "data.epsBasic", type)),
        },
        "Diluted EPS": {
            tooltip: `The diluted EPS (Earnings Per Share). This represents the company's earnings per share based on the diluted average shares outstanding.
            It is calculated by dividing the net income by the diluted average shares outstanding.

            A higher number reflects better earnings performance.`,
            value: formatCurrencyValues(getRawValues(income, "data.epsDiluted", type)),
        },
        "Basic average shares": {
            tooltip: `This represents the average number of shares outstanding during the specified period.
            It is used in the calculation of basic EPS.`,
            value: formatStandardValues(getRawValues(income, "data.basicSharesOutstanding", type)),
        },
        "Diluted average shares": {
            tooltip: `This represents the average number of shares outstanding during the specified period, assuming all potentially dilutive securities are exercised.
            It is used in the calculation of diluted EPS.`,
            value: formatStandardValues(getRawValues(income, "data.dilutedSharesOutstanding", type)),
        },
        EBITDA: {
            tooltip: `The EBITDA (Earnings Before Interest, Taxes, Depreciation, and Amortization). This represents the company's earnings before accounting for interest, taxes, depreciation, and amortization.
            It is a measure of the company's operating performance. A higher number is generally better.`,
            value: formatCurrencyValues(getRawValues(income, "data.ebitda", type)),
        },
        "Net income from continuing op.": {
            tooltip: `The net income from continuing operations. 
            This represents the company's net income from its ongoing operations, excluding any income or expenses from discontinued operations.

            A higher number is generally better.`,
            value: formatCurrencyValues(getRawValues(income, "data.netIncomeContinuousOperations", type)),
        },
        "Minority interests": {
            tooltip: `This represents the portion of the company's net income that is attributable to minority shareholders or non-controlling interests.
            A lower number is generally better.`,
            value: formatCurrencyValues(getRawValues(income, "data.minorityInterests", type)),
        },
        "Preferred stock dividends": {
            tooltip: `This represents the dividends paid to preferred stockholders.
            A lower number is generally better.`,
            value: formatCurrencyValues(getRawValues(income, "data.preferredStockDividends", type)),
        },
    });

    const formattedBalanceSheet = (type = "quarterly") => ({
        "Fiscal date": {
            value: formatDateValues(getRawValues(income, "fiscalDate", type)),
            tooltip: `The date of the fiscal period.`,
        },
        "Total assets": {
            value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.totalAssets", type)),
            tooltip: `The total value of all assets owned by the company. A higher value is preferred.`,
        },
        "Current assets": {
            _total: {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.totalCurrentAssets", type)),
                tooltip: `The total value of current assets, which are expected to be converted into cash within one year. A higher value is preferred.`,
            },
            Cash: {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.cash", type)),
                tooltip: `The amount of cash held by the company. A higher value is preferred.`,
            },
            "Cash equivalents": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.cashEquivalents", type)),
                tooltip: `Short-term investments that are easily convertible into cash. A higher value is preferred.`,
            },
            "Cash and cash equivalents": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.cashAndCashEquivalents", type)),
                tooltip: `The combined value of cash and cash equivalents. A higher value is preferred.`,
            },
            "Other short term investments": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.otherShortTermInvestments", type)),
                tooltip: `The value of other short-term investments. A higher value is preferred.`,
            },
            "Accounts receivable": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.accountsReceivable", type)),
                tooltip: `The amount of money owed to the company by its customers. A higher value is preferred.`,
            },
            "Other receivables": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.otherReceivables", type)),
                tooltip: `The value of other receivables. A higher value is preferred.`,
            },
            Inventory: {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.inventory", type)),
                tooltip: `The value of inventory, which includes raw materials, work-in-progress, and finished goods. A higher value is preferred.`,
            },
            "Prepaid assets": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.prepaidAssets", type)),
                tooltip: `The value of prepaid assets, which are expenses paid in advance. A higher value is preferred.`,
            },
            "Restricted cash": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.restrictedCash", type)),
                tooltip: `The amount of cash that is restricted and cannot be used freely by the company. A higher value may indicate financial constraints.`,
            },
            "Assets held for sale": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.assetsHeldForSale", type)),
                tooltip: `The value of assets that are held for sale and expected to be converted into cash within one year. A higher value may indicate potential liquidity.`,
            },
            "Hedging assets": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.hedgingAssets", type)),
                tooltip: `The value of assets held for hedging purposes. A higher value may indicate risk management strategies.`,
            },
            "Other current assets": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.currentAssets.otherCurrentAssets", type)),
                tooltip: `The value of other current assets. A higher value is preferred.`,
            },
        },
        "Non current assets": {
            _total: {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.totalNonCurrentAssets", type)),
                tooltip: `The total value of non-current assets, which are expected to provide economic benefits beyond one year. A higher value is preferred.`,
            },
            "Land and improvements": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.landAndImprovements", type)),
                tooltip: `The value of land and improvements, such as buildings and structures. A higher value is preferred.`,
            },
            "Machinery furniture equipment": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.machineryFurnitureEquipment", type)),
                tooltip: `The value of machinery, furniture, and equipment used in the company's operations. A higher value is preferred.`,
            },
            "Construction in progress": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.constructionInProgress", type)),
                tooltip: `The value of assets under construction. A higher value may indicate future expansion or investment.`,
            },
            Leases: {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.leases", type)),
                tooltip: `The value of leased assets. A higher value may indicate long-term commitments.`,
            },
            "Accumulated depreciation": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.accumulatedDepreciation", type)),
                tooltip: `The cumulative depreciation of non-current assets. A higher value may indicate aging assets.`,
            },
            Goodwill: {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.goodwill", type)),
                tooltip: `The value of intangible assets, such as brand reputation and customer relationships that represents the excess purchase price paid for a company over its net asset value.
                A higher value may indicate strong market position.`,
            },
            "Investment properties": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.investmentProperties", type)),
                tooltip: `The value of properties held for investment purposes. A higher value may indicate diversification strategies.`,
            },
            "Financial assets": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.financialAssets", type)),
                tooltip: `The value of financial assets, such as stocks and bonds. A higher value may indicate investment activities.`,
            },
            "Intangible assets": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.intangibleAssets", type)),
                tooltip: `The value of intangible assets, such as patents and trademarks. A higher value may indicate intellectual property value.`,
            },
            "Investments and advances": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.investmentsAndAdvances", type)),
                tooltip: `The value of investments and advances made by the company. A higher value may indicate growth opportunities.`,
            },
            "Other non current assets": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.assets.nonCurrentAssets.otherNonCurrentAssets", type)),
                tooltip: `The value of other non-current assets. A higher value is preferred.`,
            },
        },
        "Total liabilities": {
            value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.totalLiabilities", type)),
            tooltip: `The total value of all liabilities owed by the company. A lower value is preferred.`,
        },
        "Current liabilities": {
            _total: {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.currentLiabilities.totalCurrentLiabilities", type)),
                tooltip: `The total value of current liabilities, which are expected to be settled within one year. A lower value is preferred.`,
            },
            "Accounts payable": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.currentLiabilities.accountsPayable", type)),
                tooltip: `The amount of money owed by the company to its suppliers. A lower value is preferred.`,
            },
            "Accrued expenses": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.currentLiabilities.accruedExpenses", type)),
                tooltip: `The value of expenses that have been incurred but not yet paid. A lower value is preferred.`,
            },
            "Short term debt": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.currentLiabilities.shortTermDebt", type)),
                tooltip: `The amount of debt that is due within one year. A lower value is preferred.`,
            },
            "Deferred revenue": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.currentLiabilities.deferredRevenue", type)),
                tooltip: `The value of revenue that has been received in advance but not yet earned. A lower value is preferred.`,
            },
            "Tax payable": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.currentLiabilities.taxPayable", type)),
                tooltip: `The amount of taxes owed by the company. A lower value is preferred.`,
            },
            Pensions: {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.currentLiabilities.pensions", type)),
                tooltip: `The value of pension obligations. A lower value may indicate lower long-term liabilities.`,
            },
            "Other current liabilities": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.currentLiabilities.otherCurrentLiabilities", type)),
                tooltip: `The value of other current liabilities. A lower value is preferred.`,
            },
        },
        "Non current liabilities": {
            _total: {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.nonCurrentLiabilities.totalNonCurrentLiabilities", type)),
                tooltip: `The total value of non-current liabilities, which are expected to be settled beyond one year. A lower value is preferred.`,
            },
            "Long term provisions": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.nonCurrentLiabilities.longTermProvisions", type)),
                tooltip: `The value of provisions that are expected to be settled beyond one year. A lower value is preferred.`,
            },
            "Long term debt": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.nonCurrentLiabilities.longTermDebt", type)),
                tooltip: `The amount of debt that is due beyond one year. A lower value is preferred.`,
            },
            "Provision for risks and charges": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.nonCurrentLiabilities.provisionForRisksAndCharges", type)),
                tooltip: `The value of provisions made for potential risks and charges. A lower value is preferred.`,
            },
            "Deferred liabilities": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.nonCurrentLiabilities.deferredLiabilities", type)),
                tooltip: `The value of liabilities that are expected to be settled beyond one year. A lower value is preferred.`,
            },
            "Derivative product liabilities": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.nonCurrentLiabilities.derivativeProductLiabilities", type)),
                tooltip: `The value of liabilities related to derivative products. A lower value is preferred.`,
            },
            "Other non current liabilities": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.liabilities.nonCurrentLiabilities.otherNonCurrentLiabilities", type)),
                tooltip: `The value of other non-current liabilities. A lower value is preferred.`,
            },
        },
        "Shareholders equity": {
            "Common stock": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.shareholdersEquity.commonStock", type)),
                tooltip: `The value of common stock issued by the company. A higher value may indicate shareholder confidence.`,
            },
            "Retained Earnings": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.shareholdersEquity.retainedEarnings", type)),
                tooltip: `The accumulated profits or losses that have been retained by the company. A higher value may indicate profitability.`,
            },
            "Other shareholders equity": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.shareholdersEquity.otherShareholdersEquity", type)),
                tooltip: `The value of other shareholders' equity. A higher value may indicate additional investments or contributions.`,
            },
            "Total shareholders equity": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.shareholdersEquity.totalShareholdersEquity", type)),
                tooltip: `The total value of shareholders' equity. A higher value is preferred.`,
            },
            "Additional paid in capital": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.shareholdersEquity.additionalPaidInCapital", type)),
                tooltip: `The value of additional capital contributed by shareholders. A higher value may indicate financial support.`,
            },
            "Treasury stock": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.shareholdersEquity.treasuryStock", type)),
                tooltip: `The value of shares repurchased by the company. A lower value may indicate capital allocation decisions.`,
            },
            "Minority interest": {
                value: formatCurrencyValues(getRawValues(balanceSheet, "data.shareholdersEquity.minorityInterest", type)),
                tooltip: `The value of equity held by minority shareholders. A higher value may indicate external ownership.`,
            },
        },
    });

    const formattedCashFlow = (type = "quarterly") => ({
        "Fiscal date": {
            value: formatDateValues(getRawValues(income, "fiscalDate", type)),
            tooltip: `The fiscal date represents the end date of the financial period.`,
        },
        "Operating activities": {
            _total: {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.operatingActivities.operatingCashFlow", type)),
                tooltip: `The total operating activities cash flow represents the net cash generated or used by the company's core operations. A higher value is preferred.`,
            },
            "Net income": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.operatingActivities.netIncome", type)),
                tooltip: `Net income is the company's total revenue minus all expenses and taxes. A higher value is preferred.`,
            },
            Depreciation: {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.operatingActivities.depreciation", type)),
                tooltip: `Depreciation represents the decrease in value of an asset over time. A higher value may indicate higher asset utilization.`,
            },
            "Deferred taxes": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.operatingActivities.deferredTaxes", type)),
                tooltip: `Deferred taxes represent the difference between taxes paid and taxes owed. A lower value is preferred.`,
            },
            "Stock-based compensation": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.operatingActivities.stockBasedCompensation", type)),
                tooltip: `Stock-based compensation refers to the value of stock options or other equity-based compensation given to employees. A lower value is preferred.`,
            },
            "Other non cash items": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.operatingActivities.otherNonCashItems", type)),
                tooltip: `Other non cash items include non-cash expenses or income that affect the company's cash flow. The impact of these items depends on the specific nature of the transaction.`,
            },
            "Accounts receivable": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.operatingActivities.accountsReceivable", type)),
                tooltip: `Accounts receivable represents the amount of money owed to the company by its customers. A lower value may indicate better cash collection.`,
            },
            "Accounts payable": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.operatingActivities.accountsPayable", type)),
                tooltip: `Accounts payable represents the amount of money the company owes to its suppliers. A higher value may indicate better cash management.`,
            },
            "Other assets liabilities": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.operatingActivities.otherAssetsLiabilities", type)),
                tooltip: `Other assets and liabilities include non-operating items that affect the company's cash flow. The impact of these items depends on the specific nature of the transaction.`,
            },
        },
        "Investing activities": {
            _total: {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.investingActivities.investingCashFlow", type)),
                tooltip: `The total investing activities cash flow represents the net cash generated or used by the company's investment activities. A higher value is preferred.`,
            },
            "Capital expenditures": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.investingActivities.capitalExpenditures", type)),
                tooltip: `Capital expenditures refer to the money spent on acquiring or improving long-term assets. A higher value may indicate higher investment in the company's future growth.`,
            },
            "Net intangibles": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.investingActivities.netIntangibles", type)),
                tooltip: `Net intangibles represent the change in value of intangible assets, such as patents or trademarks. The impact of these items depends on the specific nature of the transaction.`,
            },
            "Net acquisitions": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.investingActivities.netAcquisitions", type)),
                tooltip: `Net acquisitions represent the net cash used for acquiring or disposing of other companies. The impact of these items depends on the specific nature of the transaction.`,
            },
            "Purchase of investments": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.investingActivities.purchaseOfInvestments", type)),
                tooltip: `Purchase of investments represents the cash spent on acquiring financial assets, such as stocks or bonds. A higher value may indicate higher investment in other companies.`,
            },
            "Sale of investments": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.investingActivities.saleOfInvestments", type)),
                tooltip: `Sale of investments represents the cash received from selling financial assets, such as stocks or bonds. A higher value may indicate higher divestment from other companies.`,
            },
            "Other investing activity": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.investingActivities.otherInvestingActivity", type)),
                tooltip: `Other investing activity includes non-operating items related to the company's investment activities. The impact of these items depends on the specific nature of the transaction.`,
            },
        },
        "Financing activities": {
            _total: {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.financingActivities.financingCashFlow", type)),
                tooltip: `The total financing activities cash flow represents the net cash generated or used by the company's financing activities. A higher value is preferred.`,
            },
            "Long term debt issuance": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.financingActivities.longTermDebtIssuance", type)),
                tooltip: `Long term debt issuance represents the cash received from issuing long-term debt, such as bonds or loans. A higher value may indicate higher borrowing for the company's operations.`,
            },
            "Long term debt payments": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.financingActivities.longTermDebtPayments", type)),
                tooltip: `Long term debt payments represent the cash paid for repaying long-term debt, such as bonds or loans. A lower value is preferred.`,
            },
            "Short term debt issuance": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.financingActivities.shortTermDebtIssuance", type)),
                tooltip: `Short term debt issuance represents the cash received from issuing short-term debt, such as commercial paper or lines of credit. A higher value may indicate higher borrowing for the company's short-term needs.`,
            },
            "Common stock issuance": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.financingActivities.commonStockIssuance", type)),
                tooltip: `Common stock issuance represents the cash received from issuing new shares of common stock. A higher value may indicate higher equity financing for the company's operations.`,
            },
            "Common stock repurchase": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.financingActivities.commonStockRepurchase", type)),
                tooltip: `Common stock repurchase represents the cash spent on buying back shares of common stock. A lower value may indicate a return of capital to shareholders.`,
            },
            "Common dividends": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.financingActivities.commonDividends", type)),
                tooltip: `Common dividends represent the cash paid to shareholders as a distribution of profits. A higher value may indicate a higher return to shareholders.`,
            },
            "Other financing charges": {
                value: formatCurrencyValues(getRawValues(cashFlow, "data.financingActivities.otherFinancingCharges", type)),
                tooltip: `Other financing charges include non-operating items related to the company's financing activities. The impact of these items depends on the specific nature of the transaction.`,
            },
        },
        "End cash position": {
            value: formatCurrencyValues(getRawValues(cashFlow, "data.endCashPosition", type)),
            tooltip: `The end cash position represents the company's cash balance at the end of the financial period.`,
        },
        "Income tax paid": {
            value: formatCurrencyValues(getRawValues(cashFlow, "data.incomeTaxPaid", type)),
            tooltip: `Income tax paid represents the cash paid for income taxes. A lower value may indicate lower tax liabilities.`,
        },
        "Interest paid": {
            value: formatCurrencyValues(getRawValues(cashFlow, "data.interestPaid", type)),
            tooltip: `Interest paid represents the cash paid for interest on debt. A lower value may indicate lower interest expenses.`,
        },
        "Free cash flow": {
            value: formatCurrencyValues(getRawValues(cashFlow, "data.freeCashFlow", type)),
            tooltip: `Free cash flow represents the cash generated by the company's operations after accounting for capital expenditures. A higher value is preferred.`,
        },
    });

    const onChange = (obj, key, value) => {
        if (!value) return;

        const newObj = JSON.parse(JSON.stringify(statement));
        setValue(newObj, key, value);
        setStatement(newObj);
    };

    const buttons = [
        {
            title: "Quarterly",
            onClick: () => handleSelectedType("quarterly"),
            variant: selectedType === "quarterly" ? "contained" : "outlined",
            size: "small",
        },
        {
            title: "Annual",
            onClick: () => handleSelectedType("annual"),
            variant: selectedType === "annual" ? "contained" : "outlined",
            size: "small",
        },
    ];

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} sx={{ textAlign: "center", mt: 1 }}>
                <CustomInput field="statement" obj={statement} select={["income", "balance sheet", "cash flow"]} onChange={onChange} size="small" toggle />
            </Grid>

            {statement.statement === "income" && (
                <Grid item xs={12}>
                    <PaperFragment
                        p={2}
                        title={
                            <StackedHeader
                                {...props}
                                title="Income statement"
                                subtitle="The revenues, expenses, gains, and losses of a company over a specific period."
                                buttons={buttons}
                            />
                        }
                        value={<TextTable data={formattedIncome(selectedType)} firstRowBold />}
                    />
                </Grid>
            )}

            {statement.statement === "balance sheet" && (
                <Grid item xs={12}>
                    <PaperFragment
                        p={2}
                        title={<StackedHeader {...props} title="Balance sheet" subtitle="Summary of the financial balances of a business." buttons={buttons} />}
                        value={<TextTable data={formattedBalanceSheet(selectedType)} firstRowBold />}
                    />
                </Grid>
            )}

            {statement.statement === "cash flow" && (
                <Grid item xs={12}>
                    <PaperFragment
                        p={2}
                        title={<StackedHeader {...props} title="Cash flow" subtitle="Payments made into or out of a business." buttons={buttons} />}
                        value={<TextTable data={formattedCashFlow(selectedType)} firstRowBold />}
                    />
                </Grid>
            )}
        </Grid>
    );
}
