import * as React from "react";

import { Avatar, Grid, Box, Paper, Typography } from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import SignUpForm from "./components/SignUpForm.js";
import CustomImage from "../../helper-components/misc/CustomImage.js";

import { getLanguage } from "../../config/language/language.js";

/**
 * Sign up page.
 */
export default function SignUp(props) {
    return (
        <Grid container>
            <Grid item xs={true} sm={5} md={7}>
                <CustomImage id="signUpBackground" cover transparent height="100%" componentHeight="100%" p={0} m={0} url={`${process.env.PUBLIC_URL}/images/stock_photos/btc_dollar.png`} />
            </Grid>

            <Grid item xs={12} sm={7} md={5} component={Paper} elevation={6} square>
                <Box className="form-box">
                    <Avatar className="avatar">
                        <LockOutlinedIcon />
                    </Avatar>

                    <Typography component="h1" variant="h5">
                        {getLanguage().BUTTON_SIGNUP}
                    </Typography>

                    <SignUpForm {...props} />
                </Box>
            </Grid>
        </Grid>
    );
}
