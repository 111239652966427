import { includeObjectsByKeys, removeObjectsByKeys } from "../validation/object";

import { getModifiedValues } from "../validation/object";
import { removeEmptyStrings } from "../validation/object";

/**
 * Create the POST request structure that will be sent to the API for "empty" (i.e. restart device) endpoints.
 *
 * The final object includes the type, timestamp and version.
 */
export const createEmptyUploadConfig = (props, version, type) => {
    const result = {};

    result["timestamp"] = new Date().getTime();
    result["version"] = version;
    result["user"] = props.user.username;
    result["type"] = type;

    return result;
};

/**
 * Create the HTTP request structure that will be sent to the API.
 *
 * Remove any form items.
 * Only include those keys that are specified in the 'include' field.
 * Remove empty strings.
 *
 * Return a deep copy of the object.
 */
export const createUploadObject = (obj, exclude = ["form"], include = ["*"]) => {
    const uploadObj = JSON.parse(JSON.stringify(obj));

    const excludedKeys = removeObjectsByKeys(uploadObj, exclude);
    const includedKeys = includeObjectsByKeys(excludedKeys, include);
    const emptyStringsRemoved = removeEmptyStrings(includedKeys);

    return emptyStringsRemoved;
};

/**
 * Create the HTTP request structure that will be sent to the API.
 *
 * Remove any form items.
 * Only include those keys that have been modified.
 * Don't remove empty strings.
 */
export const createOnlyModifiedUploadObject = (obj1, obj2, exclude = ["form"], include = ["*"]) => {
    const uploadObj = getModifiedValues(obj1, obj2);
    uploadObj.id = obj1.id || obj2.id;

    const excludedKeys = removeObjectsByKeys(uploadObj, exclude);
    const includedKeys = includeObjectsByKeys(excludedKeys, include);

    return includedKeys;
};
