import * as React from "react";

import { Container, Grid } from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleIcon from "@mui/icons-material/Article";
import PolicyIcon from "@mui/icons-material/Policy";

import NavigationFooter from "../../helper-components/misc/NavigationFooter.js";

/**
 * The privacy policy page.
 * The PDF is displayed in an iframe.
 */
export default function PrivacyPolicy(props) {
    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={1}>
                <Grid item xs={12} className="center">
                    <iframe title="privacy-policy" src={`${process.env.PUBLIC_URL}/documents/Privacy Policy.pdf`} width="90%" height="1000px" />
                </Grid>

                <NavigationFooter
                    rows={2}
                    options={[
                        {
                            path: `/terms-and-conditions`,
                            title: `Terms and conditions`,
                            icon: <ArticleIcon />,
                        },
                        {
                            path: `/privacy-policy`,
                            title: `Privacy policy`,
                            disabled: true,
                            icon: <PolicyIcon />,
                        },
                        {
                            path: `/signup`,
                            title: `Sign up`,
                            icon: <AccountCircleIcon />,
                        },
                    ]}
                />
            </Grid>
        </Container>
    );
}
