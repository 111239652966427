// These are the rules by which the form input fields will be checked against.

import { getValue, isArray, setFormValues } from "./object.js";
import { noValidation } from "./utils.js";

import * as Enums from "../../../config/constants/Enums.js";

/**
 * Criteria:
 * 1) Required
 * 2) No whitespace
 * 3) Must match regex
 */
const timeframeValidation = (prevConfig, row, value, customKey) => {
    const key = customKey || "timeframe";

    // 1)
    if (value === "") {
        return setFormValues(row, key, true, "Required");
    }

    // 2)
    if (/\s/g.test(value)) {
        return setFormValues(row, key, true, "Whitespace not allowed");
    }

    // 3)
    if (!/^[0-9]+[smhdwm]$/gi.test(value)) {
        return setFormValues(row, key, true, "Invalid timeframe");
    }

    return setFormValues(row, key, false, "");
};

/**
 * Criteria:
 * 1) Required
 */
const nameValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "") {
        return setFormValues(row, "name", true, "Required");
    }

    return setFormValues(row, "name", false, "");
};

/**
 * Criteria:
 * 1) Required
 */
const orderTypeValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "" || value === null) {
        return setFormValues(row, "orderType", true, "Required");
    }

    return setFormValues(row, "orderType", false, "");
};

/**
 * Criteria:
 * 1) Required
 */
const visibilityValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "" || value === null) {
        return setFormValues(row, "visibility", true, "Required");
    }

    return setFormValues(row, "visibility", false, "");
};

/**
 * Criteria:
 * 1) Required
 */
const statusValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "") {
        return setFormValues(row, "status", true, "Required");
    }

    return setFormValues(row, "status", false, "");
};

/**
 * Criteria:
 * 1) Must be empty unless PROTECTED visibility
 */
const codeValidation = (prevConfig, row, value) => {
    const visibility = getValue(row, "visibility");

    // 1)
    if (visibility === Enums.SIGNAL_VISIBILITY_PROTECTED && value === "") {
        return setFormValues(row, "code", true, "Required");
    }

    return setFormValues(row, "code", false, "");
};

/**
 * Criteria:
 * 1) Required
 */
const _assetIdValidation = (prevConfig, row, _assetId) => {
    const assetId = getValue(row, "assetId");

    // 1)
    if (assetId === "") {
        return setFormValues(row, "_assetId", true, "Required");
    }

    return setFormValues(row, "_assetId", false, "");
};

/**
 * Criteria:
 * 1) Should be an array
 * 2) Must have at least 2 elements
 */
const _idsValidation = (prevConfig, row, _ids) => {
    const ids = getValue(row, "ids");

    // 1)
    if (!isArray(ids)) {
        return setFormValues(row, "_ids", true, "Invalid");
    }

    // 2)
    if (ids.length < 2) {
        return setFormValues(row, "_ids", true, "At least 2 signals required");
    }

    return setFormValues(row, "_ids", false, "");
};

/**
 * Criteria:
 * 1) Required
 */
const conditionValidation = (prevConfig, row, value) => {
    // 1)
    if (value === "") {
        return setFormValues(row, "condition", true, "Required");
    }

    return setFormValues(row, "condition", false, "");
};

/**
 * Criteria:
 * 1) Check timeframe if condition is TIMEFRAME
 */
const _valueValidation = (prevConfig, row, _value, obj) => {
    const condition = getValue(row, "condition");
    const value = getValue(row, "value");

    // 1)
    if (condition === Enums.CONDITION_TIMEFRAME) {
        return timeframeValidation(prevConfig, row, value, "_value");
    }

    return setFormValues(row, "_value", false, "");
};

// The main constant, directing to the specific validation function.
const VALIDATION = {
    name: {
        check: nameValidation,
    },
    description: {
        check: noValidation,
    },
    orderType: {
        check: orderTypeValidation,
    },
    visibility: {
        check: visibilityValidation,
    },
    status: {
        check: statusValidation,
    },
    code: {
        check: codeValidation,
    },
    _assetId: {
        check: _assetIdValidation,
    },
};

export const EXTERNAL_VALIDATION = {
    ...VALIDATION,
};

export const COMBINED_VALIDATION = {
    ...VALIDATION,

    _ids: {
        check: _idsValidation,
    },
    condition: {
        check: conditionValidation,
    },
    _value: {
        check: _valueValidation,
    },
};
