import React, { useState } from "react";
import { useParams } from "react-router";
import dayjs from "dayjs";

import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

import * as Showdown from "showdown";

import { Container, Grid, Stack, Typography, useTheme } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import CustomForm from "../../helper-components/form/CustomForm";
import CustomImage from "../../helper-components/misc/CustomImage";
import CustomInput from "../../helper-components/input/CustomInput";
import CustomButton from "../../helper-components/input/CustomButton";
import PaperFragment from "../../helper-components/misc/PaperFragment";

import { isAdmin } from "../../helper-functions/utils/misc/utils";
import { convertToDate, isEmpty, setValue } from "../../helper-functions/utils/validation/object";
import { queryPost, updatePost, uploadPostImage } from "../../helper-functions/api/postQueries";

import { getLanguage } from "../../config/language/language";
import { PUBLIC_POSTS_PATH } from "../../config/constants/Paths";
import { POST_VISIBILITY_PRIVATE, POST_VISIBILITY_PROTECTED, POST_VISIBILITY_PUBLIC, POST_VISIBILITY_TYPES } from "../../config/constants/Enums";

/**
 * A page for creating and editing posts.
 * Uses markdown for formatting.
 */
export default function PostEditor(props) {
    const { id } = useParams(); // Get the URL parameters from react-router.
    const theme = useTheme();

    const colorMode = theme.palette.mode;
    const className = colorMode === "dark" ? "react-mde-custom-theme" : null;
    const admin = isAdmin(props);

    const postObj = {
        title: "",
        content: "",
        createdOn: dayjs(new Date()),
        visibility: "",
        bannerImage: null,
        notification: false,
    };

    const publicPosts = props?.posts?.public?.data;
    const privatePosts = props?.posts?.private?.data;
    const followingPosts = props?.posts?.following?.data;

    const combinedPostsData = [...publicPosts, ...privatePosts, ...followingPosts];

    const [post, setPost] = useState(postObj); // Set default values if post is not available yet - otherwise, error message occurs.
    const [postQuery, setPostQuery] = React.useState(false);
    const [previewImg, setPreviewImg] = useState(null);

    const [content, setContent] = useState("");
    const [selectedTab, setSelectedTab] = useState("write");
    const [previousVisibility, setPreviousVisibility] = useState(null);

    // Markdown converter.
    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true,
    });

    // Listen for paste events, and set the image when the event is fired.
    React.useEffect(() => {
        const pasteArea = document.getElementById("paste-area");

        pasteArea.addEventListener("paste", (event) => {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;

            for (const item of items) {
                if (item.kind === "file" && item.type.startsWith("image/")) {
                    const file = item.getAsFile();
                    setAndPreviewImage(file);
                }
            }
        });

        return () => {
            pasteArea.removeEventListener("paste", () => {});
        };
    }, []);

    // Set the post, and potentially query it if it's not available.
    React.useEffect(() => {
        const possiblePost = combinedPostsData.find((post) => post.id === parseInt(id));
        if (post.id !== undefined) return;

        if (!isEmpty(possiblePost)) {
            setPostQuery(true);
            setPost({ ...possiblePost, createdOn: dayjs(convertToDate(possiblePost.createdOn)), bannerImage: null });
            setContent(possiblePost.content);
            setPreviousVisibility(possiblePost.visibility);
        }

        if (isEmpty(possiblePost) && !postQuery) {
            setPostQuery(true);
            queryPost(props, { id });
        }
    }, [combinedPostsData, post]);

    /**
     * Executed when typing something into an input field.
     */
    const onChange = (obj, key, value) => {
        const newPost = { ...post };

        setValue(newPost, key, value);
        setPost(newPost);
    };

    /**
     * Open file dialog and select an image.
     * If an image is already selected, clear it.
     */
    const handleSelect = () => {
        if (post.bannerImage) {
            setPost({ ...post, bannerImage: null });
            setPreviewImg(null);
            return;
        }

        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/png, image/jpeg, image/jpg";
        input.click();

        input.onchange = (e) => {
            try {
                const file = e.target.files[0];
                if (!file.type.includes("image")) throw new Error("File is not an image!");

                setAndPreviewImage(file);
            } catch (e) {
                console.error("Error selecting file:", e);
                props.showSnackbar({ message: "Error selecting file!", color: "error" });
            }
        };
    };

    const setAndPreviewImage = (file) => {
        setPost({ ...post, bannerImage: file });

        // Show preview image.
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewImg(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    /**
     * Save the post to the server.
     */
    const handleSubmit = () => {
        if (!isValid(post)) {
            props.showSnackbar({ message: "Form invalid, check for errors!", color: "warning" });
            return;
        }

        const body = {
            id: post?.id,
            title: post?.title,
            content,
            createdOn: post?.createdOn,
            visibility: post?.visibility,
            notification: post?.notification,
        };

        const formData = new FormData();
        formData.append("file", post.bannerImage);
        formData.append(
            "request",
            new Blob([JSON.stringify(body)], {
                type: "application/json",
            })
        );

        updatePost(props, { body: formData, id: post.id, previousVisibility });
    };

    /**
     * Upload the pasted image to the server.
     * The image is uploaded as a form-data object.
     * Returns the URL of the uploaded image, which is then used in the markdown content.
     */
    const saveImage = async function* (data) {
        // Create a new File object from the pasted image data
        const file = new File([data], "image.png", { type: data.type });

        // Use FormData to allow file upload.
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
            "request",
            new Blob([JSON.stringify({})], {
                type: "application/json",
            })
        );

        const url = await uploadPostImage(props, { body: formData });
        yield `${PUBLIC_POSTS_PATH}/image/${url}`;

        // Return status of the upload.
        return url !== null;
    };

    const isValid = (post) => {
        return post.title && content && post.createdOn && post.visibility;
    };

    const createVisibilityHelperText = () => {
        const { visibility } = post;

        if (visibility === POST_VISIBILITY_PRIVATE) return `Post will be visible only to you.`;
        if (visibility === POST_VISIBILITY_PROTECTED) return `Only your followers can see this post.`;
        if (visibility === POST_VISIBILITY_PUBLIC) return `Anyone can see this post.`;
    };

    return (
        <Container maxWidth="xl" className="container">
            {/* Intro text. */}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().POSTS_EDIT_TITLE} subtitle={getLanguage().POSTS_EDIT_SUBTITLE} icon={<EditIcon />} />
                </Grid>

                <Grid item xs={12}>
                    <PaperFragment p={0.75} m={0} value={<CustomInput field="title" obj={post} onChange={onChange} label="Title" />} />
                </Grid>

                <Grid item xs={12}>
                    <ReactMde
                        value={content}
                        onChange={setContent}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
                        classes={{ toolbar: className, textArea: className, reactMde: className }}
                        initialEditorHeight={300}
                        childProps={{
                            writeButton: {
                                tabIndex: -1,
                            },
                        }}
                        paste={{
                            saveImage: saveImage,
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div id="paste-area">
                        <CustomForm
                            title="Finalize post"
                            subtitle="Select a date & time, and a banner image."
                            form={
                                <Grid container spacing={1} mt={4}>
                                    <Grid item xs={12} sm={6}>
                                        <CustomInput field="createdOn" obj={post} onChange={onChange} label="Select date & time (DD/MM/YYYY HH:mm)" datetime />
                                    </Grid>

                                    <Grid item xs={12} sm={6} textAlign="center" style={{ paddingTop: 0, marginTop: -15 }}>
                                        <Typography className="italic sm-font xs-margin">{"Visibility type"}</Typography>
                                        <CustomInput field="visibility" obj={post} select={POST_VISIBILITY_TYPES} onChange={onChange} toggle />
                                        <Typography className="italic xs-font xs-margin" color={post?.form?.visibility?.error ? "#f44336" : "inherit"}>
                                            {createVisibilityHelperText()}
                                        </Typography>
                                    </Grid>

                                    {admin && (
                                        <Grid item xs={12}>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <CustomInput field="notification" obj={post} onChange={onChange} checkbox disabled={post.visibility !== "PUBLIC"} />
                                                <Typography variant="body2" color="textSecondary">
                                                    {"Send notification to all users (admin only)"}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    )}

                                    <Grid item xs={12} sm={6}>
                                        <CustomButton
                                            {...props}
                                            title={post?.bannerImage?.name ?? "Overwrite existing banner image..."}
                                            onClick={handleSelect}
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <CustomButton {...props} title="Update post" onClick={handleSubmit} fullWidth />
                                    </Grid>

                                    {previewImg && (
                                        <Grid item xs={12}>
                                            <CustomImage url={previewImg} center maxHeight="150px" maxWidth="100%" transparent />
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}
