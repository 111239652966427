import Chart from "./Chart";

import { isEmpty } from "../../helper-functions/utils/validation/object";

/**
 * Chart which displays a line chart.
 * Utilizes the underlying Chart component.
 *
 * Props:
 * - data
 * - options
 */
export default function LineChart(props) {
    const { data, options = {} } = props;

    return (
        <Chart
            {...props}
            options={{
                ...options,
                dataset: [
                    {
                        ...options.dataset[0],
                        source: data,
                    },
                ],
            }}
            show={!isEmpty(data)}
        />
    );
}
