import * as React from "react";

export default function Snek() {
    // The src is actually according to the /public folder.
    return (
        <>
            <iframe className="snake-iframe" id="snek" src={`${process.env.PUBLIC_URL}/snek.html`} title="Snek" frameBorder="0" scrolling="no" />
        </>
    );
}
