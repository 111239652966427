import React from "react";

import { useTheme } from "@mui/material/styles";
import { List, Collapse } from "@mui/material";

import CustomListItem from "./CustomListItem.js";

import * as General from "../../config/constants/General.js";

/**
 * Drop-down items component, used to create the different categories for the sidebar.
 *
 * Loops thought the categories array, creating a specific nested list for each.
 */
export default function CustomListDropdown(props) {
    const theme = useTheme();

    const { categories } = props;
    const { isMobile } = props.browser;
    const { openAppDrawer: openDrawer, toggleAppDrawer: toggleDrawer } = props.sidebar;

    const visibleCategories = categories.filter((category) => (typeof category.visible === "function" ? category.visible(props) : category?.visible ?? true));

    const minWidth = General.SIDEBAR_TEXT_MIN_WIDTH + 15;

    const [expanded, setExpanded] = React.useState(false);

    const handleClick = (panel) => (event) => {
        setExpanded(panel === expanded ? false : panel);
    };

    const handleMobileClose = (panel) => (event) => {
        if (isMobile && openDrawer) toggleDrawer();
    };

    /**
     * Create the tooltip component, shown on the parent component.
     */
    const createTooltip = (category) => {
        return <CustomListItem {...props} category={category} handleClick={handleClick} expanded={expanded} enableExpand enableTooltip />;
    };

    /**
     * Create the subcategory list.
     */
    const createSublist = (category) => {
        const subcategories = Object.values(category.categories);
        const visibleSubcategories = subcategories.filter((subcategory) => (typeof subcategory.visible === "function" ? subcategory.visible(props) : subcategory?.visible ?? true));

        return visibleSubcategories.map((item) => (
            <CustomListItem
                {...props}
                key={item.path}
                category={item}
                sx={{ minWidth, ml: 1 }}
                path={category.path + item.path}
                enableNavigation
                enableTooltip
                handleClick={handleMobileClose}
            />
        ));
    };

    return visibleCategories.map((category, index) => (
        <List key={index} className={`sidebar-parent-${theme.palette.mode}`} component="nav" aria-labelledby="nested-list-subheader" disablePadding>
            {createTooltip(category)}

            <Collapse in={expanded === category.id} className="sidebar-collapse" timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {createSublist(category)}
                </List>
            </Collapse>
        </List>
    ));
}
