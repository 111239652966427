import { Avatar, Typography } from "@mui/material";

import PaperFragment from "../misc/PaperFragment";
import TransparentFragment from "../misc/TransparentFragment";

/**
 * A universal form component.
 * Includes icon, title, subtitle, and the actual form.
 *
 * Required props:
 * title
 * subtitle
 * icon
 * form
 *
 * Optional props:
 * show (boolean)
 * transparent (boolean)
 */
export default function CustomForm(props) {
    const title = props.title;
    const subtitle = props.subtitle;
    const icon = props.icon;
    const form = props.form;

    const height = props.height;
    const show = props?.show ?? true;

    const FragmentComponent = props.transparent ? TransparentFragment : PaperFragment;

    return (
        show && (
            <FragmentComponent
                center
                height={height}
                width="98.5%"
                value={
                    <>
                        {icon && <Avatar className="avatar">{icon}</Avatar>}

                        <Typography component="h1" variant="h5" sx={{ pb: 0.5 }}>
                            {title}
                        </Typography>

                        <Typography component="p" variant="subtitle2" className="pre-line">
                            {subtitle}
                        </Typography>

                        {form}
                    </>
                }
            />
        )
    );
}
