import { POST_VISIBILITY_PRIVATE, POST_VISIBILITY_PROTECTED, POST_VISIBILITY_PUBLIC } from "../../../config/constants/Enums";
import { setUniqueArrayState } from "./utils";

/**
 * Handle the posts response from the API.
 */
export const handleAddPublicPosts = (props, response) => {
    const setData = props.posts.public.setData;
    const data = response.elements;

    setUniqueArrayState(setData, data);
};

export const handleAddPrivatePosts = (props, response) => {
    const setData = props.posts.private.setData;
    const data = response.elements;

    setUniqueArrayState(setData, data);
};

export const handleAddFollowingPosts = (props, response) => {
    const setData = props.posts.following.setData;
    const data = response.elements;

    setUniqueArrayState(setData, data);
};

/**
 * Handle add info from websockets.
 */
export const handleWebsocketAddPublicPosts = (props, payload) => {
    handleAddPublicPosts(props, { elements: [payload] });
};

export const handleWebsocketAddFollowingPosts = (props, payload) => {
    handleAddFollowingPosts(props, { elements: [payload] });
};

export const getStateByPostVisibility = (props, visibility) => {
    if (visibility === POST_VISIBILITY_PUBLIC) return props.posts.public;
    if (visibility === POST_VISIBILITY_PROTECTED) return props.posts.following;
    if (visibility === POST_VISIBILITY_PRIVATE) return props.posts.private;
};

/**
 * Show these static posts even if the server is down.
 */
export const getFallbackPosts = () => {
    return [
        {
            id: 100000000001,
            title: "What Warren Buffett gets right about Bitcoin",
            content: `Warren Buffett's most recent critique about Bitcoin is its lack of “producing” anything, which actually proves its monetary properties and usefulness as money.  See https://bitcoinmagazine.com/business/what-warren-buffett-gets-right-about-bitcoin for more information.`,
            bannerImagePath: "/images/posts/buffet_btc.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000002,
            title: "Why do people not understand TSLA?",
            content: `Tesla is a unique and special company. They continue to share the most mind-blowing news, yet the stock price is not where it should be.
            James from IA will break that down and try to figure out why…
            View details here: https://investanswers.substack.com/p/recap-tsla-down-heres-why`,
            bannerImagePath: "/images/posts/tsla_down.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000003,
            title: "IA Tesla Bot Valuation Model - 2030 Price Target",
            content: `This whitepaper provides an in-depth analysis of Tesla's venture into the humanoid robotics sector, focusing on its economic feasibility, market prospects, and potential to bring transformative changes. 
            We contend that Tesla's Optimus project will be a game-changer across various industries, offering substantial financial gains and societal advancements.
            View details here: https://investanswers.substack.com/p/ia-tesla-bot-valuation-model-2030`,
            bannerImagePath: "/images/posts/optimus.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000004,
            title: "The morality of capitalism",
            content: `Is it moral to make money by investing or starting a business? This lecture explores the moral implications of capitalism in a completely new light.
            Presented by Yaron Brook, chairman of the Ayn Rand Institute, he argues that capitalism is the only moral system, and that it is the only system that respects individual rights and freedom.
            View details here: https://www.youtube.com/live/5nIvsfl6wO0?si=BycnOOHNLFG1MwRO&t=246`,
            bannerImagePath: "/images/posts/moral_capitalism.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000005,
            title: "Bitcoin resources by Jameson Lopp",
            content: `Jameson Lopp is a software engineer who has been involved in Bitcoin since 2012.
            View his collection of resources on Bitcoin, including articles, podcasts, and videos.
            Details here: https://www.lopp.net/bitcoin-information.html`,
            bannerImagePath: "/images/posts/lopp_btc.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000006,
            title: "Why Bitcoin only?",
            content: `Delve into the reasons why Bitcoin is the only cryptocurrency worth investing in, and why it is the only one that will survive in the long run.
            View details here: https://whybitcoinonly.com/`,
            bannerImagePath: "/images/posts/why_btc_only.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000007,
            title: "How does blockchain work?",
            content: `This interactive guide explains the concept of blockchain technology and how it works. 
            It is a great resource for beginners and experts alike.
            View details here: https://andersbrownworth.com/blockchain/`,
            bannerImagePath: "/images/posts/blockchain_demo.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000008,
            title: "What backs Bitcoin?",
            content: `Bitcoin doesn't need to be backed by anything tangible to be valuable; like fiat currencies, much of its value derives from consumer confidence.
            View details here: https://decrypt.co/resources/what-is-bitcoin-backed-by`,
            bannerImagePath: "/images/posts/what_backs_btc.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000009,
            title: "BEEST (Bitcoin Energy & Emissions Sustainability Tracker)",
            content: `The Bitcoin Mining Council (BMC) reports that the Bitcoin network uses 59.4% zero-emission* power sources.
            View details here: https://batcoinz.com/beest/`,
            bannerImagePath: "/images/posts/beest.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000010,
            title: "The Sovereign Individual by Davidson and Rees-Mogg",
            content: `One of those few books where you see the world differently after reading it. 
            The next couple decades of technological advancements will give us unprecedented ability to live in ways that would have been unthinkable 30 years ago. 
            This book outlines how tech will change the ways we live, especially relative to countries, and how we can take advantage of it.
            View details here: https://www.nateliason.com/notes/sovereign-individual`,
            bannerImagePath: "/images/posts/sov_indiv.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000011,
            title: "Broken money",
            content: `An analysis of the past, present, and future of money, and how technology impacts it over time.
            How the current disequilibrium (transactions way faster than settlements) creates centralized and corrupted money clusters.
            View details here: https://x.com/LynAldenContact/status/1788968884688630225`,
            bannerImagePath: "/images/posts/broken_money.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000012,
            title: "Comparing cash amounts visually",
            content: `This is what a trillion dollars in cash would look like!
            The end of the video shows a comparison of a trillion dollars in cash - it's a great visual representation of how much money that actually is.
            View details here: https://x.com/historyinmemes/status/1772236489499570636`,
            bannerImagePath: "/images/posts/cash_comparison.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
        {
            id: 100000000013,
            title: "Unlocking the Secrets of Solana",
            content: `What makes Solana so special? How does it differ from other blockchains?
            High Transaction Capacity, Low Fees, and Fast Confirmation Times are just a few of the reasons why Solana is the next big thing in the crypto world.
            View details here: https://investanswers.substack.com/p/mert-cracks-the-crypto-da-vinci-code`,
            bannerImagePath: "/images/posts/sol.png",
            visibility: "PUBLIC",
            author: { id: 1, username: "J-Dog" },
            createdOn: 1716208575.784,
            fallback: true,
        },
    ];
};
