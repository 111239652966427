import * as React from "react";

import { Grid, Container, Chip, Divider } from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyIcon from "@mui/icons-material/Key";

import NotificationSettingsForm from "./components/NotificationSettingsForm.js";
import NotificationSelectForm from "./components/NotificationSelectForm.js";
import UserUpdateForm from "./components/UserUpdateForm.js";
import UserDeleteForm from "./components/UserDeleteForm.js";

import CustomForm from "../../../helper-components/form/CustomForm.js";

import { getLanguage } from "../../../config/language/language.js";
import UserSecretForm from "./components/UserSecretForm.js";

/**
 * A page for users to modify settings related to their account.
 */
export default function Settings(props) {
    return (
        <Container maxWidth="xl" className="container">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomForm title={getLanguage().SETTINGS_TITLE} subtitle={getLanguage().SETTINGS_SUBTITLE} icon={<SettingsIcon />} />
                </Grid>

                <Grid item xs={12}>
                    <Divider>
                        <Chip label="Audio settings" variant="outlined" />
                    </Divider>
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomForm
                        title={getLanguage().NOTIFICATION_VOLUME_TITLE}
                        subtitle={getLanguage().NOTIFICATION_VOLUME_SUBTITLE}
                        icon={<EditNotificationsIcon />}
                        form={<NotificationSettingsForm {...props} />}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomForm
                        title={getLanguage().NOTIFICATION_SELECT_TITLE}
                        subtitle={getLanguage().NOTIFICATION_SELECT_SUBTITLE}
                        icon={<MusicNoteIcon />}
                        form={<NotificationSelectForm {...props} />}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider>
                        <Chip label="Account settings" variant="outlined" />
                    </Divider>
                </Grid>

                <Grid item xs={12} md={6}>
                    <CustomForm
                        title={getLanguage().USER_UPDATE_TITLE}
                        subtitle={getLanguage().USER_UPDATE_SUBTITLE}
                        icon={<AccountCircleIcon />}
                        form={<UserUpdateForm {...props} />}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Grid container xs={12}>
                        <CustomForm title={getLanguage().USER_SECRET_TITLE} subtitle={getLanguage().USER_SECRET_SUBTITLE} icon={<KeyIcon />} form={<UserSecretForm {...props} />} />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomForm
                            title={getLanguage().USER_DELETE_TITLE}
                            subtitle={getLanguage().USER_DELETE_SUBTITLE}
                            icon={<RemoveCircleIcon />}
                            form={<UserDeleteForm {...props} />}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
