import * as React from "react";

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from "@mui/material";

import CustomButton from "../input/CustomButton";

/**
 * A card consisting of a picture, some text and a few buttons.
 * Used for news articles.
 */
export default function MediaCard(props) {
    const { title, summary, source, sentiment, timePublished, url, urlTitle, fullClick, raised = false } = props;
    const { titleClassName = "", summaryClassName = "", sourceClassName = "", actionsClassName = "" } = props;
    const { contentHeight, bannerHeight, bannerImage, actions } = props;

    const image = bannerImage || `${process.env.PUBLIC_URL}/images/other/image_placeholder.png`;

    /**
     * Open the URL depending on whether it is an internal or external link.
     */
    const urlClick = () => {
        if (url.startsWith("http")) {
            window.open(url);
        } else {
            props.browser.navigate(url);
        }
    };

    /**
     * Calculate the width of the buttons based on the number of visible buttons.
     */
    const calculateWidth = (values) => {
        const numValues = values.filter((value) => value).length;
        return numValues > 0 ? `${100 / numValues}%` : "0%";
    };

    const width = calculateWidth([sentiment, timePublished, urlTitle]);

    const ActionComponent = fullClick ? CardActionArea : "div";

    return (
        <Card raised={raised}>
            <ActionComponent onClick={fullClick ? urlClick : null}>
                {bannerImage !== false && <CardMedia className="media-card" style={{ height: bannerHeight }} image={image} title={title} component="img" />}

                <CardContent className="card-content" style={{ height: contentHeight }}>
                    {title && (
                        <Typography className={`media-card-title ${titleClassName}`} variant="h7" component="div" gutterBottom>
                            {title}
                        </Typography>
                    )}

                    {summary && (
                        <Typography className={`media-card-summary ${summaryClassName}`} variant="body2" color="text.secondary">
                            {summary}
                        </Typography>
                    )}

                    {source && (
                        <Typography className={`media-card-source ${sourceClassName}`} variant="body2" color="text.secondary">
                            {source}
                        </Typography>
                    )}
                </CardContent>
            </ActionComponent>

            {(sentiment || timePublished || urlTitle || actions) && (
                <CardActions sx={{ width: "100%", justifyContent: "space-between" }}>
                    <CustomButton {...props} width={width} className={actionsClassName} title={sentiment} size="small" variant="text" hidden={!sentiment} disabled />
                    <CustomButton {...props} width={width} className={actionsClassName} title={timePublished} size="small" variant="text" hidden={!timePublished} disabled />
                    <CustomButton
                        {...props}
                        width={width}
                        className={actionsClassName}
                        title={urlTitle}
                        size="small"
                        variant="text"
                        hidden={!urlTitle || !url}
                        onClick={urlClick}
                    />
                    {actions}
                </CardActions>
            )}
        </Card>
    );
}
