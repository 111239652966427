import { createCriteriaParameters, standardDeleteQuery, standardGetQuery } from "../utils/misc/api.js";
import { setDeleteArrayState } from "../utils/misc/utils.js";
import { handleAddAlerts } from "../utils/asset/alert.js";
import { getCategory } from "../utils/asset/utils.js";

import * as Paths from "../../config/constants/Paths.js";
import * as Enums from "../../config/constants/Enums.js";
import { getLanguage } from "../../config/language/language.js";

/**
 * Request alerts' data from the server.
 *
 * Parameters:
 * apiPath: the path to the API
 * states: the states to set the data to (e.g. props.alerts.external.user)
 * Options: page, pageSize, filter, sort
 */
export const queryAlerts = (props, options = {}) => {
    const { path: apiPath, state } = options;

    const path = apiPath + createCriteriaParameters(options);

    standardGetQuery(props, path, (response) => {
        handleAddAlerts(props, state, response.body);

        if (options.page) state.setQueriedUntil(options.page);
        if (response.body.total) state.setRowCount(response.body.total);
    });
};

/**
 * Query all category alerts from the server.
 * TODO: query all alerts once with one DB request to avoid multiple requests?
 */
export const queryAllAlerts = (props, options) => {
    const { queryExternal = true, queryCombined = true } = options;
    const { queryOwner = false, querySubscribed = false } = options;

    const combinedCategory = getCategory(Enums.COMBINED_ALERT);
    const externalCategory = getCategory(Enums.EXTERNAL_ALERT);

    const externalAlerts = props.alerts.external;
    const combinedAlerts = props.alerts.combined;

    const { owner: externalOwner, subscribed: externalSubscribed } = externalAlerts;
    const { owner: combinedOwner, subscribed: combinedSubscribed } = combinedAlerts;

    if (queryExternal) {
        if (querySubscribed) queryAlerts(props, { ...options, path: externalCategory.paths.subscribed, state: externalSubscribed });
        if (queryOwner) queryAlerts(props, { ...options, path: externalCategory.paths.owner, state: externalOwner });
    }

    if (queryCombined) {
        if (querySubscribed) queryAlerts(props, { ...options, path: combinedCategory.paths.subscribed, state: combinedSubscribed });
        if (queryOwner) queryAlerts(props, { ...options, path: combinedCategory.paths.owner, state: combinedOwner });
    }
};

/**
 * Delete alerts' data from the server.
 * Body: [{ signalId, timestamp }, ...]
 */
export const deleteAlerts = (props, options = {}) => {
    const { path = Paths.API_ALERT_DELETE_PATH, state, body } = options;

    standardDeleteQuery(props, path, body, (response) => {
        const { setData, setRowCount, setSelected } = state;

        setSelected([]);
        setRowCount((prev) => prev - body.length);
        setDeleteArrayState(setData, body, { keys: ["signalId", "timestamp"] });
        props.showSnackbar({ message: getLanguage().SNACKBAR_SUCCESS_ALERT_DELETED });
    });
};
