import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

import PaperFragment from "./PaperFragment.js";
import TransparentFragment from "./TransparentFragment.js";
import ComponentLoader from "./ComponentLoader.js";

/**
 * Custom image component.
 * Allows for the display of a custom image, along with style options.
 */
export default function CustomImage(props) {
    const theme = useTheme();
    const borderColor = theme.palette.mode === "dark" ? "white" : "dark";

    const { id, border, p, m } = props;
    const { componentHeight, height, width, minHeight, minWidth, maxHeight, maxWidth } = props;
    const { url = `${process.env.PUBLIC_URL}/images/other/image_placeholder.png` } = props;
    const { contain = false, cover = false, center = true, scroll = false, transparent = false, subtitle = false, loaded = true } = props;

    let className = "";
    if (contain) className += "contain-image ";
    if (cover) className += "cover-image ";
    if (scroll) className += "scroll-image ";

    const [imageLoaded, setImageLoaded] = React.useState(false);
    const visible = imageLoaded && loaded;

    const Component = transparent ? TransparentFragment : PaperFragment;

    return (
        <>
            <ComponentLoader isVisible={!visible} width={props.loaderWidth} />

            <Component
                className={visible ? "loaded-image" : "non-loaded-image"}
                center={center}
                height={componentHeight}
                p={p}
                m={m}
                title={
                    <img
                        id={id}
                        src={url}
                        alt=""
                        className={className}
                        style={{ height, width, minHeight, minWidth, maxHeight, maxWidth, border: border ? border.replace("<theme>", borderColor) : null }}
                        onLoad={() => {
                            setImageLoaded(true);
                        }}
                    />
                }
                value={subtitle ? <Typography className="pre-line italic xs-margin sm-font">{subtitle}</Typography> : null}
            />
        </>
    );
}
