import * as React from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";

import CustomButton from "./CustomButton";

/**
 * A configurable confirmation dialog.
 * Automatically handles closing.
 *
 * Features:
 * title
 * message
 * action button color
 * action button title
 * action button callback w/ result and an optional obj
 */
export default function ConfirmationDialog(props) {
    const options = props.options;

    const { open, setOpen, title, message, setId } = options;
    const { actionColor = "secondary", actionTitle = "OK" } = options;
    const { cancelColor = "primary", cancelTitle = "Cancel" } = options;
    const { component, componentProps = {}, onClick, minHeight } = options;

    /**
     * Execute the callback and close the dialog.
     */
    const handleClick = (result) => {
        if (onClick) onClick(result);

        setId(null);
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={() => handleClick(false)} PaperProps={{ style: { minHeight } }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <>
                    {/* Allow to pass a message to the dialog. */}
                    {message}

                    {/* Also allow to pass a component to the dialog. */}
                    {/* Provide additional or modified props to the cloned element. */}
                    {component && <Divider className="sm-margin" />}
                    {component && React.cloneElement(component, { ...props, componentProps })}
                </>
            </DialogContent>

            {!component && (
                <DialogActions>
                    <CustomButton {...props} title={cancelTitle} onClick={() => handleClick(false)} color={cancelColor} variant="text" />
                    <CustomButton {...props} title={actionTitle} onClick={() => handleClick(true)} color={actionColor} variant="text" />
                </DialogActions>
            )}
        </Dialog>
    );
}
